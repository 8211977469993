import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAccounts, defaultValue } from 'app/shared/model/accounts.model';

const initialState: EntityState<IAccounts> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = `${SERVER_API_URL}api/accounts`;
// const countries = `${SERVER_API_URL}api/state`;
const countries = `${SERVER_API_URL}api/public/country/list`;
// const countryList = `${SERVER_API_URL}api/admin/master/countries`;
// const signatureList = `${SERVER_API_URL}api/accounts/sign`;
// const stateList = `${SERVER_API_URL}api/public/state/list`;

export const getEntities = createAsyncThunk('accounts/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IAccounts[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'accounts/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAccounts>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getCountryList = createAsyncThunk('accounts/fetch_country_list', async () => {
  try {
    const response = await axios.get(countries);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch country list');
  }
});

export const uploadDocument = createAsyncThunk<void, IAccounts>(
  'account/upload_document',
  async ({ accountId, file, docType }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const documentUploadUrl = `${SERVER_API_URL}api/document/${accountId}?doc_type=${docType}`;

      const response = await axios.post(documentUploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      thunkAPI.dispatch(getEntities({}));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const createEntity = createAsyncThunk(
  'accounts/create_entity',
  async (entity: IAccounts, thunkAPI) => {
    try {
      const result = await axios.post<IAccounts>(apiUrl, cleanEntity(entity));

      return result;
    } catch (error) {
      return error;
    }
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'accounts/update_entity',
  async (entity: any, thunkAPI) => {
    const data = cleanEntity(entity);
    const accountId = String(data?.accountId);
    // delete data?.accountId;
    const result = await axios.put<IAccounts>(`${apiUrl}/${accountId}`, data);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'accounts/partial_update_entity',
  async (entity: IAccounts, thunkAPI) => {
    const result = await axios.patch<IAccounts>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'accounts/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAccounts>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const companyAccountsSlice = createEntitySlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyLastLevel(state, action) {
      state.entity.last_level = action.payload;
    },
    reset(state) {
      state.entity = { ...defaultValue, last_level: 1 };
      state.signatureData = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data.last_level === 100) {
          state.entity = { ...defaultValue, last_level: 1 };
        } else {
          state.entity = { ...state.entity, ...action.payload.data };
        }
        return state;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = { ...defaultValue, last_level: 0 };
      })

      .addCase(uploadDocument.pending, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = { ...defaultValue, last_level: 0 };
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = { ...defaultValue, last_level: 0 };
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = { ...defaultValue, last_level: 0 };
      })

      .addCase(getCountryList.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = { ...defaultValue, country: action.payload };
      })

      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { setCompanyLastLevel,reset } = companyAccountsSlice.actions;

export default companyAccountsSlice.reducer;
