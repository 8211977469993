import './header.scss';
import React, { useEffect, useRef, useState } from 'react';
import {
  Navbar,
  Nav,
  NavbarToggler,
  Collapse,
  NavLink,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarBrand,
} from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Client, OpenCompany, OpenAccount, Brand, SignUp, Admin } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu } from '../menus';
import { Link, useLocation } from 'react-router-dom';
import SmartReserve2 from 'app/entities/smartReserve/SmartReserve2';
import { useAppSelector } from 'app/config/store';
export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
}

const Header = (props: IHeaderProps) => {
  const sidebarRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [investDropdownOpen, setInvestDropdownOpen] = useState(false);
  const [username, setUsername] = useState('');
  const dropdownRef = useRef(null);

  const [userType, setUserType] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      // Splitting the token into parts
      const tokenParts = accessToken.split('.');

      // Ensure token has required parts
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1]));

        // Check if realm_access exists in payload
        if (payload?.realm_access?.roles) {
          const { roles } = payload.realm_access;

          // Check if roles array exists and has elements
          if (Array.isArray(roles) && roles.length > 0) {
            const role = roles.indexOf('ROLE_ADMIN') > -1 ? 'ROLE_ADMIN' : 'ROLE_USER';



            if (role === 'ROLE_ADMIN') {
              setUserType('ROLE_ADMIN');
            } else if (role === 'ROLE_USER') {
              setUserType('ROLE_USER');
            }
          }
        }
      }
    }
  }, []);


  const renderDevRibbon = () =>
    !props.isInProduction && (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    );
  const [phone, setPhone] = useState();
  const USER_NAME = 'user-name';
  const PHONE_NUMBER = 'phone-number';
  useEffect(() => {
    setUsername(sessionStorage.getItem(USER_NAME));
  }, [sessionStorage.getItem(USER_NAME), sessionStorage.getItem('phone-number')]);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleInvestDropdown = () => setInvestDropdownOpen(!investDropdownOpen);

  const handleMouseOver = () => {
    setDropdownOpen(true);
    setInvestDropdownOpen(false);
  };
  const handleInvestMouseOver = () => {
    setInvestDropdownOpen(true);
    setDropdownOpen(false);
  };

  const handleInvestMouseLeave = () => {
    setTimeout(() => {
      if (dropdownRef.current && !dropdownRef.current.contains(document.activeElement)) {
        setInvestDropdownOpen(false);
      }
    }, 300);
  };

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  function handleClickOpenStarlightSmartReserve(event) {
    event.preventDefault();

    window.location.href = '/smart-reserve';

    window.scrollTo(0, 0);
  }

  function handleClickMarketFund(event) {
    event.preventDefault();

    window.location.href = '/smart-reserve#MarketFund';

    setTimeout(() => {
      const section = document.getElementById('MarketFund');
      if (section) {
        const offset = section.offsetTop;
        window.scrollTo({
          top: offset - 150,
          behavior: 'smooth',
        });
      }
    });
  }

  function handleClickAdvantage(event) {
    event.preventDefault();

    window.location.href = '/smart-reserve#Advantage';

    setTimeout(() => {
      const section = document.getElementById('Advantage');
      if (section) {
        const offset = section.offsetTop;
        window.scrollTo({
          top: offset - 150,
          behavior: 'smooth',
        });
      }
    });
  }

  function handleClickPortfolio(event) {
    event.preventDefault();

    window.location.href = '/smart-reserve#Portfolio';

    setTimeout(() => {
      const section = document.getElementById('Portfolio');
      if (section) {
        const offset = section.offsetTop;
        window.scrollTo({
          top: offset - 150,
          behavior: 'smooth',
        });
      }
    });
  }

  function handleClickTheSmartPortfolios(event) {
    event.preventDefault();

    window.location.href = '/StarlightSmartInvest';

    window.scrollTo(0, 0);
  }

  function handleClickHowItWorks(event) {
    event.preventDefault();

    window.location.href = '/StarlightSmartInvest#HowItWorks';

    setTimeout(() => {
      const section = document.getElementById('HowItWorks');
      if (section) {
        const offset = section.offsetTop;
        window.scrollTo({
          top: offset - 150,
          behavior: 'smooth',
        });
      }
    });
  }
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // useEffect(() => {
  //   // Add event listener to detect clicks outside the sidebar when it is open
  //   if (isSidebarOpen) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }
  //   // Cleanup event listener on component unmount or when sidebar closes
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [isSidebarOpen]);

  // const handleClickOutside = (event) => {
  //   // Check if the click is outside the sidebar
  //   if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //     setIsSidebarOpen(false); // Close the sidebar
  //   }
  // };

  const path_location = useLocation();
  const [selectedNav, setSelectedNav] = useState(false);


  useEffect(() => {
    if (path_location.pathname === "/accounts") {
      setSelectedNav(true)
    }
    else if (path_location.pathname === "/company") {
      setSelectedNav(true)
    }
    else if (path_location.pathname === "/") {
      setSelectedNav(true)
    }
    else if (path_location.pathname === "/user-profile") {
      setSelectedNav(true)
    }
    else if (path_location.pathname === "/terms-and-disclosures") {
      setSelectedNav(true)
    }
    else {
      setSelectedNav(false)
    }
  }, [path_location.pathname]);

  return (
    <div id="app-header">
      <Navbar color="faded" fixed="top" expand="xl" dark data-cy="navbar"
        className={`  ${
                    selectedNav ? "white-bg-custom" : "bg-dark"
                  }`}>
        <Brand selectedNav={selectedNav } />
        <NavbarToggler onClick={toggleNavbar} className="me-2 button-width-toggle" />
        <Collapse isOpen={!collapsed} navbar className="collapse-div-set">
          <div className="navbar-collapse-div">
            <Nav className={`  me-auto  nav-bar-li ${selectedNav ? "" : "bg-black"}`} navbar>
              {/* <Broker userName={username} userType={userType} /> */}
              {userType === 'ROLE_USER' && <Client userName={username} userType={userType} />}
              {userType === 'ROLE_ADMIN' && <Admin userName={username} userType={userType} />}
              {/* <OpenAccount userName={username} userType={userType} />
              <OpenCompany userName={username} userType={userType} /> */}
              <SignUp userName={username} userType={userType} />
              {props.isAuthenticated && <EntitiesMenu />}
              {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
            </Nav>
            {/* {userType === 'ROLE_USER' ? ( */}
            {/* <Nav className="me-auto bg-black nav-bar-li-bottom" navbar>
              <NavItem>
                <NavLink active href="/about" style={{ fontFamily: 'Montserrat', fontSize: '13px', fontWeight: 'bold' }}>
                  About US
                </NavLink>
              </NavItem>
              <NavItem className="display-none-li">
                <NavLink active href="#" style={{ fontFamily: 'Montserrat', fontSize: '13px' }}>
                  |
                </NavLink>
              </NavItem>
              <NavItem style={{ listStyle: 'none' }} onMouseOver={handleMouseOver}>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    style={{
                      background: 'black',
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '13px',
                      fontWeight: '200',
                      padding: 0,
                    }}
                  >
                    <Link to="/smart-reserve" className="dropdown--header-link-custon" style={{ fontWeight: 'bold' }}>
                      {' '}
                      Starlight Smart Reserve
                    </Link>
                  </DropdownToggle>
                  <DropdownMenu style={{ width: '190px' }}>
                    <DropdownItem
                      style={{ fontSize: '12px' }}
                      tag={Link}
                      to="/smart-reserve"
                      onClick={handleClickOpenStarlightSmartReserve}
                    >
                      Open Starlight Smart Reserve
                    </DropdownItem>
                    <DropdownItem style={{ fontSize: '12px' }}>
                      <a href="/smart-reserve#MarketFund" className="a-tag-font" onClick={handleClickMarketFund}>
                        What is a Money Market Fund?
                      </a>
                    </DropdownItem>
                    <DropdownItem style={{ fontSize: '12px' }}>
                      <a href="/smart-reserve#Advantage" className="a-tag-font" onClick={handleClickAdvantage}>
                        The Smart Reserve Advantage
                      </a>
                    </DropdownItem>
                    <DropdownItem style={{ fontSize: '12px' }}>
                      <a href="/smart-reserve#Portfolio" className="a-tag-font" onClick={handleClickPortfolio}>
                        The Smart Reserve Portfolio
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem className="display-none-li">
                <NavLink active href="#" style={{ fontFamily: 'Montserrat', fontSize: '13px' }}>
                  |
                </NavLink>
              </NavItem>
              <NavItem style={{ listStyle: 'none' }} onMouseOver={handleInvestMouseOver}>
                <Dropdown isOpen={investDropdownOpen} toggle={toggleInvestDropdown}>
                  <DropdownToggle
                    style={{
                      background: 'black',
                      border: 'none',
                      fontFamily: 'Montserrat',
                      fontSize: '13px',
                      fontWeight: '200',
                      padding: '0',
                    }}
                  >
                    <Link to="/StarlightSmartInvest" className="dropdown--header-link-custon" style={{ fontWeight: 'bold' }}>
                      Starlight Smart Invest
                    </Link>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      style={{ fontSize: '12px' }}
                      tag={Link}
                      to="/StarlightSmartInvest"
                      onClick={handleClickTheSmartPortfolios}
                    >
                      The Smart Portfolios
                    </DropdownItem>
                    <DropdownItem style={{ fontSize: '12px' }}>
                      <a href="/StarlightSmartInvest#HowItWorks" className="a-tag-font" onClick={handleClickHowItWorks}>
                        How it Works?
                      </a>
                    </DropdownItem>
                    <DropdownItem style={{ fontSize: '12px' }}>
                      <a href="/StarlightSmartInvest#Bellwether" className="a-tag-font">
                        Bellwether
                      </a>
                    </DropdownItem>
                    <DropdownItem style={{ fontSize: '12px' }}>
                      <a href="/StarlightSmartInvest#Euroscape" className="a-tag-font">
                        Euroscape
                      </a>
                    </DropdownItem>
                    <DropdownItem style={{ fontSize: '12px' }}>
                      <a href="/StarlightSmartInvest#Stars&Stripes" className="a-tag-font">
                        Stars and Stripes
                      </a>
                    </DropdownItem>
                    <DropdownItem style={{ fontSize: '12px' }}>
                      <a href="/StarlightSmartInvest#BigTech" className="a-tag-font">
                        Big Tech
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem className="display-none-li">
                <NavLink active href="#" style={{ fontFamily: 'Montserrat', fontSize: '13px' }}>
                  |
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/faq" style={{ fontFamily: 'Montserrat', fontSize: '13px', fontWeight: 'bold', color: 'white' }}>
                  FAQs
                </NavLink>
              </NavItem>
              <NavItem className="display-none-li">
                <NavLink active href="#" style={{ fontFamily: 'Montserrat', fontSize: '13px' }}>
                  |
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact" style={{ fontFamily: 'Montserrat', fontSize: '13px', fontWeight: 'bold', color: 'white' }}>
                  Contact Us
                </NavLink>
              </NavItem>
            </Nav> */}
            {/* ) : (
              <></>
            )} */}
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;