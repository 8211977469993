// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validated-fieldCustomLabel {
  content: "*";
  color: red;
  margin-right: 4px;
  font-weight: 400;
  font-family: "Montserrat";
}

.react-datepicker-wrapper {
  width: 100%;
  border: 1px solid #757575;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  color: #757575 !important;
  box-shadow: 0px 0.5px #c8c6c6;
  box-shadow: none;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper .customDatePickerWidth > div > div.react-datepicker__input-container .customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 100%;
  border-radius: 4px;
  border-width: 0px;
  height: 32px;
  padding: 0px 12px;
  font-family: "Montserrat";
  font-size: 16px;
}

::-moz-placeholder {
  color: #adafae;
  font-family: "Montserrat";
}

::placeholder {
  color: #adafae;
  font-family: "Montserrat";
}

.custom-invalid-feedback {
  color: red;
  font-weight: 600;
  font-size: 0.875em;
  font-family: "Montserrat";
}

.maskInput {
  width: 100%;
  height: 32px;
  font-family: "Montserrat";
  font-size: 16px;
}

.maskInput > input {
  width: 100%;
  border-radius: 4px;
  border-width: 0px;
  padding: 0px 12px;
  height: 32px;
  font-family: "Montserrat";
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/components/ValidatedDatePickerField/date-picker-field-style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;AACF;;AACA;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,yBAAA;EACA,6BAAA;EACA,gBAAA;AAEF;;AAAA;;EAIE,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,iBAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAAC;EACC,cAAA;EACA,yBAAA;AAGF;;AALC;EACC,cAAA;EACA,yBAAA;AAGF;;AAAA;EACE,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;AAGF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AAGF;;AADA;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AAIF","sourcesContent":[".validated-fieldCustomLabel {\n  content: '*';\n  color: red;\n  margin-right: 4px;\n  font-weight: 400;\n  font-family: 'Montserrat';\n}\n.react-datepicker-wrapper{\n  width: 100%;\n  border: 1px solid #757575;\n  border-radius: 4px;\n  background-color: #fff;\n  font-size: 16px;\n  color: #757575 !important;\n  box-shadow: 0px 0.5px #c8c6c6;\n  box-shadow: none;\n}\n.customDatePickerWidth,\n.customDatePickerWidth > div.react-datepicker-wrapper\n.customDatePickerWidth > div > div.react-datepicker__input-container\n.customDatePickerWidth > div > div.react-datepicker__input-container input {\n  width: 100%;\n  border-radius: 4px;\n  border-width: 0px;\n  height: 32px;\n  padding: 0px 12px;\n  font-family: 'Montserrat';\n  font-size: 16px;\n}::placeholder {\n  color: #adafae;\n  font-family: 'Montserrat';\n}\n\n.custom-invalid-feedback{\n  color: red;\n  font-weight: 600;\n  font-size: 0.875em;\n  font-family: 'Montserrat';\n}\n\n.maskInput {\n  width: 100%;\n  height: 32px;\n  font-family: 'Montserrat';\n  font-size: 16px;\n}\n.maskInput > input {\n  width: 100%;\n  border-radius: 4px;\n  border-width: 0px;\n  padding: 0px 12px;\n  height: 32px;\n  font-family: 'Montserrat';\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
