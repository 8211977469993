import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ErrorBoundary from 'app/shared/error/error-boundary';

import AppRoutes from './routes';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {


  return (
    <BrowserRouter basename={baseHref}>
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
