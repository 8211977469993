// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
  margin: 0;
  width: 100%;
  height: auto;
  font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
}

.section-1 {
  width: 100%;
  min-height: 400px;
  background-image: url("../../../content/images/contact_bg.png");
  color: white;
}

.section-2 {
  width: 100%;
  min-height: 80vh;
  background: #dcf2f1;
  color: black;
  font-size: 17px;
  font-weight: 400;
}

.contact-btn-style {
  background-color: #607980;
  border-color: #b6c1cd;
  border-width: 2px;
  font-size: 13px;
  color: white;
  padding: 2px 13px;
}

.contact-btn-style:hover {
  background-color: #e1eff2;
  font-size: 13px;
  color: rgb(0, 0, 0);
}

.contact-btn-style:disabled {
  background-color: #607980;
  color: black;
  cursor: not-allowed !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/accounts/contact.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,WAAA;EACA,YAAA;EACA,8DAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,+DAAA;EACA,YAAA;AACF;;AACA;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AAEF;;AACA;EACE,yBAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;AAEF;;AACA;EACE,yBAAA;EACA,eAAA;EACA,mBAAA;AAEF;;AACA;EACE,yBAAA;EACA,YAAA;EACA,8BAAA;AAEF","sourcesContent":[".contact-container {\n  margin: 0;\n  width: 100%;\n  height: auto;\n  font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;\n}\n\n.section-1 {\n  width: 100%;\n  min-height: 400px;\n  background-image: url('../../../content/images/contact_bg.png');\n  color: white;\n}\n.section-2 {\n  width: 100%;\n  min-height: 80vh;\n  background: #dcf2f1;\n  color: black;\n  font-size: 17px;\n  font-weight: 400;\n}\n\n.contact-btn-style {\n  background-color: #607980;\n  border-color: #b6c1cd;\n  border-width: 2px;\n  font-size: 13px;\n  color: white;\n  padding: 2px 13px;\n}\n\n.contact-btn-style:hover {\n  background-color: #e1eff2;\n  font-size: 13px;\n  color: rgb(0, 0, 0);\n}\n\n.contact-btn-style:disabled {\n  background-color: #607980;\n  color: black;\n  cursor: not-allowed !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
