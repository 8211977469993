
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { ValidatedField, logDebug } from 'react-jhipster';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { updateEntityProperty, uploadDocument } from '../accounts/accounts.reducer';
import Dropzone from 'app/modules/DropZone';
import animationData from '../../../content/lottie/upload_loader.json';
import { FileEarmarkMedicalFill, Trash } from 'react-bootstrap-icons';
import './company.scss';
import CommonToast from 'app/modules/components/CommonToast';
import Lottie from 'lottie-react';
import { updateEntity } from './company-accounts-reducer';
import { Form } from 'react-router-dom';
import ValidatedDatePickerField from 'app/modules/components/ValidatedDatePickerField';

interface AuthorizedPersonProp {
  onFormComplete: (completed: boolean) => void;
}
export interface AuthorizedPersonRef {
  syncAuthorizedPersonRefServer: () => Promise<any>;
}

const AuthorizedPeopleForm: React.ForwardRefRenderFunction<AuthorizedPersonRef, AuthorizedPersonProp> = ({ onFormComplete }: AuthorizedPersonProp, ref) => {

  const dispatch = useAppDispatch();
  // const accountsEntity = useAppSelector(state=>state.account.entities);

  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const [countryList, setCountryList] = useState<string[]>(mainAccountsEntity?.countryList || []);
  const [stateList, setStateList] = useState<string[]>(mainAccountsEntity?.statesList || []);
  const key_personData: any = localStorage.getItem('key_person_details')
  const parsed_data = JSON.parse(key_personData)
  const [passport_doc, setpassport_doc] = useState<{ [key: string]: string }>({});
  const [isUploadingOne, setIsUploadingOne] = useState({});
  const [isUploadingTwo, setIsUploadingTwo] = useState({});
  const [validationError, setvalidationErrors] = useState<{ [key: number]: string }>({});
  const [proof_of_address, setproof_of_address] = useState<{ [key: number]: string }>({});
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [validatePhoneError, setValidationPhoneError] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [total, setTotal] = useState(0);
  const [authorizedPerson, setSecondaryButton] = useState<boolean>(false);
  const uthorizedpersonRef = useRef<{ syncSecondaryDetailsWithServer: () => Promise<any> }>(null);

  const [emailError, setEmailError] = useState('');
  const [numberOfForms, setNumberOfForms] = useState(1);


  const clearFileRef = useRef()
  interface IValidationErrors {
    [key: string]: string;
  }
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
  const [key_person_details, setkey_person_details] = useState({
    authorized_person_details: [
      {
        name: '',
        role: '',
        firstname: '',
        lastname: '',
        passport_number: '',
        company_email_address: '',
        mobile_number: '+3538',
        home_address: '',
        account_number: '',
        customer_id: '',
        date_of_bith: '',
        citizenship: '',
        issued_country: '',
        passport_expiry_date: '',
        country_of_birth_and_citizen: '',
        pps_number: '',
        country_of_tax_residency: '',
        owns_more_25_of_company: false,
        source_of_person_wealth: '',
        passport_doc: '',

        address: {
          country: 'Ireland',
          state: ' ',
          city: '',
          postal_code: '',
          street: '',
        },
        proof_of_address: '',

      }
    ]
  });
  const [companyAddressDetails, setCompanyAddressDetails] = useState({

    accountHolderMobileNumber: '+3538',
    state_or_province: ' ',
    townorcity: '',
    country: 'Ireland',
    postcode: '',
    addressLine1: '',

  });
 

  // const[country,setCountry]=useState<any>


  useImperativeHandle(ref, () => ({
    syncAuthorizedPersonRefServer
  }));



  const syncAuthorizedPersonRefServer = (): Promise<any> => {
    const _errors: IValidationErrors = {};
    const minAllowedDate = getFifteenDaysFromToday();

    mainAccountsEntity?.company_details?.authorized_person_details?.forEach((person, index) => {
      if (!person.passport_number) {
        _errors[`authorized_person_details_${index}_passport_number`] = 'This field is mandatory';
      }
      if (!person?.company_email_address) {
        _errors[`authorized_person_details_${index}_company_email_address`] = 'This field is mandatory';
      }
      if (!person.mobile_number || person.mobile_number === '+3538' || person.mobile_number.length !== 13) {
        _errors[`authorized_person_details_${index}_mobile_number`] = 'Please enter a valid mobile number starting with +3538 and up to 8 digits.';
      }
      if (emailError !== '') {
        _errors.emailError = emailError;
      }
      if (!person.date_of_bith) {
        _errors[`authorized_person_details_${index}date_of_bith`] = 'This field is mandatory';
      } 
      // else {
      //   if (!isValidDate(person.date_of_bith)) {
      //     _errors[`authorized_person_details_${index}date_of_bith`] = "Enter a valid date.";
      //   } else if (!isOver18(person.date_of_bith)) {
      //     _errors[`authorized_person_details_${index}date_of_bith`] = "You must be 18 years old or older.";
      //   } else if (!isValidYearLength(person.date_of_bith)) {
      //     _errors[`authorized_person_details_${index}date_of_bith`] = "Enter a valid four-digit year.";
      //   }
      // }

      if (!person.passport_expiry_date) {
        _errors[`authorized_person_details_${index}passport_expiry_date`] = 'This field is mandatory';
      } 
      else if (new Date(person.passport_expiry_date) < new Date(minAllowedDate)) {
        _errors[`authorized_person_details_${index}passport_expiry_date`] = `Passport expiry date must be at least 15 days from today.`;
      }
      if (!person.pps_number) {
        _errors[`authorized_person_details_${index}pps_number`] = 'This field is mandatory';
      }
      if (!person.home_address) {
        _errors[`authorized_person_details_${index}home_address`] = 'This field is mandatory';
      }

      if (!person.passport_doc) {
        _errors[`authorized_person_details_${index}passport_doc`] = 'Passport doc is mandatory';
      }
      if (!person.proof_of_address) {
        _errors[`authorized_person_details_${index}proof_of_address`] = 'Proof of address is mandatory';
      }

      if (!person?.address?.city) {
        _errors[`authorized_person_details_${index}_address_city`] = 'City is mandatory';
      }      
      if (!person?.address?.state) {
        _errors[`authorized_person_details_${index}_address_state`] = 'County is mandatory';
      }

      if (!person?.address?.postal_code) {
        _errors[`authorized_person_details_${index}_address_postal_code`] = 'Post Code is mandatory';
      }
 
      // if (!person?.address?.street) {
      //   _errors[`authorized_person_details_${index}_address_street`] = 'Street is mandatory';
      // }
    });

    if (Object.keys(_errors).length > 0) {
      setValidationErrors(_errors);
      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);
      return;
    }
    setValidationErrors({});

    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 4 }));

  };




  useEffect(() => {
    if (mainAccountsEntity) {
      setCompanyAddressDetails(prevState => ({
        ...prevState,
        state_or_province: companyAddressDetails?.state_or_province || 'IE-MN',
      }));
 
      const updatedProperties = {
        state_or_province: mainAccountsEntity?.state_or_province  ,
        issued_country: mainAccountsEntity?.issued_country || 'Ireland',
 
        company_details: {
          ...mainAccountsEntity.company_details,
          authorized_person_details: mainAccountsEntity?.company_details?.authorized_person_details?.map((person, i) => {
            const keyPersonDetail = key_person_details?.authorized_person_details[i];
            const defaultCountryOfBirth = 'Ireland';
            const defaultCountryOfTaxResidency = 'Ireland';
            const countryOfBirth = person?.country_of_birth_and_citizen || keyPersonDetail?.country_of_birth_and_citizen || defaultCountryOfBirth;
            const countryOfTaxResidency = person?.country_of_tax_residency || keyPersonDetail?.country_of_tax_residency || defaultCountryOfTaxResidency;
            // const Citizenship =  keyPersonDetail?.citizenship ||defaultCountryOfTaxResidency ;
            const issuedCountry = person?.issued_country || keyPersonDetail?.issued_country || defaultCountryOfTaxResidency;
            const mobileNumber = (keyPersonDetail?.mobile_number && keyPersonDetail.mobile_number.length > 12)
            ? keyPersonDetail.mobile_number
            : (person?.mobile_number && person.mobile_number.length > 12)
            ? person.mobile_number
            : null;
            return {
              ...person,
              role: keyPersonDetail?.role || person?.role ||
                (parsed_data && parsed_data.authorized_person_details && parsed_data.authorized_person_details[i]
                  ? parsed_data.authorized_person_details[i].role
                  : undefined),
              country_of_birth_and_citizen: (keyPersonDetail?.country_of_birth_and_citizen === '' || keyPersonDetail?.country_of_birth_and_citizen == null)
              ? (person?.country_of_birth_and_citizen || defaultCountryOfBirth) 
              : keyPersonDetail?.country_of_birth_and_citizen,
              country_of_tax_residency: (keyPersonDetail?.country_of_tax_residency === '' || keyPersonDetail?.country_of_tax_residency == null)
              ? (person?.country_of_tax_residency || defaultCountryOfTaxResidency) 
              : keyPersonDetail?.country_of_tax_residency,
              issued_country: (keyPersonDetail?.issued_country === '' || keyPersonDetail?.issued_country == null)
              ? (person?.issued_country || 'Ireland') 
              : keyPersonDetail?.issued_country,
          
          
              citizenship:  (keyPersonDetail?.citizenship === '' || keyPersonDetail?.citizenship == null)
              ? (person?.citizenship || 'Ireland') 
              : keyPersonDetail?.citizenship,
        
              passport_number: keyPersonDetail?.passport_number || person?.passport_number,
              company_email_address: keyPersonDetail?.company_email_address || person?.company_email_address,
              mobile_number:mobileNumber,
              home_address: keyPersonDetail?.home_address || person?.home_address,
              account_number: keyPersonDetail?.account_number || person?.account_number,
              customer_id: keyPersonDetail?.customer_id || person?.customer_id,
              date_of_bith: keyPersonDetail?.date_of_bith || person?.date_of_bith,
              passport_expiry_date: keyPersonDetail?.passport_expiry_date || person?.passport_expiry_date,
              pps_number: keyPersonDetail?.pps_number || person?.pps_number,
              proof_of_address: keyPersonDetail?.proof_of_address || person?.proof_of_address,
              // country_of_birth_and_citizen: countryOfBirth,

              owns_more_25_of_company: keyPersonDetail?.owns_more_25_of_company ,
              source_of_person_wealth: keyPersonDetail?.source_of_person_wealth || person?.source_of_person_wealth,
              passport_doc: keyPersonDetail?.passport_doc || person?.passport_doc,
              address: {
                ...person.address,
                country: keyPersonDetail?.address?.country || person.address?.country || 'Ireland',
                street: keyPersonDetail?.address?.street || person?.address?.street,
                city: keyPersonDetail?.address?.city || person.address?.city,
                postal_code: keyPersonDetail?.address?.postal_code || person?.address?.postal_code,
                state: keyPersonDetail?.address?.state || person?.address?.state ,
 
              }
            };
 
          })
        }
      };
 
      const updatedMainAccountsEntity = {
        company_details: {
          ...mainAccountsEntity.company_details,
          authorized_person_details: updatedProperties.company_details.authorized_person_details,
        },
      };
      
      // // Dispatch action to update Redux state without resetting other parts
      dispatch(updateEntityProperty(updatedMainAccountsEntity));
      // dispatch(updateEntityProperty({ ...mainAccountsEntity, updatedProperties }));

 
    }
  }, [key_person_details,dispatch]);  

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    getFieldState,
    formState: {isValid },
  } = useForm({ mode: 'onChange' });
  
  
  useEffect(() => {
    setTotal(Number(sessionStorage.getItem('totalpeople')))
  }, []);
  const [minDate, setMinDate] = useState('');
  useEffect(() => {
    setMinDate(getFifteenDaysFromToday());
  }, []);
  const getFifteenDaysFromToday = () => {
    const today = new Date();
    today.setDate(today.getDate() + 15);
    return today.toISOString().split('T')[0];
  };

  const clearValidationError = (field: string) => {
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handlePassportFileUploaded = (file: File, docType: string, index: number) => {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }
    if (!mainAccountsEntity.accountId) {
      console.error('No accountId found');
      return;
    }

    setIsUploadingTwo((prev) => {
      return {
        ...prev,
        [index]: true
      }
    }
    );

    dispatch(
      uploadDocument({
        accountId: mainAccountsEntity.accountId,
        file,
        docType,
      })
    )
      .then((response: any) => {
        if (response.payload?.data) {
          const uploadedDocument = response.payload.data;
          setpassport_doc(prevState => ({
            ...prevState,
            ["doc" + index]: uploadedDocument
          }));


          setkey_person_details(prevState => {
            const updatedDetails = [...prevState.authorized_person_details];

            updatedDetails[index] = {
              ...updatedDetails[index],
              passport_doc: uploadedDocument

            };

            return {
              ...prevState,
              authorized_person_details: updatedDetails
            };

          });
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
        } else if (response?.payload?.response?.status===400) {
          console.error('Response payload is undefined');
           
           setToastMessage((prev) => 'Document upload failed!!');
          setToastType('error');
          setToastVisible(true);
        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Failed!! Document size is more than 2MB');
          setToastType('error');
          setToastVisible(true);
        }
    
        setIsUploadingTwo(false);
      })
      .catch(error => {
        console.error(`Error uploading ${docType}:`, error);
      })
      .finally(() => {
        setIsUploadingTwo((prev) => {
          return {
            ...prev,
            [index]: false
          }
        }
        );
      });
  };


  const findCountryNameByKey = (key: string | null | undefined): string => {
    const country: any = countryList.find((obj: any) => obj.name === key);


    return country ? country.name : '';
  };

  const findStateNameByKey = (key: string | null | undefined): string => {
    const state: any = stateList?.find((obj: any) => obj?.code === key);


    return state ? state.name : '';
  };
  const handleNewDeleteFile = (index, docType) => {

    const updatedDetails = [...key_person_details.authorized_person_details];

    // Check which document type to delete
    if (docType === 'PASSPORT') {
      updatedDetails[index] = {
        ...updatedDetails[index],
        passport_doc: '', // Remove the passport document
      };
    } else if (docType === 'PROOF_OF_ADDRESS') {
      updatedDetails[index] = {
        ...updatedDetails[index],
        proof_of_address: '',
      };
    }

    const updatedMainAccountsEntity = {
      ...mainAccountsEntity,
      company_details: {
        ...mainAccountsEntity.company_details,
        authorized_person_details: updatedDetails,
      },
    };
    setkey_person_details(prevState => {
      return {
        ...prevState,
        authorized_person_details: updatedDetails,
      };
    });
    // Dispatch action to update Redux state without resetting other parts
    dispatch(updateEntityProperty(updatedMainAccountsEntity));

  };

  const handleFileUploaded = (file: File, docType: string, index: number) => {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }
    setIsUploadingOne((prev) => {
      return {
        ...prev,
        [index]: true
      }
    }
    );
    dispatch(
      uploadDocument({
        accountId: mainAccountsEntity?.accountId,
        file,
        docType,
      })
    )
      .then((response: any) => {
        if (response.payload?.data) {
          const uploadedFile = response.payload?.data;
          setproof_of_address(prevState => ({
            ...prevState,
            ["doc" + index]: uploadedFile
          }));
          setkey_person_details(prevState => {
            const updatedDetails = [...prevState.authorized_person_details];
            updatedDetails[index] = {
              ...updatedDetails[index],
              proof_of_address: uploadedFile
            };
            return {
              ...prevState,
              authorized_person_details: updatedDetails
            };
          });
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
        }else if (response?.payload?.response?.status===400) {
          console.error('Response payload is undefined');
          setToastMessage((prev) => 'Document upload failed!!');
          setToastType('error');
          setToastVisible(true);
          setIsUploadingOne(false);
        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Failed!! Document size is more than 2MB');
          setToastType('error');
          setToastVisible(true);
        }
    
        setIsUploadingOne(false);
      })
      .catch(error => {
        console.error(`Error uploading ${docType}:`, error);
      })
      .finally(() => {
        setIsUploadingOne(false);
      });
  };



  const handleDeleteFile = (index: number) => {
    setproof_of_address((prev) => {
      const newUploadedFile = { ...prev };
      delete newUploadedFile["doc" + index];
      return newUploadedFile;
    });

    setIsUploadingOne(false);
  };

  const handleExperienceCheckboxChange = (role, index, checked) => {
    const updatedDetails: any[] = [...(key_person_details.authorized_person_details || [{}])];
    if (checked) {
      updatedDetails[index] = { ...updatedDetails[index], role };
    } else {
      updatedDetails[index] = { ...updatedDetails[index], role: '' };
    }
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const handleStreetChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            street: value,
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });

    clearValidationError(`authorized_person_details_${index}_address_street`);

  };

  const handlePostCodeChange = (index, event) => {
    const { value } = event.target;
    const value_Upper = value.toUpperCase();
    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            postal_code: value_Upper,
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
    // const updatedMainAccountsEntity = {
    //   ...mainAccountsEntity,
    //   company_details: {
    //     ...mainAccountsEntity.company_details,
    //     authorized_person_details: updatedDetails,
    //   },
    // };


    // dispatch(updateEntityProperty(updatedMainAccountsEntity));
    clearValidationError(`authorized_person_details_${index}_address_postal_code`);
  };

  const handleCityChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            city: value,
            country: detail.address?.country ?? 'Ireland',
            state: detail.address?.state ?? 'Galway',
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
    // const updatedMainAccountsEntity = {
    //   ...mainAccountsEntity,
    //   company_details: {
    //     ...mainAccountsEntity.company_details,
    //     authorized_person_details: updatedDetails,
    //   },
    // };

    // // Dispatch action to update Redux state without resetting other parts
    // dispatch(updateEntityProperty(updatedMainAccountsEntity));
    clearValidationError(`authorized_person_details_${index}_address_city`);

  };

  const handleStateChange = (index, event) => {
    const newState = event.target.value;
    const { value } = event.target;
 
    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
 
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            state: value,
          },
        };
      }
      return detail;
    });

    const updatedKeyPersonDetails = {
      ...key_person_details,
      authorized_person_details: updatedDetails,
    };
  
    setkey_person_details(updatedKeyPersonDetails);
  
    localStorage.setItem('key_person_details', JSON.stringify(updatedKeyPersonDetails));
    dispatch(updateEntityProperty(updatedKeyPersonDetails));

    if (newState.trim() !== "") {
      setValidationErrors(prevErrors => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[`authorized_person_details_${index}_address_state`];
        return updatedErrors;
      });
    }
 
  };

  useEffect(() => {

  localStorage.setItem('key_person_details', JSON.stringify(mainAccountsEntity.company_details));
    const storedData = localStorage.getItem('key_person_details');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setkey_person_details(parsedData);
    }
  }, []);

  const handleCountryChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            country: value,
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  const isOver18 = dob => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1 >= 18;
    }
    return age >= 18;
  };

  // const isValidYearLength = date => {
  //   if (!date) return true;
  //   const year = new Date(date).getFullYear().toString();
  //   return year.length === 4;
  // };
  
  // const isValidDate = date => {
  //   const [year, month, day] = date.split('-').map(Number);
  
  //   // Basic validation to check if year, month, and day are valid numbers
  //   if (isNaN(year) || isNaN(month) || isNaN(day)) {
  //     return false;
  //   }
  
  //   // Check if the month is between 1 and 12
  //   if (month < 1 || month > 12) {
  //     return false;
  //   }
  
  //   // Create a date object and check if the day matches (to prevent invalid dates like April 31)
  //   const parsedDate = new Date(year, month - 1, day);
  //   return parsedDate.getFullYear() === year &&
  //          parsedDate.getMonth() === month - 1 &&
  //          parsedDate.getDate() === day;
  // };
  
  

  const handleEmailChange = (index, event) => {
    const { value } = event.target;
    // const company_email_address = e.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (value === '') {
      setEmailError('');
    }
    if (!emailPattern.test(value)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], company_email_address: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });

    clearValidationError(`authorized_person_details_${index}_company_email_address`);

  };
  type PassportExpiryErrorType = {
    passport_expiry_date?: string;
  };
  const [passport_expiry_date, setPassport_expiry_date] = useState<string>(mainAccountsEntity?.company_details?.passport_expiry_date || '');
  const [passportexpiryError, setPassportExpiryError] = useState<PassportExpiryErrorType>({});

  const handlePassportExpiry = (index, event) => {
    const { value } = event.target; 
    const today = new Date(); 
    const minAllowedDate = new Date(getFifteenDaysFromToday()); // The date 15 days from today
    const enteredDate = new Date(value);

    let hasError = false;
  
    const updatedDetails:any[] = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];
  
    if (!updatedDetails[index]) {
      updatedDetails[index] = {};
    }
  
    updatedDetails[index] = { ...updatedDetails[index], passport_expiry_date: value };
  
    const enteredYear = enteredDate.getFullYear();    
      if (enteredYear < today.getFullYear()) {
        setValidationErrors((prev) => ({
          ...prev,
          [`authorized_person_details_${index}passport_expiry_date`]: 'The year must not be in the past.',
        }));
        hasError = true;
      } else {
        clearValidationError(`authorized_person_details_${index}passport_expiry_date`);
      }
   
  
      if (!hasError && enteredDate < minAllowedDate) {
        setValidationErrors((prev) => ({
          ...prev,
          [`authorized_person_details_${index}passport_expiry_date`]: 'Passport expiry date must be at least 15 days from today.',
        }));
        hasError = true;
      } else {
        clearValidationError(`authorized_person_details_${index}passport_expiry_date`);
      }
   
  
    if (!hasError) {
      setkey_person_details({
        ...key_person_details,
        authorized_person_details: updatedDetails,
      });
    }
  };
  

  const handleRoleName = (index, event) => {
    const { value } = event.target;
    // const company_email_address = e.target.value;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], firstname: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });

    clearValidationError(`authorized_person_details_${index}_company_email_address`);

  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, i: any) => {
    const { name, value } = e.target;

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: key_person_details.authorized_person_details.map((person, index) => {
        if (index === i) { // Assuming 'i' is defined somewhere in your component
          return {
            ...person,
            [name]: value,
          };
        }
        return person;
      }),
    });
  };


  const handleMobileNumberChange = (index, event) => {
    const input = event.target.value;
    // Ensure the input starts with +353
    if (!input.startsWith('+353')) {
      setValidationPhoneError('Mobile number must start with +3538');
      return;
    }

    const value = input.replace(/[^\d+]/g, '');
    const mobile_number = value.slice(0, 13);

    let lclValidationError = '';
    if (!/^(\+353)\d{9}$/.test(mobile_number)) {
      lclValidationError = 'Please enter a valid mobile number starting with +3538 and followed by 8 digits.';
    } else if (value.length < 13) {
      lclValidationError = 'Mobile number should have 13 digits.';
    }
    setValidationPhoneError(lclValidationError);

    if (mobile_number.length === 13) {
      event.target.value = mobile_number;
      event.preventDefault();

    }

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], mobile_number };
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });

    if (mobile_number.length === 13) {
      clearValidationError(`authorized_person_details_${index}_mobile_number`);
    }
  };

  // const handleMobileNumberChange = (index, event) => {
  //   const input = event.target.value;
  //   // Ensure the input starts with +353
  //   if (!input.startsWith('+353')) {
  //     setValidationPhoneError('Mobile number must start with +353');
  //     return;
  //   }

  //   const value = input.replace(/[^\d+]/g, '');
  //   const mobile_number = value.slice(0, 13);

  //   let lclValidationError = '';
  //   if (!/^(\+353)\d{9}$/.test(mobile_number)) {
  //     lclValidationError = 'Please enter a valid mobile number starting with +353 and followed by 9 digits.';
  //   } else if (value.length < 13) {
  //     lclValidationError = 'Mobile number should have 13 digits.';
  //   }
  //   setValidationPhoneError(lclValidationError);

  //   if (mobile_number.length === 13) {
  //     event.target.value = mobile_number;
  //     event.preventDefault();
  //   }

  //   const updatedDetails = key_person_details.authorized_person_details
  //     ? [...key_person_details.authorized_person_details]
  //     : [];

  //   updatedDetails[index] = { ...updatedDetails[index], mobile_number };
  //   setkey_person_details({
  //     ...key_person_details,
  //     authorized_person_details: updatedDetails,
  //   });

  //   if (mobile_number.length === 13) {
  //     clearValidationError(`authorized_person_details_${index}_mobile_number`);
  //   }
  // };


  // const handleMobileNumberChange = (index, event) => {
  //   const value = event.target.value.replace(/[^\d+]/g, '');
  //   const mobile_number = value.slice(0, 13);

  //   let lclValidationError = '';
  //   if (!/^(\+353)\d{9}$/.test(mobile_number)) {
  //     lclValidationError = 'Please enter a valid mobile number starting with +353 and followed by 9 digits.';
  //   } else if (value.length < 13) {
  //     lclValidationError = 'Mobile number should have 13 digits.';
  //   }
  //   setValidationPhoneError(lclValidationError);
  //   if (mobile_number.length === 13) {
  //     event.target.value = mobile_number;
  //     event.preventDefault();

  //   }
  //   const updatedDetails = key_person_details.authorized_person_details
  //     ? [...key_person_details.authorized_person_details]
  //     : [];

  //   updatedDetails[index] = { ...updatedDetails[index], mobile_number };
  //   setkey_person_details({
  //     ...key_person_details,
  //     authorized_person_details: updatedDetails,
  //   });
  //   if (mobile_number.length === 13) {

  //     clearValidationError(`authorized_person_details_${index}_mobile_number`);
  //   }
  // };

  const handleAddressChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], home_address: value };
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });

    clearValidationError(`authorized_person_details_${index}home_address`);

  };
  const handleCountryofBirthChange = (index, event) => {

    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], country_of_birth_and_citizen: value };



    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });




  };

  const handleTaxResidencyChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], country_of_tax_residency: value };
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
    clearValidationError(`authorized_person_details_${index}country_of_tax_residency`);

  };
  const handleCountryIssuedChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], issued_country: value };
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
    clearValidationError(`authorized_person_details_${index}country_of_tax_residency`);

  };
  const handleCountryOfCitizenshipChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], citizenship: value };
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
    clearValidationError(`authorized_person_details_${index}citizenship`);

  };
  const [ppsError, setPpsError] = useState('');
  const PPS_REGEX = /^\d{7}[A-Z]{1,2}$/;


  // const handlePPSNumberChange = (index, event) => {
  //   const { value } = event.target;

  //   const updatedDetails = key_person_details.authorized_person_details
  //     ? [...key_person_details.authorized_person_details]
  //     : [];

  //   updatedDetails[index] = { ...updatedDetails[index], pps_number: value };

  //   setkey_person_details({
  //     ...key_person_details,
  //     authorized_person_details: updatedDetails,
  //   });
  //   if (PPS_REGEX.test(value)) {
  //     setPpsError('');
  //   } else {
  //     clearValidationError(`authorized_person_details_${index}pps_number`);

  //   }
  //   setPpsError('Invalid PPS number format. Expected format: 7 digits followed by 1 or 2 uppercase letters.');

  // };

  const handlePPSNumberChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], pps_number: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
    // dispatch(updateEntityProperty({...mainAccountsEntity,authorized_person_details:key_person_details.authorized_person_details}))


    if (PPS_REGEX.test(value)) {
      setPpsError('');
    } else {
      setPpsError('Invalid PPS number format. Expected format: 7 digits followed by 1 or 2 uppercase letters.');
      clearValidationError(`authorized_person_details_${index}pps_number`);
    }
  };


  const handlePassportNumberChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], passport_number: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
    clearValidationError(`authorized_person_details_${index}_passport_number`);
  };
  const handleOwnsCompanyChange = (index, event) => {
    const { value } = event.target;
  
    const isTrue = value === 'true';
  
    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];
  
    updatedDetails[index] = {
      ...updatedDetails[index],
      owns_more_25_of_company: isTrue
    };
  
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };


  const handlePersonalWealthChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], source_of_person_wealth: value };
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const handleKeyDown = (event) => {
    const allowedKeys = /[0-9+\b]/;
    const value = event.target.value;

    if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
      event.preventDefault();
    }

    if (value.length >= 13 && event.keyCode !== 8 && !(event.keyCode === 8)) {
      event.preventDefault();
    }

    if (event.key === '+' && value.length !== 0) {
      event.preventDefault();
    }
    if ((event.key === 'Backspace' || event.keyCode === 8) && event.target.selectionStart <= 5) {
      event.preventDefault();
    }
  };

  const {
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });
  const [validationDateError, setValidationDateError] = useState('');

  const handleDateOfBirthChange = (index, event) => {
    const { value } = event.target;
    const updatedDetails: any[] = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];
    if (!updatedDetails[index]) {
      updatedDetails[index] = {};
    }

    updatedDetails[index] = { ...updatedDetails[index], date_of_bith: value };
    const error = isOver18(value) ? '' : 'You must be 18 years old or older.';
    setvalidationErrors(prev => ({ ...prev, [index]: error }));
    if (!isOver18(value)) {
      setValidationDateError('You must be 18 years old or older.');
      return;
    }
    setValidationDateError('');
    setValue(`authorized_person_details_${index}date_of_bith`, value);
    trigger(`authorized_person_details_${index}date_of_bith`);
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });

    clearValidationError(`authorized_person_details_${index}date_of_bith`);
  };

  // key_person_details,
  // setkey_person_details,
  // emailError,
  // setEmailError,
  // validatePhoneError,
  // setValidationPhoneError,
  // numberOfForms,
  // countryList,
  // stateList,
  // clearValidationError,
  // validationErrors,
  // setNumberOfForms,
  const renderForms = () => {
    const forms = [];
    const roles = [
      { name: 'Company Authorised Person', role: 'AUTHORIZED OFFICER' },
      { name: 'Director', role: 'DIRECTOR' },

      { name: 'Beneficial Owner', role: 'BENIFICIAL OWNERS' },
      { name: 'Approval Authority', role: 'APPROVAL AUTHORITY' },
    ];

    for (let i = 0; i < (total || numberOfForms); i++) {
      const formRole = roles[i % roles.length];  // Rotate through roles array
      const formName = formRole.name;
      const currentRoles = mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.role || [];

      forms.push(
        <>
          <div key={i}>
              <Col md="12" className="form-key-person-account-head-custom py-2">
                <span className="form-key-person-account-head-custom">{`Key Person ${i + 1}`}</span>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label className="label-account-class text-left">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                    <span>{`Name`}</span>

                  </Label>
                  <Input
                    type="text"
                    name="companyAuthorisedPersonFullName"
                    id={`fullname-${i}`}
                    className="custom-from-input"
                    placeholder="Enter full name"
                    defaultValue={`${mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.firstname || parsed_data?.authorized_person_details?.[i]?.firstname || key_person_details.authorized_person_details?.[i]?.firstname || ''} ${mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.lastname ||key_person_details?.authorized_person_details?.[i]?.lastname || parsed_data?.authorized_person_details?.[i]?.lastname}`.trim()}
                    onChange={(event) => handleRoleName(i, event)}

                  // onChange={(e) => {
                  //   const [first, ...last] = e.target.value.split(' ');

                  //   setkey_person_details((prevState) => ({
                  //     ...prevState,
                  //     authorized_person_details: prevState.authorized_person_details.map((person, idx) =>
                  //       idx === i ? { ...person, firstname: first, lastname: last.join(' ') } : person
                  //     ),
                  //   }));
                  // }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
            <Label className="label-account-class text-left">
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <span>Roles in the Company</span>
            </Label>
              {roles.map((checkbox, idx) => (
                <Col md="12" key={idx}>
                  <FormGroup check>
                    <Label className="label-account-class" check>
                      <Input
                        type="checkbox"
                        readOnly={true}
                        disabled
                        name={checkbox.role}
                        className="custom-from-input-readonly"
                        checked={currentRoles.includes(checkbox.role)}
                        onChange={(e) => handleExperienceCheckboxChange(checkbox.role, i, e.target.checked)}
                      />{' '}
                      {checkbox.name}
                    </Label>
                  </FormGroup>
                </Col>
              ))}
          </Col>
              <Col md="12">
                <FormGroup>
                  <Label className="label-account-class text-left">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <span>Company Email Address</span>
                  </Label>
                  <Input
                    type="text"
                    name="companyEmail"
                    id="companyEmail"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.company_email_address || key_person_details.authorized_person_details[i]?.company_email_address}
                    onChange={(event) => handleEmailChange(i, event)}
                    className="custom-from-input"
                  />
                </FormGroup>
                {validationErrors[`authorized_person_details_${i}_company_email_address`] && (
                  <div className="text-danger">
                    {validationErrors[`authorized_person_details_${i}_company_email_address`]}
                  </div>
                )}
                {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
              </Col>

           <Col md="12">
                <FormGroup>
                  <Label className="label-account-class text-left">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
 
                    <span>Mobile Number</span>
                  </Label>
                  {/* <ValidatedField
                    name="phone"
                    placeholder="+353 Enter your mobile number"
                    required
                    className="validated-custom-from-input"
                    data-cy="mobile number"
                    validate={{
                      required: 'Mobile number cannot be empty!',
                      pattern: {
                        value: /^\+353\d{0,9}$/,
                        message: 'Please enter a valid mobile number starting with +353 and followed by up to 9 digits.',
                      },
                    }}
                    onChange={(event) => handleMobileNumberChange(i, event)}
                    error={errors.phone as FieldError}
                    style={{ width: '100%', padding: '3px 12px' }}
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details[i]?.mobile_number ||key_person_details.authorized_person_details[i]?.mobile_number }
                    onKeyDown={handleKeyDown}
                  /> */}
                  <ValidatedField
                    name="phone"
                    placeholder="+353 Enter your mobile number"
                    required
                    className="validated-custom-from-input"
                    data-cy="mobile number"
                    validate={{
                      required: 'Mobile number cannot be empty!',
                      pattern: {
                        value: /^\+3538\d{0,8}$/,
                        message: 'Please enter a valid mobile number starting with +3538 and followed by up to 8 digits.',
                      },
                    }}
                    onChange={(event) => handleMobileNumberChange(i, event)}
                    error={errors.phone as FieldError}
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.mobile_number || key_person_details?.authorized_person_details?.[i]?.mobile_number || '+3538'}
                    onKeyDown={handleKeyDown}
                  />
 
                </FormGroup>
                {validationErrors[`authorized_person_details_${i}_mobile_number`] && (
                  <div className="text-danger">
                    {validationErrors[`authorized_person_details_${i}_mobile_number`]}
                  </div>
                )}               </Col>
 
              <Col md="12">
                <FormGroup>
                  <Label className="label-account-class text-left">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
 
                    <span>Date Of Birth</span>
                  </Label>
                  {/* <ValidatedField
                    name="dateOfBirth"
                    required
                    className="datefield-expiry-style"
                    type="date"
                    data-cy="date of birth"
                    validate={{
                      required: 'Date entered is wrong!',
                      validate: value => isOver18(value) || 'You must be 18 years old or older.',
                      
                    }}
                    // value={key_person_details.authorized_person_details?.[i]?.date_of_bith}
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.date_of_bith}
                    onChange={(event) => handleDateOfBirthChange(i, event)}
 
                    placeholder="Enter your date of birth"
                  /> */}
                <ValidatedDatePickerField
                  onChange={(date) => {
                    handleDateOfBirthChange(i, { target: { value: date, name: 'date_of_birth' } });
                  }}
                  getFieldState={getFieldState}
                  register={register}
                />

                {validationDateError && (
                  <div className="text-danger">{validationDateError}</div>
                )}

                {validationErrors[`authorized_person_details_${i}date_of_birth`] && (
                  <div className="text-danger">
                    {validationErrors[`authorized_person_details_${i}date_of_birth`]}
                  </div>
                )}

                </FormGroup>
              </Col>
              <Col md="12" >
                <FormGroup>
                  <Label className="label-account-class">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
 
                    <span>Country of Tax Residency</span>
                  </Label>
                  <Input
                    type="select"
                    name="taxCountry"
                    id={`taxCountry_${i}`}
                    className="custom-from-input"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.country_of_tax_residency || key_person_details?.authorized_person_details?.[i]?.country_of_tax_residency || 'Ireland'}
                    onChange={(event) => handleTaxResidencyChange(i, event)}
                  >
                    {mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.country_of_tax_residency && (<option defaultValue={mainAccountsEntity.company_details.authorized_person_details[i]?.country_of_tax_residency}>{findCountryNameByKey(mainAccountsEntity.company_details.authorized_person_details[i]?.country_of_tax_residency) || findCountryNameByKey("IRE")}</option>)}
                    {countryList && countryList.map((item: any, index) => (
                      <option key={index} value={item.name}>{item.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                {validationErrors[`authorized_person_details_${i}country_of_tax_residency`] && (
                  <div className="text-danger">
                    {validationErrors[`authorized_person_details_${i}country_of_tax_residency`]}
                  </div>
                )}
              </Col>
              <Col md="12" >
            <FormGroup>
              <Label className="label-account-class text-left">
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
                <span>Owns {'>'}15% of the Company?</span>
              </Label>
              <Input
                type="select"
                name="ownsCompany"
                id="ownsCompany"
                className="custom-from-input"
                value={key_person_details?.authorized_person_details?.[i]?.owns_more_25_of_company ? "true" : "false"}
                onChange={(event) => handleOwnsCompanyChange(i, event)}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Input>
            </FormGroup>
          </Col>

 
            
              {key_person_details?.authorized_person_details?.[i]?.owns_more_25_of_company === true && (
                <Col md="12"style={{paddingLeft:"27px"}}>
                  <FormGroup>
                    <Label className="label-account-class text-left">
                      <span style={{ color: 'red', fontSize: 20 }}>*</span>
                      <span>Source of personal wealth</span>
                    </Label>
                    <Input
                      type="text"
                      name="personalWealth"
                      id="personalWealth"
                      className="custom-from-input"
                      style={{ width: "106%" }} // Ensures width matches

                      value={key_person_details?.authorized_person_details?.[i]?.source_of_person_wealth}
                      defaultValue={mainAccountsEntity.company_details.authorized_person_details?.[i]?.source_of_person_wealth}
                      onChange={(event) => handlePersonalWealthChange(i, event)}
                    />
                    <span style={{ fontSize: '10px', color: '#808080' }}>
                      If yes, please give the main source of wealth (e.g., employment, property, investments)
                    </span>
                  </FormGroup>
                </Col>
              )}

                          
              {/* House Street Address */}
              <Col md="12" className="pt-2">
                <FormGroup style={{paddingBottom:"14px"}}>
                  <Label className="label-account-class text-left">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                    <span>House Street Address</span>
                  </Label>
                  <Input
                    className="custom-from-input"
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Enter your address"
                    style={{ width: '100%' }} // Increase the width here
                    defaultValue={
                      mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.home_address || 
                      key_person_details?.authorized_person_details?.[i]?.home_address
                    }
                    onChange={(event) => handleAddressChange(i, event)}
                  />
                  {validationErrors[`authorized_person_details_${i}home_address`] && (
                    <div className="text-danger">
                      {validationErrors[`authorized_person_details_${i}home_address`]}
                    </div>
                  )}
                </FormGroup>
              </Col>

              {/* Address Line 2 */}
              <Col md="12" className="pt-2">
                <FormGroup>
                    <Label className="label-account-class">
                      <span style={{ color: 'red', fontSize: 20 }}></span>
                  Address Line 2</Label>
                  <Input
                    type="text"
                    name="street"
                    id="street"
                    className="custom-from-input"
                    style={{ width: '100%' }} // Increase the width here
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.address?.street}
                    onChange={(event) => handleStreetChange(i, event)}
                  />
                
            
                </FormGroup>
              </Col>

              {/* Town/City */}
              <Col md="12" className="pt-2">
                <FormGroup>
                  <Label className="label-account-class">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                    Town/City
                  </Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    className="custom-from-input"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.address?.city || ''}
                    onChange={(event) => handleCityChange(i, event)}
                  />
                  {validationErrors[`authorized_person_details_${i}_address_city`] && (
                    <div className="text-danger">
                      {validationErrors[`authorized_person_details_${i}_address_city`]}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md="12" className="pt-2">
              <FormGroup key={i}>
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
              <Label className="label-account-class">County</Label>
              <Input
                type="select"
                name={`authorized_person_details_${i}_address_state`}
                defaultValue={
                  mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.address?.state ||
                   key_person_details?.authorized_person_details?.[i]?.address?.state 
                }
                id={`employeraddressstate_${i}`}
                className="custom-from-input"
                onChange={(event) => handleStateChange(i, event)}
              >
                <option value=""></option>
                {stateList && stateList.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>
              {validationErrors[`authorized_person_details_${i}_address_state`] && (
                <div className="text-danger">
                  {validationErrors[`authorized_person_details_${i}_address_state`]}
                </div>
              )}
            </FormGroup>

              </Col>
            
              <Col md="12" className="pt-2">
                <FormGroup>
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <Label className="label-account-class">Country</Label>
                  <Input
                    type="select"
                    name="employeraddresscountry"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.address?.country || key_person_details?.authorized_person_details?.[i]?.address?.country}
                    id="employeraddresscountry"
                    className="custom-from-input"
                    onChange={(event) => handleCountryChange(i, event)}
                    // disabled
                  >
                    <option value={key_person_details?.authorized_person_details?.[i]?.address?.country || 'Ireland'}>
                      {findCountryNameByKey("Ireland")}
                    </option>
                    {countryList && countryList.map((item: any, index) => (
                      <option key={index} value={item.name}>{item.name}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12" className="pt-2">
                <FormGroup>
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <Label className="label-account-class">Post Code</Label>
                  <Input
                    type="text"
                    name="employeraddresspostalcode"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.address?.postal_code}
                    id="employeraddresspostalcode"
                    className="custom-from-input"
                    style={{ textTransform: 'uppercase' }}

                    onChange={(event) => handlePostCodeChange(i, event)}
                  />
                  {validationErrors[`authorized_person_details_${i}_address_postal_code`] && (
                  <div className="text-danger">
                    {validationErrors[`authorized_person_details_${i}_address_postal_code`]}
                  </div>
                )}
                </FormGroup>
              </Col>


              {/* <Col md="12" className="pt-2">
                <FormGroup>
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  <Label className="label-account-class">Country</Label>
                  <Input
                    type="select"
                    name="employeraddresscountry"
                    defaultValue={mainAccountsEntity.company_details.authorized_person_details?.[i]?.address?.country || key_person_details?.authorized_person_details?.[i]?.address?.country}
                    id="employeraddresscountry"
                    className="custom-from-input"
                    onChange={(event) => handleCountryChange(i, event)}
                  // disabled
                  >
                    <> <option value={key_person_details?.authorized_person_details?.[i]?.address?.country || 'Ireland'}>{findCountryNameByKey("Ireland")}</option></>
                    {countryList && countryList.map((item: any, index) => (
                      <option key={index} value={item.name}>{item.name}</option>
                    ))}

                  </Input>

                </FormGroup>
              </Col>
              <Col md="12" className="pt-2">
                <FormGroup>
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>

                  <Label className="label-account-class">Post Code</Label>
                  <Input
                    type="text"
                    name="employeraddresspostalcode"
                    // value={key_person_details?.authorized_person_details?.[i]?.address?.postal_code}
                    defaultValue={mainAccountsEntity.company_details.authorized_person_details?.[i]?.address?.postal_code}
                    id="employeraddresspostalcode"
                    className="custom-from-input"
                    onChange={(event) => handlePostCodeChange(i, event)}
                  />
                </FormGroup>
                {validationErrors[`authorized_person_details_${i}_address_postal_code`] && (
                  <div className="text-danger">
                    {validationErrors[`authorized_person_details_${i}_address_postal_code`]}
                  </div>
                )}
              </Col> */}

              <Col md="12">
                  <FormGroup>
                    <Label className="label-account-class">
                      <span style={{ color: 'red', fontSize: 20 }}>*</span>

                      <span>PPS Number</span>
                    </Label>
                    <Input
                      type="text"
                      name="ppsNumber"
                      id="ppsNumber"
                      placeholder="Enter your PPS number"
                      className="custom-from-input"
                      defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.pps_number} // Display formatted value
                      // value={key_person_details?.authorized_person_details?.[i]?.pps_number} // Display formatted value
                      onChange={(event) =>
                        handlePPSNumberChange(i, event)
                      }
                    />
                    <span style={{ fontFamily: '10px', color: '#808080' }}>Or equivalent if not Irish resident, eg National Security number</span>
                    {/* {ppsError && <div className="error-message" style={{ color: 'red' }}>{ppsError}</div>} */}

                  </FormGroup>
                  {validationErrors[`authorized_person_details_${i}pps_number`] && (
                    <div className="text-danger">
                      {validationErrors[`authorized_person_details_${i}pps_number`]}
                    </div>
                  )}
              </Col>
              <Col md="12" className="">
                  <Label className="label-account-class text-left">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                    <span>Proof of Address Upload</span>
                  </Label>

                    {isUploadingOne?.[i] ? (
                      <div style={{ width: '50px' }}>
                        <Lottie animationData={animationData} loop autoplay />
                        <span style={{ color: '#607980' }}>Uploading...</span>
                      </div>
                    ) : (
                      <>
                        {key_person_details?.authorized_person_details?.[i]?.proof_of_address || mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.proof_of_address ? (
                          <div id={"upload" + i} className="custom-from-input-upload upload-button-new-style">
                            <ul style={{ listStyleType: 'none' }}>
                              <li className="text-light iconxs">
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <span className="text-danger cursor-pointer ms-2 strong gap-5">
                                    <FileEarmarkMedicalFill className="text-uploaded-document" style={{ fontSize: '40px' }} /> <span> &nbsp;</span>{' '}
                                    <span className="text-uploaded-document">
                                      {/* {key_person_details.authorized_person_details[i].proof_of_address} */}
                                      {'PPC'}
                                    </span>
                                  </span>
                                  <div className="trash-btn"
                                    onClick={() => { handleNewDeleteFile(i, 'PROOF_OF_ADDRESS') }}
                                    style={{ cursor: 'pointer' }}>
                                    <Trash className="trash-btn" />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <>
                              <Dropzone

                                key={"dp" + i}
                                onFileUploaded={(file: File, docType: string) => handleFileUploaded(file, docType, i)}
                                docType={'PROOF_OF_ADDRESS' + i}
                              />
                              <div className="text-black">
                              Please upload a recent bank statement or utility bill in PNG, JPG, JPEG, or PDF file format.
                              This should match the above address details.
                              </div>
                            {validationErrors[`authorized_person_details_${i}proof_of_address`] && (
                              <div className="text-danger">
                                {validationErrors[`authorized_person_details_${i}proof_of_address`]}
                              </div>
                            )}      
                       </>
                        )}

                      </>
                    )}
              
              </Col>

              <Col md="12" className='pt-2'>
                <FormGroup>
                  <Label className="label-account-class">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <span>Passport Number</span>
                  </Label>
                  <Input
                    type="text"
                    name="passport_number"
                    id="passport_number"
                    placeholder="Enter your Passport number"
                    className="custom-from-input"
                    // value={key_person_details?.authorized_person_details?.[i]?.passport_number} // Display formatted value
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.passport_number} // Display formatted value
                    onChange={(event) =>
                      handlePassportNumberChange(i, event)
                    }
                  />
                  {/* <span style={{ fontFamily: '10px', color: '#808080' }}>Or equivalent if not Irish resident</span> */}
                </FormGroup>
                {validationErrors[`authorized_person_details_${i}_passport_number`] && (
                  <div className="text-danger">
                    {validationErrors[`authorized_person_details_${i}_passport_number`]}
                  </div>
                )}
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label className="label-account-class">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>Country Issued
                  </Label>
                  <Input
                    type="select"
                    name="issued_country"

                    id={`issued_country${i}`}
                    className="custom-from-input"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.issued_country || key_person_details?.authorized_person_details?.[i]?.issued_country || 'Ireland'}
                    onChange={(event) => handleCountryIssuedChange(i, event)}
                  >
                    {mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.issued_country && (<option defaultValue={mainAccountsEntity?.company_details?.authorized_person_details[i]?.issued_country}>{findCountryNameByKey(mainAccountsEntity?.company_details?.authorized_person_details[i]?.issued_country) || findCountryNameByKey("IRE")}</option>)}
                    {countryList && countryList.map((item: any, index) => (
                      <option key={index} value={item.name}>{item.name}</option>
                    ))}
                  </Input>

                  {validationErrors[`authorized_person_details_${i}issued_country`] && (
                    <div className="text-danger">
                      {validationErrors[`authorized_person_details_${i}issued_country`]}
                    </div>
                  )}
                </FormGroup>
              </Col>
 
              <Col md="12">
                <FormGroup>
                  <Label className="label-account-class text-left">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                    <span>Passport Expiry Date</span>
                  </Label>

                  {/* <Form className="pt-3"> */}
                  {/* <Input
                    name="passport_expiry_date"
                    required

                    className="datefield-expiry-style"
                    type="date"
                    data-cy="passport expiry date"
                    // value={key_person_details?.authorized_person_details?.[i]?.passport_expiry_date}
                    // value={key_person_details.authorized_person_details?.[i]?.passport_expiry_date}
                    onChange={(event) => handlePassportExpiry(i, event)}

                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.passport_expiry_date || key_person_details?.authorized_person_details?.[i]?.passport_expiry_date || ''}
                    min={minDate} 
                    // onKeyDown={(e) => e.preventDefault()}
                  /> */}
                   <ValidatedDatePickerField
                    onChange={(date) => {
                      handlePassportExpiry(i, { target: { value: date } })}
                    }
                    getFieldState={getFieldState}
                    register={register}
                  />
                  {validationErrors[`authorized_person_details_${i}passport_expiry_date`] && (
                    <div className="text-danger">
                      {validationErrors[`authorized_person_details_${i}passport_expiry_date`]}
                    </div>
                  )}                  {/* </Form> */}
                </FormGroup>
              </Col>
             
              <Col md="12" className="py-1">
                <Label className="label-account-class text-left">
                  <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  <span>Passport Document Upload</span>
                </Label>

                
                  {isUploadingTwo[i] ? (
                    <div style={{ width: '50px' }}>
                      <Lottie animationData={animationData} loop autoplay />
                      <span style={{ color: '#607980' }}>Uploading...</span>
                    </div>
                  ) : (
                    <>
                      {key_person_details?.authorized_person_details?.[i]?.passport_doc || mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.passport_doc ? (
                        <div id={"upload" + i} className="custom-from-input-upload upload-button-new-style">
                          <ul style={{ listStyleType: 'none' }}>
                            <li className="text-light iconxs">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <span className="text-danger cursor-pointer ms-2 strong gap-5">
                                  <FileEarmarkMedicalFill className="text-uploaded-document" style={{ fontSize: '40px' }} /> <span> &nbsp;</span>{' '}
                                  <span className="text-uploaded-document">
                                    {/* {key_person_details.authorized_person_details[i].passport_doc} */}
                                    <span className="text-uploaded-document">{'PASSPORT'}</span>
                                  </span>
                                </span>
                                <div className="trash-btn"
                                  onClick={() => { handleNewDeleteFile(i, 'PASSPORT') }}
                                  style={{ cursor: 'pointer' }}>
                                  <Trash className="trash-btn" />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ) : key_person_details?.authorized_person_details?.[i]?.passport_doc === '' ? (
                        <>
                         
                            <Dropzone key={"dp" + i} ref={clearFileRef}
                              onFileUploaded={(file: File, docType: string) => handlePassportFileUploaded(file, docType, i)} docType={'PASSPORT' + i} />
                          {validationErrors[`authorized_person_details_${i}passport_doc`] && (
                            <div className="text-danger">
                              {validationErrors[`authorized_person_details_${i}passport_doc`]}
                            </div>
                          )}
                           <div className="text-black">
                            Please upload a recent passport document in PNG, JPG, JPEG, or PDF  file format. This should include the main page and the signature page,which must be signed.
                          </div>
                        </>
                      ) : (
                        <>
                            <Dropzone

                              key={"dp" + i}
                              onFileUploaded={(file: File, docType: string) => handlePassportFileUploaded(file, docType, i)}
                              docType={'PASSPORT' + i}
                            />
                          {validationErrors[`authorized_person_details_${i}passport_doc`] && (
                            <div className="text-danger">
                              {validationErrors[`authorized_person_details_${i}passport_doc`]}
                            </div>
                            )}
                            <div className="text-black">
                            Please upload a recent passport document in PNG, JPG, JPEG, or PDF file format. This should include the main page and the signature page, which must be signed.
                          </div>
                        </>
                      )}

                    </>
                  )}
              </Col>
              <Col md="12" className='pt-2'>
                <FormGroup>
                  <Label className="label-account-class text-left">
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>

                    <span>Country of Birth</span>
                  </Label>
                  <Input
                    type="select"
                    name="country_of_birth"
                    id={`country_of_birth${i}`}

                    className="custom-from-input"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.country_of_birth_and_citizen || key_person_details?.authorized_person_details?.[i]?.country_of_birth_and_citizen || 'Ireland'}
                    onChange={(event) =>
                      handleCountryofBirthChange(i, event)
                    }
                  >
                    {mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.country_of_birth_and_citizen && (<option defaultValue={mainAccountsEntity?.company_details?.authorized_person_details[i]?.country_of_birth_and_citizen}>{mainAccountsEntity?.company_details?.authorized_person_details[i]?.country_of_birth_and_citizen ||"Ireland"}</option>)}
                    {countryList && countryList?.map((item: any, index) => (
                      <option key={index} value={item.name}>{item.name}</option>
                    ))}
                  </Input>
                </FormGroup>
                {/* {validationErrors && !key_person_details.country_of_birth_and_citizen && <div className="text-danger">{validationErrors}</div>} */}
              </Col>
                  <Col  className="md-6">
                    <FormGroup>
                      <Label className="label-account-class">
                        <span style={{ color: 'red', fontSize: 20 }}>*</span>Country of Citizenship
                      </Label>
                      <Input
                        type="select"
                        name="citizenship"
                        id={`citizenship${i}`}
                        className="custom-from-input"
                        defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.citizenship || key_person_details?.authorized_person_details?.[i]?.citizenship || 'Ireland'}
                        onChange={(event) => handleCountryOfCitizenshipChange(i, event)}
                      >
                        {mainAccountsEntity?.company_details?.authorized_person_details?.[i]?.citizenship && (
                          <option defaultValue={mainAccountsEntity.company_details.authorized_person_details[i]?.citizenship}>
                            {mainAccountsEntity.company_details.authorized_person_details[i]?.citizenship|| "Ireland"}
                          </option>
                        )}
                        {countryList && countryList.map((item: any, index) => (
                          <option key={index} value={item.name}>{item.name}</option>
                        ))}
                      </Input>

                      {validationErrors[`authorized_person_details_${i}issued_country`] && (
                        <div className="text-danger">
                          {validationErrors[`authorized_person_details_${i}issued_country`]}
                        </div>
                      )}
                    </FormGroup>
                  </Col>

              <FormGroup>
                <Col md="12" className="under-border-div mt-1 mb-1"></Col>
              </FormGroup>
          </div >
        </>,
      );
    }

    return forms;
  };

  return (
    <div>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}


      />
      <Col md="12" className="form-firstrow-account-head-custom py-2">
        <span>Details of Company Key People</span>
      </Col>
      {renderForms()}
    </div>
  );
};
export default forwardRef(AuthorizedPeopleForm);


