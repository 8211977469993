import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Card, CardBody, CardText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './about.scss';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const Founders = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState({
    "Denis O'Connell": false,
    "Alex McKnight": false,
    "John Holohan": false
  });

  const [founderData, setFounderData] = useState([])


  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/meet-the-founders?populate=Founderss,Founderss.image,Founderss.button,Founderss.readMoreDescription,Founderss.readLessDescription')
      .then(async response => {
        const { data } = response;
        const modifiedData = data?.data?.map(async (item: any) => {
          if (item.attributes.description) {
            item.attributes.description = item.attributes.description.replace(/className=\\"/g, 'className="');
          }
          if (item.attributes.Heading) {
            item.attributes.Heading = item.attributes.Heading.replace(/className=\\"/g, 'className="');
          }
          return item;
        });

        // Wait for all asynchronous modifications to complete
        const processedData = await Promise.all(modifiedData);

        setFounderData(processedData);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const toggleExpanded = (title) => {
    setExpanded((prevState) => ({
      ...prevState,
      [title]: !prevState[title]
    }));
  };

  const getInitialWords = (description, wordCount) => {
    const words = description.split(' ');
    return words.slice(0, wordCount).join(' ') + (words.length > wordCount ? '...' : '');
  };
  const getRemainingParagraphs = (description) => {
    const paragraphs = description.split('</p>');
    return paragraphs.slice(1).join('</p>');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {Array.isArray(founderData) && founderData.map((data: any) => {
        return (
          <Row>
            <Col md="12" className="mt-3">
              <div className=" text-center" id="section1">
                <p className="cardtitle-about-others"> {data.attributes.title}</p>
                <Col md="12" className="d-flex justify-content-center">
                  <div className="cardtitle-underline"></div>
                </Col>
                <Row>
                  {data?.attributes?.Founderss?.map((item) => {
                    return (
                      <Col md="4">
                        <Card className="card-about-founders text-start">
                          <CardBody>
                            <img
                              src={'https://starwealth-dev-strapie.livelocal.delivery' + (item.image?.data?.attributes?.url || '')}
                              style={{ height: '100%', width: '100%' }}
                            />
                            <CardText className="founder-name mt-2">{item.title}</CardText>
                            <CardText className="founder-subname d-flex">
                              {item.subTitle}&nbsp;<FontAwesomeIcon className="pt-1" icon={faEnvelope} />
                              &nbsp;&nbsp;<p className="founder-subname-linkdin">in</p></CardText>
                            
                            <CardText className="founder-content">
                              <div className="col">
                                {expanded[item.title]
                                  ?   <span dangerouslySetInnerHTML={{ __html:item.description}}/>
                                  : getInitialWords(item.description, 30)}
                                {!expanded[item.title] && (
                                  <Link
                                    to=""
                                    className="read-btn"
                                    onClick={() => toggleExpanded(item.title)}
                                  >
                                    Read More
                                  </Link>
                                )}
                              </div>

                              {expanded[item.title] && (
                                <span>
                                  <p className="pt-3">
                                    <span dangerouslySetInnerHTML={{ __html: getRemainingParagraphs(item.description) }} />
                                  </p>
                                  <Link
                                    to=""
                                    className="read-btn"
                                    onClick={() => toggleExpanded(item.title)}
                                  >
                                    Read Less
                                  </Link>
                                </span>
                              )}
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                    )
                  })}

                </Row>

              </div>
            </Col>
          </Row>
        )
      })}
    </>
  );
};

export default Founders;
