import React from 'react';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      <MenuItem icon="asterisk" to="/accounts">
        Accounts
      </MenuItem>
      <MenuItem icon="asterisk" to="/website-config">
        Website Config
      </MenuItem>
      <MenuItem icon="asterisk" to="/security-questions">
        Security Questions
      </MenuItem>
      <MenuItem icon="asterisk" to="/customer">
        Customer
      </MenuItem>
    </>
  );
};

export default EntitiesMenu;
