import dayjs from 'dayjs';

export interface IAccounts {
  id?: number;
  accountId?: string;
  customerId?: string;
  accountMasterType?: string | null;
  accountHolderName?: string | null;
  accountHolderEmail?: string | null;
  accountHolderType?: string | null;
  accountHolderTitle?: string | null;
  accountHolderSurname?: string | null;
  accountHolderMaritalStatus?: string | null;
  accountHolderDependents?: number | null;
  accountHolderMobileNumber?: string | null;
  investmentAmount?: number | null;
  employerAddress?: {
    country: string;
    state: string;
    post_code: string;
    city: string;
    street: string;
  } | null;
  // postcode: string | null;
  townOrCity?: string | null;
  isAgeOverAgreed?: boolean | null;
  isAgeOverAgreedTs?: dayjs.Dayjs | null;
  beneficialOwnerAgreed?: boolean | null;
  beneficialOwnerAgreedTs?: dayjs.Dayjs | null;
  residentInIrelandAgreed?: boolean | null;
  residentInIrelandAgreedTs?: dayjs.Dayjs | null;
  taxResidentInIrelandAgreed?: boolean | null;
  taxResidentInIrelandAgreedTs?: dayjs.Dayjs | null;
  employmentIncomePercentage?: number | null;
  propertyPercentage?: number | null;
  giftPercentage?: number | null;
  last_level: number | null;
  addressLine1?: string | null;
  investmentPercentage?: number | null;
  otherPercentage?: number | null;
  totalNetWorth?: number | null;
  liquidNetWorth?: number | null;
  totalNetIncome?: number | null;
  accountOwnerName?: string | null;
  nameOfBank?: string | null;
  isPep?: boolean | null;
  isFamilyPep?: boolean | null;
  financialEmployee?: boolean | null;
  securityQuestionAnswer?: string | null;
  securityQuestionAnswer1?: string | null;
  securityQuestionAnswer2?: string | null;

  iban?: string | null;
  // investmentFromOthers:string|null;
  state_or_province?: string | null;
  isRegisteredInIreland?: boolean | null;
  isRegisteredInIrelandTs?: dayjs.Dayjs | null;
  isTaxResidentInIreland?: boolean | null;
  isTaxResidentInIrelandTs?: dayjs.Dayjs | null;
  lumpSumAvailableToInvest?: boolean | null;
  lumpSumAvailableToInvestTs?: dayjs.Dayjs | null;
  descriptionOfBusiness?: string | null;
  yourName?: string | null;
  yourRole?: string | null;
  yourEmail?: string | null;
  productType?: string[] | null;
  ppsNumber?: string | null;
  ppsNumberDoc?: string | null;
  passportDoc?: string | null;
  proofOfAddressDoc?: string | null;
  taxResidentInIreland?: boolean;
  createdAt?: dayjs.Dayjs | null;
  documentUploadLoading?: boolean;
  country?: [];
  countryList?: [];
  statesList?: [];
  file?: File;
  docType?: string | null;
  // uploadedDocuments: { accountId: string; docType: string; fileName: string }[];
  isapubliclylistedcompany?: string | null;
  enquiries?: any;
  is_company_account: boolean;
  citizenship: string | null;

  mailing_address?: {
    country: string | null;
    addressLineOne: string | null;
    addressLineTwo?: string | null;
    city: string | null;
    stateOrProvince: string | null;
    zipOrPostalCode: string | null;
  };
  birth_info?: {
    countryOfBirth: string | null;
    phoneType: string | null;
    location: string | null;
    phoneNumber: string | null;
  };
  joint_details: {
    jointnametitle: string | null;
    jointfirstname: string | null;
    jointsurname: string | null;
    jointmaritalstatus: string | null;
    jointdependents: number | null;
    jointmobilenumber: string | null;
    jointaddressline1: string | null;
    jointaddressline2: string | null;
    jointtownorcity: string | null;
    jointcountry: string | null;
    jointpostcode: string | null;
    jointppsnumber: string | null;
    jointistaxresident: boolean | null;
    jointothertaxresidence: string | null;
    jointemploymentstatus: string | null;
    jointoccupation: string | null;
    jointemployername: string | null;
    jointemployerbusiness: string | null;
    jointownership: number | null;
    jointemployeraddress: {
      country: string;
      state: string;
      post_code: string;
      city: string;
      street: string;
    } | null;
    jointisapubliclylistedcompany: boolean | null;
    joint_username: string | null;
    jointdob: string;
    jointissuedcountry: string;
    jointstateorprovince: string;
    jointcountryofbirth: string;
    isSameAddress?: boolean | null;
  };
  company_details?: {
    company_name?: string | null;
    trading_name?: string | null;
    accountHolderName?: string | null;
    tax_residency?: boolean;
    beneficialOwnerAgreed?: boolean;
    beneficialOwnerAgreedTs?: boolean;
    registration_number?: string | null;
    registered_address?: string | null;
    description_of_business_activities?: string | null;
    legal_entity_identifier?: string | null; // Changed boolean to string
    tax_registration_number?: string | null;
    fatca_classification?: string | null;
    countries_revenue?: string | null;
    business_revenue?: string | null;
    investment_funding?: string | null;
    other_provide_details?: string | null;
    authorized_person_name?: string | null;
    authorized_person_role?: string | null;
    company_email_address?: string | null;
    phone_number?: string | null;
    director_name?: string | null;
    role?: string | null;
    investment_objectives?: string | null;
    investment_timeframe?: string | null;
    starlight_products?: string[] | null;
    trading_experience?: string | null;
    trading_frequency?: string | null;
    knowledge?: string | null;
    funds_to_cover?: string | null;
    company_net_assets?: string | null;
    financial_commitments?: string | null;
    for_account_servicing_only?: boolean | null;
    marketing_contact?: string | null;
    ownsgt15company?: boolean | null; // Fixed typo, added a question mark
    proof_of_company_doc?: string | null;
    pps_number?: number; // Added a question mark
    lei?: string | null; // Changed boolean to string
    revenue_outside_ireland?: boolean | null;
    is_lei?: boolean | null;
    authorized_person_details?: [
      {
        name: string;
        role: string;
        company_email_address: string;
        mobile_number: string;
        home_address: string;
        date_of_bith: string; // Removed (YYYY-MM-DD) format since it's not a TypeScript type
        country_of_birth_and_citizen: string;
        pps_number: string;
        country_of_tax_residency: string;
        owns_more_25_of_company: boolean;
        issued_country: string;
        passport_expiry_date: string;
        firstname: string,
        lastname: string,
        passport_number: string,
        account_number: string,
        customer_id: string,
        source_of_person_wealth: string,
        passport_doc: string,
        address: string,
        proof_of_address: null
      },
    ];

  } | null;

}

export const defaultValue: Readonly<IAccounts> = {
  isAgeOverAgreed: false,
  beneficialOwnerAgreed: false,
  residentInIrelandAgreed: false,
  taxResidentInIrelandAgreed: false,
  isPep: false,
  isFamilyPep: false,
  financialEmployee: false,
  isRegisteredInIreland: false,
  isTaxResidentInIreland: false,
  taxResidentInIreland: false,
  lumpSumAvailableToInvest: false,
  documentUploadLoading: false,
  last_level: 1,
  country: [],
  countryList: [],
  statesList: [],
  enquiries: [],
  is_company_account: false,
  citizenship: null,
  mailing_address: {
    country: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    stateOrProvince: '',
    zipOrPostalCode: '',
  },
  joint_details: {
    jointnametitle: '',
    jointfirstname: '',
    jointsurname: '',
    jointmaritalstatus: '',
    jointdependents: 0,
    jointmobilenumber: '',
    jointaddressline1: '',
    jointaddressline2: '',
    jointtownorcity: '',
    jointcountry: '',
    jointpostcode: '',
    jointppsnumber: '',
    jointistaxresident: false,
    jointothertaxresidence: '',
    jointemploymentstatus: '',
    jointoccupation: '',
    jointemployername: '',
    jointemployerbusiness: '',
    jointownership: 50,
    jointemployeraddress: {
      country: '',
      state: '',
      post_code: '',
      city: '',
      street: '',
    },
    jointisapubliclylistedcompany: false,
    joint_username: '',
    jointdob: '',
    jointissuedcountry: '',
    jointstateorprovince: '',
    jointcountryofbirth: '',
  },
  company_details: {
    company_name: '',
    trading_name: '',
    accountHolderName: '',
    tax_residency: true,
    beneficialOwnerAgreed: false,
    beneficialOwnerAgreedTs: false,
    registration_number: '',
    registered_address: '',
    description_of_business_activities: '',
    legal_entity_identifier: '',// Changed boolean to string
    tax_registration_number: '',
    fatca_classification: '',
    countries_revenue: '',
    business_revenue: '',
    investment_funding: '',
    other_provide_details: '',
    authorized_person_name: '',
    authorized_person_role: '',
    company_email_address: '',
    phone_number: '',
    director_name: '',
    role: '',
    investment_objectives: '',
    investment_timeframe: '',
    starlight_products: [''],
    trading_experience: '',
    trading_frequency: '',
    knowledge: '',
    funds_to_cover: '',
    company_net_assets: '',
    financial_commitments: '',
    for_account_servicing_only: false,
    marketing_contact: '',
    ownsgt15company: false,
    proof_of_company_doc: '',
    pps_number: 0,
    lei: '',
    revenue_outside_ireland: false,
    is_lei: true,
  }
};
