// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-row {
  text-align: center;
  width: 100%;
}

.img-col {
  border-radius: 45%;
  margin-top: 2rem;
  width: 47%;
  position: relative;
}

.button-hover-style {
  background-color: #607980;
  width: 80%;
  color: white;
}

.button-hover-style:hover {
  background-color: #e1eff2;
  color: rgb(0, 0, 0);
}

@media (min-width: 992px) {
  .img-row {
    margin-left: 6rem;
    margin-top: 150px;
    width: 85%;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/customer/customer.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;;AACA;EACE,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;AAEF;;AAAA;EACE,yBAAA;EACA,UAAA;EACA,YAAA;AAGF;;AADA;EACE,yBAAA;EAEA,mBAAA;AAGF;;AAAA;EACE;IACE,iBAAA;IACA,iBAAA;IACA,UAAA;EAGF;AACF","sourcesContent":[".img-row {\n  text-align: center;\n  width: 100%;\n}\n.img-col {\n  border-radius: 45%;\n  margin-top: 2rem;\n  width: 47%;\n  position: relative;\n}\n.button-hover-style {\n  background-color: #607980;\n  width: 80%;\n  color: white;\n}\n.button-hover-style:hover {\n  background-color: #e1eff2;\n\n  color: rgb(0, 0, 0);\n}\n\n@media (min-width: 992px) {\n  .img-row {\n    margin-left: 6rem;\n    margin-top: 150px;\n    width: 85%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
