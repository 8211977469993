import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Accounts from './accounts';
import WebsiteConfig from './website-config';
import SecurityQuestions from './security-questions';
import Customer from './customer';
import Faq from './accounts/faq';
import CustomFaq from './accounts/custom-faq';

import SmartReserve2 from './smartReserve/SmartReserve2';
import SmartReserve3 from './smartReserve/SmartReserve3';

import SmartReserveHolder from './smartReserve/SmartReserveHolder';



export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        <Route path="customer/*" element={<Customer />} />
        <Route path="accounts/*" element={<Accounts />} />
        <Route path="faq/*" element={<Faq />} />
        <Route path="faqs/*" element={<CustomFaq />} />
        <Route path="smart-reserve/*" element={<SmartReserveHolder />} />
        <Route path="portFolio/*" element={<SmartReserve3 />} />
        <Route path="reserve2/*" element={<SmartReserve2 />} />
        <Route path="website-config/*" element={<WebsiteConfig />} />
     
        <Route path="security-questions/*" element={<SecurityQuestions />} />
        <Route path="customer/*" element={<Customer />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};
