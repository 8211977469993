import React, { useEffect, useRef, useState } from 'react';
import SmartReserve2 from './SmartReserve2';
import SmartReserve3 from './SmartReserve3';
import SmartReserve1 from './SmartReserve1';
import SmartReserve4 from './SmartReserve4';

const SmartReserveHolder = () => {
  const component1Ref = useRef(null);
  const component2Ref = useRef(null);
  const component3Ref = useRef(null);
  const component4Ref = useRef(null);

  return (
    <div className="">
      <div ref={component1Ref}>
        <SmartReserve1 />
      </div>
      <div ref={component2Ref} id="Advantage">
        <SmartReserve4 />
      </div>
      <div ref={component3Ref} id="Market-Fund">
        {' '}
        <SmartReserve2 />
      </div>
      <div ref={component4Ref} id="Portfolio">
        <SmartReserve3 />
      </div>
    </div>
  );
};
export default SmartReserveHolder;
