import React, { useEffect, useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import './profile.scss';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getCustomerDetails } from 'app/entities/customer/customer.reducer';
import { getCompletedAccountType, getPendingTask, ibkrportal } from './profile.reducer';
import { setAccountLastLevel, getEntity, getSignature, getIbkr } from 'app/entities/accounts/accounts.reducer';
import { Link, useNavigate } from 'react-router-dom';
import { saveInitialPath } from '../account/register/register.reducer';

interface Customer {
  account_id: string;
  primary_signed_date: string;
  primary_sign: string;
  primary_customer_id: string;
  joint_customer_id: string | null;
  joint_signed_date: string | null;
  joint_sign: string | null;
}

interface DataObject {
  [x: string]: any;
  smart_reserve_portfolio: Customer;
  statement_of_suitability: Customer;
  w8_ben: Customer;
}

const Profile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signatureData = useAppSelector(state => state.accounts.signatureData);
console.log("signature dataaa", signatureData);

  const accountId = useAppSelector(state => state.accounts?.entity?.accountId)
    || signatureData?.statement_of_suitability?.account_id;
  const mainAccountsDetails = useAppSelector(state => state.accounts?.entity);

  const [pendingDocumentation, setPendingDocumentation] = useState(true);
  const [pendingDocumentation2, setPendingDocumentation2] = useState(true);
  const [completeDocumentation, setCompleteDocumentation] = useState(true);

  const [PendingTasks, setPendingTasks] = useState([]);
  const [signaturePendingData, setSignaturePendingData] = useState<DataObject | null>(null);
  const [AccountHolderName, setAccountHolderName] = useState('');
  const [AccountHolderSur, setAccountHolderSur] = useState('');


  const [accountNumber, setAccountNumber] = useState(null)

  const accountsEntity = useAppSelector(state => state.accounts.entity);
  const [secondaryCustomerId, setSecondaryCustomerId] = useState(null)
  const [holderType, setHolderType] = useState();
  const [data, setData] = useState([]);
  const [ibkrData, setIbkrData] = useState(null);
  const [masterType, setMasterType] = useState();
  const [pendingSignature, setSignatureData] = useState<any>({});



  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      const tokenParts = accessToken?.split('.');
      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));
          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
          }
        } catch (error) { /* empty */ }
      }

      // navigate('/accounts');
      if (window.location.pathname === '/accounts') {
        navigate('/accounts')
      } else {
        if (window.location.pathname === '/company') {
          navigate('/company')
        } else if (window.location.pathname === '/user-profile') {
          navigate('/user-profile')
        } else if (window.location.pathname === '/admin-profile') {
          navigate('/admin-profile')
        }
      }
    } else {
      dispatch(saveInitialPath(window.location.pathname))
      navigate('/signin');

    }
  }, []);

  const [completedAccountType, setCompletedAccountType] = useState('');
  const [completedMasterType, setCompletedMasterType] = useState('');
  useEffect(() => {
    dispatch(getCompletedAccountType({}))
      .then((response: any) => {

        setCompletedAccountType(response?.payload?.data?.accountHolderType)
        setCompletedMasterType(response?.payload?.data?.accountMasterType)

      }).catch(error => {
        console.error('Error:', error);
      });
  }, []);
  
const getAllPendingTask=() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {

        setData(response?.payload?.data?.signature_pending)
        const pendingAccountsList = response?.payload?.data?.pending_accounts;
        const signaturePendingList = response?.payload?.data?.signature_pending
        const secondaryId = signaturePendingList?.[0]?.secondary_customer_id;
        setSecondaryCustomerId(secondaryId)
        setMasterType(signaturePendingList?.[0]?.accountMasterType)

        if (signaturePendingList) {
          setAccountNumber(signaturePendingList?.[0]?.accountId)
        }

        if (pendingAccountsList && pendingAccountsList.length > 0 || signaturePendingList && signaturePendingList.length > 0) {
          let firstAccount: any = pendingAccountsList.filter(
            (accountobj: { accountId: string }) => accountobj.accountId === sessionStorage.getItem('accountid'),
          );
          const signaturePendingAccount: any = response.payload.data.signature_pending.filter(
            (accountobj: { accountId: string }) => accountobj.accountId === sessionStorage.getItem('accountid'),
          );

          let level = '';
          let firstAccountId = '';
          if (pendingAccountsList.length > 0) {
            level = pendingAccountsList[0]?.last_level;
            firstAccountId = pendingAccountsList[0]?.accountId;
          } else {
            if (signaturePendingAccount.length > 0) {
              level = signaturePendingAccount[0]?.last_level;
              firstAccountId = signaturePendingAccount[0]?.accountId;
              firstAccount = signaturePendingAccount;
            }
          }
          dispatch(setAccountLastLevel(level));          
          setPendingTasks(pendingAccountsList);

          const customerId = accountsEntity.customerId;
          const filteredHolderData = pendingAccountsList
            .filter((type: any) => {
              return type.customerId === customerId;
            })
            .map((type: any) => type.accountHolderType);

          setHolderType(filteredHolderData);

          if (firstAccountId !== '') {
            dispatch(getEntity(firstAccountId));
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const customerDetails = useAppSelector(state => state.customer.entities);
  const { last_level } = useAppSelector(state => state.accounts.entity);
  console.log("lastlevel",last_level);
  
  const [customerId, setCustomerId] = useState('');

  const [name, setName] = useState(customerDetails?.firstName || '');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  // useEffect(() => {
  //   if (customerDetails) {
  //     setName(customerDetails?.firstName || '');
  //     setLastName(customerDetails?.lastName || '');
  //     setCustomerId(customerDetails?.customerId || '');
  //     setEmail(customerDetails?.email || '');
  //     setPhone(customerDetails?.phone || '');
  //   }
  // }, [customerDetails]);

  // useEffect(() => {
  //   dispatch(getCustomerDetails());
  // }, []);

  useEffect(() => {
    if (accountId) {
      dispatch(getSignature(accountId)).then((response: any) => {
        setSignatureData(response?.payload);
        console.log('DGgg',JSON.stringify(response.payload))
      });
    }
  }, [accountId, dispatch]);

  useEffect(() => {
    dispatch(getIbkr()).then((response: any) => {
      if (response?.payload) {
        setIbkrData(response?.payload);
      }
    });
  }, [dispatch]);

  const [AccountType, setAccountType] = useState('');
  useEffect(() => {

    setAccountType(accountsEntity?.accountHolderType);
  }, [accountsEntity]);


  useEffect(() => {
    if (accountNumber) {
      sessionStorage.setItem('accountid', accountNumber);
      localStorage.setItem('accountId', accountNumber);
      dispatch(getSignature(accountNumber)).then((response: any) => {

        setSignaturePendingData(response?.payload);
      });
    } else if (accountId) {
      dispatch(getSignature(accountId)).then((response: any) => {
        if (response.payload && response.payload.data) {
          setSignaturePendingData(response?.payload);
        }
      });
    }
    getAllPendingTask();
  }, [accountId, dispatch, accountNumber]);

  const isTaskPending = (taskId: number) => {
    const pendingTasks = [2, 3, 4, 5, 6, 7, 8, 9, 99, 100, 101, 102, 103, 104];
    return pendingTasks.includes(taskId);
  };


  const onClickPendingTask = (level: number = 1) => {
    dispatch(setAccountLastLevel(level));
    navigate('/accounts');
  };
  const onClickCompanyPendingTask = (level: number = 1) => {
    dispatch(setAccountLastLevel(level));
    navigate('/company');
  };
  const onClickSignaturePendingTask = (level: number = 1, account_id: any) => {
    dispatch(setAccountLastLevel(level));
    navigate('/accounts', { state: { level, account_id } });

  };
  const handleIBKRPortalLinkClick = async () => {
    try {
      const resultAction = await dispatch(ibkrportal());

      if (resultAction && resultAction.payload) {
        const portalUrl = resultAction.payload;
        window.open(portalUrl, '_blank');
      } else {
        console.error('IBKR Portal URL not found');
      }
    } catch (error) {
      console.error('Error fetching IBKR Portal URL:', error);
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);

  return (
    <div className="container-fluid background-color-black">
      <Row className="pt-5 justify-content-center">
        <main className="col-11  ">
          <div className="card bg-white mt-2 mt-md-0 ">
            <div className="card-body " >
            <Row className='justify-content-center'>
                  <Col md="6  ">
                  
                      
                        {accountsEntity?.customerId === customerId && (
                          <>
                            {(last_level > 1 && (accountsEntity.accountHolderType?.toLowerCase() !== 'organization' || (accountsEntity.accountHolderType?.toLowerCase() === 'organization' && last_level < 100 && PendingTasks?.[0]?.accountId))) && (

                              <Alert className="mt-3 alertclrr" isOpen={pendingDocumentation} toggle={() => setPendingDocumentation(false)}>
                                You have Activities to complete.
                              </Alert>
                            )}
                          </>
                        )}
                    
                 
                    {secondaryCustomerId && (
                      <>
                        {((accountsEntity?.accountHolderType?.toLowerCase() ?? signatureData?.account_type?.toLowerCase()) === 'joint') && (<Alert className="mt-3 alertclrr" isOpen={pendingDocumentation} toggle={() => setPendingDocumentation(false)}>
                          You have Activities to complete.
                        </Alert>
                        )}

                      </>

                    )}
                  </Col>

                  </Row>
                  <div className='text-end '>

              </div>
              <div className="user-profile-text1">
                <div className="row flex-row-profile">
                  <div className="col-md-8 col-lg-9">
                    <div className="py-2 pending-task new-profile-color" style={{fontSize:"20px"}}>Your Client Portal	</div>
                    <div className="user-profile-text1">
                      <div className='d-flex justify-content-between align-item-center  new-profile-color'>
                        <div className="">
                          {accountsEntity.accountHolderType === "Organization" ? (
                            <div style={{ lineHeight: '2.5', fontSize: 'small', fontWeight: 'bold' }}>
                              <span style={{ display: 'inline-block', width: '180px' }}>Customer Name:</span>
                              <span style={{ lineHeight: '2.5', fontWeight: '300' }} className="text-black pb-2">
                                {accountsEntity?.company_details?.company_name}
                              </span>
                            </div>
                          ) : (
                            <div style={{ lineHeight: '2.5', fontSize: 'small', fontWeight: 'bold' }}>
                              <span style={{ display: 'inline-block', width: '180px' }}>Customer Name:</span>
                              <span style={{ lineHeight: '2.5', fontWeight: '300' }} className="text-black pb-2">
                                {`${accountsEntity?.accountHolderName || customerDetails?.firstName} ${accountsEntity?.accountHolderSurname || customerDetails?.lastName}`}
                              </span>
                            </div>
                          )}
                        </div>

                      </div>
                    </div>

                    {customerId === accountsEntity?.secondary_customer_id ? (
                        <div style={{ lineHeight: '2.5', fontSize: 'small', fontWeight: 'bold' }}>
                          <span style={{ display: 'inline-block', width: '180px' }}>Starlight Account Type:</span>
                          <span style={{ lineHeight: '2.5', fontWeight: '300' }} className="text-black pb-2">
                            {completedMasterType}&nbsp;{completedAccountType}
                          </span>
                        </div>
                      ) : (
                        <div className="new-profile-color" style={{ lineHeight: '2.5', fontSize: 'small', fontWeight: 'bold' }}>
                          <span style={{ display: 'inline-block', width: '180px' }}>Starlight Account Type:</span>
                          <span style={{ lineHeight: '2.5', fontWeight: '300' }} className="text-black pb-2">
                            {accountsEntity.accountMasterType || masterType || completedMasterType}&nbsp;{AccountType || signatureData?.account_type || completedAccountType}
                          </span>
                        </div>
                      )}

                  </div>
                  <div className="col-md-4 col-lg-3 button-div-profile">
                    <div>
                      <Link to={'/dashboard'}>
                        <button className='button-link-dashboard'>
                          My Starlight
                        </button>
                      </Link>
                    </div>
                    <div className='pt-2'>
                      <Link to="/change-password " >
                        <button  className="button-link-dashboard" >
                          Change Password
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>


                {!secondaryCustomerId && mainAccountsDetails.accountHolderType?.toLowerCase() !== 'organization' &&  (
                  <>
                    { last_level > 1 ? (
                      <Row className="user-profile-text1">
                        <Col md="12">
                          <div className="py-2 pending-task new-profile-color">Activities:</div>
                          <Row>
                            <Col md="12">
                              <table className="table custom-table-td">
                                <thead className='new-form-table-row-two'>
                                  <tr>
                                    <th>Tasks</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody className='table-td new-form-table-row-two'>
                                  {last_level < 3 && (
                                    <tr>
                                      <td className="table-td">Primary Applicant Details</td>
                                      <td className="table-td">
                                        <a onClick={() => onClickPendingTask(2)} className="text-primary">
                                          {isTaskPending(2) ? 'Action required' : ''}
                                        </a>
                                      </td>
                                    </tr>
                                  )}
                                  {mainAccountsDetails?.accountHolderType !== 'Individual' && last_level < 4 && (
                                    <tr>
                                      <td className="table-td">Secondary Applicant Details</td>
                                      <td className="table-td">
                                      {last_level == 3 ? (
                                      <a onClick={() => onClickPendingTask(3)} className="text-primary">
                                        {isTaskPending(3) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 5 && (
                                    <tr className="table-td">
                                      <td className="table-td">Anti-Money Laundering Requirements</td>
                                      <td className="table-td">
                                      {(last_level === 4 || (last_level === 3 && mainAccountsDetails?.accountHolderType === 'Individual')) ? (
                                        <a onClick={() => onClickPendingTask(4)} className="text-primary">
                                          {isTaskPending(4) ? 'Action required' : ''}
                                        </a>
                                      ) : (
                                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                          Pending
                                        </span>
                                      )}

                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 6 && (
                                    <tr>
                                      <td className="table-td">Your Net Worth and Annual Income</td>
                                      <td className="table-td">
                                      {last_level == 5 ? (
                                    <a onClick={() => onClickPendingTask(6)} className="text-primary">
                                      {isTaskPending(6) ? 'Action required' : ''}
                                    </a>
                                  ) : (
                                    <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                    Pending
                                    </span>
                                  )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 7 && (
                                    <tr>
                                      <td className="table-td">Politically Exposed Person (PEP)</td>
                                      <td className="table-td">
                                      {last_level == 6 ? (
                                      <a onClick={() => onClickPendingTask(6)} className="text-primary">
                                        {isTaskPending(6) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 8 && (
                                    <tr>
                                      <td className="table-td">Regulatory Information</td>
                                      <td className="table-td">
                                      {last_level == 7 ? (
                                      <a onClick={() => onClickPendingTask(7)} className="text-primary">
                                        {isTaskPending(7) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 9 && (
                                    <tr>
                                      <td className="table-td">My Starlight Smart Reserve Application Summary</td>
                                      <td className="table-td">
                                      {last_level == 8 ? (
                                      <a onClick={() => onClickPendingTask(8)} className="text-primary">
                                        {isTaskPending(8) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 101 && (
                                    <tr>
                                      <td className="table-td">Statement of Suitability</td>
                                      <td className="table-td">
                                      {last_level == 100 ? (
                                      <a onClick={() => onClickPendingTask(100)} className="text-primary">
                                        {isTaskPending(100) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                      Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 102 && (
                                    <tr>
                                      <td className="table-td">Starlight Smart Reserve Model Portfolio Description</td>
                                      <td className="table-td">
                                      {last_level == 101 ? (
                                        <a onClick={() => onClickPendingTask(101)} className="text-primary">
                                          {isTaskPending(101) ? 'Action required' : ''}
                                        </a>
                                      ) : (
                                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                          Pending
                                        </span>
                                      )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 103 && (
                                    <tr>
                                      <td className="table-td">W-8BEN Primary Form</td>
                                      <td className="table-td">
                                      {last_level == 102 ? (
                                        <a onClick={() => onClickPendingTask(102)} className="text-primary">
                                          {isTaskPending(102) ? 'Action required' : ''}
                                        </a>
                                      ) : (
                                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                          Pending
                                        </span>
                                      )}
                                      </td>
                                    </tr>
                                  )}
                                  {/* {AccountType !== 'Individual' && last_level < 104 && (
                                    <tr>
                                      <td className="table-td">W-8BEN Secondary</td>
                                      <td className="table-td">
                                        <a onClick={() => onClickPendingTask(103)} className="text-primary">
                                          {isTaskPending(103) ? 'Action required' : ''}
                                        </a>
                                      </td>
                                    </tr>
                                  )} */}
                                  {last_level < 105 && (
                                    <tr>
                                      <td className="table-td">Interactive Brokers Ireland Standard Terms And Disclosures</td>
                                      <td className="table-td">
                                      {last_level == 103 ? (
                                      <a onClick={() => onClickPendingTask(104)} className="text-primary">
                                        {isTaskPending(104) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <Row className='justify-content-center'>
                        <Col md="6  ">
                          <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
                            You Have No Outstanding Tasks
                          </Alert>
                        </Col>
                      </Row>
                    )}

                  </>
                )}

                {accountNumber ? (
                  <>
                    {(AccountType?.toLowerCase() ?? pendingSignature?.account_type?.toLowerCase()) === 'joint' && (
                      <>
                        {pendingSignature ? (

                          <Row className="user-profile-text1">
                            <Col md="12">
                              {customerId === secondaryCustomerId ? (
                                <div className="py-2 pending-task new-profile-color">Activities</div>
                              ) : (
                                <div className="py-2 pending-task new-profile-color">Documents Pending For The Secondary Applicant's Signature </div>
                              )}
                              <Row>
                                <Col md="12">
                                  <table className="table custom-table-td">
                                    <thead>
                                      <tr className="text-left className='new-form-table-row-two'">
                                        <th>Document Type</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody className="text-left new-form-table-row-two">
                                      {pendingSignature?.statement_of_suitability && (pendingSignature.statement_of_suitability.primary_sign === null || pendingSignature.statement_of_suitability.joint_sign === null) && (
                                        <tr>
                                          <td className="table-td">Statement of Suitability</td>
                                          <td className="table-td">

                                            {customerId === secondaryCustomerId ? (
                                              <a
                                                onClick={secondaryCustomerId ? () => onClickSignaturePendingTask(100, signatureData.statement_of_suitability.account_id) : null}
                                                className={secondaryCustomerId ? "text-primary" : "black"}
                                                style={{ textDecoration: secondaryCustomerId ? "underline" : "none" }}
                                              >
                                                Action Required
                                              </a>
                                            ) : (
                                              <span style={{ textDecoration: "none", color: "black" }}>Pending</span>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                     {pendingSignature.smart_reserve_portfolio && 
                                      (pendingSignature.smart_reserve_portfolio.primary_sign === null || 
                                        pendingSignature.smart_reserve_portfolio.joint_sign === null) && (
                                        <tr>
                                          <td className="table-td">Starlight Smart Reserve Model Portfolio Description</td>
                                          <td className="table-td">
                                            {customerId === secondaryCustomerId && pendingSignature.statement_of_suitability.joint_sign !== null ? (
                                              <a
                                                onClick={() => onClickSignaturePendingTask(101, pendingSignature)}
                                                className="text-primary"
                                                style={{ textDecoration: "underline" }}
                                              >
                                                {isTaskPending(102) ? 'Action Required' : ''}
                                              </a>
                                            ) : (
                                              <span style={{ textDecoration: "none", color: "black" }}>Pending</span>
                                            )}
                                          </td>
                                        </tr>
                                    )}
                                      {/* {signatureData.w8_ben && (signatureData.w8_ben.primary_sign === null) && (
                                            <tr>
                                              <td className="table-td">W-8BEN Primary</td>
                                              <td className="table-td">
                                                <a
                                                  onClick={secondaryCustomerId ? () => onClickSignaturePendingTask(102, signatureData) : null}
                                                  className={`text-primary ${!secondaryCustomerId ? 'no-cursor' : ''}`}
                                                >
                                                  Pending
                                                </a>
                                              </td>
                                            </tr>
                                          )} */}
                                      {pendingSignature.w8_ben && (pendingSignature.w8_ben.joint_sign === null) && (
                                        <tr>
                                          <td className="table-td">W-8BEN Secondary Form</td>
                                          <td className="table-td">
                                            {customerId === secondaryCustomerId && pendingSignature.smart_reserve_portfolio.joint_sign !== null? (
                                              <a
                                                onClick={secondaryCustomerId ? () => onClickSignaturePendingTask(103, pendingSignature) : null}

                                                className={secondaryCustomerId ? "text-primary" : ""}
                                                style={{ textDecoration: secondaryCustomerId ? "underline" : "none" }}
                                              >
                                                Action Required
                                              </a>
                                            ) : (
                                              <>
                                                <span>Pending</span>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                      {pendingSignature.ibkr_signature && (pendingSignature.ibkr_signature.primary_sign === null || pendingSignature.ibkr_signature.joint_sign === null) && (
                                        <tr>
                                          <td className="table-td">
                                            Interactive Brokers Ireland Standard Terms And Disclosures
                                          </td>
                                          <td className="table-td">
                                            {customerId === secondaryCustomerId && pendingSignature.w8_ben.joint_sign !== null? (
                                              <a
                                                onClick={secondaryCustomerId ? () => onClickSignaturePendingTask(104, pendingSignature) : null}
                                                className={secondaryCustomerId ? "text-primary" : ""}
                                                style={{ textDecoration: secondaryCustomerId ? "underline" : "none" }}
                                              >
                                                Action Required
                                              </a>
                                            ) : (
                                              <>
                                                <span>Pending</span>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                        ) : (
                          <Row className='justify-content-center'>
                            <Col md="6  ">
                              <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
                                You have No Outstanding Tasks
                              </Alert>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </>) : (<></>)}

                  {accountId && mainAccountsDetails.accountHolderType?.toLowerCase() === 'organization' && (
                  <>
                    {last_level >= 1 ? (
                      <Row className="user-profile-text1">
                        <Col md="12">
                          {last_level < 100 ? (
                            <div className="py-2 pending-task new-profile-color">Activities:</div>) : (
                            <></>)}
                          <Row>
                            <Col md="12">
                              <table className="table custom-table-td">
                                {last_level < 101 ? (
                                  <thead className='new-form-table-row-two'>
                                    <tr>
                                      <th>Tasks</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>) : (
                                  <>
                                    <Row className='justify-content-center bg-white'>
                                      <Col md="6">
                                        <Alert className="mt-3 alertclrr " isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
                                          You have No Outstanding Tasks
                                        </Alert>
                                      </Col>
                                    </Row>
                                  </>
                                )}
 
                                <tbody className='table-td new-form-table-row-two'>
                                  {last_level < 2 && (
                                    <tr>
                                      <td className="table-td">Company Details</td>
                                      <td className="table-td">
                                        <a onClick={() => onClickCompanyPendingTask(2)} className="text-primary">
                                          {isTaskPending(2) ? 'Action required' : ''}
                                        </a>
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 3 && (
                                    <tr>
                                      <td className="table-td">Key People associated with the Company</td>
                                      <td className="table-td">
                                      {last_level == 2 ? (
                                      <a onClick={() => onClickCompanyPendingTask(3)} className="text-primary">
                                        {isTaskPending(3) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}

                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 4 && (
                                    <tr className="table-td">
                                      <td className="table-td">Details of Company Key People</td>
                                      <td className="table-td">
                                      {last_level == 3 ? (
                                      <a onClick={() => onClickCompanyPendingTask(4)} className="text-primary">
                                        {isTaskPending(4) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 5 && (
                                    <tr>
                                      <td className="table-td">Company Financials</td>
                                      <td className="table-td">
                                      {last_level == 4 ? (
                                      <a onClick={() => onClickCompanyPendingTask(5)} className="text-primary">
                                        {isTaskPending(5) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                       Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                  {last_level < 6 && (
                                    <tr>
                                      <td className="table-td">My Starlight Smart Reserve Application Summary</td>
                                      <td className="table-td">
                                      {last_level == 5 ? (
                                      <a onClick={() => onClickCompanyPendingTask(6)} className="text-primary">
                                        {isTaskPending(6) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                       Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
                                   {last_level < 100 && (
                                    <tr>
                                      <td className="table-td">Statement of Suitability</td>
                                      <td className="table-td">
                                      {last_level == 99 ? (
                                      <a onClick={() => onClickCompanyPendingTask(7)} className="text-primary">
                                        {isTaskPending(7) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )} 
 
                               
                                  {last_level < 101 && (
                                    <tr>
                                      <td className="table-td">Starlight Smart Reserve Model Portfolio Description</td>
                                      <td className="table-td">
                                      {last_level == 100 ? (
                                      <a onClick={() => onClickCompanyPendingTask(8)} className="text-primary">
                                        {isTaskPending(8) ? 'Action required' : ''}
                                      </a>
                                    ) : (
                                      <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                        Pending
                                      </span>
                                    )}
                                      </td>
                                    </tr>
                                  )}
 
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <Row className='justify-content-center'>
                        <Col md="6  ">
                          <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
                            You have No Outstanding Tasks
                          </Alert>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
 

                {(ibkrData) && (ibkrData?.ibkr_account_number) && (
                  <>
                  <div className="d-flex align-items-center justify-content-between py-1 pending-task new-profile-color" >
                    <div style={{fontSize:"20px"}}>Your Investment Portal</div>
                    <button className='button-investment' onClick={handleIBKRPortalLinkClick} >Investment Portal</button>
                  </div>
                
                  <div className="user-profile-text1 ibkr-new-font">
                    <Row className=' new-profile-color'>
                      <div className="text-black ibkr-new-font">Click on the <span style={{color:"black",fontWeight:"bold"}}>Investment Portal</span> button to securely view your portfolio holdings, download investment account activity statements and to change your bank details. Your Investment Portal is powered by our investment broker, <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Interactive Brokers</span> Ireland Limited.
                      </div>
                      <Row>
                      <div className="d-flex align-items-center justify-content-between py-2 pending-task-broker" style={{marginTop:"10px"}} >
                      <div>Your <span style={{color:"black",fontWeight:"bold"}} >Interactive Brokers</span> Account Details:</div>
                      </div>
                        <Row>
                          <Col md="6" style={{ lineHeight: '0.5', fontSize: 'small', fontWeight: 'bold' }}>
                            Account Number :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '1.5', fontWeight: '300' }} className="text-black pb-2">
                              {ibkrData?.ibkr_account_number}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" style={{ lineHeight: '0.5', fontSize: 'small', fontWeight: 'bold' }}>
                            User Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-black pb-2" style={{ lineHeight: '1.5', fontWeight: '300' }}>
                              {ibkrData?.ibkr_username}
                            </span>
                          </Col>
                          &nbsp;
                        </Row>
                        <Row>
                          <Col md="6" style={{ lineHeight: '0.5', fontSize: 'small', fontWeight: 'bold' }}>
                            Account Status:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-black pb-2" style={{ lineHeight: '1.5', fontWeight: '300' }}>
                              {ibkrData?.ibkr_account_status}
                            </span>
                          </Col>
                          &nbsp;
                        </Row>
                        <Row>
                          <div className="text-black"><span className="d-flex align-items-center justify-content-between py-1 pending-task new-profile-color" style={{marginTop:"20px"}}>Logging in to the Investment Portal for the first time:</span></div>
                           <div style={{marginBottom:"20px"}}>1. You will need your Interactive Brokers User Name (above) and your password (sent to you by email) to log in  <br />
                            2. You will be prompted to change your password, to one which only you will know  <br />
                            3. You will be prompted to verify your email address and mobile number, to set up secure 2 Factor Authentication (2FA) on your account  <br />
                          </div> <br />
                          <div className='' style={{ fontSize: "17px", color: 'black', fontWeight: 'bold' }}>Funding your Starlight Wealth Account </div>
                          <div className="text-black" >To fund your Starlight Account, please enter the information below in your online bank app or in your branch.  </div><br />
                          <Row>
                            <Col md="6" style={{ lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                              Account Title	:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: '300' }} className="text-black pb-2">
                                Interactive Brokers Ireland
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" style={{ lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                              IBAN :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: '300' }} className="text-black pb-2">
                                DE72501108006231412815
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" style={{ lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                              BIC	:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: '300' }} className="text-black pb-2">
                                CHASDEFXXXX
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" style={{ lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                              Beneficiary Bank :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: '300' }} className="text-black pb-2">
                              JP Morgan SE, TaunusTurm, Taunustor 1, Frankfurt 60310, Germany 
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" style={{lineHeight: '1.5', fontSize: 'small', fontWeight: 'bold' }}>
                              Payment Reference :&nbsp;&nbsp;<span style={{ lineHeight: '0.5', fontWeight: 'bold' }} className="text-black pb-2">
                                {ibkrData?.ibkr_account_number}
                              </span>
                            </Col>
                          </Row>
                          <br />
                          <div>
                            <div className='' style={{ fontSize: "17px", color: 'black', fontWeight: 'bold',marginTop:"16px" }}>Important: </div>
                            <div>
                              <span className='' style={{ color: 'black', fontWeight: 'bold' }}> 1. You must include your Interactive Brokers account number as the payment reference</span><br />
                              2. For EUR fund transfer only.
                              Please contact us if you wish to transfer funds in another currency <br />
                              3. Please save the payee and payment details in your online bank app as a regular payee <br /><br />
                            </div>
                            {/* <span className="text-black" style={{fontSize:"10px"}}>Please note that Starlight does not accept or hold any client monies. Funds in your Starlight Smart Account are ‘client assets’ and are fully segregated from the assets of IBKR in accordance with Central Bank of Ireland regulatory rules. Please refer to the Interactive Brokers Ireland Limited ‘Client Asset Key Information Document’ in your application form for more information and important disclosures.</span> */}
                          </div>
                        </Row>
                      </Row>
                    </Row>
                  </div>
                </>
                
                )}
              </div>
              {/* )} */}


            </div>
          </div>
        </main >
      </Row >
    </div >
  );
};

export default Profile;