import React, { useEffect, useState } from 'react';
import { ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Spinner } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FieldError, useForm } from 'react-hook-form';
import './login.scss';
import { forgotLoginOtp, forgotPassword } from '../account/register/register.reducer';
import { useAppDispatch } from 'app/config/store';
import SuccessModal from './successmodal';
import './style.scss';
import './successmodal.scss';
import ErrorModal from './errormodal';
import OtpInput from 'react-otp-input';
import CommonToast from '../components/CommonToast';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showSignInModal, setShowSignInModal] = useState(true);
  const [showSignInForgotModal, setShowSignInForgotModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [Username, setUsername] = useState('');
  const [otp, setOtp] = useState('');
  const [timeLeft, setTimeLeft] = useState(10);
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [Password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
    navigate('/');
  };

  const handleModalClose = () => {
    toggleSignInModal();
  };

  const toggleSignInForgotModal = () => {
    setShowSignInForgotModal(!showSignInForgotModal);
    navigate('/');
  };

  const handleForgotModalClose = () => {
    toggleSignInForgotModal();
  };

  const startTimer = () => {
    setLoading(true);
    setTimeLeft(300);
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setLoading(false); 
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); 
  };

  useEffect(() => {
    const cleanup = startTimer();
    return cleanup;
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleResendOtp = async () => {
    const data = {
      email: Username,
      event: 'FORGOT_EVENT'
    };
    try {
      const response: any = await dispatch(forgotLoginOtp(data));
      setTransactionId(response?.payload.transaction_id);
      if (response?.payload?.transaction_id) {
        setLoading(false);
        setToastMessage('If the email id is registered with company, mail will be send to the mailbox. Please check your inbox or spam folder');
        setToastType('success');
        setToastVisible(true);
        setShowSignInForgotModal(true);
        setShowSignInModal(false);
        
        startTimer(); 
      } else {
        // console.error('Failed to send OTP, response:', response?.payload?.detail);
        setToastMessage('If the email id is registered with company, mail will be send to the mailbox. Please check your inbox or spam folder');
        setToastType('success');
        setToastVisible(true);
        setShowSignInForgotModal(true);

        setShowErrorModal(true);
      }
    } catch (error) {
      console.error('Error during OTP verification:', error);
    }
  };

  const handleForgotPassword = async () => {
    const data = {
      username: Username,
      password: Password,
      transaction_id: transactionId,
      otp,
      event: 'FORGOT_EVENT',
    };

    try {
      const response: any = await dispatch(forgotPassword(data));

      if (response?.payload && response?.payload?.status && response?.payload?.status === 400) {
        const errorMessage = response?.payload?.detail || 'Invalid OTP';
        setToastMessage(errorMessage);
        setToastType('error');
        setToastVisible(true);
      } else {
        setToastMessage((prev) => response?.payload);
        setToastType('success');
        setToastVisible(true);
        setShowSignInForgotModal(false);
        setShowSignInModal(false);
      }
    } catch (error) {
      console.error('Error in forgot password flow:', error);
    }
  };

  const handleCloseSuccessModal = () => {
    window.location.reload();
    setShowSuccessModal(false);
    // navigate('/');
  };

  const {
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const minLength = 8;

    // Regular expressions for validation
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    let errorMessage = '';

    if (
      newPassword.length < minLength ||
      !uppercaseRegex.test(newPassword) ||
      !lowercaseRegex.test(newPassword) ||
      !specialCharRegex.test(newPassword)
    ) {
      errorMessage = 'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character';
    }

    setPasswordError(errorMessage || null);
  };
  const handleClose=()=>{
    toggleSignInModal();
   }
  return (
    <>
      <>
        <CommonToast
          visible={toastVisible}
          onClose={() => setToastVisible(false)}
          title={toastType === 'success' ? 'Success' : 'Error'}
          description={toastMessage}
          type={toastType}
          isAutoClose={true}
        />
      </>
      <Modal isOpen={showSignInModal} toggle={toggleSignInModal} className="modal-dialog-centered mt-4">
        <Row
          style={{
            backgroundImage: "url('../../../content/images/starlight-login.webp')",
            backgroundPosition: 'center top',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          //  border: '1px solid white',
          }}
          className="justify-content-center"
        >
          <Col md="10">
            
            <ModalBody>
              <Row>
              <Col className="d-flex justify-content-center" xs={12} md={12}>
                  {/* <label style={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' }}>Starlight Wealth</label> */}
                  <img className="img-fluid logo-dashboard" src="../../../../../content/images/white_new_logo.svg" />
                </Col>
              <Col style={{marginTop:"30px"}}>
              <label style={{ fontSize: '20px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' ,marginTop:'0px',marginLeft:"1px"}}>Forgot Password</label>
                <Button className="reg-close" onClick={handleClose} style={{
                      position: 'absolute',
                      top: '10px',
                      right: '-47px',
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}>
                    X
                  </Button>
                </Col>
                {' '}
                <Col md="12" className="mt-2">
                  <ValidatedField
                    name="Email"
                    label="Email"
                    placeholder="Enter your Email"
                    required
                    autoFocus
                    onChange={e => setUsername(e.target.value)}
                    className="validated-field1"
                    data-cy="username"
                    validate={{ required: 'Email cannot be empty!' }}
                    style={{ padding: '3px 12px' }}
                    error={errors.username as FieldError}
                  />
                </Col>{' '}
              </Row>
            </ModalBody>
            <ModalFooter className="d-flex flex-column align-items-center">
              <Button className="signin-btn-style" disabled={!Username.trim()}onClick={handleResendOtp} type="submit">
                Submit
              </Button>
              <Link className="d-flex flex-column align-items-right" to="/signin" style={{ color: "#2a9fd6", fontSize: 12, fontFamily: 'Montserrat' }}>
                Log In
              </Link>
            </ModalFooter>
          </Col>
        </Row>
      </Modal>
      <Modal isOpen={showSignInForgotModal} toggle={toggleSignInForgotModal} className="modal-dialog-centered mt-4">
        <Row
          style={{
            backgroundImage: "url('../../../content/images/starlight-login.webp')",
            backgroundPosition: 'center top',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            // border: '1px solid white',
          }}
          className="justify-content-center"
        >
          <Col md="10">
           


            <ModalBody>
              <Row>
            <Col className="d-flex justify-content-center" xs={12} md={12}>
                  <label style={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' }}>Starlight Wealth</label>
                </Col>  
                 <Col>
                <h4>Verification Required</h4>
                <Button className="reg-close" onClick={handleClose} style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}>
                    X
                  </Button>
                </Col>
                <Col md="12" className="mt-2 tt">
                  <Label for="totp" className="label-account-class" style={{ color: '#9fcdda' }}>
                    Please enter or copy and paste the secure code sent to your registered email address
                  </Label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={props => <input {...props} />}
                  />
                  {loading && (
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                      <Spinner size="sm" color="primary" style={{ marginRight: '5px' }} />
                      <span style={{ color: 'white',fontFamily: 'Montserrat', fontSize: '14px' }}>{formatTime(timeLeft)} remaining</span>
                    </div>
                  )}
                  {!loading && timeLeft === 0 && (
                    <button className="signin-btn-style" onClick={handleResendOtp}>
                      Resend OTP
                    </button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mt-4 tt">
                  <ValidatedField
                    name="New Password"
                    label="New Password"
                    placeholder="Enter your new password"
                    type="password"
                    onChange={handlePasswordChange}
                    className="validated-field1"
                    required
                    data-cy="password"
                    validate={{ required: 'password cannot be empty!' }}
                    error={errors.password as FieldError}
                    style={{ padding: '3px 12px' }}
                  />
                </Col>
                {passwordError && (
                  <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                    {passwordError}
                  </div>
                )}
                <div className="col">
                  <ul className="text-start" style={{ color: 'white', fontFamily: 'Montserrat', fontSize: '14px' }}>
                    <li>Password must be at least 8 characters long</li>
                    <li>Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character</li>
                  </ul>
                </div>
              </Row>
            </ModalBody>
            <ModalFooter className="d-flex flex-column align-items-center">
              <Button className="signin-btn-style" onClick={handleForgotPassword} type="submit">
                Submit
              </Button>
              <Link className="d-flex flex-column align-items-right" to="/signin" style={{ color: "#2a9fd6", fontSize: 12, fontFamily: 'Montserrat' }}>
                Log In
              </Link>
            </ModalFooter>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ForgotPassword;
