import React, { useEffect, useState } from 'react';
import './contact.scss';
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { useDispatch } from 'react-redux';
import { createContact } from './contact-reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Contact() {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [open, setOpen] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [contactData, setContactData] = useState([])

  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/contact-uses?populate=*')
      .then(response => {
        const { data } = response;

        setContactData(prev => ([...prev, ...data.data]));


      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const toggle = () => {
    setOpen(!open);
    navigate('/');
  };
  const navigate = useNavigate();

  const [consent, setConsent] = useState(false);
  const handleChangeFirstName = e => {
    setFirstName(e.target.value);
  };

  const handleChangeLastName = e => {
    setLastName(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };
  const handleChangeMessage = e => {
    setMessage(e.target.value);
  };
  const handleChangeConsent = () => {
    if (consent) {
      setConsent(false);
    } else {
      setConsent(true);
    }
  };
 
  const handleCreateConversation = async () => {
    const body = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      message: message,
      consent: consent,
      error: null, 
    };

    try {
      const action = await dispatch<any>(createContact(body)); 
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
      setConsent(false);

      
    } catch (error) {
      console.error('Error creating contact:', error); 
      alert('Error submitting form: ' + error.message);
    }
  };

  return (
    <div className="contact-container ">

      {Array.isArray(contactData) && contactData.map((data: any) => {
        return (
          <div className="section-1">
            <Row className="d-flex align-items-center">
              <Col md="12 mt-5">
                <h2 className="text-center">{data.attributes.ContactUs.title}</h2>
              </Col>
              <p className="text-center">
                {data.attributes.ContactUs.description}
              </p>
            </Row>

          </div>
        )
      })}
      <div className="section-2 align-items-center">
        <Row style={{ marginLeft: '4rem' }}>
          <Col md="12" style={{ marginTop: '55px' }}>
            <h3 className="text-dark" style={{ color: 'black' }}>
              Let's Start the Conversation
            </h3>
          </Col>
        </Row>

        <Row className="d-flex justify-content-start  mt-5" style={{ marginLeft: '4rem' }}>
          <Col md="4">
            <p>Phone Number</p>
            <p>+353 1 901 5760</p>
          </Col>
          <Col md="4">
            <p>Email</p>
            <p>contactus@starlightwealth.ie</p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center ml-5 pt-3" style={{ marginLeft: '4rem' }}>
          <Col md="4">
            <Form>
              <FormGroup>
                <Label for="first Name">First Name</Label>
                <Input
                  className="custom-input text-white"
                  style={{ fontSize: '12px', backgroundColor: '#607980', border: '1.5px solid white', width: '70%' }}
                  id="phoneNumer"
                  name="first Name"
                  placeholder="first name"
                  type="text"
                  value={firstName}
                  onChange={handleChangeFirstName}
                />
              </FormGroup>
            </Form>
          </Col>{' '}
          <Col md="4">
            <Form>
              <FormGroup>
                <Label for="last Name">last Name</Label>
                <Input
                  className="custom-input  text-white"
                  style={{ fontSize: '12px', backgroundColor: '#607980', border: '1.5px solid white', width: '70%' }}
                  id="last Name"
                  name="last Name"
                  placeholder="last Name"
                  type="text"
                  value={lastName}
                  onChange={handleChangeLastName}
                />
              </FormGroup>
            </Form>
          </Col>{' '}
          <Col md="4">
            <Form>
              <FormGroup>
                <Label for="exampleEmail ">Email address</Label>
                <Input
                  className="custom-input text-white"
                  style={{
                    fontSize: '12px',
                    backgroundColor: '#607980',
                    border: '1.5px solid white',
                    width: '70%',
                  }}
                  id="email"
                  name="Email"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={handleChangeEmail}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center align-items-center  " style={{ marginLeft: '4rem' }}>
          <Col md="12">
            <FormGroup>
              <Label for="exampleText ">Message</Label>

              <Input
                className="custom-input text-white"
                style={{
                  fontSize: '12px',
                  backgroundColor: '#607980',
                  border: '1.5px solid white',
                  width: '70%',
                }}
                id="message"
                name="message"
                placeholder="message"
                type="text"
                value={message}
                onChange={handleChangeMessage}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ fontSize: '14px' }} className="ps-5">
          <FormGroup check inline className="ps-5">
            <input type="checkbox" className="" checked={consent} onChange={handleChangeConsent} />
            <Label check>I consent to Starlight Wealth storing and utilising my data so that it may respond to my query</Label>
          </FormGroup>
        </Row>
        <Row>
          <Col md="9" className="text-md-end pe-md-5 text-center">
            <Link to="" className="open-new">
              <Button
                className="px-5 contact-btn-style underline-none"
                onClick={handleCreateConversation}
                disabled={!(consent && message && email && lastName && firstName)}
              >
                Send
              </Button>
            </Link>
          </Col>
        </Row>
        <Modal returnFocusAfterClose={focusAfterClose} isOpen={open}>
          <ModalBody>
            {updateSuccess && (
              <div className="font-weight-bold text-white " style={{ fontFamily: 'Montserrat', justifyContent: 'center' }}>
                <FontAwesomeIcon className=" " icon={faEnvelope} style={{ fontWeight: '200' }} />
                &nbsp;Submitted Successfully{' '}
                {/* <Link to="/user-profile" className="" style={{ color: '#E1EFF2' }}>
                        {' '}
                        Client Portal{' '}
                      </Link> */}
              </div>
            ) }
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Contact;
