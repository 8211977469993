/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import './smartReserve2.scss';
import { Col, Row } from 'reactstrap';
import axios from 'axios';

function SmartReserve2() {

  const [moneyMarketData, setMoneyMarketData] = useState([])
  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/money-market-fundings?populate=*')
      .then(response => {

        const { data } = response;

        setMoneyMarketData(prev => ([...prev, ...data.data]));




      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <div className="contact-container-reserve bg-black mb-20" style={{ color: 'white', backgroundColor: 'black' }}>
      {Array.isArray(moneyMarketData) && moneyMarketData.map((money: any, index: any) => {
        return (
          <div key={"Key-" + index.toString()} className="section-reserve">
            <Row className="d-flex align-items-left justify-content-center">
              <Col  className="text-white  " md="6 mt-5 text-left mr-10" style={{ marginRight: '20px', fontSize: '20px', fontWeight: '300' }}>
                <h2 className="text-white ">{money?.attributes?.title}</h2>
                {money?.attributes?.description?.map((item, item_index) => {
                  return (


                    <div key={"ItemKey-" + item_index.toString()} style={{ fontSize: '18px' }}>
                      <p>
                        {item?.description}
                      </p>
                      <br />

                    </div>
                  )
                })}
              </Col>
            </Row>
            <br />
            <br />
            <br />

            <Row className="division " style={{ lineHeight: '1.6em' }}>


              <Col md="12" className="division-1 d-md-flex justify-content-center">
                {money?.attributes?.fund?.map((item, fund_index) => {
                  return (

                    <Col  key={"FundKey-" + fund_index.toString()} md="2" className="mb-3 text-center">
                      <Col style={{ fontWeight: '350', fontSize: '25px' }}>
                        <Row>
                          <strong>{item?.number}</strong>
                        </Row>
                        <Row>
                          <strong>billion</strong>
                        </Row>
                      </Col>
                      <br />
                      <Row className="text-left">
                        <strong className="billion-text">
                          {item?.description}
                        </strong>
                      </Row>
                    </Col>

                  )
                })}

              </Col>

            </Row>

            <br />
            <br />
          </div>
        )
      })
      }    </div>
  );
}
export default SmartReserve2;
