import React from 'react';

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, onClose, message }) => {
  return (
    <div
      className={`modal ${isOpen ? 'show' : ''}`}
      tabIndex={-1}
      style={{
        display: isOpen ? 'block' : 'none',
        height: 1000,
        paddingTop: 100,
      }}
    >
      <div className="modal-dialog p-2 mt-5" role="document">
        <div className="modal-content custom-modal1">
          <div className="cross d-flex flex-row-reverse">
            <button type="button" className="close btn rounded-circle" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>

          <div className="modal-header d-flex justify-content-center align-items-center p-0">
            <h5 className="modal-title">Error</h5>
          </div>

          <div className="modal-body">
            <p className="text-danger message">{message}</p>
          </div>
          <div className="modal-footer d-flex justify-content-center p-0 mb-2 text-center">
            <button type="button" className="btn-lg w-50 ok-btn-style" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
