import dayjs from 'dayjs';

export interface ISecurityQuestions {
  id?: string;
  section?: number | null;
  securityQuestion?: string | null;
  isEnabled?: boolean | null;
  createdAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<ISecurityQuestions> = {
  isEnabled: false,
};
