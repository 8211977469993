import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './accounts.reducer';

export const AccountsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const accountsEntity = useAppSelector(state => state.accounts.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="accountsDetailsHeading">Accounts</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{accountsEntity.id}</dd>
          <dt>
            <span id="accountId">Account Id</span>
          </dt>
          <dd>{accountsEntity.accountId}</dd>
          <dt>
            <span id="customerId">Customer Id</span>
          </dt>
          <dd>{accountsEntity.customerId}</dd>
          <dt>
            <span id="accountMasterType">Account Master Type</span>
          </dt>
          <dd>{accountsEntity.accountMasterType}</dd>
          <dt>
            <span id="accountHolderName">Account Holder Name</span>
          </dt>
          <dd>{accountsEntity.accountHolderName}</dd>
          <dt>
            <span id="accountHolderEmail">Account Holder Email</span>
          </dt>
          <dd>{accountsEntity.accountHolderEmail}</dd>
          <dt>
            <span id="accountHolderType">Account Holder Type</span>
          </dt>
          <dd>{accountsEntity.accountHolderType}</dd>
          <dt>
            <span id="accountHolderTitle">Account Holder Title</span>
          </dt>
          <dd>{accountsEntity.accountHolderTitle}</dd>
          <dt>
            <span id="accountHolderSurname">Account Holder Surname</span>
          </dt>
          <dd>{accountsEntity.accountHolderSurname}</dd>
          <dt>
            <span id="accountHolderMaritalStatus">Account Holder Marital Status</span>
          </dt>
          <dd>{accountsEntity.accountHolderMaritalStatus}</dd>
          <dt>
            <span id="accountHolderDependents">Account Holder Dependents</span>
          </dt>
          <dd>{accountsEntity.accountHolderDependents}</dd>
          <dt>
            <span id="accountHolderMobileNumber">Account Holder Mobile Number</span>
          </dt>
          <dd>{accountsEntity.accountHolderMobileNumber}</dd>
          <dt>
            <span id="investmentAmount">Investment Amount</span>
          </dt>
          <dd>{accountsEntity.investmentAmount}</dd>
          <dt>
            <span id="isAgeOverAgreed">Is Age Over Agreed</span>
          </dt>
          <dd>{accountsEntity.isAgeOverAgreed ? 'true' : 'false'}</dd>
          <dt>
            <span id="isAgeOverAgreedTs">Is Age Over Agreed Ts</span>
          </dt>
          <dd>
            {accountsEntity.isAgeOverAgreedTs ? (
              <TextFormat value={accountsEntity.isAgeOverAgreedTs} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="beneficialOwnerAgreed">Beneficial Owner Agreed</span>
          </dt>
          <dd>{accountsEntity.beneficialOwnerAgreed ? 'true' : 'false'}</dd>
          <dt>
            <span id="beneficialOwnerAgreedTs">Beneficial Owner Agreed Ts</span>
          </dt>
          <dd>
            {accountsEntity.beneficialOwnerAgreedTs ? (
              <TextFormat value={accountsEntity.beneficialOwnerAgreedTs} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="residentInIrelandAgreed">Resident In Ireland Agreed</span>
          </dt>
          <dd>{accountsEntity.residentInIrelandAgreed ? 'true' : 'false'}</dd>
          <dt>
            <span id="residentInIrelandAgreedTs">Resident In Ireland Agreed Ts</span>
          </dt>
          <dd>
            {accountsEntity.residentInIrelandAgreedTs ? (
              <TextFormat value={accountsEntity.residentInIrelandAgreedTs} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="taxResidentInIrelandAgreed">Tax Resident In Ireland Agreed</span>
          </dt>
          <dd>{accountsEntity.taxResidentInIrelandAgreed ? 'true' : 'false'}</dd>
          <dt>
            <span id="taxResidentInIrelandAgreedTs">Tax Resident In Ireland Agreed Ts</span>
          </dt>
          <dd>
            {accountsEntity.taxResidentInIrelandAgreedTs ? (
              <TextFormat value={accountsEntity.taxResidentInIrelandAgreedTs} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="employmentIncomePercentage">Employment Income Percentage</span>
          </dt>
          <dd>{accountsEntity.employmentIncomePercentage}</dd>
          <dt>
            <span id="propertyPercentage">Property Percentage</span>
          </dt>
          <dd>{accountsEntity.propertyPercentage}</dd>
          <dt>
            <span id="giftPercentage">Gift Percentage</span>
          </dt>
          <dd>{accountsEntity.giftPercentage}</dd>
          <dt>
            <span id="investmentPercentage">Investment Percentage</span>
          </dt>
          <dd>{accountsEntity.investmentPercentage}</dd>
          <dt>
            <span id="otherPercentage">Other Percentage</span>
          </dt>
          <dd>{accountsEntity.otherPercentage}</dd>
          <dt>
            <span id="totalNetWorth">Total Net Worth</span>
          </dt>
          <dd>{accountsEntity.totalNetWorth}</dd>
          <dt>
            <span id="liquidNetWorth">Liquid Net Worth</span>
          </dt>
          <dd>{accountsEntity.liquidNetWorth}</dd>
          <dt>
            <span id="totalNetIncome">Total Net Income</span>
          </dt>
          <dd>{accountsEntity.totalNetIncome}</dd>
          <dt>
            <span id="accountOwnerName">Account Owner Name</span>
          </dt>
          <dd>{accountsEntity.accountOwnerName}</dd>
          <dt>
            <span id="nameOfBank">Name Of Bank</span>
          </dt>
          <dd>{accountsEntity.nameOfBank}</dd>
          <dt>
            <span id="isPep">Is Pep</span>
          </dt>
          <dd>{accountsEntity.isPep ? 'true' : 'false'}</dd>
          <dt>
            <span id="isFamilyPep">Is Family Pep</span>
          </dt>
          <dd>{accountsEntity.isFamilyPep ? 'true' : 'false'}</dd>
          <dt>
            <span id="financialEmployee">Financial Employee</span>
          </dt>
          <dd>{accountsEntity.financialEmployee ? 'true' : 'false'}</dd>
          <dt>
            <span id="securityQuestionAnswer">Security Question Answer</span>
          </dt>
          <dd>{accountsEntity.securityQuestionAnswer}</dd>
          <dt>
            <span id="iban">Iban</span>
          </dt>
          <dd>{accountsEntity.iban}</dd>
          <dt>
            <span id="isRegisteredInIreland">Is Registered In Ireland</span>
          </dt>
          <dd>{accountsEntity.isRegisteredInIreland ? 'true' : 'false'}</dd>
          <dt>
            <span id="isRegisteredInIrelandTs">Is Registered In Ireland Ts</span>
          </dt>
          <dd>
            {accountsEntity.isRegisteredInIrelandTs ? (
              <TextFormat value={accountsEntity.isRegisteredInIrelandTs} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="isTaxResidentInIreland">Is Tax Resident In Ireland</span>
          </dt>
          <dd>{accountsEntity.isTaxResidentInIreland ? 'true' : 'false'}</dd>
          <dt>
            <span id="isTaxResidentInIrelandTs">Is Tax Resident In Ireland Ts</span>
          </dt>
          <dd>
            {accountsEntity.isTaxResidentInIrelandTs ? (
              <TextFormat value={accountsEntity.isTaxResidentInIrelandTs} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lumpSumAvailableToInvest">Lump Sum Available To Invest</span>
          </dt>
          <dd>{accountsEntity.lumpSumAvailableToInvest ? 'true' : 'false'}</dd>
          <dt>
            <span id="lumpSumAvailableToInvestTs">Lump Sum Available To Invest Ts</span>
          </dt>
          <dd>
            {accountsEntity.lumpSumAvailableToInvestTs ? (
              <TextFormat value={accountsEntity.lumpSumAvailableToInvestTs} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="descriptionOfBusiness">Description Of Business</span>
          </dt>
          <dd>{accountsEntity.descriptionOfBusiness}</dd>
          <dt>
            <span id="yourName">Your Name</span>
          </dt>
          <dd>{accountsEntity.yourName}</dd>
          <dt>
            <span id="yourRole">Your Role</span>
          </dt>
          <dd>{accountsEntity.yourRole}</dd>
          <dt>
            <span id="yourEmail">Your Email</span>
          </dt>
          <dd>{accountsEntity.yourEmail}</dd>
          <dt>
            <span id="productType">Product Type</span>
          </dt>
          <dd>{accountsEntity.productType}</dd>
          <dt>
            <span id="ppsNumber">Pps Number</span>
          </dt>
          <dd>{accountsEntity.ppsNumber}</dd>
          <dt>
            <span id="ppsNumberDoc">Pps Number Doc</span>
          </dt>
          <dd>{accountsEntity.ppsNumberDoc}</dd>
          <dt>
            <span id="passportDoc">Passport Doc</span>
          </dt>
          <dd>{accountsEntity.passportDoc}</dd>
          <dt>
            <span id="proofOfAddressDoc">Proof Of Address Doc</span>
          </dt>
          <dd>{accountsEntity.proofOfAddressDoc}</dd>
          <dt>
            <span id="createdAt">Created At</span>
          </dt>
          <dd>{accountsEntity.createdAt ? <TextFormat value={accountsEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/accounts" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/accounts/${accountsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

export default AccountsDetail;
