// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-bold-text-small {
  font-weight: bold;
  font-size: small;
}

.top-header-text-container {
  text-align: center;
}

.top-header-text-larger {
  font-weight: bold;
  font-size: x-large;
}

.top-header-text-large {
  font-weight: bold;
  font-size: large;
}

.top-header-text-small {
  font-weight: bold;
  font-size: small;
}

.top-form-text-input {
  border: none;
  background-color: rgb(255, 255, 255);
}

.invested-amount-form-text-input {
  border: none;
  background-color: rgb(255, 255, 255);
}

/* .page-one {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-two {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-three {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-four {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-five {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-six {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
}
.page-seven {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);
} */
.sign-container {
  border-bottom: 1px solid;
  width: 200px;
}

.warning-box-container {
  border: 2px solid;
}

td {
  border: 1px solid #000000;
  background-color: #77777b;
  color: #ffffff;
  text-align: center;
}

th {
  border: 1px solid #000000;
  background-color: #c9eaff;
}

tr {
  line-height: 35px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/company/companySos.css"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,iBAAA;EACA,kBAAA;AAGF;;AADA;EACE,iBAAA;EACA,gBAAA;AAIF;;AAFA;EACE,iBAAA;EACA,gBAAA;AAKF;;AAHA;EACE,YAAA;EACA,oCAAA;AAMF;;AAHA;EACE,YAAA;EACA,oCAAA;AAMF;;AAHA;;;;;;;;;;;;;;;;;;;;GAAA;AAqBA;EACE,wBAAA;EACA,YAAA;AAMF;;AAJA;EACE,iBAAA;AAOF;;AAFA;EACE,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AAKF;;AAHA;EACE,yBAAA;EACA,yBAAA;AAMF;;AAHA;EACE,iBAAA;AAMF","sourcesContent":[".default-bold-text-small {\n  font-weight: bold;\n  font-size: small;\n}\n.top-header-text-container {\n  text-align: center;\n}\n.top-header-text-larger {\n  font-weight: bold;\n  font-size: x-large;\n}\n.top-header-text-large {\n  font-weight: bold;\n  font-size: large;\n}\n.top-header-text-small {\n  font-weight: bold;\n  font-size: small;\n}\n.top-form-text-input {\n  border: none;\n  background-color: rgb(255, 255, 255);\n}\n\n.invested-amount-form-text-input {\n  border: none;\n  background-color: rgb(255, 255, 255);\n}\n\n/* .page-one {\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);\n}\n.page-two {\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);\n}\n.page-three {\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);\n}\n.page-four {\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);\n}\n.page-five {\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);\n}\n.page-six {\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);\n}\n.page-seven {\n  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.57);\n} */\n.sign-container {\n  border-bottom: 1px solid;\n  width: 200px;\n}\n.warning-box-container {\n  border: 2px solid;\n}\ntable {\n}\n\ntd {\n  border: 1px solid #000000;\n  background-color: #77777b;\n  color: #ffffff;\n  text-align: center;\n}\nth {\n  border: 1px solid #000000;\n  background-color: #c9eaff;\n}\n\ntr {\n  line-height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
