import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Card } from 'reactstrap';
import { useAppSelector } from 'app/config/store';
import { useNavigate } from 'react-router-dom';
import './smartReserve1.scss';
import { getCountryList } from '../accounts/accounts.reducer';
import { useAppDispatch } from 'app/config/store';
import { getCustomerDetails } from '../customer/customer.reducer';
import axios from 'axios';

const SmartReserve1 = () => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    // dispatch(getCountryList());
    dispatch(getCustomerDetails());

    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      navigate('/accounts');
      if (window.location.pathname === '/accounts') {
        window.location.reload();
      }
    } else {
      navigate('/login');
    }
  };

  const handleClickJoint = () => {
    dispatch(getCustomerDetails());
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      navigate('/company');
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [smartReserve, setSmartReserve] = useState([])
  const [smartInvest, setSmartInvest] = useState([])
  useEffect(() => {
  
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/starlight-smart-reserves?populate=*')
      .then(response => {
        const { data } = response;

        setSmartReserve(prev => ([...prev, ...data.data]));

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  useEffect(() => {
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/starlight-open-smart-invests?populate=*')
      .then(response => {
        const { data } = response;

        setSmartInvest(prev => ([...prev, ...data.data]));

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>


      <div className="open-container1 container-fluid">
        <Row>
          <Col md="12">
            <Row>
              {Array.isArray(smartReserve) && smartReserve.map((smartReserve: any) => {
                return (
                  <Col md="6" className="d-flex justify-content-center align-items-stretch card-bg-style1">
                    <Card className="card-bg-style1" style={{ width: '100%' }}>
                      <br />
                      <span style={{ color: 'white', fontSize: '45px', fontWeight: 400 }}>{smartReserve.attributes.heading}</span>
                      <Row>
                        <span style={{ color: 'white', fontSize: '25px', fontWeight: 350 }}>{smartReserve.attributes.subHead}</span>
                      </Row>
                      {/* <Row>
                        <p style={{ color: 'rgba(173, 216, 230, 0.8)', paddingLeft: '70px', paddingTop: '40px', fontWeight: '300' }}>
                          Earn <span style={{ fontWeight: 'bold', fontSize: '30px', color: 'white' }}>3.00% (EUR)</span>,{' '}
                          <span style={{ fontWeight: 'bold', fontSize: '30px', color: 'white' }}>4.50% (USD)</span> after fees with 5-day access
                        </p>
                      </Row> */}
                      <Row>
                        <p style={{ color: 'rgba(173, 216, 230, 0.8)', paddingLeft: '70px', paddingTop: '40px', fontWeight: '300' }}>

                          <span style={{ fontWeight: 'bold', fontSize: '30px', color: 'white' }}>{smartReserve.attributes.description}</span>
                        </p>
                      </Row>
                      <Row>
                        <span style={{ color: 'white', fontSize: '25px', fontWeight: 400, paddingLeft: '70px' }}>
                          {smartReserve.attributes.title}
                        </span>
                      </Row>
                      <Row>
                        {smartReserve.attributes.contents.map((item) => {
                          return (
                            <ul
                              style={{
                                color: 'white',
                                listStyleType: 'disc',
                                paddingLeft: '70px',
                                marginTop: '30px',
                                fontSize: '18px',
                                fontWeight: 350,
                              }}
                            >
                              <li style={{ marginBottom: '20px' }}>{item.description}</li>

                            </ul>
                          )
                        })}
                      </Row>
                      {smartReserve.attributes.subDescription.map((item) => {
                        return (


                          <Row>
                            <span style={{ fontSize: '15px', fontStyle: 'italic', paddingLeft: '70px', color: 'white', fontWeight: '300', padding: 5 }}>
                              {item.description}
                            </span>
                          </Row>
                        )
                      })}
                    </Card>
                  </Col>
                )
              })}

              {Array.isArray(smartInvest) && smartInvest.map((invest: any) => {
                return (
                  <Col md="6" className="d-flex justify-content-center card-bg-stylenew">
                    <Card style={{ padding: '20px', background: 'transparent', border: 'none' }}>
                      <br />
                      <span style={{ color: 'white', fontWeight: '400', fontSize: '45px' }}>{invest.attributes.heading}</span>
                      {/* <Row>
                        {invest.attributes.buttons.map((item, index) => {
                          return (
                            <Col md="6" sm="12" className="mt-4" key={index}>
                              <Button
                                className="open-btn-style"
                                onClick={item.title === "Open Individual/Joint" ? () => handleClick(item) : () => handleClickJoint(item)}
                              >
                                {item.title}
                              </Button>
                            </Col>
                          );
                        })}
                      </Row> */}


                      <Row>
                        <Col md="6" sm="12" className="mt-4">
                          <Button className="open-btn-style" onClick={handleClick}>
                            Open Individual/Joint
                          </Button>
                        </Col>
                        <Col md="6" sm="12" className="mt-4">
                          <Button className="open-btn-style" onClick={handleClickJoint}>
                            Open Company
                          </Button>
                        </Col>
                      </Row>



                      <Row className="mt-4">
                        <h4>{invest.attributes.subHead}</h4>
                        {invest?.attributes?.content?.map((item) => {
                          return (

                            <Col md="12">
                              <ul>
                                <li className="smart-sub-text">{item?.description}</li>

                              </ul>
                            </Col>
                          )
                        })}
                      </Row>
                      <Row className="mt-4">
                        <h4>{invest.attributes.title}</h4>
                        {invest?.attributes?.description?.map((item) => {
                          return (
                            <Col md="12" style={{ fontSize: '13px', fontFamily: 'Montserrat', marginTop: '20px' }}>
                              <p className="smart-sub-text">
                                {item?.description}
                              </p>


                            </Col>
                          )
                        })}
                      </Row>


                    </Card>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row >
      </div >

    </>
  );
};

export default SmartReserve1;
