import regexPattern from "./regexPattern";

const AuthRules: any = {
  password: {
    required: "Password required",
    minLength: {
      value: 8,
      message: "Password should not be less than 8 characters",
    },
    pattern: {
      value: regexPattern.PASSWORD,
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character!",
    },
  },
  currentPassword: {
    required: "Password required"
  },
};

export default AuthRules;
