// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-bg-stylenew {
  background-color: rgba(68, 84, 93, 0.6509803922);
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-bg-style1 {
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: black;
}

.open-container1 {
  font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
  height: auto;
  background-color: rgba(68, 84, 93, 0.6509803922);
  padding-left: 70px;
  padding-right: 70px;
  background-image: url("https://static.wixstatic.com/media/4f10d0_13bb44e12bb64e6196dc910e27c4aea2~mv2.jpg/v1/fill/w_1800,h_1686,al_c,q_90,enc_auto/4f10d0_13bb44e12bb64e6196dc910e27c4aea2~mv2.jpg");
}

.smart-sub-text {
  color: white;
  font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 15px;
  padding-top: 10px;
}

@media (max-width: 769px) {
  .card-bg-style1 {
    width: 100%;
    padding: 0;
  }
  .open-container1 {
    padding-left: 0px;
    padding-right: 0px;
    background-image: none;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/smartReserve/smartReserve1.scss"],"names":[],"mappings":"AAAA;EACE,gDAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,8DAAA;EACA,YAAA;EACA,gDAAA;EACA,kBAAA;EACA,mBAAA;EACA,oMAAA;AACF;;AAEA;EACE,YAAA;EACA,8DAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE;IACE,WAAA;IACA,UAAA;EACF;EACA;IACE,iBAAA;IACA,kBAAA;IACA,sBAAA;IACA,WAAA;EACF;AACF","sourcesContent":[".card-bg-stylenew {\n  background-color: #44545da6;\n  border-radius: 0px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.card-bg-style1 {\n  border-radius: 0px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  background: black;\n}\n\n.open-container1 {\n  font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;\n  height: auto;\n  background-color: #44545da6;\n  padding-left: 70px;\n  padding-right: 70px;\n  background-image: url('https://static.wixstatic.com/media/4f10d0_13bb44e12bb64e6196dc910e27c4aea2~mv2.jpg/v1/fill/w_1800,h_1686,al_c,q_90,enc_auto/4f10d0_13bb44e12bb64e6196dc910e27c4aea2~mv2.jpg');\n}\n\n.smart-sub-text {\n  color: white;\n  font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;\n  font-size: 15px;\n  padding-top: 10px;\n}\n\n@media (max-width: 769px) {\n  .card-bg-style1 {\n    width: 100%;\n    padding: 0;\n  }\n  .open-container1 {\n    padding-left: 0px;\n    padding-right: 0px;\n    background-image: none;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
