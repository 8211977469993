import React, { useState, useEffect } from 'react';
import '../bellwether/bellwether.scss';
import { Card, CardImg, Col, Row } from 'reactstrap';
import { CircleFill, CircleHalf, SquareFill, TriangleFill } from 'react-bootstrap-icons';
import axios from 'axios';
function Bellwether() {
  const [modal, setModal] = useState(false);
  const [bellWare, setBellWare] = useState([])
  const toggle = () => setModal(!modal);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/bell-wares?populate=Head,description,Content.Title,Content.content,MainImage,Icons.image,ImportantInformation,ImportantInformation.content,Warning')
      .then(response => {
        const { data } = response;
        // Assuming data.data is an array of objects with a "title" property
        setBellWare(data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <>
      {bellWare?.map((item, index) => {
        return (
          <div key={index.toString()} className="bellwether-container-reserve" style={{ backgroundColor: 'transparent', fontFamily: 'Montserrat' }}>
            <div className="bellwether-container-reserve-div">
              <img
                alt="Card image cap"
                src="https://static.wixstatic.com/media/4f10d0_1d00d77848e64850892e695583b37c19~mv2.jpg/v1/fill/w_764,h_605,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Image-empty-state.jpg"
                className="img-fluid bellwether-image"
              />
            </div>
            <Card inverse className="bellwether-section-reserve pt-md-5" style={{ backgroundColor: 'transparent', position: 'relative' }}>
              <Row className="w-100 text-white align-items-center justify-content-center pt-md-5">
                <Col xs={12} lg={8} className="mx-auto text-center p-lg-5 bg-black">
                  <Row>
                    <Col className="text-start">
                      {' '}
                      <a href="/StarlightSmartInvest" className="bellwether-nav-button">
                        {' '}
                        &#x3c; Back
                      </a>
                    </Col>
                    <Col className="text-end">
                      <a href="/StarlightSmartInvest/big-tech" className="bellwether-nav-button">
                        {' '}
                        Next
                      </a>
                    </Col>
                  </Row>
                  {item.attributes.Head.map((head, headIndex) => {
                    return (
                      <Row key={headIndex.toString()} className="text-start bellwether-head pt-4">
                        <h3 style={{ color: '#FAFAFA' }}>BellWeather</h3>
                      </Row>
                    )
                  })}

                  <div style={{ textAlign: 'left' }}>
                    {item?.attributes?.description.map((attributeItem, attributeIndex) => {
                      return (
                        <p key={attributeIndex.toString()} className="bellwether-head-sub">
                          {attributeItem.description}
                        </p>
                      )
                    })}
                    {item?.attributes?.Content
                      .map((contentItem) => {
                        return (
                          <>
                            <h4 className="bellwether-des-head">{contentItem.Title}</h4>
                            {contentItem.content.map((contentItemContent, contentItemContentIndex) => {
                              return (
                                <p key={contentItemContentIndex.toString()} className="bellwether-des-head-sub">
                                  {contentItemContent.description}
                                </p>
                              )
                            })}
                          </>
                        )
                      })}
                    <div className="text-center bellwether-performance-head">Historical Performance</div>
                    <CardImg
                      onClick={toggle}
                      src={'https://starwealth-dev-strapie.livelocal.delivery' + (item.attributes.MainImage?.data?.attributes?.url || '')}
                    />
                    <br />
                    <br />
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="3" className="text-center">
                            <div>
                              <SquareFill color="#607980" size={75} />
                              <div className="bigtech-percentage">32.05%</div>
                            </div>
                            <div className="bigtech-percentage-text">
                              3 yr <br /> Annualised Rolling <br />
                              Return
                            </div>
                            <div></div>
                          </Col>
                          <Col md="3" className="text-center">
                            <div>
                              <CircleFill color="#607980" size={75} />
                              <div className="bigtech-percentage">31.03%</div>
                            </div>
                            <div className="bigtech-percentage-text">
                              5 year <br /> Annualised Rolling <br />
                              Return
                            </div>
                            <div></div>
                          </Col>
                          <Col md="3" className="text-center">
                            <div>
                              <CircleHalf color="#607980" size={75} />
                              <div className="bigtech-percentage">1.07</div>
                            </div>
                            <div className="bigtech-percentage-text">
                              1 yr <br /> Model Portfolio <br />
                              Beta
                            </div>
                            <div></div>
                          </Col>
                          <Col md="3" className="text-center">
                            <div>
                              <TriangleFill color="#607980" size={75} />
                              <div className="bigtech-percentage">0.68%</div>
                            </div>
                            <div className="bigtech-percentage-text">
                              1 yr <br /> Dividend Yield
                            </div>
                            <div></div>
                          </Col>{' '}
                        </Row>

                      </Col>
                    </Row>
                    <br />
                    <br />
                    {item.attributes.ImportantInformation.map((importantInformationItem) => {
                      return (
                        <>
                          <h4 className="bellwether-des-head">{importantInformationItem.Title}</h4>
                          {importantInformationItem.content.map((importantInformationItemContent, importantInformationItemIndex) => {
                            return (
                              <p key={importantInformationItemIndex.toString()} className="bellwether-des-head-sub">
                                {importantInformationItemContent.description}
                              </p>
                            )
                          })}
                        </>
                      )
                    })}
                    <div className="bellwether-warning-text">
                      {item.attributes.Warning.map((warningItem, warningItemIndex) => {
                        return (

                          <p key={warningItemIndex.toString()} className="bellwether-des-head-sub pt-2">
                            {warningItemIndex.description}
                          </p>
                        )
                      })}
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        )
      })}
    </>
  );
}
export default Bellwether;
