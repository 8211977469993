import React from 'react';
import './successmodal.scss';

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  onNav: () => void;
  password?: boolean;
  confirmPassword?: boolean;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, message, onNav, password = false, confirmPassword = false }) => {
  return (
    <div
      className={`modal ${isOpen ? 'show' : ''}`}
      tabIndex={-1}
      style={{
        display: isOpen ? 'block' : 'none',
        height: 1000,
        paddingTop: 100,
      }}
    >
      <div className="modal-dialog p-2 mt-5" role="document">
        <div className="modal-content custom-modal1">
          {password ||
            (confirmPassword && (
              <div className="cross d-flex flex-row-reverse ">
                <button type="button" className="close  btn  rounded-circle " onClick={onClose}>
                  <span>&times;</span>
                </button>
              </div>
            ))}

          <div className="modal-header  d-flex justify-content-center align-items-center p-0">
            <h5 className="modal-title "></h5>
          </div>

          <div className="modal-body  ">
            {/* <p className="text-primary message">{message}</p> */}
            <p className="text-primary message" dangerouslySetInnerHTML={{ __html: message }}></p>
          </div>
          <div className="modal-footer  d-flex justify-content-center p-0 mb-2 text-center">
            <button type="button" className=" btn-lg w-50  ok-btn-style" onClick={onNav}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
