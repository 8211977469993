import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import './faq.scss';
import axios from 'axios';
function Faq() {
  const [activeTab, setActiveTab] = useState('general');
  const [open, setOpen] = useState('');

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggle = (id: React.SetStateAction<string>) => {
    if (open === id) {
      setOpen('');

    } else {
      setOpen(id);

    }
  };
  const [tabName, setTabName] = useState([])
  const [faq, setFaq] = useState([])
  const [faq1, setFaq1] = useState([])
  const [faq2, setFaq2] = useState([])
  useEffect(() => {
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/tab-names?populate=*')
      .then(response => {
        const { data } = response;
        // Assuming data.data is an array of objects with a "title" property
        setTabName(data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  //Tab 1
  useEffect(() => {

    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/faq-tab1s?populate=Tab1,Tab1.title,Tab1.list')
      .then(response => {
        const { data } = response;

        setFaq(prev => ([...prev, ...data.data]));

        // setFaq(data);


      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  //Tab 2
  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/faq-tab2s?populate=Tab2,Tab2.title,Tab2.list')
      .then(response => {
        const { data } = response;

        setFaq1(prev => ([...prev, ...data.data]));

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);


  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/faq-tab3s?populate=AccordianItem1,AccordianItem1.title,AccordianItem1.list, ,AccordianItem2,AccordianItem2.list,AccordianItem3.title,AccordianItem3.description,AccordianItem3.subTitle,AccordianItem3.subTitleData,AccordianItem4,AccordianItem4.description,AccordianItem4.list,AccordianItem4.description1,AccordianItem4.list1,AccordianItem4.subDescription,AccordianItem5.subTitle,AccordianItem5.description,AccordianItem5.subTitleData,AccordianItem5.subTitle1,AccordianItem5.subTitleData1')
      .then(response => {
        const { data } = response;

        setFaq2(prev => ([...prev, ...data.data]));

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className="faq-container mb-10 " style={{ marginBottom: '20px' }}>
      <Row className="d-flex align-items-center " style={{ marginTop: '45px' }}>
        <Col className="col-12" md="12 mt-5">
          <h2 className="text-center">Frequently Asked Questions</h2>
        </Col>
      </Row>
      <div className="text-center">
        <Row className="justify-content-center">
          <Col xs="12" md="8">
            {/* {Array.isArray(tabName) && tabName.map((tab, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    style={{
                      fontWeight: activeTab === tab.title ? '700' : 'normal',
                      color: '#FAFAFA',
                      fontSize: '15px',
                      fontFamily: 'Montserrat',
                    }}
                    href="#"
                    onClick={() => toggleTab(tab.title)}
                  >
                    {tab.title}
                  </NavLink>
                </NavItem>
              );
            })} */}

            <Nav className="faq-container text-center color-black">
              <NavItem>
                <NavLink
                  style={{
                    fontWeight: activeTab === 'general' ? '700' : 'normal',
                    color: '#FAFAFA',
                    fontSize: '15px',
                    fontFamily: 'Montserrat',
                  }}
                  href="#"
                  onClick={() => toggleTab('general')}
                >
                  General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontWeight: activeTab === 'smartReserve' ? '700' : 'normal', color: '#FAFAFA', fontSize: '15px' }}
                  href="#"
                  onClick={() => toggleTab('smartReserve')}
                >
                  Smart Reserve
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ fontWeight: activeTab === 'howDoI' ? '700' : 'normal', color: '#FAFAFA', fontSize: '15px' }}
                  href="#"
                  onClick={() => toggleTab('howDoI')}
                >
                  How do I ...
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              {activeTab === 'general' && (
                <TabPane tabId="general" className="content-container">
                  <div className="text-center">
                    {Array.isArray(faq) && faq.map((faqItem: any, index) => {
                      return (
                        <Accordion flush open={open} toggle={toggle} className="p-3">
                          {faqItem.attributes.Tab1.map((item, index) => {
                            const targetId = `accordion-${index}`;
                            return (
                              <AccordionItem key={targetId} className="mr-20 content">
                                <AccordionHeader
                                  color="red"
                                  targetId={targetId}
                                  style={{
                                    color: 'white',
                                    backgroundColor: 'black',
                                    boxShadow: 'none',
                                    borderColor: 'transparent',
                                    marginLeft: '-20px',
                                  }}
                                  onClick={() => toggle(targetId)}
                                >
                                  {item.title}
                                </AccordionHeader>

                                {item.list.map((subItem, subIndex) => {
                                  return (
                                    <AccordionBody key={`${targetId}-${subIndex}`} accordionId={targetId}>
                                      <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                        {subItem.description}
                                      </div>
                                    </AccordionBody>
                                  );
                                })}
                              </AccordionItem>
                            );
                          })}
                        </Accordion>
                      )
                    })}
                  </div>
                </TabPane>
              )}
              {activeTab === 'smartReserve' && (
                <TabPane tabId="smartReserve" className="content-container">
                  <div className="text-center">
                    {Array.isArray(faq1) && faq1.map((faqItem: any) => {
                      return (
                        <Accordion flush open={open} toggle={toggle} className="p-3">
                          {faqItem.attributes.Tab2.map((item, index) => {
                            const targetId = `accordion-${index}`;
                            return (


                              <AccordionItem key={targetId} className="content">
                                <AccordionHeader targetId={targetId} style={{ marginLeft: '-20px' }}>
                                  {item.title}
                                </AccordionHeader>
                                {item.list.map((itm, subIndex) => {
                                  return (


                                    <AccordionBody key={`${targetId}-${subIndex}`} accordionId={targetId}>
                                      <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                        {itm.description}
                                      </div>
                                    </AccordionBody>
                                  )
                                })}
                              </AccordionItem>
                            )
                          })}
                        </Accordion>
                      )
                    })}
                  </div>
                </TabPane>
              )}
              {activeTab === 'howDoI' && (
                <TabPane tabId="howDoI" className="content-container">
                  <div className="text-center">
                    {Array.isArray(faq2) && faq2.map((faqItem: any) => {
                      return (
                        <Accordion flush open={open} toggle={toggle} className="p-3">
                          {faqItem?.attributes?.AccordianItem1.map((item) => {
                            return (

                              <>
                                <AccordionItem className="content">
                                  <AccordionHeader targetId="1" style={{ marginLeft: '-20px', color: 'white', fontWeight: '400' }}>
                                    {item.title}
                                  </AccordionHeader>
                                  <AccordionBody accordionId="1">
                                    <div style={{ textAlign: 'left' }}>

                                      <ListGroup numbered style={{ backgroundColor: 'black', color: 'white', fontWeight: '400' }}>
                                        {item.list.map((itm) => {
                                          return (
                                            <ListGroupItem className="text-left align-item-left list-group">
                                              {itm.description}
                                            </ListGroupItem>
                                          )
                                        })}
                                      </ListGroup>

                                    </div>
                                  </AccordionBody>

                                </AccordionItem>

                                <AccordionItem className="content">
                                  {faqItem.attributes.AccordianItem2.map((item) => {

                                    return (
                                      <>
                                        <AccordionHeader targetId="2" style={{ marginLeft: '-20px' }}>
                                          {item.title}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                          <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>

                                            <ListGroup>


                                              {item.list.map((itm) => {
                                                return (


                                                  <ListGroupItem className="text-left align-item-left list-group">
                                                    {itm.description}

                                                  </ListGroupItem>
                                                )
                                              })}

                                            </ListGroup>

                                          </div>
                                        </AccordionBody>
                                      </>
                                    )
                                  })}
                                </AccordionItem>
                                <AccordionItem className="content">
                                  {faqItem.attributes.AccordianItem3.map((faq3) => {
                                    return (
                                      <>
                                        <AccordionHeader targetId="3" style={{ marginLeft: '-20px' }}>
                                          {faq3.Title}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                          <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                            {faq3.description.map((item) => {
                                              return (
                                                <>
                                                  {item.description}
                                                </>
                                              )
                                            })}
                                            {faq3.subTitle.map((itm) => {
                                              return (
                                                <p className="mt-3">{itm.description}</p>
                                              )
                                            })}
                                            <ListGroup numbered className="mt-4 mb-4">
                                              {faq3.subTitleData.map((data) => {
                                                return (


                                                  <ListGroupItem className="list-group">
                                                    {data.description}
                                                  </ListGroupItem>

                                                )
                                              }
                                              )}
                                            </ListGroup>

                                          </div>
                                        </AccordionBody>
                                      </>
                                    )
                                  })}
                                </AccordionItem>

                                <AccordionItem className="content">
                                  {faqItem.attributes.AccordianItem4.map((faq4) => {
                                    return (
                                      <>
                                        <AccordionHeader targetId="4" style={{ marginLeft: '-20px' }}>
                                          {faq4.Title}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="4">
                                          <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                            <ListGroup numbered>
                                              {faq4.description.map((item) => {
                                                return (


                                                  <p> {item.description}</p>
                                                )
                                              })}
                                              {faq4.list.map((data) => {
                                                return (
                                                  <>
                                                    <ListGroupItem className="text-left align-item-left list-group">
                                                      {data.description}
                                                    </ListGroupItem>

                                                  </>
                                                )
                                              })}
                                            </ListGroup>
                                            {faq4.subDescription.map((data) => {
                                              return (


                                                <p> {data.description}</p>
                                              )
                                            })}
                                            {faq4.description1.map((data) => {
                                              return (

                                                <p>  {data.description}</p>
                                              )
                                            })}
                                            <ListGroup numbered>
                                              {faq4?.list1.map((data) => {
                                                return (
                                                  <ListGroupItem className="text-left align-item-left list-group">
                                                    {' '}
                                                    {data.description}
                                                  </ListGroupItem>

                                                )
                                              })}
                                            </ListGroup>

                                            <p>
                                              2. We will confirm by secure message once we have validated and executed your new investment instructions.
                                            </p>
                                          </div>
                                        </AccordionBody>
                                      </>
                                    )
                                  })}
                                </AccordionItem>

                                <AccordionItem className="content">
                                  {faqItem.attributes.AccordianItem5.map((faq5) => {
                                    return (
                                      <>

                                        <AccordionHeader targetId="5" style={{ marginLeft: '-20px' }}>
                                          {faq5.title}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="5">
                                          <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                            {faq5.description.map((item) => {
                                              return (


                                                <p>
                                                  {item.description}
                                                </p>
                                              )
                                            })}
                                            <ListGroup numbered style={{ backgroundColor: 'black' }}>
                                              {faq5.subTitle.map((item) => {
                                                return (
                                                  <p>
                                                    {item.description}
                                                  </p>
                                                )
                                              })}

                                              {faq5.subTitleData
                                                .map((item) => {
                                                  return (

                                                    <ListGroupItem className="text-left align-item-left list-group">
                                                      {item.description}
                                                    </ListGroupItem>
                                                  )
                                                })}
                                            </ListGroup>
                                            {faq5.subTitle1.map((item) => {
                                              return (
                                                <p>
                                                  {item.description}
                                                </p>
                                              )
                                            })}
                                            <ListGroup>
                                              {faq5.subTitleData1.map((item) => {
                                                return (
                                                  <ListGroupItem className="text-left align-item-left list-group">
                                                    {item.description}
                                                  </ListGroupItem>

                                                )
                                              })}
                                            </ListGroup>
                                          </div>
                                        </AccordionBody>
                                      </>
                                    )
                                  })}
                                </AccordionItem>
                              </>
                            )
                          })}
                        </Accordion>
                      )
                    })}
                  </div>
                </TabPane>
              )}
            </TabContent>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Faq;
