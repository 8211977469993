import * as React from 'react';
import { Col, Row } from 'reactstrap';
import './account.scss';
import { useAppDispatch } from 'app/config/store';
import { useEffect, useState } from 'react';
import { getAgreements } from './accounts.reducer';



const ExternalLinks = () => {

    const dispatch = useAppDispatch();
    const [links, setLinks] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const response = await dispatch(getAgreements()).unwrap();
                setLinks(response);
            } catch (error) {
                console.error('Error fetching links:', error);
            }
        };

        fetchLinks();
    }, [dispatch]);


    const handleLinkClick = (e, link) => {
        e.preventDefault();
        window.open(link.document_url, '_blank');
    };


    return (
        <>
            <div className="form-container   pt-5  " >
                <div style={{ backgroundColor: "#fff", color: "black", padding: "30px", fontSize: "20px", display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", height: "auto" }}>
                    <Row>

                        <Col md="12" className="form-firstrow-account-head-custom pt-2 pb-3 text-start">
                            Interactive Brokers Ireland Standard Terms And Disclosures
                        </Col>
                    </Row>

                    <Row className='pt-3'>
                        {links.map((link) => (
                            <Col md="12" key={link.form_number} className="text-start mb-3">
                                <li >
                                    <a
                                        href="#"
                                        onClick={(e) => handleLinkClick(e, link)}
                                        style={{ color: '#597177' }}
                                    >
                                        {link.title}
                                    </a>
                                </li>
                            </Col>
                        ))}
                    </Row>

                </div >
            </div>

        </>
    );
};

export default ExternalLinks;


