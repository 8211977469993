import React from 'react'
import DashboardHeader from '../dashboardheader/DashboardHeader'
import "./style.scss"
import { Outlet } from 'react-router-dom'

const DashBoard = () => {
  return (
      <div className='dashboard-bg'>
          <DashboardHeader />
      {/* <div className="unknown-div" /> */}
      <div className="col-12 d-flex justify-content-center text-start">
        <div className="row width-full-page pb-2 relative-div-bg">
          <Outlet />
        </div>
      </div>
      
    </div>
  )
}

export default DashBoard