import { useAppDispatch } from 'app/config/store'
import { depositConfig, getIbkr, getPortfolio } from 'app/entities/accounts/accounts.reducer'
import React, { useEffect, useState } from 'react'
import {  Accordion, Card, CardBody } from 'react-bootstrap'
import { Button } from 'reactstrap'
 

const MyStarLight = () => {
  const [ibkrData, setIbkrData] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);
  const [ibkrNum, setIbkrNum] = useState(null);

  const [depositDetails, setDepositDetails] = useState(null);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getIbkr()).then((response: any) => {
      if (response?.payload) {
        setIbkrData(response.payload);
        setIbkrNum(response.payload.ibkr_account_number)
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if(ibkrData){
    dispatch(getPortfolio(ibkrNum)).then((response: any) => {
      if (response?.payload) {
        setPortfolioData(response.payload);
      }

    });
    
  }}, [ibkrData]);

  useEffect(() => {
    dispatch(depositConfig()).then((response:any) => {
      setDepositDetails(response?.payload);
    });
  }, []);
 
  return (
    
    <div className="col-12 pb-5">

<div className='heading-dashboard'>My Starlight:€{portfolioData?.balance}</div>

      <div className="pt-3">
          <Card className=' dashboard-custom-card'>
              <CardBody>
            <div className='dashboard-card-heading'>
              My Starlight Smart Account:&nbsp;
              <span className='span-account-number px-2 '>{portfolioData?.ibkr_account_number}</span>
            </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Investments:</div>
                      <div className="col sub-content-dashboard">€{portfolioData?.investment}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Cash:</div>
                      <div className="col sub-content-dashboard">€{portfolioData?.cash}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Gains  & Interest:</div>
                      <div className="col sub-content-dashboard">€{portfolioData?.gain_and_interest}</div>
                  </div>
              </CardBody>
          </Card>
      </div>
      <div className="pt-3">
      <Card className='dashboard-custom-card'>
  <CardBody>
    <div className='dashboard-card-heading'>
      My Starlight Portfolio
    </div>
    <div className="pt-2">
      <table className='dashboard-table-custom'>
        <thead>
          <tr>
            <th>Investment</th>
            <th>Principal Invested</th>
            <th>Gains & Interest</th>
            <th>Market Value (€)</th>
          </tr>
        </thead>
        <tbody>
  {portfolioData && portfolioData.starlight_portfolio &&
    Object.entries(portfolioData.starlight_portfolio).map(([key, value]: [string, { investment: number; gain_and_interest: number; market_value: number }]) => (
      <tr key={key}>
        <td className='sub-content-dashboard'>CASH({key})</td>
        <td className='sub-content-dashboard'>{value.investment.toFixed(2)}</td>
        <td className='sub-content-dashboard'>{value.gain_and_interest.toFixed(2)}</td>
        <td className='sub-content-dashboard'>{value.market_value.toFixed(2)}</td>
      </tr>
    ))
  }
</tbody>

      </table>
    </div>
  </CardBody>
</Card>

</div>
      
      <div className="pt-5 accordion-div-dashboard">
        <Card className=" dashboard-custom-card pt-3">
          <CardBody>
            <div className="dashboard-card-heading">My Pending Items & Tasks</div>
            <Accordion alwaysOpen>
                  <Accordion.Item eventKey={"0"}>
                    <Accordion.Header>
                    <div className='dashboard-card-sub-heading'>Pending Item Label 1</div>
                    </Accordion.Header>
                <Accordion.Body>
                  <div className='sub-content-dashboard'>
                    Nunc eget convallis tortor, id rhoncus tellus. Vestibulum turpis urna, sodales eget turpis
                    sit amet, porta blandit sapien. Ut a mi et augue lacinia sagittis in vitae ex. Vivamus
                    tristique est id odio vestibulum, nec consectetur nisi iaculis. Integer sed ipsum ac
                    turpis rutrum volutpat.
                  </div>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Line item information</div>
                    <div className="col-2">
                      <Button className='button-pending-dashboard'>
                          Pending
                      </Button>
                    </div>
                    <div className="">
                      <a href="">action required</a>
                    </div>
                  </div>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Line item information</div>
                    <div className="col-2">
                      <Button className='button-completed-dashboard'>
                          Completed
                      </Button>
                    </div>
                    <div className="">
                      <a href="">action required</a>
                    </div>
                  </div>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div className="col-3">Line item information</div>
                    <div className="col-2">
                      <Button className='button-error-dashboard'>
                          Error
                      </Button>
                    </div>
                    <div className="">
                      <a href="">action required</a>
                    </div>
                  </div>
                    </Accordion.Body>
                  </Accordion.Item>
            </Accordion>
          </CardBody>
        </Card>
      </div>
      

          {/* <div className="row">
              <div className="col-8">
              <div className='heading-dashboard'>Your Investment Portal</div>
          <div className='content-dashboard pt-3'>
              Click on the <b>Investment Portal</b> button to securely view your portfolio holdings, download investment account activity
              statements and the change your bank details. Your Investment Portal is powered by our investment broker, Interactive Brokers Ireland Limited.
          </div>
              </div>
              <div className="col text-end">
                  <button className='button-dashboard-content'>
                    Investment Portal
                  </button>
              </div>
          </div>
          
          <div className="pt-3">
          <Card className=' dashboard-custom-card'>
              <CardBody>
                  <div className='dashboard-card-heading'>Your Interactive Brokers Account Details:</div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">Account Number:</div>
                      <div className="col sub-content-dashboard">{ibkrData?.ibkr_account_number}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">User Name:</div>
                      <div className="col sub-content-dashboard">{ibkrData?.ibkr_username}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">Account Status:</div>
                      <div className="col sub-content-dashboard">{ibkrData?.ibkr_account_status}</div>
                  </div>
              </CardBody>
          </Card>
          </div>

          <div className="pt-4 ">
            <Card className=' dashboard-custom-card'>
                <CardBody>
                    <div className='dashboard-card-heading'>Logging in to the Investment Portal for the first time:                  </div>
                    <ol>
                        <li className='sub-content-dashboard pt-2'>You will need your Interactive Brokers User Name (above) and your password (sent to you by email) to log in.</li>
                        <li className='sub-content-dashboard pt-2'>You will be prompted to change your password, to one which only you will know.</li>
                        <li className='sub-content-dashboard pt-2'>You will be prompted to verify your email address and mobile number, to set up secure 2 Factor Authentication (2FA) on your account.</li>
                    </ol>
                </CardBody>
            </Card>
          </div>
          
          <div className="pt-4 ">
            <Card className=' dashboard-custom-card'>
                <CardBody>
                      <div className='dashboard-card-heading'>Funding your Starlight Wealth Account                  </div>
                      <div className='sub-content-dashboard pt-2'>To fund your Starlight Account, please enter the information below in your online bank app or in your branch.</div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">Account Title:</div>
                        <div className="col sub-content-dashboard">  {depositDetails?.["Account Title"]}</div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">IBAN:</div>
                        <div className="col sub-content-dashboard">  {depositDetails?.["IBAN"]}</div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">BIC:</div>
                        <div className="col sub-content-dashboard">{depositDetails?.["BIC"]}</div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">Beneficiary Bank:</div>
                        <div className="col sub-content-dashboard"> {depositDetails?.["Beneficiary Bank"]}</div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-3 dashboard-card-sub-heading">Payment Reference:</div>
                        <div className="col sub-content-dashboard">{ibkrData?.ibkr_account_number}</div>
                      </div>

                </CardBody>
            </Card>
          </div>
          
          <div className="pt-4">
            <Card className=' dashboard-custom-card'>
                <CardBody>
                    <div className='dashboard-card-heading'>Important</div>
                    <ol>
                        <li className='sub-content-dashboard pt-2'>You must include your Interactive Brokers account number as the payment reference.</li>
                        <li className='sub-content-dashboard pt-2'>For EUR fund transfer only, please contact us if you wish to transfer funds in another currency.</li>
                        <li className='sub-content-dashboard pt-2'>Please save the payee and payment details in your online bank app as a regular payee.</li>
                    </ol>
                </CardBody>
            </Card>
          </div>
          
               */}
    </div>

  )
}

export default MyStarLight