
import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { Storage } from 'react-jhipster';
import { defaultValue } from 'app/shared/model/user.model';
const initialState = {
  loading: false,
  registrationSuccess: false,
  registrationFailure: false,
  errorMessage: null,
  entity: defaultValue,
  successMessage: null,
  otpStatus: '',
  forgotPass: '',
  resetPass: '',
  initialPath: '',
};

const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
const USER_NAME = 'user-name';
const USER_ROLE = 'ROLE';
const NAME = 'name';
const PHONE_NUMBER = 'phone_number';
const ACCOUNT_TYPE = 'account_type';
export type RegisterState = Readonly<typeof initialState>;

const APIlogin = `${SERVER_API_URL}api/user/login`;
const otp = `${SERVER_API_URL}api/user/sendOtp`;
const forgotOtp = `${SERVER_API_URL}api/public/sendOtp`;
const forgot = `${SERVER_API_URL}api/user/forgotPassword`;
const passwordReset = `${SERVER_API_URL}api/user/forgotPassword`;
const validateEmail = `${SERVER_API_URL}api/user/validate`;
interface ForgotPasswordData {
  username: string;
  password: string;
  transaction_id: string;
  otp: string;
  event: string;

}
interface validateData {
  username: string;
  password: string;
  userType: string;

}
interface ResetPasswordData {
  username: string;
  password: string;
  current_password: string;

  event: string;
}
export const handleRegister = createAsyncThunk(
  'register/create_account',
  async (data: { login: string; email: string; password: string; langKey?: string; dob?: string }) => axios.post<any>('api/register', data),
  { serializeError: serializeAxiosError },
);
export const customerLoginOtp = createAsyncThunk('customer/otp', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post<any>(otp, data);

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    // Reject the promise with a meaningful error message
    return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
  }
});
export const customerValidateEmail = createAsyncThunk('customer/validate', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post<any>(validateEmail, data);

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    // Reject the promise with a meaningful error message
    return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
  }
});
export const forgotLoginOtp = createAsyncThunk('customer/forgot/otp', async (data: any, thunkAPI) => {
  try {
    const response = await axios.post<any>(forgotOtp, data);

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    // Reject the promise with a meaningful error message
    return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
  }
});
export const postLogin = createAsyncThunk(
  'register/login_account',
  async (data: any) => {
    try {
      const response = await axios.post<any>(APIlogin, data);
      let jwt = null;
      if (response?.data?.access_token) {
        jwt = response.data.access_token;
        if (jwt) {
          const decoded: any = jwtDecode(jwt);
          sessionStorage.setItem(AUTH_TOKEN_KEY, jwt);
          const mainRole = decoded?.realm_access.roles.indexOf('ROLE_ADMIN') > -1 ? 'ROLE_ADMIN' : 'ROLE_USER';
          sessionStorage.setItem(NAME, decoded?.name);
          sessionStorage.setItem(USER_NAME, decoded?.preferred_username);
          sessionStorage.setItem(USER_ROLE, mainRole);
          Storage.local.set(AUTH_TOKEN_KEY, jwt);
          localStorage.setItem(AUTH_TOKEN_KEY, jwt);
          // sessionStorage.setItem(USER_TYPE, decoded?.name);
        }
      }

      return { data: response.data, jwt };

    } catch (error) {
      return error;
    }
  },
  { serializeError: serializeAxiosError },
);

export const forgotPassword = createAsyncThunk('login/forgot_password', async (data: ForgotPasswordData, { rejectWithValue }) => {
  try {
    const response = await axios.post<any>(forgot, data);
    return response.data;
  } catch (error: any) {
    console.error('Error:', error);
    // Return a rejected value to be handled by the extraReducers in your slice
    return rejectWithValue(error.response?.data || 'Something went wrong');
  }
});

// actions.ts
export const resetPassword = createAsyncThunk('/reset/password', async (data: ResetPasswordData, { rejectWithValue }) => {

  try {
    // Assume you have an API call here
    const response = await axios.post<any>(passwordReset, data);
    return response;
  } catch (error: any) {

    return rejectWithValue(error.response?.data || 'Something went wrong');
  }

});

export const RegisterSlice = createSlice({
  name: 'register',
  initialState: initialState as RegisterState,
  reducers: {
    reset() {
      return initialState;
    },
    saveInitialPath: (state, action) => {
      state.initialPath = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(handleRegister.pending, state => {
        state.loading = true;
      })
      .addCase(handleRegister.rejected, (state, action) => ({
        ...initialState,
        registrationFailure: true,
        errorMessage: action.error.message,
      }))
      .addCase(handleRegister.fulfilled, () => ({
        ...initialState,
        registrationSuccess: true,
        successMessage: 'Registration saved! Please check your email for confirmation.',
      }))
      .addCase(customerLoginOtp.fulfilled, (state, action) => {
        state.otpStatus = 'succeeded';
        // handle the fulfilled state, e.g., save the OTP data
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPass = action.payload.data;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPass = action.payload.data;
      })

      .addCase(postLogin.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.registrationSuccess = true;
        state.successMessage = 'Login successfully.';
      });
  },
});

export const { reset, saveInitialPath } = RegisterSlice.actions;

export default RegisterSlice.reducer;

