/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { ValidatedField } from 'react-jhipster';
import { Button, Modal, Row, Col, Form } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FieldError, useForm } from 'react-hook-form';
import './login.scss';
import SuccessModal from './successmodal';
import { customerLoginOtp, postLogin } from '../account/register/register.reducer';
import { useAppDispatch } from 'app/config/store';
import CommonToast from '../components/CommonToast';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ValidatedDatePickerField from '../components/ValidatedDatePickerField';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: (
    role: string,
    username: string,
    password: string,
    firstname: string,
    lastname: string,
    email: string,
    dob: string,
    phone: string,
  ) => void;
  handleClose: () => void;
}

const LoginModal = (props: ILoginModalProps) => {

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const toggleSignInModal = () => {
    navigate('/signin');
  };
  const login = ({ username, password, firstname, lastname, email, dob, phone, role }) => {
    props.handleLogin(username, password, firstname, lastname, email, dob, phone, role);
  };

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    getFieldState,
    formState: { isValid,errors, touchedFields },
  } = useForm({ mode: 'onChange' });

  const { loginError, handleClose } = props;
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [Username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const handlePasswordChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const minLength = 8;
    const minLowercase = 1;
    const minUppercase = 1;
    const minNumbers = 1;
    const minSymbols = 1;

    const lowercaseRegex = /[a-z]/g;
    const uppercaseRegex = /[A-Z]/g;
    const numbersRegex = /[0-9]/g;
    const symbolsRegex = /[^a-zA-Z0-9]/g;

    const isMinLengthValid = newPassword.length >= minLength;
    const isMinLowercaseValid = (newPassword.match(lowercaseRegex) || []).length >= minLowercase;
    const isMinUppercaseValid = (newPassword.match(uppercaseRegex) || []).length >= minUppercase;
    const isMinNumbersValid = (newPassword.match(numbersRegex) || []).length >= minNumbers;
    const isMinSymbolsValid = (newPassword.match(symbolsRegex) || []).length >= minSymbols;

    if (!isMinLengthValid || !isMinLowercaseValid || !isMinUppercaseValid || !isMinNumbersValid || !isMinSymbolsValid) { /* empty */ }

    if (confirmPassword !== '' && newPassword !== confirmPassword) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };

  const handleConfirmPasswordChange = e => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (password !== '' && newConfirmPassword !== password) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
    }
  };

  const validatePassword = value => {
    if (!value) return 'Password cannot be empty!';
    if (value.length < 8) return 'Password must be at least 8 characters long!';
    if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/.test(value))
      return 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character!';
    return true;
  };

  const handleRegSubmit = data => {
    if (password !== confirmPassword) {
      setPasswordMismatch(true);
      return;
    }
    handleSubmit(login)(data);
  };
  const handleOtpVerification = async () => {
    const data = {
      email: Username,

    }
    try {
      const response: any = await dispatch(customerLoginOtp(data));
      if (response.payload.data) {

        setToastMessage((prev) => "otp send successfully");
        setToastType('success');
        setToastVisible(true);
        // navigate('/');
        // window.location.reload();
      }
    } catch (error) { /* empty */ }
  }

  const handleLoginSubmit = async () => {
    const data: any = {
      username: Username,
      password,

    };
    try {
      const response: any = await dispatch(postLogin(data));
      if (response.payload.data) {
        alert('logged in successful');

        // navigate('/');
        // window.location.reload();
      }
    } catch (error) { /* empty */ }
  };

  const isOver18 = dob => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1 >= 18;
    }
    return age >= 18;
  };

  // const handleKeyDown = (event) => {
  //   const allowedKeys = /[0-9+\b]/;
  //   if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
  //     event.preventDefault();
  //   }
  // };
  const handleKeyDown = (event) => {
    const allowedKeys = /[0-9\b]/;
    const value = event.target.value;

    if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
      event.preventDefault();
    }

    if (value.length >= 13 && event.keyCode !== 8) {
      event.preventDefault();
    }

    if (event.target.selectionStart < 5 && (event.keyCode !== 8 || event.target.selectionStart !== event.target.selectionEnd)) {
      event.preventDefault();
    }

    if ((event.key === 'Backspace' || event.keyCode === 8) && event.target.selectionStart <= 5) {
      event.preventDefault();
    }

    if (event.key === '+') {
      event.preventDefault();
    }
  };

  // const handleInputChange = (event) => {
  //   const value = event.target.value;
  //   if (value.length > 13) {
  //     event.target.value = value.slice(0, 13);
  //   }
  // };

  return (
    <>
      <>

        <CommonToast
          visible={toastVisible}
          onClose={() => setToastVisible(false)}
          title={toastType === 'success' ? 'Success' : 'Error'}
          description={toastMessage}
          type={toastType}
          isAutoClose={true}

        />
      </>
      <Modal isOpen={props.showModal} toggle={handleClose} backdrop="static" id="login-page" autoFocus={false} width="80%" size="lg">
        <Form onSubmit={handleSubmit(handleRegSubmit)}>
          <Row
            style={{ backgroundImage: "url('../../../content/images/starlight-login.webp')"}}
            className="justify-content-center"
          >
            <Col md="9" style={{ paddingBottom: '32px', fontSize: '13px' }}>
              <Row>
               <Row className="mt-5 align-items-center">
                <Col className="d-flex justify-content-center" xs={12} md={12}>
                    <div style={{backgroundColor:'black'}}>
                      <label style={{ fontSize: '30px', fontWeight: '300', fontFamily: 'Raleway', color: 'white',padding:'10px'}}>Starlight Wealth</label>
                    </div>
                    
                    {/* <img className="img-fluid logo-dashboard" src="../../../../../content/images/white_new_logo.svg" /> */}
                </Col>
                <Col className="d-flex justify-content-between align-items-center" xs={12} md={12}>
              <label style={{ fontSize: '26px', fontWeight: '500', fontFamily: 'Raleway', color: 'white',marginTop:"20px" }}>Register</label>
                    <Button className="reg-close" onClick={handleClose} style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontSize: '10px',
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}>
                    X
                  </Button>
                </Col>
              </Row>
                <Col md="12">
                  <span style={{ whiteSpace: 'nowrap', fontSize: '15px', color: 'white', fontFamily: 'Montserrat' }}>
                    Already have an account?{' '}
                  </span>
                  <Link to="/signin" style={{ color: '#9fcdda', fontWeight: '600', fontFamily: 'Montserrat' }}>
                    Log In
                  </Link>
                </Col>
                <Row>
                  {/* {' '}
                  <Col md="12" className="mt-4 ">
                    <Input
                      type="select"
                      name="role"
                      id="role"
                      className="textfield-style"
                      onChange={e => setUser(e.target.value)}
                      value={role}
                      register={register}
                      required
                    >
                      <option>Admin</option>
                      <option>Client</option>
                    </Input>
                  </Col> */}
                  <Col md="6" className="mt-4 ">
                    <ValidatedField
                      name="firstname"
                      label="First Name"
                      placeholder="Enter your first name"
                      required
                      autoFocus
                      className="validated-field2"
                      data-cy="username"
                      validate={{ required: 'Username cannot be empty!' }}
                      register={register}
                      error={errors.username as FieldError}
                      style={{ width: '100%', padding: '3px 12px', fontFamily: 'Montserrat' }}
                    />
                  </Col>{' '}
                  <Col md="6" className="mt-4">
                    <ValidatedField
                      name="lastname"
                      label="Last Name"
                      placeholder="Enter your last name"
                      required
                      className="validated-field2"
                      data-cy="username"
                      validate={{ required: 'Username cannot be empty!' }}
                      register={register}
                      error={errors.username as FieldError}
                      style={{ width: '100%', padding: '3px 12px', fontFamily: 'Montserrat' }}
                    />
                  </Col>
                </Row>
                <Row>
                  {' '}
                  <Col md="6">
                    <ValidatedField
                      name="email"
                      label="Email Address"
                      type="email"
                      placeholder="Enter your Email Address"
                      required
                      className="validated-field2"
                      data-cy="email"
                      validate={{
                        required: 'Email cannot be empty!',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Invalid email address',
                        },
                      }}
                      register={register}
                      error={errors.email as FieldError}
                      style={{ width: '100%', padding: '3px 12px', fontFamily: 'Montserrat' }}
                    />
                  </Col>{' '}
                  <Col md="6">
                    {/* <ValidatedField
                      name="phone"
                      label="Mobile Number"
                      placeholder="+353 Enter your mobile number"
                      required
                      className="validated-field2"
                      data-cy="mobile number"
                      validate={{
                        required: 'Mobile number cannot be empty!',
                        pattern: {
                          value: /^\+353\d{0,9}$/,
                          message: 'Please enter a valid mobile number starting with +353 and followed by up to 9 digits.',
                        },
                        validate: value => value.length === 13 || 'Mobile number must be exactly 13 characters long including +353',
                      }}
                      register={register}
                      error={errors.phone as FieldError}
                      style={{ width: '100%', padding: '3px 12px' }}
                      defaultValue="+353"
                      onKeyDown={handleKeyDown}
                    /> */}

                    <ValidatedField
                      name="phone"
                      label="Mobile Number"
                      placeholder="+353 Enter your mobile number"
                      required
                      className="validated-field2"
                      data-cy="mobile number"
                      validate={{
                        required: 'Mobile number cannot be empty!',
                        pattern: {
                          value: /^\+3538\d{0,8}$/,
                          message: 'Please enter a valid mobile number starting with +3538 and followed by up to 8 digits.',
                        },
                        validate: (value) =>
                          value.length === 13 || 'Mobile number must be exactly 13 characters long including +353',
                      }}
                      register={register}
                      error={errors.phone as FieldError}
                      style={{ width: '100%', padding: '3px 12px', fontFamily: 'Montserrat' }}
                      defaultValue="+3538"
                      onKeyDown={handleKeyDown}
                    // onChange={handleInputChange}
                    />

                  </Col>
                </Row>
                <Row style={{marginBottom: '10px'}}>
                <span className='validated-fieldCustomLabel'>* <span className='validated-field2'>Date of Birth</span></span>
              </Row>
                <ValidatedDatePickerField
                  onChange={(date) => {
                    setValue('dob', date);
                    trigger('dob');
                  }}
                  validate={{
                    required: 'Date entered is wrong!',
                    validate(value) {
                      const isValidDate = isOver18(value);
                      const year = new Date(value).getFullYear();
                      const isFourDigitYear = year.toString().length === 4;

                      return isValidDate && isFourDigitYear || 'You must be 18 years old or older and enter a valid four-digit year.';
                    }
                  }}
                  getFieldState={getFieldState}
                  register={register} />
                <Row style={{marginTop: '12px'}}>
                  <ValidatedField
                    name="password"
                    label="Enter Password"
                    placeholder="Enter your password"
                    id="password"
                    required
                    className="validated-field2"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    data-cy="password"
                    validate={{
                      required: 'Password cannot be empty!',
                      validate: validatePassword,
                    }}
                    register={register}
                    error={errors.password as FieldError}
                    style={{ width: '100%', padding: '3px 12px', fontFamily: 'Montserrat' }}
                  />
                  <div className="col">
                    <ul className="text-start" style={{ color: 'white', fontFamily: 'Montserrat' }}>
                      <li> Password must be at least 8 characters long</li>

                      <li>
                        Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character
                      </li>
                    </ul>
                  </div>
                  <ValidatedField
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm your password"
                    id="confirmPassword"
                    required
                    className="validated-field2"
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    data-cy="confirmPassword"
                    validate={{ required: 'Please confirm your password!' }}
                    register={register}
                    error={errors.confirmPassword as FieldError}
                    style={{ width: '100%', padding: '3px 12px', fontFamily: 'Montserrat' }}
                  />
                  {passwordMismatch && <p style={{ color: '#cc0000', fontWeight: 'bold', fontSize: '11px', fontFamily: 'Montserrat' }}>Passwords do not match!</p>}
                </Row>

                <Row>
                  <Col className="d-flex">
                    <ValidatedField
                      name="rememberMe"
                      type="checkbox"
                      check
                      id="rememberMeCheckbox"
                      value={true}
                      required
                      register={register}
                      onChange={e => setCheckboxChecked(e.target.checked)}
                    />
                    <label htmlFor="rememberMeCheckbox" style={{ color: 'white', fontFamily: 'Montserrat' }}>
                      I agree to the Starlight Wealth{' '}
                      <a
                        href="../../../content/policies/starlight_wealth_terms_of_business.pdf"
                        className="terms-and-conditions"
                        target="_blank"
                        style={{ color: 'white', fontFamily: 'Montserrat' }}
                      >
                        Terms of Business
                      </a>
                      ,{' '}
                      <a
                        href="../../../content/policies/starlight_wealth_data_privacy_notice.pdf"
                        className="terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'white', fontFamily: 'Montserrat' }}
                      >
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a
                        href="../../../content/policies/starlight_wealth_remuneration_and_fee_schedule.pdf"
                        className="terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'white', fontFamily: 'Montserrat' }}
                      >
                        Remuneration Policy
                      </a>
                      .
                    </label>
                  </Col>
                </Row>

                <Row className="d-flex justify-content-md-end">
                  <Col md="4" style={{ fontFamily: 'Montserrat' }}>
                    <Button style={{ fontFamily: 'Montserrat' }} className="open-btn-style" onClick={handleRegSubmit} disabled={!isCheckboxChecked ||!isValid ||passwordMismatch}>
                      Register
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          navigate('./otpscreen', {});
        }}
        message="Otp Send Success"
      />
    </>
  );
};

export default LoginModal;

