import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import {  getSortState } from 'react-jhipster';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './customer.scss';
import axios from 'axios';

export const Customer = () => {

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const [productData, setProductData] = useState([])
  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer 111f903d1749b067c02570ed711929e6481ed262b990607fb66e02820a4365f005a1adc270de7c0abea830b7452b3fe0201de5fbfd9ca2df852f84ac9de7f5b8d2515480a7d1109dd7309a7562ea071ef90f0cb1e2db4cc77ec2f7cae948cd46bf979b961e162174ecb9db275550c69399aa6c6b10b1cbd2d898cb2ac32a972e");

    axios.get("https://starwealth-dev-strapie.livelocal.delivery/api/starlight-products?populate=Products,Products.image,Products.button", { headers: { "Authorization": "Bearer 111f903d1749b067c02570ed711929e6481ed262b990607fb66e02820a4365f005a1adc270de7c0abea830b7452b3fe0201de5fbfd9ca2df852f84ac9de7f5b8d2515480a7d1109dd7309a7562ea071ef90f0cb1e2db4cc77ec2f7cae948cd46bf979b961e162174ecb9db275550c69399aa6c6b10b1cbd2d898cb2ac32a972e" } })
      .then(async response => {
        const { data } = response;
        const modifiedData = data?.data?.map(async (item: any) => {
          if (item.attributes.description) {
            item.attributes.description = item.attributes.description.replace(/className=\\"/g, 'className="');
          }
          if (item.attributes.Heading) {
            item.attributes.Heading = item.attributes.Heading.replace(/className=\\"/g, 'className="');
          }
          return item;
        });

        // Wait for all asynchronous modifications to complete
        const processedData = await Promise.all(modifiedData);

        setProductData(processedData);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }, []);
  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };
  const handleClick = () => {
    navigate('/open');
  };
  const handleClick1 = () => {
    navigate('/invest');
  };
  const handleClick2 = () => {
    navigate('/invest');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      {productData && productData.map((item: any) => {
        return (
          <>
            <h2 className="text-center" style={{ marginTop: '2rem' }}>
              {item.attributes.title}
            </h2>
            <div className="img-row">
              <Row>
                {item.attributes.Products.map((item) => {
                  return (
                    < Col md="4" xs="12" sm="12" className="ml-2" >


                      <img className="img-shape"
                        src={'https://starwealth-dev-strapie.livelocal.delivery' + (item?.image?.data?.attributes?.url || '')}

                      />
                      {/* <img className="img-shape " src="../../../content/images/StarlightSmartInvest.png" /> */}


                      <div style={{ marginTop: '2rem', color: 'white' }}>{item.title}</div>

                      <div className="pt-3">
                        {item.button.map((btn) => {
                          return (
                            < Button
                              type="button"
                              className="button-style align-content-center"
                              color="light"
                              tag={Link}
                              to={item.title === "Starlight Smart Invest" ? "/open" : "/invest"}
                            >
                              {btn.title}
                            </Button>
                          )
                        })}
                      </div>

                    </Col>
                  )
                })}


              </Row>
            </div >
          </>
        )
      })}
    </>
  );
};

export default Customer;
