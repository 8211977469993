// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container-reserve {
  margin: 0;
  width: 100%;
  height: auto;
  font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
  background-color: black !important;
  position: relative;
}

.contact-container-reserve-div {
  position: absolute;
  top: 0;
  width: 100%;
}

.section-reserve {
  width: 100%;
  color: white;
}

.billion-text {
  font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/smartReserve/smartReserve2.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,WAAA;EACA,YAAA;EACA,8DAAA;EACA,kCAAA;EACA,kBAAA;AACF;;AACA;EACE,kBAAA;EACA,MAAA;EACA,WAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;AAEF;;AAAA;EACE,8DAAA;EACA,eAAA;AAGF","sourcesContent":[".contact-container-reserve {\n  margin: 0;\n  width: 100%;\n  height: auto;\n  font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;\n  background-color: black !important;\n  position: relative;\n}\n.contact-container-reserve-div {\n  position: absolute;\n  top: 0;\n  width: 100%;\n}\n\n.section-reserve {\n  width: 100%;\n  color: white;\n}\n.billion-text {\n  font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
