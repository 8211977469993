import axios from 'axios';
import './footer.scss';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

const Footer = () => {
  const [footerData, setFooterData] = useState([])

  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/footers?populate=content.button,subTitle,description,subDescription,AboutButtons.button,StarlightAccountButtons.button,LegalButtons.button')
      .then(response => {
        const { data } = response;

        setFooterData(prev => ([...prev, ...data.data]));

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  function handleClickOurStory(event) {
    event.preventDefault();

    window.location.href = '/about#section2';

    setTimeout(() => {
      const section = document.getElementById('section2');
      if (section) {
        const offset = section.offsetTop;
        window.scrollTo({
          top: offset - 200,
          behavior: 'smooth',
        });
      }
    });
  }

  function handleClickAboutUs(event) {
    event.preventDefault();

    window.location.href = '/about#section1';

    setTimeout(() => {
      const section = document.getElementById('section1');
      if (section) {
        const offset = section.offsetTop;
        window.scrollTo({
          top: offset - 200,
          behavior: 'smooth',
        });
      }
    });
  }

  return (
    <>
      {Array.isArray(footerData) && footerData?.map((footer: any) => {
        return (
          <div className="footer-container">
            <div className="page-content d-flex justify-content-center align-items-center mt-5">
              <Row>
                <Col md="12" className="footer-head footer-sm-nav ">
                  <div className="footer-head-content-1">{footer?.title}</div>
                  <div className="footer-head-content-2 pt-2 pt-md-0">{footer?.subTitle}</div>
                </Col>
              </Row>
            </div>
            <div className="footer pt-3" style={{ fontSize: '13px' }}>
              <Row className="footer-row d-flex justify-content-center  text-center">
                {/* {footer?.attributes?.StarlightAccountButtons?.map((btn) => {
                  return (


                    <Col md="3" xs="12" sm="12" className="footer-sm-nav">
                      <div style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat' }} className="footer-column-navm">
                        {btn.head}
                      </div>
                      {btn?.button?.map((item) => {
                        return (


                          <div className="pt-2">
                            <NavLink to={item.href} style={{ textDecoration: 'none', color: '#ffffff', fontWeight: '300', fontFamily: 'Montserrat' }}>
                              {item.title}
                            </NavLink>
                            <br />
                          </div>
                        )
                      })}

                    </Col>
                  )
                })} */}
                {/* {footer.attributes.AboutButtons.map((btn) => {
                  return (


                    <Col md="3" xs="12" sm="12" className="footer-sm-nav">
                      <div style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat' }} className="footer-column-navm">
                        {btn.head}
                      </div>
                      {btn.button.map((item) => {
                        return (


                          <div className="pt-2">
                            <NavLink to={item.href} style={{ textDecoration: 'none', color: '#ffffff', fontWeight: '300', fontFamily: 'Montserrat' }}>
                              {item.title}
                            </NavLink>
                            <br />
                          </div>
                        )
                      })}

                    </Col>
                  )
                })} */}
                {/* {footer?.attributes?.AboutButtons?.map((btn) => {
                  return (
                    <Col md="3" xs="12" sm="12" className="footer-sm-nav pt-5 pt-md-0">
                      <div style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat' }} className="footer-column-nav about-footer">
                        {btn.head}
                      </div>
                      {btn?.button?.map((item) => {
                        return (
                          <div className="pt-2" key={item.title}>
                            <a
                              href={item.href}
                              style={{ textDecoration: 'none', color: '#ffffff', fontWeight: '300', fontFamily: 'Montserrat' }}
                              onClick={(event) => {
                                if (item.title === "About Us") {
                                  handleClickAboutUs(event);
                                } else if (item.title === "Our Story") {
                                  handleClickOurStory(event);
                                } else {
                                  window.location.href = "/founders";
                                }
                              }}
                            >
                              {item?.title}
                            </a>
                            <br />
                          </div>
                        );
                      })}

                      <br />
                    </Col>
                  )
                })}
                {footer?.attributes?.LegalButtons.map((btn) => {
                  return (


                    <Col md="3" xs="12" sm="12" className="footer-sm-nav">
                      <div style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Montserrat' }} className="footer-column-navm">
                        {btn.head}
                      </div>
                      {btn?.button.map((item) => {
                        return (


                          <div className="pt-2">
                            <NavLink to={item.href} style={{ textDecoration: 'none', color: '#ffffff', fontWeight: '300', fontFamily: 'Montserrat' }}>
                              {item?.title}
                            </NavLink>
                            <br />
                          </div>
                        )
                      })}

                    </Col>
                  )
                })} */}


              </Row>
              <br />
              <br />

              <div className='text-center'>
                {footer?.attributes?.description?.map((item:any) => {
                  return (
                    <span
                      className="big  justify-content-center footer-sm-nav"
                      style={{
                        textDecoration: 'none',
                        color: '#ffffff',
                        fontSize: '14px',
                        fontWeight: '300',
                        fontFamily: 'Montserrat',
                        textAlign: 'justify',
                        width: '100%',
                        marginTop: '30px',
                      }}
                    >
                      {item?.description}
                    </span>
                  )
                })}
              </div>
              <br />

              <div className="pt-3 text-center">
                {footer?.attributes?.subDescription?.map((item) => {
                  return (
                    <span
                      className="para-footer big footer-sm-nav"
                      style={{
                        textDecoration: 'none',
                        color: '#ffffff',
                        fontSize: '14px',
                        fontWeight: '300',
                        fontFamily: 'Montserrat',
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      {item?.description}
                    </span>
                  );
                })}
              </div>

            </div>
          </div>
        )
      })}
    </>

  );
}
export default Footer;
