import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Input, Label, Row, FormGroup, Form, Button } from 'reactstrap';
import "../accounts/account.scss";
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { log } from 'react-jhipster';
import { getAdminAccounts } from '../admin-profile/admin.reduce';
import { useReactToPrint } from 'react-to-print';
import { getAgreements, getSignature } from '../accounts/accounts.reducer';
import { useLocation, useParams } from 'react-router-dom';

const CompanyIbkr = ({
    accountIdNum,
    interactive,
    accountHolderType,
    ibkrCompany_sign,
    setibkrCompany_Sign,
    companyDetails,
    handleCheckboxibustomerAgreement, handleCheckboxibclientFinancialAdvisor, setAuthorisedPosition3,
    handleCheckboxelt, handleCheckboxibsdNotification, handleCheckboxLLA, authorisedPosition3, handleAuthoriseIbkrPosition//

}) => {
    const location = useLocation();
    const currentDate = new Date().toLocaleDateString('en-GB');
    const signatureData = useAppSelector(state => state.accounts.signatureData);
    const accountsEntity = useAppSelector(state => state.accounts.entity.accountId);
    const dispatch = useAppDispatch();
    const { level, _signatureDataPending } = location.state || {};

    const [customerId, setCustomerId] = useState()
    const mainAccountsEntity = useAppSelector(state => state.accounts.entity);

    const { accountId } = useParams();

    const [applicationData, setApplicationData] = useState<any>({});
    useEffect(() => {
        if (accountId) {
            dispatch(getAdminAccounts({ accountId }))
                .then((response: any) => {
                    if (response.payload.data) {
                        setApplicationData(response.payload.data.account_signature_response);


                    }
                })
                .catch((error) => {
                });
        }
    }, [accountId]);

    const handleLinkClick = async (e) => {
        e.preventDefault();
        try {
            await dispatch(getAgreements()).unwrap();
        } catch (error) {
            console.error('Error fetching agreements:', error);
        }
        open('/terms-and-disclosures')
    };

    useEffect(() => {
        const accessToken = localStorage.getItem('jhi-authenticationToken');
        if (accessToken) {

            const tokenParts = accessToken.split('.');

            if (tokenParts?.length === 3) {
                try {
                    const payload = JSON.parse(atob(tokenParts[1]));

                    if (payload?.sub) {
                        const { sub } = payload;
                        setCustomerId(sub)
                    }
                } catch (error) {
                    console.error("Failed to parse JWT payload", error);
                }
            }
        }
    }, []);

    const [secondaryId, setSecondaryId] = useState()
    useEffect(() => {
        dispatch(getPendingTask({}))
            .then((response: any) => {
                const signaturePending = response?.payload?.data?.signature_pending;

                if (signaturePending) {
                    signaturePending.forEach((item) => {
                        setSecondaryId(item.secondary_customer_id);
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [dispatch]);

    useEffect(() => {
        if (accountIdNum) {
            dispatch(getSignature(accountIdNum));
        }
    }, [accountIdNum, dispatch]);
    useEffect(() => {
        if (signatureData?.ibkr_signature?.primary_sign != null) {
            setibkrCompany_Sign(signatureData?.ibkr_signature?.primary_sign);

        }
    }, [signatureData]);



    const pageStyle = `
 
  @media print {
    body {
      font-size: 13px;
    }
 
    .no-print {
      display: none;
    }
 
    .new-form-bold-text,
    .new-form-italic-text,
    .new-form-underline-text {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }
 
    .new-form-heading-text {
      font-weight: bold;
      font-size: 25px;
      color: black;
    }
 
    .new-form-light-text {
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
 
    .new-form-very-light-text {
      font-weight: 300;
      font-size: 12px;
      color: black;
    }
 
    .new-form-italic-text {
      font-style: italic;
    }
 
    .bordered-table {
      width: 100%;
      border-collapse: collapse;
    }
    .bordered-table th,
    .bordered-table td {
      border: 1px solid black;
      padding: 8px;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .bordered-table th {
      background-color: #f2f2f2;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .new-form-table-row-one {
      background: #607980;
      color: black;
      font-weight: 300;]
      font-size: 13px;
    }
    .new-form-table-row-two {
      background-color: #d5d8da;
      color: black;
      font-weight: 300;
      font-size: 13px;
    }
  }
 
`;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Statement of Suitability',
        onBeforePrint: () => console.log('before printing...'),
        onAfterPrint: () => console.log('after printing...'),
        pageStyle,
        removeAfterPrint: true,
    });
    const componentRef = useRef();

    return (
        <div ref={componentRef}>
            <Row className='bg-white pt-2'>
                <Col md="12" >
                    <div className=" justify-content-center">
                        <Form className=" bg-white pt-3">
                            <Row>
                                <Col md="12" className="pt-3 text-start new-form-heading-text">
                                    Interactive Brokers Ireland <br /><br />
                                </Col>
                                <Col md="12 new-form-light-text">
                                    Starlight Smart Reserve and Starlight Smart Invest are provided via the Starlight Smart Account, a self-directed advisory investment account opened in your name with our partner investment broker, <span className='' style={{ color: 'red', fontWeight: 'bold' }}>Interactive Brokers Ireland Limited.</span> <br /><br />
                                </Col>
                                <Col md="12 new-form-light-text">
                                    {`Your cash and investments in your account are 'client assets' and are fully segregated from the assets of Interactive Brokers in accordance with Central Bank of Ireland regulatory rules. In the unlikely event of IBKRs failure or insolvency, your assets are not available or accessible to Interactive Brokers' creditors and would be returned to you. `}<br /><br />
                                </Col>
                                <Col md="12 new-form-light-text">
                                    Starlight Wealth does not accept, hold, or have any access to your cash or investments and so in the unlikely event of our failure, your cash and investments are not available or accessible to our creditors. <br /><br />
                                </Col>
                                <Col md="12 new-form-light-text">
                                    Interactive Brokers Ireland Limited is a MIFID authorised investment firm regulated by the Central Bank of Ireland and is a wholly owned group subsidiary of Interactive Brokers Group LLC, an A- publicly rated and listed global investment firm with a market capitalisation exceeding US$32bn, US$14bn in equity capital and over 2.5 million customers. <br />
                                </Col>
                                <Col md="12 new-form-light-text">
                                    {`To open a Starlight Account, you must agree to Interactive Brokers' standard terms and disclosures.`}
                                </Col>
                                <Col md="12" className=" new-form-bold-text pt-5">
                                    Interactive Brokers Ireland standard terms and disclosures
                                </Col> <br />
                                <Col md="12">
                                    <FormGroup check>
                                        <Label className="label-account-class" check>
                                            <Input
                                                type="checkbox"
                                                name="ib_customer_agreement"
                                                checked={interactive?.ib_customer_agreement}
                                                className="input-front-account-custom-bgnone"
                                                onChange={handleCheckboxibustomerAgreement}
                                            />{' '}
                                            <a href="https://starwealth-dev.azureedge.net/development-storage/Interactive%20Brokers%20Ireland%20Ltd%20Customer%20AgreementForm4750.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                                                Interactive Brokers Ireland Ltd Customer Agreement
                                            </a>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup check>
                                        <Label className="label-account-class" check>
                                            <Input
                                                type="checkbox"
                                                name="over18"
                                                checked={interactive?.ib_client_financial_advisor}
                                                className="input-front-account-custom-bgnone"
                                                onChange={handleCheckboxibclientFinancialAdvisor}
                                            />{' '}
                                            <a href="https://starwealth-dev.azureedge.net/development-storage/Agreement%20Limiting%20Interactive%20Brokers%20LiabilityForm6108.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                                                IB Ireland Ltd Client Financial Advisor Agreement
                                            </a>
                                        </Label>
                                        {/* //test docType */}

                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup check>
                                        <Label className="label-account-class" check>
                                            <Input
                                                type="checkbox"
                                                name="over18"
                                                checked={interactive?.elt}
                                                className="input-front-account-custom-bgnone"
                                                onChange={handleCheckboxelt}
                                            />{' '}
                                            <a href="https://starwealth-dev.azureedge.net/development-storage/Form4772Advisory.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                                                Essential Legal Terms for your IB Account
                                            </a>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup check>
                                        <Label className="label-account-class" check>
                                            <Input
                                                type="checkbox"
                                                name="over18"
                                                checked={interactive?.lla}
                                                className="input-front-account-custom-bgnone"
                                                onChange={handleCheckboxLLA}
                                            />{' '}
                                            <a href="https://starwealth-dev.azureedge.net/development-storage/Legal%20AcknowledgementForm2109.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                                                Limited Liability Agreement
                                            </a>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup check>
                                        <Label className="label-account-class" check>
                                            <Input
                                                type="checkbox"
                                                name="over18"
                                                checked={interactive?.ibsd_notification}
                                                className="input-front-account-custom-bgnone"
                                                onChange={handleCheckboxibsdNotification}
                                            />{' '}
                                            <a href="https://starwealth-dev.azureedge.net/development-storage/Interactive%20Brokers%20Ireland%20Ltd%20Retail%20Client%20NotificationForm4769.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: '#597177' }}
                                                onClick={handleLinkClick}
                                            >
                                                Interactive Brokers standard disclosures and notifications (click to view all)
                                            </a>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <div>
                                    <br />
                                    <div className="col-md-6 signature-container-inside-box-2">
                                        <div>
                                            <div>
                                                <br />
                                                <div className='mt-5 sign-container'>
                                                    <input className='companysosdoc-text-fields'
                                                        value={companyDetails?.company_name || accountsEntity?.company_details?.company_name}
                                                    // onChange={handleOnBehalfOf}
                                                    />
                                                </div>
                                                <div className='default-bold-text-small'>
                                                    For and on behalf of:
                                                </div>
                                                <div className='mt-5'>
                                                    <div className='d-flex align-item-center '>
                                                        Name of Authorised Signatory:
                                                        &nbsp;<input className="signature-container-inside-box-2-input mb-2" value={ibkrCompany_sign || applicationData?.statement_of_suitability?.primary_sign}
                                                            onChange={e => setibkrCompany_Sign(e.target.value)} />
                                                    </div>
                                                    <div className='d-flex mt-5'>
                                                        Position:
                                                        &nbsp;<input className='signature-container-inside-box-2-input'
                                                            value={authorisedPosition3}
                                                            onChange={handleAuthoriseIbkrPosition}
                                                        />
                                                    </div>
                                                    <div className='d-flex mt-3'>
                                                        Date:&nbsp;{currentDate}
                                                        {/* <DatePickerComponent
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MM/dd/yyyy"
                                className="form-control"
                                styles={customStyles}
                                popperClassName="datepicker-popper"
                            /> */}

                                                    </div>
                                                </div>
                                            </div>
                                            {/* {(accountHolderType === 'Individual' || applicationData?.account_type === 'Individual') ? (
                                                <>
                                                    <div>
                                                        <label>Please tick the boxes above and type your FullName in the space below to confirm that standard terms and disclosures.</label>
                                                    </div>
                                                    <div>
                                                        <Input
                                                            type="text"
                                                            className="signature-container-inside-box-2-input"
                                                            value={ibkrCompany_sign || applicationData?.ibkr_signature?.primary_sign}
                                                            onChange={e => setibkrCompany_Sign(e.target.value)}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div>
                                                        <label>Please tick the boxes above and type your fullName in the space below to confirm that standard terms and disclosures.</label>
                                                    </div>
                                                    <div>
                                                        {customerId === mainAccountsEntity?.customerId ? (
                                                            <Input
                                                                type="text"
                                                                className="signature-container-inside-box-2-input"
                                                                value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.ibkr_signature
                                                                    ?.primary_sign : ibkrCompany_sign || ''}
                                                                onChange={e => setibkrCompany_Sign(e.target.value)}
                                                                id="signPrimary"

                                                            />
                                                        ) : (
                                                            <Input
                                                                type="text"
                                                                className="signature-container-inside-box-2-input"
                                                                value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.ibkr_signature
                                                                    ?.primary_sign : ibkrCompany_sign || applicationData?.ibkr_signature?.primary_sign}
                                                                id="signPrimary"
                                                                readOnly

                                                            />
                                                        )}
                                                    </div>
                                                    <div>
                                                        <label>Signature of Secondary owner</label>
                                                    </div>
                                                    <div>
                                                        {customerId !== (secondaryId || mainAccountsEntity?.secondary_customer_id) ? (
                                                            <Input
                                                                type="text"
                                                                id="signSecondary"
                                                                className="signature-container-inside-box-2-input"
                                                                value={ibkrCompany_sign || applicationData?.ibkr_signature?.joint_sign}
                                                                readOnly

                                                            />
                                                        ) : (
                                                            <Input
                                                                type="text"
                                                                id="signSecondary"
                                                                className="signature-container-inside-box-2-input"
                                                                value={ibkrCompany_sign || ''}

                                                                onChange={e => setibkrCompany_Sign(e.target.value)}

                                                            />
                                                        )}
                                                    </div>

                                                </>
                                            )
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <FormGroup>
                                    <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                                </FormGroup>
                            </Row>
                            <Col md="12" className="text-end no-print">
                                <Button onClick={() => handlePrint({ content: () => document.body })}>Print</Button>
                            </Col>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );

}
export default memo(CompanyIbkr);
