// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open-container {
  min-height: 100vh;
  background-color: rgba(68, 84, 93, 0.6509803922);
  background-image: url("https://static.wixstatic.com/media/4f10d0_13bb44e12bb64e6196dc910e27c4aea2~mv2.jpg/v1/fill/w_1800,h_1686,al_c,q_90,enc_auto/4f10d0_13bb44e12bb64e6196dc910e27c4aea2~mv2.jpg");
}

.card-bg-style {
  background-color: rgba(68, 84, 93, 0.6509803922);
  width: 70%;
  border-radius: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-primary {
  color: #fff;
  background-color: #607980;
  border-color: #607980;
  --bs-btn-active-border-color: #607980;
  --bs-btn-active-bg:#607980;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: #607980;
  border-color: #607980;
}

.open-btn-style {
  background-color: #607980;
  border-color: #b6c1cd;
  border-width: 2px;
  font-size: 13px;
  color: white;
  width: 100%;
}

.open-btn-style:hover {
  background-color: #e1eff2;
  color: rgb(0, 0, 0);
}

.open-new {
  color: black;
}

.open-new:hover {
  color: black;
}

@media (max-width: 600px) {
  .card-bg-style {
    width: 100%;
    border-radius: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/open.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gDAAA;EACA,oMAAA;AACF;;AAEA;EACE,gDAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,qBAAA;EACA,qCAAA;EACA,0BAAA;AACF;;AACA;;;;;EAKE,yBAAA;EACA,qBAAA;AAEF;;AACA;EACE,yBAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AAEF;;AAAA;EACE,yBAAA;EACA,mBAAA;AAGF;;AAAA;EACE,YAAA;AAGF;;AAAA;EACE,YAAA;AAGF;;AAAA;EACE;IACE,WAAA;IACA,kBAAA;IACA,gBAAA;IACA,mBAAA;EAGF;AACF","sourcesContent":[".open-container {\n  min-height: 100vh;\n  background-color: #44545da6;\n  background-image: url('https://static.wixstatic.com/media/4f10d0_13bb44e12bb64e6196dc910e27c4aea2~mv2.jpg/v1/fill/w_1800,h_1686,al_c,q_90,enc_auto/4f10d0_13bb44e12bb64e6196dc910e27c4aea2~mv2.jpg');\n}\n\n.card-bg-style {\n  background-color: #44545da6;\n  width: 70%;\n  border-radius: 0px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.btn-primary {\n  color: #fff;\n  background-color: #607980;\n  border-color: #607980; \n  --bs-btn-active-border-color: #607980;\n  --bs-btn-active-bg:#607980;\n}\n.btn-primary:hover,\n.btn-primary:focus,\n.btn-primary:active,\n.btn-primary.active,\n.open > .dropdown-toggle.btn-primary {\n  background-color: #607980;\n  border-color: #607980; \n}\n\n.open-btn-style {\n  background-color: #607980;\n  border-color: #b6c1cd;\n  border-width: 2px;\n  font-size: 13px;\n  color: white;\n  width: 100%;\n}\n.open-btn-style:hover {\n  background-color: #e1eff2;\n  color: rgb(0, 0, 0);\n}\n\n.open-new {\n  color: black;\n}\n\n.open-new:hover {\n  color: black;\n}\n\n@media (max-width: 600px) {\n  .card-bg-style {\n    width: 100%;\n    border-radius: 0px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
