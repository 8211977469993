import React, { useState } from 'react';

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Col,
    List,
    ListGroup,
    ListGroupItem,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import './faq.scss';
function CustomFaq() {
    const [activeTab, setActiveTab] = useState('smartReserve');
    const [open, setOpen] = useState('');

    const toggleTab = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggle = (id: React.SetStateAction<string>) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };







    return (
        <div className="faq-container mb-10 " style={{ marginBottom: '20px' }}>
            <Row className="d-flex align-items-center " style={{ marginTop: '45px' }}>
                <Col className="col-12" md="12 mt-5">
                    <h2 className="text-center">Frequently Asked Questions</h2>
                </Col>
            </Row>
            <div className="text-center">
                <Row className="justify-content-center">
                    <Col xs="12" md="8">
                        <Nav className="faq-container text-center color-black">
                            {/* <NavItem>
                                <NavLink
                                    style={{
                                        fontWeight: activeTab === 'general' ? '700' : 'normal',
                                        color: '#FAFAFA',
                                        fontSize: '15px',
                                        fontFamily: 'Montserrat',
                                    }}
                                    href="#"
                                    onClick={() => toggleTab('general')}
                                >
                                    General
                                </NavLink>
                            </NavItem> */}
                            {/* <NavItem>
                                <NavLink
                                    style={{ fontWeight: activeTab === 'smartReserve' ? '700' : 'normal', color: '#FAFAFA', fontSize: '15px' }}
                                    href="#"
                                    onClick={() => toggleTab('smartReserve')}
                                >
                                    Smart Reserve
                                </NavLink>
                            </NavItem> */}
                            {/* <NavItem>
                                <NavLink
                                    style={{ fontWeight: activeTab === 'howDoI' ? '700' : 'normal', color: '#FAFAFA', fontSize: '15px' }}
                                    href="#"
                                    onClick={() => toggleTab('howDoI')}
                                >
                                    How do I ...
                                </NavLink>
                            </NavItem> */}
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            {/* {activeTab === 'general' && (
                                <TabPane tabId="general" className="content-container">
                                    <div className="text-center">
                                        <Accordion flush open={open} toggle={toggle} className="p-3">
                                            <AccordionItem className="mr-20 content">
                                                <AccordionHeader
                                                    color="red"
                                                    targetId="1"
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor: 'black',
                                                        boxShadow: 'none',
                                                        borderColor: 'transparent',
                                                        marginLeft: '-20px',
                                                    }}
                                                >
                                                    Is Starlight Wealth regulated?
                                                </AccordionHeader>

                                                <AccordionBody accordionId="1">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        Future Wealth Limited, trading as Starlight Wealth, is regulated by the Central Bank of Ireland as an Investment
                                                        Intermediary under the Investments Intermediaries Act, 1995 and is registered as an Insurance Intermediary under
                                                        the European Union (Insurance Distribution) Regulations, 2018 under institution authorisation code C496326.
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="2" style={{ marginLeft: '-20px' }}>
                                                    Is my money safe and secure?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="2">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p className="text-left">
                                                            Starlight Smart Reserve and Starlight Smart Invest are provided via the Starlight Smart Account, a
                                                            self-directed advisory investment account opened in your name with our partner investment broker, Interactive
                                                            Brokers Ireland Limited (IBKR).
                                                        </p>
                                                        <p>
                                                            Your cash and investments in your account are ‘client assets’ and are fully segregated from the assets of IBKR
                                                            in accordance with Central Bank of Ireland regulatory rules. In the unlikely event of IBKRs failure or
                                                            insolvency, your assets are not available or accessible to IBKR's creditors and would be returned to you.{' '}
                                                        </p>
                                                        <p>
                                                            Starlight Wealth does not accept, hold, or have any access to your cash or investments and so in the unlikely
                                                            event of our failure, your cash and investments are are not available or accessible to our creditors.
                                                        </p>
                                                        <p>
                                                            Interactive Brokers Ireland Limited is a MIFID authorised investment firm regulated by the Central Bank of
                                                            Ireland and is a wholly owned group subsidiary of Interactive Brokers Group LLC, an A- publicly rated and
                                                            listed global investment firm with a market capitalisation exceeding US$32bn, US$14bn in equity capital and
                                                            over 2.5 million customers.
                                                        </p>
                                                    </div>{' '}
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <AccordionHeader targetId="3" style={{ marginLeft: '-20px' }}>
                                                    What happens if Starlight Wealth goes out of business?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="3">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400', fontSize: '16px' }}>
                                                        <p>
                                                            Starlight Wealth does not accept, hold or have any access to your cash or investments and so in the unlikely
                                                            event of our failure your cash and investments are are not available or accessible to our creditors, or
                                                            impaired in any way. You have full access to your account at all times to both deposit and withdraw funds as
                                                            necessary.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>

                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="4" style={{ marginLeft: '-20px' }}>
                                                    Is my account covered by the Ireland Deposit Guarantee Scheme?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="4">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>
                                                            No, as Starlight Smart Reserve and Starlight Smart Invest are investment accounts and not deposit accounts
                                                            with an Irish bank they are not covered by this guarantee.
                                                        </p>

                                                        <p>
                                                            Your cash in Starlight Smart Reserve is allocated to Short-Term AAA and Sovereign Money Market Funds holding
                                                            large diversified portfolios of overnight and short-term deposits of highly rated banks, short-term rated bank
                                                            and corporate commercial paper, and sovereign state bonds issued and backed by France, Germany, the European
                                                            Union among others. The risk of these investments compares favourably to the risk of the Irish State, the
                                                            guarantor under the deposit guarantee scheme. Ireland is currently rated AA- (or equivalent) by the major
                                                            range agencies.
                                                        </p>

                                                        <p>
                                                            {' '}
                                                            Further, by curating a select portfolio of MMFs in Starlight Smart Reserve, your funds are spread across a
                                                            number of MMFs rather than being concentrated in just a single MMF, giving you unrivalled, market leading
                                                            diversification benefits in the management of your cash reserves.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>

                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="5" style={{ marginLeft: '-20px' }}>
                                                    What are your fees and charges?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="5">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>
                                                            {' '}
                                                            We charge a simple transparent fee of 0.75% p.a. of the value of your assets in your Starlight Smart Account.
                                                            This fee is accrued daily and charged monthly in arrears.
                                                        </p>

                                                        <p>
                                                            {' '}
                                                            We do not charge any upfront fees, contribution fees, account set-up fees, fees to administer your account,
                                                            fees to execute trade orders on your behalf, or if you ever decide to leave, exit fees.
                                                        </p>

                                                        <p>
                                                            {' '}
                                                            As we want to do our very best for you as our client, we do not accept or retain any upfront or ongoing
                                                            third-party payments or commissions from product producers such as life companies or fund managers with
                                                            respect to the Starlight Smart Account, including Starlight Smart Reserve and Starlight Smart Invest.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>

                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="6" style={{ marginLeft: '-20px' }}>
                                                    Are there any transaction costs or commissions I need to pay?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="6">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p className="mt-3 mb-3">
                                                            Interactive Brokers Ireland Limited, our partner investment broker, charges fixed cost trading commissions on
                                                            the purchase and sale of shares. These are some of the lowest in the industry.
                                                            <p className="mt-3 mb-3">
                                                                For example with respect to the Starlight Smart Reserve model portfolio, as of January 2024, these
                                                                commissions totalled €14.85 on each investment made into the Starlight Smart Reserve model portfolio
                                                                regardless of the size of the investment you make. If you sell all, or some, of your investment in Starlight
                                                                Smart Reserve you can expect to be charged aproximately the same trading commission on exit.
                                                            </p>
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </TabPane>
                            )} */}
                            {activeTab === 'smartReserve' && (
                                <TabPane tabId="smartReserve" className="content-container">
                                    <div className="text-center">
                                        <Accordion flush open={open} toggle={toggle} className="p-3">
                                            {/* <AccordionItem className="content">
                                                <AccordionHeader targetId="1" style={{ marginLeft: '-20px' }}>
                                                    What is a Money Market Fund?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="1">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        Money Market Funds (MMFs) are regulated funds that invest in high-quality, short-term debt instruments, cash,
                                                        and cash equivalents. They were originally developed in the US in the 1970s. MMFs are widely used by large
                                                        corporations, individuals, and governments that want to sensibly limit how much exposure they have to their
                                                        relationship banks, while maintaining liquidity and earning interest. EU MMFs are regulated in the European
                                                        Union under the EU Money Market Fund Regulation (MMFR). As of November 2023, Ireland was home to over €700
                                                        billion of cash reserves held in Irish MMFs out of an estimated total of €1,600 billion held in MMFs across the
                                                        EU.
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="2" style={{ marginLeft: '-20px' }}>
                                                    What makes Starlight Smart Reserve different?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="2">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>
                                                            In developing Starlight Smart Reserve, we understood that security and trust is paramount for your cash
                                                            holdings. Our expert team reviewed c.300 MMFs and only selected Short-Term MMFs with the highest credit
                                                            quality, with an emphasis on capital security and liquidity.
                                                        </p>

                                                        <p>
                                                            {' '}
                                                            The average rating of the assets underlying the Starlight Smart Reserve Portfolio exceeds the rating of the
                                                            typical retail bank deposit, and your investment in the Starlight Smart Reserve Portfolio is typically spread
                                                            across more than 200 different holdings in select AAA and Sovereign Short-Term MMFs. This provides you with a
                                                            unique opportunity to diversify your cash reserves in a banking system such as Ireland, where €150 billion in
                                                            cash is concentrated in just a handful of banks and building societies.
                                                        </p>

                                                        <p>
                                                            {' '}
                                                            Further, by curating a select portfolio of MMFs in Starlight Smart Reserve, your funds are spread across a
                                                            number of MMFs rather than being concentrated in just a single MMF, giving you unrivalled, market leading
                                                            diversification benefits in the management of your cash reserves.
                                                        </p>

                                                        <p>
                                                            {' '}
                                                            In addition to the significant capital protection mechanisms that underpin Starlight Smart Reserve, it also
                                                            provides a return that reflects the ECB deposit rate while giving you demand access to your cash reserves all
                                                            brought to you by the expert team of a Central Bank of Ireland regulated investment adviser, Starlight Wealth.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="3" style={{ marginLeft: '-20px' }}>
                                                    Is Starlight Smart Reserve covered by the Ireland Deposit Guarantee Scheme?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="3">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>
                                                            No, as Starlight Smart Reserve is an investment account and not a deposit account with an Irish bank it is not
                                                            covered by this guarantee.
                                                        </p>

                                                        <p>
                                                            Your cash in Starlight Smart Reserve is allocated to Short-Term AAA and Sovereign Money Market Funds holding
                                                            large diversified portfolios of overnight and short-term deposits of highly rated banks, short-term rated bank
                                                            and corporate commercial paper, and sovereign state bonds issued and backed by France, Germany, the European
                                                            Union among others. The risk of these investments compares favourably to the risk of the Irish State, the
                                                            guarantor under the deposit guarantee scheme. Ireland is currently rated AA- (or equivalent) by the major
                                                            range agencies.
                                                        </p>

                                                        <p>
                                                            {' '}
                                                            Further, by curating a select portfolio of MMFs in Starlight Smart Reserve, your funds are spread across a
                                                            number of MMFs rather than being concentrated in just a single MMF, giving you unrivalled, market leading
                                                            diversification benefits in the management of your cash reserves.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>

                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="4" style={{ marginLeft: '-20px', color: 'white', fontWeight: '400' }}>
                                                    How do I earn interest income in Starlight Smart Reserve?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="4">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>
                                                            Starlight Smart Reserve earns deposit and interest income that is accumulated within the MMFs, increasing the
                                                            net asset value of the MMFs each day by the accumulated interest. This is received by you when you redeem your
                                                            investment in the MMFs. As a result, interest is rolled-up and compounded daily within Starlight Smart
                                                            Reserve.
                                                        </p>

                                                        <p>
                                                            We will provide you with streamlined monthly reporting of your Starlight Smart Reserve return straight to your
                                                            inbox, while in the meantime you can monitor your portfolio at your convenience via your Starlight Client
                                                            Portal.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="5" style={{ marginLeft: '-20px' }}>
                                                    Tell me about transaction fees and commissions?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="5">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>Starlight Wealth does not charge transaction fees.</p>

                                                        <p>
                                                            Interactive Brokers Ireland Limited, our partner investment broker, charges fixed cost trading commissions on
                                                            the purchase and sale of shares in the MMFs comprising the Starlight Smart Reserve model portfolio.{' '}
                                                        </p>
                                                        <p>
                                                            As of January 2024, these commissions totalled €14.85 on each investment made into the Starlight Smart Reserve
                                                            model portfolio regardless of the size of the investment you make.{' '}
                                                        </p>
                                                        <p>
                                                            If you sell all, or some, of your investment in Starlight Smart Reserve you can expect to be charged
                                                            aproximately the same trading commission on exit.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem> */}
                                            <AccordionItem className="content" id='W-8BEN'>
                                                <AccordionHeader targetId="6" style={{ marginLeft: '-20px' }}>
                                                    What is a W-8BEN form?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="6">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>A W-8BEN form is an Internal Revenue Service (IRS – US Tax Authority) form that serves as a Certificate of Foreign Status of Beneficial Owner. It enables Irish residents to receive preferential tax treatment in certain circumstances.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="7" style={{ marginLeft: '-20px' }}>
                                                    Do I need to complete a W-8BEN form?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="7">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>Yes. You must complete a W-8BEN form if you wish to open a trading account with Interactive Brokers Ireland Limited, our partner investment broker.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="8" style={{ marginLeft: '-20px' }}>
                                                    What is the W-8BEN form for?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="8">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>By completing this form you confirm that you are
                                                        </p>
                                                        <ul>
                                                            <li>resident in Ireland; and </li>
                                                            <li>that you wish to benefit from the tax treaty between Ireland and the United States.  </li>
                                                        </ul>
                                                        <p>This means that at any point, if for example, you invest in US shares (e.g. Apple, Tesla, Microsoft etc.) and such shares pay a dividend then you will be able to automatically benefit from any preferential treatment under the treaty which allows such dividends to be paid without deduction for US tax by the US authorities or at a reduced rate, as applicable</p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="9" style={{ marginLeft: '-20px' }}>
                                                    What do I need to do?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="9">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p> When you open a Starlight Smart Account, your W8-BEN form is automatically completed based on the information you provide as part of the account opening process.
                                                        </p>
                                                        <p>You then sign the W8-BEN form electronically during the final stages of the onboarding process to confirm that you wish to benefit from the tax treaty between Ireland and the United States </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </TabPane>
                            )}
                            {/* {activeTab === 'howDoI' && (
                                <TabPane tabId="howDoI" className="content-container">
                                    <div className="text-center">
                                        <Accordion flush open={open} toggle={toggle} className="p-3">
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="1" style={{ marginLeft: '-20px', color: 'white', fontWeight: '400' }}>
                                                    Contact Starlight Wealth about my Starlight Smart Account?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="1">
                                                    <div style={{ textAlign: 'left' }}>
                                                        <ListGroup numbered style={{ backgroundColor: 'black', color: 'white', fontWeight: '400' }}>
                                                            <ListGroupItem className="list-group text-left align-item-left">
                                                                You can contact us securely at any time using your <strong>Starlight Client Portal</strong>
                                                            </ListGroupItem>
                                                            <ListGroupItem className="list-group">
                                                                Click on the Messages icon at the top right of the portal screen
                                                            </ListGroupItem>
                                                            <ListGroupItem className="list-group">
                                                                Send us a secure message and you will be notified by email when we respond
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="2" style={{ marginLeft: '-20px' }}>
                                                    Change my Address?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="2">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <ListGroup numbered>
                                                            <ListGroupItem className="list-group text-left align-item-left">
                                                                Log in to your Starlight Client Portal{' '}
                                                            </ListGroupItem>

                                                            <ListGroupItem className="list-group">Click on Messages icon at top right</ListGroupItem>

                                                            <ListGroupItem className="list-group">Send us a secure message with your new address</ListGroupItem>

                                                            <ListGroupItem className="list-group">
                                                                Upload a new Proof of Address (recent utility bill, bank statement, driver’s licence with the new address)
                                                                by clicking on Documents Share document
                                                            </ListGroupItem>
                                                            <ListGroupItem className="list-group">
                                                                We will confirm by secure message once we have updated your address{' '}
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="3" style={{ marginLeft: '-20px' }}>
                                                    Deliver an Investment Instruction (Trade Order)?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="3">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        To deliver a trade order to buy or sell a cash amount of a specific Starligt Smart Model Portfolio or other
                                                        listed security on the dealing platform,
                                                        <p className="mt-3">1. Click on the Starlight Smart Account Investment Instruction form here:</p>
                                                        <ListGroup numbered className="mt-4 mb-4">
                                                            <ListGroupItem className="list-group">
                                                                Fill in your name, email and Interactive Brokers account number.{' '}
                                                            </ListGroupItem>

                                                            <ListGroupItem className="list-group">
                                                                Insert the cash amount of the Starlight Model Portfolios or security that you want us to buy or sell on your
                                                                behalf.
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                        <p>
                                                            2. We will confirm by secure message once we have validated and executed your new investment instructions.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>

                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="4" style={{ marginLeft: '-20px' }}>
                                                    Change my Investment Allocation (Model Portfolio Rebalancing Trade Order)?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="4">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <ListGroup numbered>
                                                            <p> You can change how your funds are allocated to the Starlight Model Portfolios at any time:</p>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                There is no minimum mandatory investment period where your investments are locked in.{' '}
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                As your circumstances change, your needs may change.{' '}
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                {' '}
                                                                Our recommendation is that you review your investment allocations on an annual basis.
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                        <p> To change your allocation</p>

                                                        <p> 1. Click on the Change My Investment Allocation form here:</p>
                                                        <ListGroup numbered>
                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                {' '}
                                                                Fill in your name, email and Interactive Brokers account number.
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                Tell us how you want your funds allocated to Starlight Model Portfolios and your allocation to Cash.
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                The total must add up to 100% and you must allocate minimum 1% to Cash to cover fees.
                                                            </ListGroupItem>
                                                        </ListGroup>

                                                        <p>
                                                            2. We will confirm by secure message once we have validated and executed your new investment instructions.
                                                        </p>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>

                                            <AccordionItem className="content">
                                                <AccordionHeader targetId="5" style={{ marginLeft: '-20px' }}>
                                                    Withdraw Funds from my Starlight Smart Account?
                                                </AccordionHeader>
                                                <AccordionBody accordionId="5">
                                                    <div style={{ textAlign: 'left', color: 'white', fontWeight: '400' }}>
                                                        <p>
                                                            Cash can be withdrawn from your Starlight Smart Account at any time. Withdrawn funds will typically be
                                                            available in your bank account in 1-3 days, depending on your bank
                                                        </p>
                                                        <ListGroup numbered style={{ backgroundColor: 'black' }}>
                                                            <p> 1. Withdrawal instructions</p>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                Log into your Starlight Portfolio Portal{' '}
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                Click on Transfer & Pay Transfer Funds Make a Withdrawal{' '}
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                {' '}
                                                                If you have previously set up a bank account, you can select this. If not, click on “Use a new withdrawal
                                                                method” and follow the instructions to add your bank account (you will need your IBAN) Choose the currency
                                                                (typically EUR) and Withdrawal Method (typically SEPA) and add your bank details
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                If you have previously set up a bank account, you can select this. If not, click on “Use a new withdrawal
                                                                method” and follow the instructions to add your bank account (you will need your IBAN) Choose the currency
                                                                (typically EUR) and Withdrawal Method (typically SEPA) and add your bank details{' '}
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                {' '}
                                                                For security, you will need to confirm your identity
                                                            </ListGroupItem>

                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                {' '}
                                                                Enter the amount you wish to withdraw and confirm the instruction
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                        <p> 2. You can only withdraw funds that are in cash: </p>
                                                        <ListGroup>
                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                If you have insufficient funds in cash,
                                                            </ListGroupItem>
                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                {' '}
                                                                complete the Starlight Smart Account Withdrawal Instruction to allocate sufficient funds to cash See the
                                                                Change my Investment Allocation FAQ for additional guidance if required
                                                            </ListGroupItem>
                                                            <ListGroupItem className="text-left align-item-left list-group">
                                                                Once cash funds are available in your Interactive Broker account (this takes 1-3 days), funds may be
                                                                withdrawn as per the above process
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                </TabPane>
                            )} */}
                        </TabContent>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default CustomFaq;
