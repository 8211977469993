import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ContactForm, defaultValue } from 'app/shared/model/contact.models';
import { EntityState } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const initialState: EntityState<ContactForm> = {
  loading: false,
  errorMessage: null,
  entity: defaultValue,
  entities: [],
  updating: false,
  updateSuccess: false,
};

const apiUrl = `${SERVER_API_URL}api/contact-form/create`;

export const createContact = createAsyncThunk('contacts/create', async (entity: ContactForm) => {
  try {
    const result = await axios.post<ContactForm>(apiUrl, cleanEntity(entity));
    return result.data;
  } catch (error) {
    // Thunk automatically handles rejections, no need to return the error here
    throw error; // Rethrow the error
  }
});

// Assuming you have a utility function cleanEntity to sanitize the entity

export const getContactDetails = createAsyncThunk('contacts/fetchDetails', async () => {
  try {
    const response = await axios.get<ContactForm>(apiUrl);
    return response.data;
  } catch (error) {
    // Thunk automatically handles rejections, no need to return the error here
    throw error; // Rethrow the error
  }
});

export const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createContact.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload as string;
      })
      .addCase(getContactDetails.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getContactDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(getContactDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload as string;
      });
  },
});

export default contactSlice.reducer;
