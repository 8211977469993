import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Col, Form, Input, Row, Button } from 'reactstrap';

// import './account.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useLocation, useParams } from 'react-router-dom';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { getAdminAccounts } from '../admin-profile/admin.reduce';
import { getSignature } from '../accounts/accounts.reducer';
import moment from 'moment-timezone';

export const MpsdDoc = ({
  companyDetails,
  handleAuthoriseMPSDPosition,
  onBehalfOf,

  smartReserveAuthorisePosition,
  setSmart_reserve_authorise_position,
  accountHolderType,
  smart_reserve_primary_sign,
  setsmart_reserve_primary_sign= (data)=>{},
}) => {

  const { accountId } = useParams();

  const [applicationData, setApplicationData] = useState<any>({});
  const [newData, setNewData] = useState<any>({});
  useEffect(() => {
    if (accountId) {
      dispatch(getAdminAccounts({ accountId }))
        .then((response: any) => {
          if (response.payload.data) {
            setApplicationData(response.payload.data.account_signature_response);
            setNewData(response.payload.data)


          }
        })
        .catch((error) => {
        });
    }
  }, [accountId]);

  const pageStyle = `
 
  @media print {

    @page {
  size: auto;
  margin-top: 20mm;
  margin-bottom: 20mm;
  color:black !important;
}
  .page-break-mpsd{
  page-break-before:always;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
    
    body {
      font-size: 13px;
    }
 
    .no-print {
      display: none;
    }
 
    .new-form-bold-text,
    .new-form-italic-text,
    .new-form-underline-text {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }
 
    .new-form-heading-text {
      font-weight: bold;
      font-size: 25px;
      color: black;
    }
   
    .new-form-light-text {
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
 
    .new-form-very-light-text {
      font-weight: 300;
      font-size: 12px;
      color: black;
    }
 
    .new-form-italic-text {
      font-style: italic;
    }
 
    .bordered-table {
      width: 100%;
      border-collapse: collapse;
    }
    .bordered-table th,
    .bordered-table td {
      border: 1px solid black;
      padding: 8px;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .bordered-table th {
      background-color: red;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .new-form-table-row-one td{
      background: #607980;
      color: black !important;
      font-weight: 300;
      font-size: 13px;
    }
    .new-form-table-row-two {
      background-color: #d5d8da;
      color: black;
      font-weight: 300;
      font-size: 13px;
    }
    .textfield-style {
      padding: 0.5rem 0.75rem;
      border: 1px solid #f1eded;
      border-radius: 0.25rem;
      background-color: #fff;
      font-size: 14px;
      color: #333;
      box-shadow: 0px 0.5px #c8c6c6;
    }
  }
 
`;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentDate = new Date().toLocaleDateString('en-GB');
  const { level, _signatureDataPending } = location.state || {};
  const accountsEntity = useAppSelector(state => state.accounts.entity.accountId);
  const signatureData = useAppSelector(state => state.accounts.signatureData);
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const [signatureTimestamp, setSignatureTimestamp] = useState<string>(''); 

  const handleSignatureChange = (setter: Function, value: string) => {
      setter(value);
      const userTimezone = moment.tz.guess(); 
      const currentDateTimeWithZone = moment().utc().format('DD-MM-YYYY HH:mm:ss') + ' GMT'; 
      const formattedDateTimeWithCity = `${currentDateTimeWithZone} (${userTimezone.replace('_', ' ')})`;
      setSignatureTimestamp(formattedDateTimeWithCity);
      localStorage.setItem('companympsdsignatureTimestamp', formattedDateTimeWithCity); 
    };
    const formattedprimaryApiTimestamp = applicationData?.smart_reserve_portfolio?.primary_signed_timestamp
    ? moment.utc(applicationData?.smart_reserve_portfolio?.primary_signed_timestamp, "DD-MM-YYYYTHH:mm:ss.SSSSSSZ")
        .tz(moment.tz.guess())
        .format("DD-MM-YYYY HH:mm:ss [GMT]") + ` (${moment.tz.guess()})`
    : null;
  
    useEffect(() => {
      const storedSignatureTimestamp = localStorage.getItem('companympsdsignatureTimestamp');
      
      if (storedSignatureTimestamp) {
        setSignatureTimestamp(storedSignatureTimestamp);
      }
    }, []);

  const [secondaryId, setSecondaryId] = useState()
  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {

        const signaturePending = response?.payload?.data?.signature_pending;

        if (signaturePending) {
          signaturePending.forEach((item) => {
            setSecondaryId(item.secondary_customer_id);
          });
        }
      })
      .catch(error => {
      });
  }, [dispatch]);

  const [customerId, setCustomerId] = useState();
  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      const tokenParts = accessToken.split('.');
      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));

          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
            // Do something with the sub, such as setting it in state or using it elsewhere
          }
        } catch (error) {
        }
      }
    }
  }, []);
  useEffect(() => {
    if (accountsEntity) {
      dispatch(getSignature(accountsEntity));
    }
  }, [accountsEntity, dispatch]);
  useEffect(() => {
    if (signatureData?.smart_reserve_portfolio) {
      setsmart_reserve_primary_sign(signatureData?.smart_reserve_portfolio?.primary_sign);
    }
  }, [signatureData]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Starlight Smart Reserve Portfolio (EUR)(Series 1)',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    pageStyle: pageStyle,
    removeAfterPrint: true,
  });

  const componentRef = useRef();

  const isPrintButtonEnabled = () => {
    const isPrimarySignValid = smart_reserve_primary_sign || applicationData?.smart_reserve_portfolio?.primary_sign;
    const isPositionValid = smartReserveAuthorisePosition || newData?.smartReserveAuthorisePosition;
    
    return !!isPrimarySignValid && !!isPositionValid;  
  };

  return (
    <div ref={componentRef}>
      <Row className='bg-white'>
        <Col md="12 " >
          <div className=" justify-content-center pt-3">
            <Form className=" bg-white ">
            <div className='logo-div-form'>
          <img className="img-fluid logo-form" src="../../../../../content/images/black_new_logo.svg" />
        </div>
              <div className=''>
                <Row>
                  <Col md="12" className="pt-3 new-form-bold-text">
                    Starlight Smart Reserve Portfolio (EUR)
                    <span>(Series 1)</span>
                  </Col>

                  <Col md="12" className="pt-2 pb-3 new-form-light-text">
                    Short-Term Money Market Funds
                  </Col>

                  <Col md="12 new-form-light-text">
                    The Starlight Smart Reserve Portfolio is a curated model portfolio of short-term Money Market Funds designed for clients seeking
                    to generate an institutional rate of return on their cash that exceeds prevailing retail bank deposit rates but with a similar
                    risk profile.
                  </Col>
                  <Col md="12 new-form-light-text">
                    The model portfolio comprises a select portfolio of high-quality short-term EUR Money Market Funds (MMFs), each sponsored and
                    managed by established blue-chip international asset management and financial institutions.
                  </Col>
                  {/* Main Heading */}
                  <Col md="12 " className="pt-3 new-form-bold-text">
                    Investment Objectives & Portfolio Allocation
                  </Col>

                  <Col md="12 pt-3 new-form-light-text">
                    The model portfolio is designed to generate an income yield that closely tracks the Euro Short Term Rate (ESTR), the overnight
                    interest rate of Eurozone banks. Starlight has reviewed c.300 MMFs as part of the selection process and the model portfolio is
                    100% allocated to Short-Term Money Market Funds.
                  </Col>
                  <Col md="12 new-form-bold-text pt-3">Portfolio Overview</Col>
                  <Col md="12 new-form-bold-text pt-3">Holdings</Col>

                  <Col md="12 table-new-style" id='unique-table'>
                    {' '}
                    <table className="bordered-table custom-table-td">
                      <thead>
                        <tr className='new-form-table-row-two'>
                          <td>Weight</td>
                          <td>Money Market Fund</td>
                          <td>Sponsor Manager</td>

                          <td>EU Regulation Category</td>
                          <td>Rating</td>
                          <td>Benchmark</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="new-form-table-row-one">
                          <td>25%</td>
                          <td>Amundi Euro Liquidity Short Term SRI Term SR</td>
                          <td>Amundi Asset Management S.A.</td>
                          <td>Short-Term MMF</td>
                          <td>AAA (Fitch)</td>
                          <td>ESTR</td>
                        </tr>
                        <tr className="new-form-table-row-two">
                          <td>25%</td>
                          <td>Amundi Euro Liquidity Short Term Govies</td>

                          <td>Amundi Asset Management S.A</td>
                          <td>Short-Term MMF </td>
                          <td>Not rated</td>
                          <td>ESTR</td>
                        </tr>
                        <tr className="new-form-table-row-one">
                          <td>25%</td>
                          <td>BNP Paribas INSTICASH EUR 1D</td>
                          <td>BNP Paribas Asset Management Luxembourg S.A</td>
                          <td>Short-Term MMF</td>
                          <td>AAAm (S&P)</td>
                          <td>ESTR</td>
                        </tr>
                        <tr className="new-form-table-row-two">
                          <td>25%</td>
                          <td>Pictet - Sovereign Short-Term Money Market EUR</td>
                          <td>Pictet Asset Management (Europe) S.A. </td>
                          <td>Short-Term MMF</td>
                          <td>Not Rated</td>
                          <td>German Sovereign</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col md="12 new-form-bold-text pt-2 page-break-mpsd">MMF Sponsor Asset Management Firms</Col>
                  <Col md="12 pt-3 new-form-light-text">
                    <span className="new-form-bold-text "> Amundi Asset Management</span> is a leading European asset manager, ranking among the top
                    10 asset managers globally, with c. 100 million clients - retail, institutional and corporate. Amundi offers a complete range of
                    savings and investment solutions in active and passive management, with clients benefitting from the expertise and advice of 5,400
                    employees in 35 countries. A subsidiary of the Crédit Agricole group and listed on the stock exchange, Amundi manages more than
                    €1.9 trillion of assets as of 31st December 2022.
                  </Col>

                  <Col md="12 new-form-light-text"><br />
                    {' '}
                    <span className="new-form-bold-text">BNP Paribas Asset Management</span> is the investment management arm of BNP Paribas, one of
                    the world’s major financial institutions. With €526 billion in assets under management as of 31 March 2023, BNP Paribas Asset
                    Management has c. 3000 employees in more than 30 countries as of 31st December 2022.
                  </Col>
                  {/* // */}
                  <Col md="12" className="pt-3 new-form-light-text">
                    <span className="new-form-bold-text">Pictet Asset Management</span> is a leading European investment manager and part of the
                    Pictet Group. Originally founded in 1805, the group’s asset management division has more than €200 billion in assets under
                    management, over 1000 employees and more than 400 investment professionals.
                  </Col>

                  <Col md="12" className="pt-3 new-form-bold-text">
                    Important Information and Disclosures
                  </Col>
                  <Col md="12 pt-3 new-form-light-text">
                    <span className="new-form-bold-text" style={{ fontStyle: 'italic' }}>
                      Money Market Funds:&nbsp;
                    </span>
                    Clients’ investments in this model portfolio are made up of whole and fractional holdings of Money Market Funds (MMF). By
                    investing in this portfolio, clients own the individual whole and fractional shares of the MMFs comprising the model portfolio.
                  </Col>

                  <Col md="12" className="pt-3">
                    <span className="new-form-bold-text">A MMF is not a guaranteed investment vehicle</span>
                  </Col>
                  <Col md="12 new-form-light-text pb-3">
                    An investment in a MMF is different from an investment in deposits; the principal invested in a MMF is capable of fluctuation and
                    the risk of loss of the principal is to be borne by the investor. An event such as default by a bank with which the MMF has
                    deposited money would cause the fund price to fall. A fall may also occur if interest rates fall so low or become negative as to
                    be less than the charges applied to the fund.
                  </Col>
                  <div className='p-3' style={{ border: '1px solid grey' }}>
                    <Col md="12" className=" new-form-bold-text">
                      <span className="strong">Warning: If you invest in this product you may lose some or all of the money you invest. </span>
                    </Col>
                    <Col md="12" className="pt-3 new-form-bold-text">
                      <span className="strong">Warning: Past Performance is not a reliable guide to future performance.</span>
                    </Col>
                    <Col md="12" className="pt-3 new-form-bold-text">
                      <span className="strong">Warning: The value of your investment may go down as well as up. </span>
                    </Col>
                    <Col md="12" className="pt-3 new-form-bold-text">
                      <span className="strong">Warning: The income you get from this investment may go down as well as up</span>
                    </Col>
                    <Col md="12" className="pt-3 new-form-bold-text">
                      <span className="strong">Warning: This product may be affected by changes in currency exchange rate</span>
                    </Col>
                  </div>
                  <Col md="12" className="py-3 text-center new-form-bold-text page-break-mpsd">
                    <span className="strong">Starlight Smart Account Allocation Mandate - Client Order Instruction </span>
                  </Col>
                  <Col md="12 new-form-light-text">
                    <span>
                      I hereby instruct Future Wealth Limited, trading as Starlight Wealth, to transmit trade orders to the MIFID Executing Broker
                      and, as applicable, utilise the available balance of my <span className="new-form-bold-text">Starlight Smart Account</span>{' '}
                      allocated to the <span className="new-form-bold-text">Starlight Smart Reserve Portfolio (EUR) (Series 1)</span> (as specified in
                      my Starlight Smart Allocation Mandate), to buy or sell an amount of each of the individual securities comprising this model
                      portfolio in proportion to the weightings of this model portfolio in accordance with the Terms of Business. Capitalised terms
                      used herein have the meaning given to such terms in the Terms of Business
                    </span>
                  </Col>
                  <Row md="12">
                    {/* {accountHolderType === 'Individual' ? (
            <Col md="3">
              <hr />
              <Row>
                <Col md="12 new-form-light-text">
                  Name 1:
                  <span className="">
                    <Input type="text" name="docname" id="docname1" value={name} {...lastName} className="textfield-style" />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md="12 new-form-light-text pt-2">
                  Date:
                  <span className="">
                    <Input
                      type="date"
                      className="textfield-style"
                      value={signatureDate1}
                      onChange={e => setSignatureDate1(e.target.value)}
                    />
                  </span>
                </Col>
              </Row>
            </Col>
          ) : (
            <>
              <Col md="3">
                <hr />
                <Row>
                  <Col md="12 new-form-light-text">
                    Name 1:
                    <span className="">
                      <Input type="text" name="docname" id="docname1" value={name} {...lastName} className="textfield-style" />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="12 new-form-light-text pt-2">
                    Date:
                    <span className="">
                      <Input
                        type="date"
                        className="textfield-style"
                        value={signatureDate1}
                        onChange={e => setSignatureDate1(e.target.value)}
                      />
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col md="3 new-form-light-text">
                <hr />
                <Row>
                  <Col md="12 new-form-light-text">
                    Name 2:
                    <span className="">
                      <Input type="text" name="docname" id="docname2" value={secondaryform.jointfirstname} className="textfield-style" />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="12 new-form-light-text pt-2">
                    Date:
                    <span>
                      <Input
                        type="date"
                        className="textfield-style"
                        value={signatureDate2}
                        onChange={e => setSignatureDate2(e.target.value)}
                      />
                    </span>
                  </Col>
                </Row>
              </Col>
            </>
          )} */}
                    <Col md="6">
                      {/* <hr />
            <Row>
              <Col md="12 new-form-light-text">
                For and on behalf of:
                <span>
                  <Input type="text" name="forAndOnBehalf" id="forAndOnBehalf" className="textfield-style" />
                </span>
              </Col>
            </Row>
 
            <Row className="pt-2">
              <Col md="12 new-form-light-text">
                Name of Authorised Signatory:
                <span>
                  {' '}
                  <Input type="text" name="authorisedSignatoryName2" id="authorisedSignatoryName2" className="textfield-style" />
                </span>
              </Col>
            </Row> */}
                      {/* <Row className="pt-2">
              <Col md="12 new-form-light-text">
                Position:
                <span>
                  {' '}
                  <Input type="text" name="position" id="position" className="textfield-style" />
                </span>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col md="12 new-form-light-text">
                Date:
                <span>
                  {' '}
                  <Input type="text" name="signatoryDate" id="signatoryDate" className="textfield-style" />
                </span>
              </Col>
            </Row> */}
                    </Col>
                  </Row>

                  {/* <Col md="12 new-form-light-text">
          <ul>
            <span>
              1 Individual Account <br />
            </span>
 
            <span>
              2 Individual – Joint Account <br />
            </span>
 
            <span>3 Company Account</span>
          </ul>
        </Col> */}
                  {/* <Col md="12 new-form-light-text">Starlight Smart Reserve Portfolio (EUR) (Series1) MPSD v3.12.23</Col>
                  <Col md="12" className="pt-3 new-form-light-text" style={{ fontSize: 'small' }}>
                    Future Wealth Limited, trading as Starlight Wealth, is regulated by the Central Bank of Ireland (institution code C496326)
                    Registered Office: The Mews Building, Robin Hill, Glen Road, Delgany A63 VH73, Ireland; Company Registration No. 715287{' '}
                  </Col> */}
                  <br />
                  <div>
                    <br />
                    <div className='mt-2 sign-container'>
                      <input className='companysosdoc-text-fields'
                        value={companyDetails?.company_name || newData?.company_details?.company_name || accountsEntity?.company_details?.company_name || mainAccountsEntity?.company_details?.company_name}
                      // onChange={handleOnBehalfOf}
                      />
                    </div>
                    <div className='row default-bold-text-small pt-2'>
                      <div className="col max-width-sign-label-div">
                        For and on behalf of
                      </div>
                      <div className="col">:</div>
                    </div>
                      <div className='row align-item-center pt-2'>
                        <div className='col max-width-sign-label-div'>Name of Authorised Signatory</div>
                        <div className='col'>
                        : <input className="signature-container-inside-box-2-input mb-2" value={smart_reserve_primary_sign || applicationData?.smart_reserve_portfolio?.primary_sign}
                          onChange={(e) => handleSignatureChange(setsmart_reserve_primary_sign,e.target.value)} />
                        </div>
                      </div>
                      <div className='row pt-2'>
                        <div className='col max-width-sign-label-div'>Position</div>
                        <div className='col'>
                        : <input className='signature-container-inside-box-2-input'
                          value={smartReserveAuthorisePosition || newData?.smartReserveAuthorisePosition}
                          onChange={handleAuthoriseMPSDPosition}
                        />
                        </div>
                      </div>
                      <div className='row pt-2'>
                        <div className='col max-width-sign-label-div'>Date</div>
                        <div className='col'>: {signatureTimestamp || formattedprimaryApiTimestamp}</div>
                        {/* <DatePickerComponent
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MM/dd/yyyy"
                                className="form-control"
                                styles={customStyles}
                                popperClassName="datepicker-popper"
                            /> */}

                      </div>
                  </div>
                  <Col md="12" className="text-end no-print">
                    <Button className="print_btn_w8"  disabled={!isPrintButtonEnabled()} onClick={() => handlePrint({ content: () => document.body })}>Print</Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
