import axios from 'axios';
import { Storage } from 'react-jhipster';
import { jwtDecode } from 'jwt-decode';

const TIMEOUT = 1 * 150 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    if(config?.url.indexOf("https://starwealth-dev-strapie.livelocal.delivery") > -1){
      return config;
    }
    if (config?.url.indexOf("api/user/login") > -1 || config?.url.indexOf("api/user/register") > -1 || config?.url.indexOf("api/user/sendOtp") || config?.url.indexOf("api/user/forgotPassword") > -1) {
      /* empty block */
    } else {
      checkTokenExpiration();
    }
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = response => response;

  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    if (status === 400) {
      if (err.message.startsWith("IBKR BadRequest ")) {
        let customMessage = err.message.replace("IBKR BadRequest ", "");
        if (customMessage.startsWith("[")) {
          customMessage = new Array(customMessage).join(",");
        }
        alert(customMessage);
      }
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
  const checkTokenExpiration = () => {
    const token = Storage.local.get('jhi-authenticationToken');

    // Decode the token to get the expiration time
    const decodedToken = jwtDecode(token);
    const tokenExpirationTime = decodedToken.exp * 1000; // Expiration time is in seconds, so convert it to milliseconds

    const currentTime = new Date().getTime();

    if (currentTime > tokenExpirationTime) {
      onUnauthenticated(); // Automatically log out if token is expired
    }
    // Start token expiration check (check every minute)
    setInterval(checkTokenExpiration, 60000);
  };
};

export default setupAxiosInterceptors;
