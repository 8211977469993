import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SecurityQuestions from './security-questions';
import SecurityQuestionsDetail from './security-questions-detail';
import SecurityQuestionsUpdate from './security-questions-update';
import SecurityQuestionsDeleteDialog from './security-questions-delete-dialog';

const SecurityQuestionsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SecurityQuestions />} />
    <Route path="new" element={<SecurityQuestionsUpdate />} />
    <Route path=":id">
      <Route index element={<SecurityQuestionsDetail />} />
      <Route path="edit" element={<SecurityQuestionsUpdate />} />
      <Route path="delete" element={<SecurityQuestionsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SecurityQuestionsRoutes;
