

import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useEffect, useState } from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import '../accounts/account.scss';
import { PencilFill } from 'react-bootstrap-icons';
import { getEntity, getCountryList, updateEntity } from './company-accounts-reducer';
import { FieldError } from 'react-hook-form';
import { ValidatedField } from 'react-jhipster';
import { getBankList, getState, updateEntityProperty } from '../accounts/accounts.reducer';
import companyDetails from './company-details';
import { getCustomerDetails } from '../customer/customer.reducer';
interface SummaryProps {
  onFormComplete: (completed: boolean) => void;
}
export interface SummaryRef {
  syncSummaryWithServer: () => Promise<any>;
}
interface Country {
  code: string;
  name: string;
}
interface State {
  code: string;
  name: string;
}

const Summary: React.ForwardRefRenderFunction<SummaryRef, SummaryProps> = ({ onFormComplete }: SummaryProps, ref) => {

  const dispatch = useAppDispatch();
  const [validatePhoneError, setValidationPhoneError] = useState('');
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const { last_level } = useAppSelector(state => state.accounts.entity);
  const [isEditingCompany, setIsEditingCompany] = useState(false);

  const [showdiv, setShowdiv] = useState(last_level);
  const [countryList, setCountryList] = useState<string[]>(mainAccountsEntity?.countryList || []);
  const [stateList, setStateList] = useState<string[]>(mainAccountsEntity?.statesList || []);
  const [bankList, setBankList] = useState<string[]>([]);
  const [numberOfForms, setNumberOfForms] = useState(1);
  const [validationError, setValidationErrror] = useState<{ [key: number]: string }>({});
  const [total, setTotal] = useState(0);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({ ...mainAccountsEntity, ...mainAccountsEntity.company_details } || {});

  const [key_person_details, setkey_person_details] = useState({
    authorized_person_details: [
      {
        name: '',
        role: '',
        firstname: '',
        lastname: '',
        passport_number: '',
        company_email_address: '',
        mobile_number: '+353',
        home_address: '',
        account_number: '',
        customer_id: '',
        date_of_bith: '',
        issued_country: "Ireland",
        passport_expiry_date: '',
        country_of_birth_and_citizen: 'Ireland',
        pps_number: '',
        country_of_tax_residency: 'Ireland',
        owns_more_25_of_company: false,
        source_of_person_wealth: '',
        passport_doc: '',
        address: {
          country: 'Ireland',
          state: 'Galway',
          city: '',
          postal_code: '',
          street: '',
        },
        proof_of_address: '',

      }
    ]
  });

  useEffect(() => {
    setTotal(Number(sessionStorage.getItem('totalpeople')))
    console.log("mainsaccountentity-->",mainAccountsEntity);
    
  }, []);
  const handleExperienceCheckboxChange = (role, index, checked) => {
    const updatedDetails = [...(mainAccountsEntity?.key_person_details?.authorized_person_details || [{}])];
    if (checked) {
      updatedDetails[index] = { ...updatedDetails[index], role };
    } else {
      updatedDetails[index] = { ...updatedDetails[index], role: '' };
    }
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const [emailError, setEmailError] = useState('');

  const handleRoleName = (index, event) => {
    const { value } = event.target;
    // const company_email_address = e.target.value;




    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], firstname: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });


  };

  const handleEmailChange = (index, event) => {
    const { value } = event.target;
    // const company_email_address = e.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (value === '') {
      setEmailError('');
    }
    if (!emailPattern.test(value)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], company_email_address: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };


  const handlePassportNumberChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], passport_number: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  // const handleChangetraiding = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setCompanyDetails({ ...companyDetails, tradingName: e.target.value });
  // };


  const handleMobileNumberChange = (index, event) => {
    const value = event.target.value.replace(/[^\d+]/g, '');
    const mobile_number = value.slice(0, 13);

    let lclValidationError = '';
    if (!/^(\+353)\d{9}$/.test(mobile_number)) {
      lclValidationError = 'Please enter a valid mobile number starting with +353 and followed by 9 digits.';
    } else if (value.length < 13) {
      lclValidationError = 'Mobile number should have 13 digits.';
    }
    setValidationPhoneError(lclValidationError);
    if (mobile_number.length === 13) {
      event.target.value = mobile_number;
      event.preventDefault();
    }
    const updatedDetails = mainAccountsEntity.key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], mobile_number };
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const handleAddressChange = (index, event) => {
    const { value } = event.target;

    // Create a copy of the authorized_person_details array
    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    // Update the home_address at the specified index
    updatedDetails[index] = { ...updatedDetails[index], home_address: value };

    // Set the updated key_person_details state
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  const handleKeyDown = (event) => {
    const allowedKeys = /[0-9+\b]/;
    const value = event.target.value;

    if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
      event.preventDefault();
    }

    if (value.length >= 13 && event.keyCode !== 8 && !(event.keyCode === 8)) {
      event.preventDefault();
    }

    if (event.key === '+' && value.length !== 0) {
      event.preventDefault();
    }
  };

  const handleCompanyEditClick = () => {
    setIsEditingCompany(true);
  };
  const handleCompanyUpdateClick = () => {
    setIsEditingCompany(false);
    return dispatch(updateEntity({
      ...mainAccountsEntity,
      is_company_account: true,
      companyAccount: true,
      last_level: 99
    }));
  };




  //---------------------------------------------

  const accessToken = localStorage.getItem('jhi-authenticationToken');

  // useEffect(() => {
  //   if (accessToken) {
  //     dispatch(getCountryList()).then((res) => {
  //       dispatch(getCustomerDetails());

  //       if (res.payload) {
  //         const countryData = res.payload.map((item: any) => ({
  //           code: item.code,
  //           name: item.name,
  //         }));

  //         setCountryList(countryData);
  //       }
  //     });
  //   }
  // }, [dispatch, accessToken]);

  // useEffect(() => {
  //   dispatch(getState()).then((res) => {
  //     if (res.payload) {
  //       const stateData = res.payload.map((item: any) => ({
  //         code: item.code,
  //         name: item.name,
  //       }));
  //       setStateList(stateData);
  //     }
  //   });

  // }, [dispatch]);



  // useEffect(() => {
  //   if (accessToken) {
  //     if (showdiv === 2 || showdiv === 4 || showdiv === 7) {
  //       dispatch(getState()).then((res) => {

  //         if (res.payload) {
  //           const stateData = res.payload.map((item: any) => item);
  //           setStateList(stateData);
  //         }
  //       });
  //     }
  //   }
  // }, [showdiv, dispatch]);
  // useEffect(() => {
  //   if (accessToken) {

  //     if (showdiv === 4 || showdiv === 7 || showdiv === 2) {
  //       dispatch(getCountryList()).then((res) => {

  //         if (res.payload) {
  //           const countryData = res.payload.map((item: any) => item);
  //           setCountryList(countryData);
  //         }

  //       });
  //     }
  //   }
  // }, [showdiv, dispatch]);




  useEffect(() => {
    if (accessToken) {
      dispatch(getCountryList()).then((res) => {
        dispatch(getCustomerDetails());

        if (res.payload) {
          const countryData: Country[] = res.payload.map((item: any) => ({
            code: item.code,
            name: item.name,
          }));
          setCountryList(mainAccountsEntity?.countryList);
        }
      });
    }
  }, [dispatch, accessToken]);





  useEffect(() => {
    if (accessToken) {

      if (showdiv === 5 || showdiv === 7) {
        dispatch(getBankList()).then((res) => {

          if (res.payload) {
            const bankData = res.payload.map((item: any) => item);
            setBankList(bankData);
          }

        });
      }
    }
  }, [showdiv, dispatch]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value } = e.target;
    const newProp: any = { ...mainAccountsEntity.company_details, [inputName]: value }
    dispatch(updateEntityProperty({ ['company_details']: newProp }));
    inputValues[inputName] = value;
  };

  //----------------------------------


  const findCountryNameByKey = (key: string | null | undefined): string => {
    const country: any = countryList.find((obj: any) => obj.name === key);


    return country ? country.name : '';
  };


  const findStateNameByKey = (key: string | null | undefined): string => {
    const state: any = stateList.find((obj: any) => obj.name === key);


    return state ? state.name : '';
  };


  const isOver18 = dob => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1 >= 18;
    }
    return age >= 18;
  };
  const handleDateOfBirthChange = (index, event) => {
    const { value } = event.target;
    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];
    // if (!updatedDetails[index]) {
    //   updatedDetails[index] = {};
    // }

    updatedDetails[index] = { ...updatedDetails[index], date_of_bith: value };
    const error = isOver18(value) ? '' : 'You must be 18 years old or older.';
    setValidationErrror(prev => ({ ...prev, [index]: error }));

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  const handleCountryofBirthChange = (index, event) => {
    const { value } = event.target;

    // Create a copy of the authorized_person_details array
    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    // Update the home_address at the specified index
    updatedDetails[index] = { ...updatedDetails[index], country_of_birth_and_citizen: value };

    // Set the updated key_person_details state
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const handleTaxResidencyChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], country_of_tax_residency: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  const handlePPSNumberChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], pps_number: value };

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  const handleOwnsCompanyChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], owns_more_25_of_company: value };


    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  const [companyAddressCountry, setCompanyAddressCountry] = useState();
  const [companyAddressStateorProvince, setCompanyAddressStateorProvince] = useState();


  const [companyAddressState, setCompanyAddressState] = useState('');
  // const [newCompanySummaryData, setNewCompanySummaryData] = useState('');
  const i = 0;

  useEffect(() => {
    const accountId = mainAccountsEntity?.toString() || '';
    if (accountId) {
      dispatch(getEntity(mainAccountsEntity?.accountId))
        .then((new_response: any) => {

          setCompanyAddressCountry(new_response?.payload?.data?.company_details?.authorized_person_details?.[i]?.address?.country);
          setCompanyAddressState(new_response?.payload?.data?.company_details?.authorized_person_details?.[i]?.address?.state);
          setCompanyAddressStateorProvince(new_response?.payload?.data?.state_or_province);

        })
        .catch(error => {
          console.error('Failed to fetch account entity:', error);
        });
    }
  }, []);

  const handlePersonalWealthChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details
      ? [...key_person_details.authorized_person_details]
      : [];

    updatedDetails[index] = { ...updatedDetails[index], source_of_person_wealth: value };
    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const handleCountryChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            country: value,
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const handleStateChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            state: value,
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const handleCityChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            city: value,
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };
  const handlePostCodeChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            postal_code: value,
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  const handleStreetChange = (index, event) => {
    const { value } = event.target;

    const updatedDetails = key_person_details.authorized_person_details.map((detail, idx) => {
      if (idx === index) {
        return {
          ...detail,
          address: {
            ...detail.address,
            street: value,
          },
        };
      }
      return detail;
    });

    setkey_person_details({
      ...key_person_details,
      authorized_person_details: updatedDetails,
    });
  };

  const renderForms = () => {
    const forms = [];
    const roles = [

      { name: 'Company Authorised Person', role: 'COMPANY AUTHORIZED PERSON' },
      { name: 'Director', role: 'DIRECTOR' },
      { name: 'Beneficiary Owners', role: 'BENIFICIAL OWNER' },
      { name: 'Approval Authority', role: 'APPROVAL AUTHORITY' },
    ];

    for (let j = 0; j < (total || numberOfForms); j++) {
      const formRole = roles[j % roles.length];  // Rotate through roles array
      const formName = formRole.name;

      forms.push(
        <>
          <div key={j}>
            <Row>
              <Col md="12" className="pt-3 form-firstrow-account-head-custom" >
                <span>{`Key Person ${j + 1}`}</span>
              </Col>
              <Col md="6" style={{ lineHeight: '2.5' }}>
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span>{`Name`}</span>
                  </Label>

                  <Input
                    type="text"
                    name="firstname"
                    id={`firstname-${j}`}
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    defaultValue={`${mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.firstname} ${mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.lastname}`}
                    // defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.firstname}
                    // value={inputValues?.firstname}
                    onChange={(event) => handleRoleName(j, event)}

                  />
                </FormGroup>
              </Col>
              <Col md="6">
  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
    <span className='strong'>Role in the Company</span>
  </Label>

  <Row className="ms-2">
    <Col md="6">
    {['COMPANY AUTHORISED PERSON', 'APPROVAL AUTHORITY', 'DIRECTOR', 'BENEFICIAL OWNER'].map((possibleRole, index) => {
  const roles = mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.role || [];
  const isCompanyAuthorizedPersonChecked = 
    possibleRole === 'COMPANY AUTHORISED PERSON' && roles.includes('AUTHORIZED OFFICER');
    const isBeneficialChecked = 
    possibleRole === 'BENEFICIAL OWNER' && roles.includes('BENIFICIAL OWNERS');
  const isChecked = 
    isCompanyAuthorizedPersonChecked ||isBeneficialChecked|| roles.includes(possibleRole);

  return (
    <FormGroup check key={index}>
      <Label className="label-account-class" check>
        <Input
          type="checkbox"
          readOnly={true}
          disabled
          className="input-account-custom-bgnone"
          checked={isChecked}
        />{' '}
        {possibleRole}
      </Label>
    </FormGroup>
  );
})}
    </Col>
  </Row>
</Col>


              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Company Email Address</span>
                  </Label>
                  <Input
                    type="text"
                    name="company_email_address"
                    id="company_email_address"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.company_email_address}

                    value={inputValues?.company_email_address}
                    onChange={(event) => handleEmailChange(j, event)}
                    className="summary-text-fields"
                    readOnly={!isEditingCompany} />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Mobile Number</span>
                  </Label>
                  <ValidatedField
                    name="phone"
                    required
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    data-cy="mobile_number"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.mobile_number}
                    value={inputValues?.mobile_number}
                    validate={{
                      required: 'Mobile number cannot be empty!',
                      pattern: {
                        value: /^\+353\d{0,9}$/,
                        message: 'Please enter a valid mobile number starting with +353 and followed by up to 9 digits.',
                      },
                    }}
                    onChange={(event) => handleMobileNumberChange(j, event)}
                    style={{ width: '100%', padding: '3px 12px' }}
                    // defaultValue="+353"
                    onKeyDown={handleKeyDown}
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Date Of Birth</span>
                  </Label>
                  <Input
                    name="date_of_bith"
                    required
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    type="date"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.date_of_bith}

                    value={inputValues?.date_of_bith}
                    onChange={(event) => handleDateOfBirthChange(j, event)}
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Owns {'>'}15% of the Company?</span>
                  </Label>
                </FormGroup>

                {!isEditingCompany && (
                  <Col md="12" className='summary-country-text-styles'>
                    {mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.owns_more_25_of_company === true ? (
                      <span className='summary-country-text-styles'>Yes</span>
                    ) : (

                      <span className='summary-country-text-styles'>No</span>
                    )}
                  </Col>
                )}
                {mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.owns_more_25_of_company === true || isEditingCompany && (
                  <Col md="12 " className='summary-country-text-styles'>
                    <Input
                      type="select"
                      name="ownsCompany"
                      id="ownsCompany"
                      className="summary-text-fields"
                      defaultValue={mainAccountsEntity?.authorized_person_details?.[j]?.owns_more_25_of_company}
                      onChange={(event) => handleOwnsCompanyChange(j, event)}
                    >
                      <option value={"true"}>Yes</option>
                      <option value={"false"}>No</option>
                    </Input>
                  </Col>
                )}
                {mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.owns_more_25_of_company === true && (
                  <Col md="12">
                    <FormGroup>
                      <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        <span className='strong'>Source of personal wealth</span>
                      </Label>
                      <Input
                        type="text"
                        name="source_of_person_wealth"
                        id="source_of_person_wealth"
                        className="summary-text-fields"
                        defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.source_of_person_wealth}
                        value={inputValues?.source_of_person_wealth}
                        onChange={(event) => handlePersonalWealthChange(j, event)}
                        readOnly={!isEditingCompany}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Country of Tax Residency</span>
                  </Label>
                  <Input
                    type="text"
                    name="country_of_tax_residency"
                    id="country_of_tax_residency"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    defaultValue={findCountryNameByKey(mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.country_of_tax_residency) || findCountryNameByKey("Ireland")}

                    value={inputValues?.country_of_tax_residency}
                    onChange={(event) =>
                      handleTaxResidencyChange(j, event)
                    }
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Passport Number</span>
                  </Label>
                  <Input
                    type="text"
                    name="passportnumber"
                    id="passportnumber"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.passport_number}

                    value={inputValues?.passport_number}
                    onChange={(event) =>
                      handlePassportNumberChange(j, event)
                    }
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    {/* <span style={{ color: 'red', fontSize: 20 }}>*</span> */}
                    <span>Passport Expiry Date</span>
                  </Label>


                  <Input
                    name="passport_expiry_date"
                    required
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    type="date"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.passport_expiry_date}

                    value={inputValues?.passport_expiry_date}

                  />




                </FormGroup>
              </Col>

              <Col md="6" className="py-3">
                <FormGroup>
                  <Label className="label-account-class" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span >Country Issued</span>
                  </Label>
                  <Input
                    type="text"
                    name="country_Issued"
                    id="country_Issued"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    defaultValue={findCountryNameByKey(mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.issued_country) || findCountryNameByKey("Ireland")}

                    value={inputValues?.issued_country}

                  ></Input>
                </FormGroup>
              </Col>
              <Col md="6" className="py-3">
                <FormGroup>
                  <Label className="label-account-class" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span >Country Of Citizenship</span>
                  </Label>
                  <Input
                    type="text"
                    name="citizenship"
                    id="citizenship"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    defaultValue={findCountryNameByKey(mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.citizenship) || findCountryNameByKey("Ireland")}
                    value={inputValues?.citizenship}

                  ></Input>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>PPS Number</span>
                  </Label>
                  <Input
                    type="text"
                    name="ppsNumber"
                    id="ppsNumber"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.pps_number}

                    value={inputValues?.pps_number}
                    onChange={(event) =>
                      handlePPSNumberChange(j, event)
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Country of Birth</span>
                  </Label>
                  <Input
                    type="text"
                    name="country_of_birth_and_citizen"
                    id="country_of_birth_and_citizen"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    defaultValue={findCountryNameByKey(mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.country_of_birth_and_citizen) || findCountryNameByKey("Ireland")}

                    value={inputValues?.country_of_birth_and_citizen}
                    onChange={(event) =>
                      handleCountryofBirthChange(j, event)
                    }
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>House Street Address</span>
                  </Label>
                  <Input
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    type="text"
                    name="home_address"
                    id="home_address"
                    // placeholder="enter your address"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.home_address}

                    value={inputValues?.home_address}
                    onChange={(event) => handleAddressChange(j, event)}


                  />
                </FormGroup>
                {/* {validationErrror && !key_person_details.address && <div className="text-danger">{validationErrror}</div>} */}
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class  text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'> Address Line 2</span></Label>
                  <Input
                    type="text"
                    name="street"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.address?.street}
                    value={inputValues?.street}

                    id="street"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    onChange={(event) => handleStreetChange(j, event)}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Town/City </span></Label>
                  <Input
                    type="text"
                    name="city"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.address?.city}

                    value={inputValues?.city}

                    id="city"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    onChange={(event) => handleCityChange(j, event)}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>County</span>
                  </Label>
                  <Input
                    type="text"
                    name="state"
                    defaultValue={findStateNameByKey(mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.address?.state) || findStateNameByKey("Galway")}

                    value={inputValues?.state}

                    id="state"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    onChange={(event) => handleStateChange(j, event)}
                  >
                    {/* {stateList && stateList.map((item, index) => (
                      <option key={index} value={item.code}>{item.name}</option>
                      <option key={index} value={item.code}>{item.name}</option>
                    ))} */}

                    <> <option value="Galway">{findStateNameByKey("Galway")}</option></>
                    {stateList && stateList.map((item: any, index) => (
                      <option key={index} value={item.name}>{item.name}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>


              <Col md="6" >
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'> Country</span>
                  </Label>
                  <Input
                    type="text"
                    name="country"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.address?.country}

                    value={inputValues?.country}
                    id="country"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    onChange={(event) => handleCountryChange(j, event)}
                  // disabled
                  >

                    <> <option value="Ireland">{findCountryNameByKey("Ireland")}</option></>
                    {countryList && countryList.map((item: any, index) => (
                      <option key={index} value={item.name}>{item.name}</option>
                    ))}
                    {/* {countryList && countryList.map((item, index) => (
                      <option key={index} value={item.code}>{item.name}</option> // Ensure to use appropriate properties
                    ))} */}
                  </Input>

                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-account-class text-left" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className='strong'>Post Code</span></Label>
                  <Input
                    type="text"
                    name="postal_code"
                    defaultValue={mainAccountsEntity?.company_details?.authorized_person_details && mainAccountsEntity?.company_details?.authorized_person_details?.[j]?.address?.postal_code}

                    value={inputValues?.postal_code}

                    id="postal_code"
                    className="summary-text-fields"
                    readOnly={!isEditingCompany}
                    onChange={(event) => handlePostCodeChange(j, event)}
                  />
                </FormGroup>
              </Col>

            </Row>
          </div>
        </>,
      );
    }

    return forms;
  };

  return (
    <div>
      <Row>
        {/* <Col md="12" className="pt-3 text-end">
          {isEditingCompany ? (
            <Button onClick={handleCompanyUpdateClick} className="update-button-account-summary-submit">
              Update
            </Button>
          ) : (
            <PencilFill className="edit-button-style" onClick={handleCompanyEditClick} />
          )}
        </Col> */}
        <Col md="12" className="form-firstrow-account-head-custom pt-3 pb-3">
        My Starlight Smart Reserve Application Summary  
        </Col>

        <Col md="12" className="pt-3">
          <span className="strong">Please review the information you have entered. If you need to make any changes, please navigate back to the relevant form section to update the information.</span>
        </Col>
        {/* <Col md="12" className="pt-3">
          <span className="strong">If you Need to Make Any Changes: </span>
          <li>Click on the pencil icon <span><PencilFill /></span> at the top right </li>
          <li>Make the changes  </li>
          <li>Then click on the Update Button (again, top right) </li>
          <li>Then click Continue (bottom right) </li>
        </Col> */}
        <Col md="12" className="pt-3 form-firstrow-account-head-custom" >
          Initial Requirements Check<br />


        </Col>

        <Col md="12" className="pt-3" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
          Type of Reserve Account:
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          {mainAccountsEntity?.productType?.map((item, index) => {
            const hasUSD = mainAccountsEntity?.USDReserve && index === 0;
            const hasEUR = mainAccountsEntity?.EURReserve && index === 1;

            let displayText = item;
            if (hasUSD) {
              displayText += ' ';
            }
            if (hasEUR) {
              displayText += ' ';
            }

            return (
              <Col md="12" style={{ lineHeight: '2.5' }} key={index}>
                {displayText}
              </Col>
            );
          })}
        </Col>

        <Col md="12" className="pt-3" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
          Investment Amount:
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          {`€${mainAccountsEntity?.investmentAmount}`}
        </Col>
        <Col md="12" className="pt-3" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
          Initial Suitability Check
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          You have confirmed that the Company:
          <br />
          Agrees to the Starlight Wealth Terms of Business, Privacy Policy, and Remuneration Policy

        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          Is registered in Ireland

        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          Is tax resident in Ireland

        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          Will be the beneficial owner of the account

        </Col>

        <Col md="12" style={{ lineHeight: '2.5' }}>
          Has a lump sum available to invest
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          Has a minimum of 3 months of operating expenses covered from other funds
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          Investment Objective is to preserve capital and generate a return that reflects the European Central Bank (ECB) deposit rate
        </Col>

        <Col md="12" style={{ lineHeight: '2.5' }}>
          Will only hold the company’s proprietary assets in the account and will not hold funds of its clients or passive investors
        </Col>

        {/* // */}
        <Col md="12" className="pt-3 form-firstrow-account-head-custom" >
          <span className="strong">Company Details</span>
        </Col>

        <Col md="12" style={{ lineHeight: '2.5' }}></Col>
        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Company Name :</span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <Input
            type="text"
            name="company_name"
            id="company_name"
            defaultValue={mainAccountsEntity?.company_details?.company_name}
            value={inputValues?.company_name}
            onChange={e => handleValueChange(e)}
            className="summary-text-fields"
            readOnly={!isEditingCompany}
          />
        </Col>
        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Trading Name:</span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <Input
            type="text"
            name="trading_name"
            id="trading_name"
            defaultValue={mainAccountsEntity?.company_details?.trading_name}
            value={inputValues?.trading_name}
            onChange={e => handleValueChange(e)}
            className="summary-text-fields"
            readOnly={!isEditingCompany}
          />
        </Col>
        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Company Registration Number :</span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <Input
            type="text"
            name="registration_number"
            id="registration_number"
            // value={companyDetails.companyRegistrationNumber || mainAccountsEntity?.company_details?.registration_number}
            defaultValue={mainAccountsEntity?.company_details?.registration_number}
            value={inputValues?.registration_number}
            onChange={e => handleValueChange(e)}
            className="summary-text-fields"
            readOnly={!isEditingCompany}
          />
        </Col>
        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Phone Number</span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <Input
            type="tel"
            name="accountHolderMobileNumber"
            id="accountHolderMobileNumber"
            // value={companyAddressDetails.accountHolderMobileNumber || mainAccountsEntity?.accountHolderMobileNumber}
            defaultValue={mainAccountsEntity?.accountHolderMobileNumber}
            value={inputValues?.accountHolderMobileNumber}
            onChange={e => handleValueChange(e)}
            className="summary-text-fields"
            readOnly={!isEditingCompany}
          />
        </Col>
        <Row>
          <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            <span className="strong"> Company Registered Address </span>
          </Col>
          <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            <span className="strong"> Street</span>
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="addressLine1"
              id="addressLine1"
              className="summary-text-fields"
              readOnly={!isEditingCompany}
              // value={companyAddressDetails.addressLine1 || mainAccountsEntity?.addressLine1}
              defaultValue={mainAccountsEntity?.street_or_province}
              value={inputValues?.addressLine1}
              onChange={e => handleValueChange(e)}
            />
          </Col>


          <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            <span className="strong">Town / City</span>
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="townorcity"
              id="townorcity"
              className="summary-text-fields"
              readOnly={!isEditingCompany}
              // value={companyAddressDetails.townorcity || mainAccountsEntity?.townOrCity}
              defaultValue={mainAccountsEntity?.townOrCity}
              value={inputValues?.townOrCity}
              onChange={e => handleValueChange(e)}
            />

          </Col>
          <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            <span className="strong">
              County
            </span>
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="state_or_province"
              id="state_or_province"
              className="summary-text-fields"
              readOnly={!isEditingCompany}
              // value={companyAddressDetails.state_or_province || mainAccountsEntity?.state_or_province}
              defaultValue={findStateNameByKey(mainAccountsEntity?.state_or_province) || findStateNameByKey("Galway")}

              value={inputValues?.state_or_province}
              // defaultValue={companyAddressStateorProvince}
              onChange={e => handleValueChange(e)}
            >
              <> <option value="Galway">{findStateNameByKey("Galway")}</option></>
              {stateList && stateList.map((item: any, index) => (
                <option key={index} value={item.name}>{item.name}</option>
              ))}
            </Input>

          </Col>

          <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            <span className="strong">Country</span>
            <Input
              type="text"
              name="country"
              id="country"
              className="summary-text-fields"
              readOnly={!isEditingCompany}
              // value={companyAddressDetails.country}
              defaultValue={mainAccountsEntity?.country}
              value={inputValues?.country}
            // onChange={e => handleCountryChange(e)}
            />
          </Col>
          <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            <span className="strong"> Post Code</span>
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="postcode"
              id="postcode"
              className="summary-text-fields"
              readOnly={!isEditingCompany}
              // value={companyAddressDetails.postcode}
              defaultValue={mainAccountsEntity?.postcode}
              value={inputValues?.postcode}
              onChange={e => handleValueChange(e)}
            />
          </Col>

        </Row>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <span className="strong" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
            Description of Business Activities:
          </span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <Input
            type="text"
            name="description_of_business_activities"
            id="description_of_business_activities"
            // value={companyDetails.description}
            defaultValue={mainAccountsEntity?.description_of_business_activities}
            value={inputValues?.description_of_business_activities}
            onChange={handleValueChange}
            className="summary-text-fields"
            readOnly={!isEditingCompany}
          />
        </Col>

        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">
            Legal Entity Identifier(LEI)
          </span>
        </Col>
        <Col md="12" className='summary-country-text-styles'>
          {mainAccountsEntity?.company_details?.lei}

        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">
            If the company does NOT have an LEI, please indicate if you wish Interactive Brokers to apply for an LEI on the company’s
            behalf:
          </span>
        </Col>
        <Col md="12" className='summary-country-text-styles'>
          {mainAccountsEntity?.company_details?.is_lei ? 'Yes' : 'No'}

        </Col>
        {mainAccountsEntity?.company_details?.lei === false && (
          <>
            <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
              <span className="strong">Legal Entity Identifier (LEI):</span>
            </Col>
            <Col md="12" style={{ lineHeight: '2.5' }}>
              <Input
                type="text"
                name="lei"
                id="lei"
                // value={companyDetails.Lei}
                defaultValue={mainAccountsEntity?.lei}
                value={inputValues?.lei}
                onChange={handleValueChange}
                className="summary-text-fields"
                readOnly={!isEditingCompany}
              />
            </Col>
          </>
        )}



        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Tax Residency</span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Is the company Tax Resident in Ireland:</span>
        </Col>
        <Col md="12" className='summary-country-text-styles'>
          {mainAccountsEntity?.company_details?.tax_residency === true ? 'Yes' : 'No'}
          {mainAccountsEntity?.company_details?.tax_residency || isEditingCompany && (
            <>
              <div>
                <label>
                  <input type="radio" className='summary-country-text-styles' value="true" checked={mainAccountsEntity?.tax_residency === 'true'} onChange={handleValueChange} />
                  Yes
                </label>
              </div>
              <div>
                <label>
                  <input type="radio" className='summary-country-text-styles' value="false" checked={mainAccountsEntity?.tax_residency === 'false'} onChange={handleValueChange} />
                  No
                </label>
              </div>
            </>
          )}
        </Col>
        {mainAccountsEntity?.company_details?.tax_residency ? (
          <>
            <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
              <span className="strong">Company Tax Registration Number:</span>
            </Col>
            <Col md="12" style={{ lineHeight: '2.5' }}>
              <Input
                type="text"
                name="tax_registration_number"
                id="tax_registration_number"
                // value={companyDetails.taxregistrationNumber}
                defaultValue={mainAccountsEntity?.company_details?.tax_registration_number}
                value={inputValues?.taxregistrationNumber}
                onChange={handleValueChange}
                className="summary-text-fields"
                readOnly={!isEditingCompany}
              />
            </Col>
            <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
              <span className="strong">FATCA Classification(if known):</span>
            </Col>
            <Col md="12" style={{ lineHeight: '2.5' }}>
              <Input
                type="text"
                name="fatca"
                id="fatca"
                // value={companyDetails.fatca}
                defaultValue={mainAccountsEntity?.company_details?.fatca_classification}
                value={inputValues?.fatca}
                onChange={handleValueChange}
                className="summary-text-fields"
                readOnly={!isEditingCompany}
              />
            </Col>
          </>
        ) : null}

        <div>
          <Col md="12" className="form-firstrow-account-head-custom py-2">
            <span>Details Of Company Key People</span>
          </Col>
          {renderForms()}
        </div>

        <Col md="12" className="pt-3 form-firstrow-account-head-custom" >
          <span className="strong">Company Financials</span>
        </Col>
        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Total Net Worth:</span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <Input
            type="text"
            name="totalnetworth"
            id="totalnetworth"
            // value={formatCurrency(FormDataNetworth?.totalNetWorth) || mainAccountsEntity?.totalNetWorth}
            defaultValue={`€${mainAccountsEntity?.totalNetWorth}`}
            value={`€${inputValues?.totalNetWorth}`}
            inputMode="numeric"
            onChange={handleValueChange}
            className="summary-text-fields"
            readOnly={!isEditingCompany}
          />
        </Col>
        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Liquid Net Worth:</span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <Input
            type="text"
            name="liquidnetworth"
            id="liquidnetworth"
            // value={formatCurrency(FormDataNetworth?.liquidnetworth) || mainAccountsEntity?.liquidNetWorth}
            defaultValue={`€${mainAccountsEntity?.liquidNetWorth}`}
            value={`€${inputValues?.liquidNetWorth}`}
            inputMode="numeric"
            onChange={handleValueChange}
            className="summary-text-fields"
            readOnly={!isEditingCompany}
          />
        </Col>

        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Annual Net Income:</span>
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
          <Input
            className="summary-text-fields"
            readOnly={!isEditingCompany}
            name="totalnetincome"
            id="totalnetincome"
            // value={formatCurrency(FormDataNetworth?.totalnetincome) || mainAccountsEntity?.totalNetIncome}
            defaultValue={`€${mainAccountsEntity?.totalNetIncome}`}
            value={`€${inputValues?.totalNetIncome}`}
            required
            onChange={handleValueChange}
          />
        </Col>

        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Does your company generate 10% or more of its revenue outside of Ireland:</span>
        </Col>
        <Col md="12" className="summary-country-text-styles">
          <Input
            type="text"
            name="revenue_10_other_country"
            id="revenue_10_other_country"
            readOnly
            value={inputValues?.revenue_10_other_country || mainAccountsEntity?.company_details?.revenue_10_other_country ? ("Yes") : "No"}
            onChange={handleValueChange}
            className="summary-text-fields"
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Input>
        </Col>

        {mainAccountsEntity?.company_details?.revenue_10_other_country === true ? (
          <>
            <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
              <span className="strong">
                Please list all countries outside Ireland where the company generates 10% or more of its revenue
              </span>
            </Col>
            <Col md="12" style={{ lineHeight: '2.5' }}>
              <Input
                type="text"
                name="countries_revenue"
                id="countries_revenue"
                className="summary-text-fields"
                value={mainAccountsEntity?.company_details?.countries_revenue || ''}
                onChange={handleValueChange}
              />
            </Col>
          </>
        ) : null}

        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Business Revenue %:</span>
        </Col>
        <Col md="12" className='summary-country-text-styles'>
          <Input
            className="summary-text-fields"
            readOnly={!isEditingCompany}
            type="tel"
            name="buisnesRevenue"
            id="buisnesRevenue"
            defaultValue={mainAccountsEntity?.company_details?.business_revenue}
            value={inputValues?.business_revenue}
            onChange={handleValueChange}
          />
        </Col>
        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Investment / Funding Proceeds %:</span>
        </Col>
        <Col md="12" className='summary-country-text-styles'>
          <Input
            className="summary-text-fields"
            readOnly={!isEditingCompany}
            type="tel"
            name="InvestmentOrfundingProceeds"
            id="InvestmentOrfundingProceeds"
            // value={mainAccountsEntity.InvestmentOrfundingProceeds !== 0 ? mainAccountsEntity.InvestmentOrfundingProceeds : ''}
            defaultValue={mainAccountsEntity?.company_details?.investment_funding}
            value={inputValues?.investment_funding}
            onChange={handleValueChange}
          />
        </Col>


        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Other %:</span>
        </Col>
        <Col md="12" className='summary-country-text-styles'>
          <Input
            className="summary-text-fields"
            readOnly={!isEditingCompany}
            type="tel"
            name="investmentFromOthers"
            id="investmentFromOthers"
            // value={mainAccountsEntity?.OtherProvideDetailsPercent !== 0 ? mainAccountsEntity?.OtherProvideDetailsPercent : ''}
            defaultValue={mainAccountsEntity?.investmentFromOthers}
            value={inputValues?.investmentFromOthers}
            onChange={handleValueChange}
          />
        </Col>
        <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Other (provide details):</span>
        </Col>
        <Col md="12" className='summary-country-text-styles'>
          <Input
            className="summary-text-fields"
            readOnly={!isEditingCompany}
            type="text"
            name="otherDetails"
            id="otherDetails"
            // value={FormDataNetworth.OtherProvideDetails}
            defaultValue={mainAccountsEntity?.company_details?.other_provide_details}
            value={inputValues?.other_provide_details}
            onChange={handleValueChange}
          />
        </Col>

      </Row>
    </div>
  );
};

export default forwardRef(Summary);
