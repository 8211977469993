import dayjs from 'dayjs';

export interface ICustomer {
  id?: string;
  kcCustomerId?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  mobileNumber?: string | null;
  dateOfBirth?: dayjs.Dayjs | null;
  role?: string | null;
  tcAgreed?: boolean | null;
  agreedTcContent?: string | null;
  createdAt?: dayjs.Dayjs | null;
  last_level?: number | null;
  loginEvents?: string | null;
}

export const defaultValue: Readonly<ICustomer> = {
  tcAgreed: false,
};
