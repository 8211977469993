
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Input, Row, Button } from 'reactstrap';
import './account.scss';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, getSignature } from './accounts.reducer';
import { useLocation, useParams } from 'react-router-dom';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { getAdminAccounts } from '../admin-profile/admin.reduce';
import moment from 'moment-timezone';


const SosDoc = ({
    FName,
    LName,
    invest,
    docConfirmation,
    setDocConfirmation,
    sosPrimary_sign,
    sosSecondarySign,
    accountIdNum,
    // AccountType,
    accountHolderType,
    setsosPrimarySign= (data)=>{},
    // handleSignatureUpdate,
    setsosSecondarySign,
}) => {
    const dispatch = useAppDispatch();
    const { accountId } = useParams();
    const location = useLocation();
    const { level, _signatureDataPending } = location.state || {};
    const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
    const signatureData = useAppSelector(state => state.accounts.signatureData);
    const currentDate = new Date().toLocaleDateString('en-GB');
    const [secondaryId, setSecondaryId] = useState()
    const [secondaryInvestmentAmount, setSecondaryInvestmentAmount] = useState()
    const [primaryInputValue, setPrimaryInputValue] = useState(sosPrimary_sign || '');
    const [secondaryInputValue, setSecondaryInputValue] = useState(sosSecondarySign || '');
    const [signatureTimestamp, setSignatureTimestamp] = useState<string>(''); 
    const [signatureTimezone, setSignatureTimezone] = useState<string>(''); 

    const [jointsignatureTimestamp, setJointSignatureTimestamp] = useState<string>(''); 

    useEffect(() => {
        dispatch(getPendingTask({}))
            .then((response: any) => {
                const signaturePending = response?.payload?.data?.signature_pending;

                if (signaturePending) {
                    signaturePending.forEach((item) => {
                        setSecondaryId(item.secondary_customer_id);
                        setSecondaryInvestmentAmount(item?.investmentAmount)
                    });
                }
            })
            .catch(error => {
            });
    }, [dispatch]);

 
    useEffect(() => {
        const accountId = mainAccountsEntity?.toString() || '';

        if (mainAccountsEntity?.accountId) {

            dispatch(getEntity(mainAccountsEntity?.accountId))
                .then((new_response: any) => {
                    setSecondaryInvestmentAmount(new_response?.payload?.data?.investAmount);

                })
                .catch(error => {
                    console.error('Failed to fetch account entity:', error);
                });
        }
    }, []);
    const [applicationData, setApplicationData] = useState<any>({});
    const [newData, setNewData] = useState<any>({});

    useEffect(() => {
        if (accountId) {
            dispatch(getAdminAccounts({ accountId }))
                .then((response: any) => {
                    if (response.payload.data) {
                        setApplicationData(response.payload.data.account_signature_response);
                        setNewData(response.payload.data);


                    }
                })
                .catch((error) => {
                });
        }
    }, [accountId]);
    const pageStyle = `

  @media print {

  @page {
  size: auto;
  margin-top: 20mm;
  margin-bottom: 20mm;
}
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
    body {
      font-size: 13px;
    }

    .no-print {
      display: none;
    }

    .new-form-bold-text,
    .new-form-italic-text,
    .new-form-underline-text {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }

    .new-form-heading-text {
      font-weight: bold;
      font-size: 25px;
      color: black;
    }

    .new-form-light-text {
      font-weight: 300;
      font-size: 13px;
      color: black;
    }

    .new-form-very-light-text {
      font-weight: 300;
      font-size: 12px;
      color: black;
    }

    .new-form-italic-text {
      font-style: italic;
    }

    .bordered-table {
      width: 100%;
      border-collapse: collapse;
    }
    .bordered-table th,
    .bordered-table td {
      border: 1px solid black;
      padding: 8px;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .bordered-table th {
      background-color: #f2f2f2;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .new-form-table-row-one {
      background: #607980;
      color: black;
      font-weight: 300;]
      font-size: 13px;
    }
    .new-form-table-row-two {
      background-color: #d5d8da;
      color: black;
      font-weight: 300;
      font-size: 13px;
    }
  }


`;
    const [customerId, setCustomerId] = useState()
    useEffect(() => {
        const accessToken = localStorage.getItem('jhi-authenticationToken');
        if (accessToken) {
            const tokenParts = accessToken.split('.');

            if (tokenParts?.length === 3) {
                try {
                    const payload = JSON.parse(atob(tokenParts[1]));

                    if (payload?.sub) {
                        const { sub } = payload;
                        setCustomerId(sub)
                        // Do something with the sub, such as setting it in state or using it elsewhere
                    }
                } catch (error) { /* empty */ }
            }
        }
    }, []);

    useEffect(() => {
        if (accountIdNum) {
            dispatch(getSignature(accountIdNum));
        }
    }, [accountIdNum, dispatch]);
    useEffect(() => {
        if (signatureData?.statement_of_suitability) {
            setsosPrimarySign(signatureData?.statement_of_suitability?.primary_sign);
        }
    }, [signatureData]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Statement of Suitability',
        onBeforePrint: () => console.log('before printing...'),
        onAfterPrint: () => console.log('after printing...'),
        pageStyle,
        removeAfterPrint: true,
    });
    const componentRef = useRef();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    const checkIfPrintButtonShouldBeEnabled = () => {
        if (accountHolderType === 'Individual' && mainAccountsEntity?.accountHolderType === 'Individual' || applicationData?.account_type === 'Individual') {
        
          return mainAccountsEntity?.customerId === customerId && (primaryInputValue.trim() !== '' || sosPrimary_sign || applicationData?.statement_of_suitability?.primary_sign);
        } else {
          
          const isPrimaryValid = customerId === mainAccountsEntity?.customerId 
            ? (primaryInputValue.trim() !== '' || sosPrimary_sign || applicationData?.statement_of_suitability?.primary_sign) 
            : true;
          const isSecondaryValid = customerId === secondaryId 
            ? (secondaryInputValue.trim() !== '' || sosSecondarySign || applicationData?.statement_of_suitability?.joint_sign) 
            : true;
    
          return isPrimaryValid && isSecondaryValid;
        }
      };
      useEffect(() => {
        setIsButtonEnabled(checkIfPrintButtonShouldBeEnabled());
      }, [primaryInputValue, secondaryInputValue, sosPrimary_sign, sosSecondarySign]);

      const handleSignatureChange = (setter, value) => {
        setter(value);
        const userTimezone = moment.tz.guess();
        const currentDateTimeWithZone = moment().utc().format('DD-MM-YYYY HH:mm:ss') + ' GMT';
        const formattedDateTimeWithCity = `${currentDateTimeWithZone} (${userTimezone.replace('_', ' ')})`;
        setSignatureTimestamp(formattedDateTimeWithCity);
        localStorage.setItem('signatureTimestamp', formattedDateTimeWithCity);
    };
    const formattedsosApiTimestamp = applicationData?.statement_of_suitability?.primary_signed_timestamp
    ? moment.utc(applicationData?.statement_of_suitability?.primary_signed_timestamp, "DD-MM-YYYYTHH:mm:ss.SSSSSSZ")
        .tz(moment.tz.guess())
        .format("DD-MM-YYYY HH:mm:ss [GMT]") + ` (${moment.tz.guess()})`
    : null;
    
      const handleJointSignatureChange = (setter: Function, value: string) => {
        setter(value);
        const userTimezone = moment.tz.guess(); 
        const currentDateTimeWithZone = moment().utc().format('DD-MM-YYYY HH:mm:ss') + ' GMT'; 
        const formattedDateTimeWithCity = `${currentDateTimeWithZone} (${userTimezone.replace('_', ' ')})`; 
        setJointSignatureTimestamp(formattedDateTimeWithCity);
        localStorage.setItem('sosjointsignatureTimestamp', formattedDateTimeWithCity); 
      };
      const formattedsosjointApiTimestamp = applicationData?.statement_of_suitability?.joint_signed_timestamp
    ? moment.utc(applicationData?.statement_of_suitability?.joint_signed_timestamp, "DD-MM-YYYYTHH:mm:ss.SSSSSSZ")
        .tz(moment.tz.guess())
        .format("DD-MM-YYYY HH:mm:ss [GMT]") + ` (${moment.tz.guess()})`
    : null;

      useEffect(() => {
        const storedSignatureTimestamp = localStorage.getItem('signatureTimestamp');
        const storedJointSignatureTimestamp = localStorage.getItem('sosjointjointsignatureTimestamp');
        
        if (storedSignatureTimestamp) {
          setSignatureTimestamp(storedSignatureTimestamp);
        }
      
        if (storedJointSignatureTimestamp) {
          setJointSignatureTimestamp(storedJointSignatureTimestamp);
        }
      }, []);

    return (
        <div ref={componentRef}>
            <Row className='bg-white'>
                <Col md="12">
                    <div className=" justify-content-center">
                        <Form className=" bg-white pt-3 px-3 py-2">
                            <div className='logo-div-form-sos'>
                            {/* <img className="img-fluid logo-form" src="../../../../../content/images/black_new_logo.svg" /> */}
                                <label style={{ fontSize: '30px', fontWeight: '300', fontFamily: 'Raleway', color: 'black',padding:'10px',border:'1px solid black'}}>Starlight Wealth</label>
                            </div>
                                


                            <Col md="12" className="pt-3 text-center new-form-heading-text">
                                Statement of Suitability
                            </Col>
                            <div className='top-header-text-container pt-3'>
                                <span className=" text-center new-form-heading-text">
                                    Starlight Smart Reserve
                                </span>
                            </div>
                            <div className="form-warning-box mt-2">
                                <Col md="12" className="pt-3 text-center new-form-bold-text">
                                    Important Notice – Statement of Suitability
                                </Col>

                                <Col md="12" style={{ lineHeight: '2.5' }} className="new-form-very-light-text p-2">
                                    <span className="strong">
                                        This is an important document which sets out the reasons why the product(s) or service(s) offered or recommended is/are
                                        considered suitable, or the most suitable, for your particular needs, objectives and circumstances.
                                    </span>
                                </Col>
                            </div>
                            {/* Main Heading */}
                            <Row>
                                <Col md="12" className="new-form-bold-text pt-3 d-flex">
                                    <div style={{ width: '100px' }}>Prepared for:</div>
                                    <div>{FName && LName ? `${FName} ${LName}` : `${newData?.accountHolderName} ${newData?.accountHolderSurname}`}</div>
                                </Col>
                                <Col md="12" className="new-form-bold-text d-flex">
                                    <div style={{ width: '100px' }}>Prepared by:</div>
                                    <div>Starlight Wealth</div>
                                </Col>
                                <Col md="12" className="new-form-bold-text d-flex">
                                    <div style={{ width: '100px' }}>Date:</div>
                                    <div>{currentDate}</div>
                                </Col>
                                </Row>


                            {customerId === secondaryId ? (
                                <Col md="12" className="new-form-bold-text pt-2">
                                    Your Investment Amount €{secondaryInvestmentAmount}
                                </Col>
                            ) : (
 
 
                                <Col md="12" className="new-form-bold-text pt-2">
                                    Your Investment Amount €{mainAccountsEntity?.investmentAmount || newData?.investmentAmount || invest}
                                </Col>
                            )}

                            <Col md="12" className="new-form-bold-text pt-2">
                                Introduction
                            </Col>
                            <Col md="12" className="new-form-bold-text pt-2">
                                Dear &nbsp;{FName && LName ? `${FName} ${LName}` : `${newData?.accountHolderName} ${newData?.accountHolderSurname}`}
                            </Col>
                            <Col md="12 new-form-light-text">
                                Thank you for taking the time to complete a fact find and tell us about your investment needs. Following an analysis of your
                                financial circumstances and based on the information you have provided, we can now make an investment recommendation that we
                                believe meets your investment objectives and your specific needs and objectives with respect to your Investment Amount.
                            </Col>
                            {/* // */}
                            <Col md="12" className="pt-3 new-form-bold-text">
                                Our Services
                            </Col>
                            <Col md="12 new-form-light-text">
                                Starlight Wealth is not a tied agent with respect to Starlight Smart Reserve. We provide investment intermediary services on the
                                basis of independent advice. This means that the firm does not accept or retain any third party payments (i.e. commissions) from
                                product producers with respect to Starlight Smart Reserve. We may provide investment advice and may receive and transmit orders in
                                respect of the following regulated instruments: listed shares and bonds and collective investments.
                            </Col>
                            <Col md="12" className="new-form-bold-text pt-3">
                                Your Needs and Objectives
                            </Col>
                            <Col md="12 new-form-light-text">You have confirmed that:</Col>
                            <Col md="12 new-form-light-text">
                                <ul>
                                    <li>You have a lump sum available to invest</li>
                                    <li>
                                        Your Investment Objective is to Preserve Capital and Generate a Return that reflects the European Central Bank (ECB) Deposit
                                        Rate
                                    </li>
                                    <li>You wish to open an individual / a joint account</li>
                                </ul>
                            </Col>
                            <Col md="12" className=" new-form-bold-text pt-3">
                                Your Personal Situation
                            </Col>
                            <Col md="12 new-form-light-text">You have confirmed that:</Col>
                            <Col md="12 new-form-light-text">
                                <ul>
                                    <li>You are over 18</li>
                                    <li>You are resident in Ireland</li>
                                    <li>You will be the beneficial owner of the account</li>
                                    <li>You are tax resident in Ireland</li>
                                    <li>You have a minimum of 3 months of living expenses covered from other funds</li>
                                </ul>
                            </Col>
                            <Col md="12" className="new-form-bold-text">
                                Suitability and Recommendations
                            </Col>
                            <Col md="12 new-form-light-text">
                                Based upon the information you have provided to us, we recommend that you invest your specified Investment Amount in the{' '}
                                <span className="new-form-bold-text">Starlight Smart Reserve (EUR)</span> model portfolio of Short-Term Money Market Funds via a{' '}
                                <span className="new-form-bold-text">Starlight Smart Account (“Starlight Smart Reserve”)</span>
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                                The <span className="new-form-bold-text">Starlight Smart Reserve Portfolio (EUR) </span>comprises a select model portfolio of AAA
                                rated and Sovereign Short Term Money Market Funds (MMFs), each sponsored and managed by established blue-chip international
                                financial institutions. The Starlight Smart Reserve Portfolio (EUR) is a demand deposit alternative which is designed to generate
                                an income yield that tracks the Euro Short Term Rate (ESTR), the overnight deposit rate of Eurozone banks. The model portfolio is
                                100% allocated to Short Term Money Market Funds.
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                                We recommend that your investment horizon with respect to the Starlight Smart Reserve Portfolio (EUR) be no less than one week.
                            </Col>
                            <Col md="12" className="new-form-bold-text pt-3">
                                Given your needs and objectives with respect to the Investment Amount, we recommend that you invest in accordance with the
                                Starlight Smart Account Allocation Mandate set out in Schedule A.
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                                It is in your best interests that you review the investment portfolio comprising your{' '}
                                <span className="new-form-bold-text">Starlight Smart Account</span> on a regular basis. As your circumstances change, your needs
                                may change. Our recommendation is that your investment allocations be reviewed on an annual basis. Should you wish to change your
                                investment allocation at any time (for example, to invest in the equity markets) this may be done by contacting us via the{' '} 
                                <span className="new-form-bold-text">‘Client Portal’</span> button on the Starlight website. We may require you to complete a further suitability assessment and
                                a risk questionnaire at this stage.
                            </Col>
                            <Col md="12" className="new-form-bold-text pt-3">
                                Product features and options
                            </Col>
                
                            <Col md="12 new-form-light-text">
                                The <span className="new-form-bold-text pt-3">Starlight Smart Account</span> is a self-directed advisory investment account that we
                                arrange and open in your name with our MIFID Executing Broker, Interactive Brokers Ireland Limited. The Starlight Smart Account provides 
                                access to our range of proprietary Starlight Model Portfolios on the MIFID Executing Broker dealing platform, including <span className="new-form-bold-text">Starlight Smart Reserve (EUR)</span>, 
                                as well as other listed shares, bonds, and exchange traded funds, subject in all cases to our Terms of Business and, except for <span className="new-form-bold-text">Starlight Smart Reserve (EUR)</span>, 
                                an additional Statement of Suitabillity.
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                            The <span className="new-form-bold-text">Starlight Smart Account</span> is a ‘cash only’ investment account that does not allow you to borrow money to supplement or leverage your own funds to make investments in your account. 
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                            The <span className="new-form-bold-text">Starlight Smart Account</span> is subject to a straightforward transparent fee as set out in the Starlight Remuneration and Fee Schedule. 
                            Our fee is charged on the value of the assets in your account and is accrued daily and charged monthly in arrears. This fee will be automatically 
                            debited on the first Business Day of each month. We do not charge any upfront fees, contribution fees, account set-up fees, fees to administer your account, 
                            fees to execute trade orders on your behalf, or if you ever decide to leave, exit fees. 
                            </Col>
                            <Col md="12 new-form-light-text">There is no minimum mandatory investment period where your investments are locked in. You can instruct us to sell your investments at any time. You can start or 
                            stop regular contributions or make further ad hoc lump sum contributions at any time. You have the option to switch investments at any time subject to our Terms of Business. You will be able to 
                            see the value of your investment portfolio via the <span className="new-form-bold-text">‘Client Portal’</span>  button on the Starlight website.  </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                                <span className="new-form-bold-text">1. Diversification:</span> Your Investment Amount is spread across approximately 200 deposits
                                and short-term investments held by the money market funds comprising the Starlight Smart Reserve Portfolio (EUR), rather than just
                                being held in a single bank. In the unlikely event that one of these proportionately small deposits or investments were to
                                default, as your Investment Amount is so broadly diversified, any loss should be small and in most cases should be more than
                                offset by the income you earn over a typical investment horizon. In contrast, if you kept your Investment Amount on deposit with a
                                single bank, then if that bank were to default, you may lose all of your money if the State guarantee could not pay-out or the
                                State guarantee did not apply to some or all of your deposit.
                            </Col>
                            <Col md="12 new-form-light-text pt-3">
                                <span className="new-form-bold-text pt-3">2. Pricing Power – Institutional Return on Cash: </span>
                                As the money market funds comprising the Starlight Smart Reserve Portfolio (EUR) pool your Investment Amount with other investors’
                                money, these money market funds are able to get a much higher rate of interest than you would be able to get as a normal bank
                                customer. This higher rate of interest is shared with you.
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                                <span className="new-form-bold-text">3. Liquidity - Access to Funds: </span>You can withdraw some or all of your Investment Amount
                                at any time. There is no fixed-term and no exit penalties. It typically takes 2 to 5 business days to access your funds, subject
                                to and in accordance with our Terms of Business.
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                                <span className="new-form-italic-text">The Ireland Deposit Guarantee Scheme Does Not Apply to Your Investment.</span> The AAA
                                rated and Sovereign EUR money market funds comprising the Starlight Smart Reserve Portfolio (EUR) are not guaranteed and do not
                                benefit from the Irish State’s Deposit Guarantee Scheme.
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                                An investment in a money market fund is different from an investment in deposits; the principal invested in a money market fund is
                                capable of fluctuation and the risk of loss of the principal is to be borne by the investor. Under EU law, Short-Term Money Market
                                Funds, including the money market funds comprising the Starlight Smart Reserve Portfolio (EUR), are required to maintain the
                                highest levels of overnight liquidity under the relevant regulations, which is explicitly designed to significantly mitigate
                                Credit Risk, Market Risk and Interest Rate Risk.
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                                <span className='new-form-italic-text'>Credit Risk:</span> If a bank deposit or investment in one of the money market funds comprising the Starlight Smart Reserve Portfolio
                                (EUR) were to default and such deposit or investment did not benefit from a full state guarantee or was not otherwise remedied,
                                then the money market fund would receive back less than the full amount of the deposit or capital that it has invested. This would
                                cause the money market fund redemption price to fall which would result in the income yield payable on the Starlight Smart Reserve
                                Portfolio (EUR) being reduced. If the default was significantly large enough, this could result in you suffering a loss of
                                capital.
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                            <span className='new-form-italic-text'>Market Risk:</span> If the value of the bank deposit or investments in one or more of the money market funds comprising the Starlight
                                Smart Reserve Portfolio (EUR) was to fall then the fall in value would be reflected in the money market fund redemption price.
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                            <span className='new-form-italic-text'>Interest Rate Risk:</span> If interest rates rise very quickly then the redemption price of money market funds comprising the Starlight
                                Smart Reserve Portfolio (EUR) may fall temporarily. As such money market funds are Short Term Money Market Funds, this fall should
                                be quickly offset by increased income from the reinvestment of redeeming assets in the money market funds into new assets earning
                                a higher interest rate.
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                            <span className='new-form-italic-text'>Low and Negative Interest Rates:</span> If interest rates fall so low or become negative as to be less than the charges applied to the
                                money market funds comprising the Starlight Smart Reserve Portfolio (EUR) and the Starlight Smart Account, such charges would be
                                discharged from your capital and accumulated interest.
                            </Col>
                            <Col md="12" className="new-form-bold-text pt-3">
                                Product Guarantees and Associated Risks
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                                <span className="new-form-underline-text">The investments are not guaranteed. </span>If you invest in this product you may lose
                                some or all of the money you invest.
                            </Col>
                            <Col md="12" className="new-form-bold-text pt-3">
                                Starlight Wealth does not accept or hold any client monies. Your Investment Amount is invested directly in the AAA and Sovereign
                                EUR Money Market Funds comprising the Starlight Smart Reserve Portfolio (EUR). Funds and investments in your Starlight Smart
                                Account are ‘client assets’ and are fully segregated from the assets of the MIFID Executing Broker in accordance with Central Bank
                                of Ireland regulatory rules.
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                                Future Wealth Limited, trading as <span className="new-form-bold-text">Starlight Wealth,</span> is a member of both the Investor
                                Compensation Company (ICCL) and Brokers Ireland compensation schemes.
                            </Col>
                            <Col md="12 new-form-bold-text pt-3">Taxation</Col>
                            <Col md="12 new-form-light-text">
                                Your tax and accounting position is personal to you and may affect the ultimate return on your investment. We do not advise on the
                                tax or accounting consequences of investments. Please note that the basis and rate of taxation as applicable to investments in the{' '}
                                <span className="new-form-bold-text">Starlight Smart Account</span> may change without notice. Please see Appendix A for an
                                outline of our understanding of the general tax position as it relates to the investments.
                            </Col>
                            <Col md="12" className="new-form-bold-text pt-3">
                                Next Steps
                            </Col>
                            <Col md="12 new-form-light-text">
                                Please review this document and the associated Starlight Smart Reserve (EUR) model portfolio summary document (MPSD) carefully. If
                                you agree with the recommendation and wish to proceed, please accept and sign this document. By signing this document, you confirm
                                that:
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                                1. You have read this Statement of Suitability and that the information about your circumstances, needs and objectives and
                                financial situation is accurate; and <br /> 2. You want your funds to be allocated in accordance with your Starlight Smart Account
                                Allocation Mandate and the Terms of Business.
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                                This document supersedes all prior oral communication and prior writings with respect to its subject matter.
                            </Col>
                            <Col md="12" className="new-form-bold-text pt-3">
                                Client Name:&nbsp;{FName && LName ? `${FName} ${LName}` : `${newData?.accountHolderName} ${newData?.accountHolderSurname}`}
                            </Col>
                            <Col md="12" className="new-form-bold-text pb-3">
                                Date:&nbsp;{currentDate}{' '}
                            </Col>

                            <div className="form-warning-box p-3">
                                <Col md="12" className="new-form-bold-text">
                                    Warning: If you invest in this product you may lose some or all of the money you invest.
                                </Col>
                                <Col md="12" className="pt-3 new-form-bold-text">
                                    Warning: Past Performance is not a reliable guide to future performance.
                                </Col>
                                <Col md="12" className="pt-3 new-form-bold-text">
                                    Warning: The value of your investment may go down as well as up.
                                </Col>
                                <Col md="12" className="pt-3 new-form-bold-text">
                                    Warning: The income you get from this investment may go down as well as up
                                </Col>
                                <Col md="12" className="pt-3 new-form-bold-text">
                                    Warning: These figures are estimates only. They are not a reliable guide to future performance of this investment.
                                </Col>
                                <Col md="12" className="pt-3 new-form-bold-text">
                                    Warning: This product may be affected by changes in currency exchange rates
                                </Col>
                            </div>
                            <Col md="12" className="pt-3 new-form-bold-text">
                                Schedule A
                            </Col>
                            <Col md="12" className="new-form-bold-text">
                                Starlight Smart Account Allocation Mandate
                            </Col>
                            <div className="pt-3 table-new-style" id='unique-table'>
                                <table className="bordered-table custom-table-td">
                                    <tbody>
                                        <tr className="new-form-table-row-one">
                                            <td className="fs-6">Model Portfolio/Cash</td>
                                            <td className="fs-6">Description</td>
                                            <td className="fs-6">Reference Document</td>
                                            <td className="fs-6">Risk Rating</td>
                                            <td className="fs-6">Allocation%</td>
                                        </tr>
                                        <tr className="new-form-table-row-two">
                                            <td>Starlight Smart Reserve Portfolio</td>
                                            <td>Money Market Fund Model Portfolio</td>
                                            <td>Smart Reserve Portfolio (EUR) (Series 1) MPSD</td>
                                            <td>1</td>
                                            <td>99%</td>
                                        </tr>
                                        <tr className="new-form-table-row-two">
                                            <td>Cash</td>
                                            <td>Cash Balance</td>
                                            <td>N/A</td>
                                            <td>1</td>
                                            <td>1%</td>
                                        </tr>
                                        <tr className="new-form-table-row-one">
                                            <td>Overall</td>
                                            <td>Starlight Smart Account</td>
                                            <td>&nbsp;</td>
                                            <td>1</td>
                                            <td>100%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <Col md="12" className="pt-3 new-form-light-text">
                                In accordance with the Terms of Business, I hereby instruct Future Wealth Limited, trading as Starlight Wealth, to transmit trade
                                orders to the MIFID Executing Broker and, as applicable, utilise the available balance of my Starlight Smart Account allocated to
                                each model portfolio (as specified in this Starlight Smart Allocation Mandate), to buy or sell an amount of each of the individual
                                securities comprising each model portfolio in proportion to the weightings of the model portfolio set out in the relevant model
                                portfolio summary document (MPSD).
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                                Capitalised terms used herein have the meaning given to such terms in the Terms of Business.
                            </Col>
                            <Col md="12" className="pt-3 new-form-bold-text">
                                Appendix A
                            </Col>
                            <Col md="12" className=" new-form-bold-text">
                                Taxation
                            </Col>
                            <Col md="12" className="pt-3 new-form-bold-text">
                                Starlight Smart Reserve Portfolio (EUR); Starlight Smart Reserve Portfolio (USD)
                            </Col>
                            <Col md="12" className="pt-3 new-form-light-text">
                                Clients’ investments in these model portfolios are made up of whole and fractional holdings of Money Market Funds (MMF). By
                                investing in the relevant model portfolio(s), clients own the individual whole and fractional shares of the MMFs comprising the
                                model portfolio
                            </Col>
                            <Col md="12" className="pt-3 new-form-italic-text">
                                Individuals
                            </Col>
                            <Col md="12 pt-3 new-form-light-text">
                                It is our understanding that the taxation of returns/accumulated interest in the money market funds comprising these model
                                portfolio(s) would currently be taxed at 41%, and that these investments are not subject to capital gains tax (CGT) or deposit
                                interest retention tax (DIRT). It is also our understanding that returns/accumulated interest will be rolled up on a gross basis
                                allowing for compounding within the funds but subject to tax only following redemption or distribution from the money market funds
                                comprising the model portfolio(s).
                            </Col>
                            <Col md="12" className="pt-3 new-form-bold-text">
                                Please note that the views or opinions concerning taxation accounting or legal matters, whether in writing or orally, are intended
                                to be expressions of opinion and are not to be construed as investment, tax, accounting, or legal advice.
                            </Col>
                            <Col md="12" className="pt-3 new-form-bold-text">
                                Your tax and accounting position is personal to you and may affect the ultimate return on your investment. We do not advise on the
                                tax or accounting consequences of investments.
                            </Col>
                            <Col md="12" className="pt-3 new-form-bold-text">
                                For tax advice, you are advised to contact an independent tax adviser, for accounting advice, your company accountant and for
                                legal advice an appropriately licensed law firm.
                            </Col>
                            <Col md="12" className="pt-3 new-form-bold-text">
                                Please note that the basis and rate of taxation as applicable to investments in the Starlight Smart Account may change without
                                notice.
                            </Col>{' '}
                            <br />
                            <div>
                                <br />
                                <div className="col-md-6 signature-container-inside-box-2">
                                    <div>
                                        {(accountHolderType === 'Individual' && mainAccountsEntity?.accountHolderType === 'Individual' || applicationData?.account_type === 'Individual'
                                        ) ? (
                                            <>
                                                <div>
                                                    <label>Type your full name in the space below to confirm that you have read and agree to the above document.</label>
                                                </div>
                                                <div>
                                                    <Input
                                                        type="text"
                                                        className="signature-container-inside-box-2-input"
                                                        value={sosPrimary_sign || applicationData?.statement_of_suitability?.primary_sign
                                                        }
                                                        onChange={e => {
                                                            handleSignatureChange(setPrimaryInputValue, e.target.value);
                                                            setsosPrimarySign(e.target.value);
                                                          }}
                                                        readOnly={customerId !== mainAccountsEntity?.customerId}
                                                    />
                                                 <p className="timestamp-text">Date and Time: {signatureTimestamp || formattedsosApiTimestamp}</p> 
                                                 {/* <p className="timezone-text">Timezone: {signatureTimezone}</p> */}

                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    <label>Type your full name in the space below to confirm that you have read and agree to the above document.</label>
                                                </div>
                                                <div>
                                                    {customerId === mainAccountsEntity?.customerId ? (
                                                        <Input
                                                            type="text"
                                                            className="signature-container-inside-box-2-input"
                                                            value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.statement_of_suitability?.primary_sign : sosPrimary_sign || ''}
                                                            onChange={e => {
                                                                handleSignatureChange(setPrimaryInputValue, e.target.value);
                                                                setsosPrimarySign(e.target.value);
                                                              }}
                                                        />


                                                    ) : (

                                                        <Input
                                                            type="text"

                                                            className="signature-container-inside-box-2-input"
                                                            value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.statement_of_suitability?.primary_sign : sosPrimary_sign || applicationData?.statement_of_suitability?.primary_sign}
                                                            readonly

                                                        />
                                                    )}
                                                  <p className="timestamp-text">Date and Time: {signatureTimestamp || applicationData?.statement_of_suitability?.primary_signed_timestamp}</p> 
                                                  {/* <p className="timezone-text">Timezone: {signatureTimezone}</p> */}

                                                </div>
                                                <div>
                                                    <label>Signature of Secondary owner</label>
                                                </div>
                                                <div>
                                                    {customerId === secondaryId ? (
                                                        <Input
                                                            type="text"
                                                            className="signature-container-inside-box-2-input"
                                                            value={sosSecondarySign || ''}
                                                            // style={{ backgroundColor: "red" }}

                                                            onChange={e => {
                                                                handleJointSignatureChange(setSecondaryInputValue, e.target.value);
                                                                setsosSecondarySign(e.target.value);
                                                              }}
                                                        />
                                                    ) : (

                                                        <Input
                                                            type="text"
                                                            className="signature-container-inside-box-2-input"
                                                            value={sosSecondarySign || applicationData?.statement_of_suitability?.joint_sign}
                                                            // style={{ backgroundColor: "yellow" }}

                                                            readOnly
                                                        />
                                                    )}
                                               <p className="timestamp-text">Date and Time: {jointsignatureTimestamp || formattedsosjointApiTimestamp }</p> {/* Display timestamp */}

                                                </div>
                                            </>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <Col md="12" className="text-end no-print">
                                <div>
                                <Button className="print_btn_w8" disabled = {!checkIfPrintButtonShouldBeEnabled()} onClick={() => handlePrint({ content: () => document.body })}>Print</Button>
                                </div>
                            </Col>

                        </Form>
                    </div>
                </Col>
            </Row>
        </div >
    );
};

export default SosDoc;
