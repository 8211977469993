// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-container {
  font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
  color: #fafafa !important;
}

.acordian-header {
  color: "inherit";
  background-color: "transparent";
  box-shadow: "none";
  border-color: "transparent";
  font-size: 18px;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.accordion-button:not(.collapsed) {
  color: #fafafa;
  background-color: black;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  border: none;
  outline: none;
}

.accordion-button {
  color: white;
}

.content-container {
  animation: slideInFromBottom 0.5s ease forwards;
  will-change: transform, opacity;
}

.list-group {
  background-color: black;
  border: none;
}

.content {
  font-size: 16px;
  line-height: 1.5;
  color: #fafafa;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/accounts/faq.scss"],"names":[],"mappings":"AAAA;EACE,8DAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,+BAAA;EACA,kBAAA;EACA,2BAAA;EACA,eAAA;AACF;;AAGA;EACE;IACE,2BAAA;IACA,UAAA;EAAF;EAGA;IACE,wBAAA;IACA,UAAA;EADF;AACF;AAIA;EACE,cAAA;EACA,uBAAA;EACA,+CAAA;EACA,YAAA;EACA,aAAA;AAFF;;AAKA;EACE,YAAA;AAFF;;AAKA;EACE,+CAAA;EACA,+BAAA;AAFF;;AAKA;EACE,uBAAA;EACA,YAAA;AAFF;;AAKA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAFF","sourcesContent":[".faq-container {\n  font-family: 'Montserrat', Verdana, Geneva, Tahoma, sans-serif;\n  color: #fafafa !important;\n}\n\n.acordian-header {\n  color: 'inherit';\n  background-color: 'transparent';\n  box-shadow: 'none';\n  border-color: 'transparent';\n  font-size: 18px;\n}\n\n\n@keyframes slideInFromBottom {\n  from {\n    transform: translateY(100%);\n    opacity: 0;\n  }\n\n  to {\n    transform: translateY(0);\n    opacity: 1;\n  }\n}\n\n.accordion-button:not(.collapsed) {\n  color: #fafafa;\n  background-color: black;\n  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);\n  border: none;\n  outline: none;\n}\n\n.accordion-button {\n  color: white;\n}\n\n.content-container {\n  animation: slideInFromBottom 0.5s ease forwards;\n  will-change: transform, opacity;\n}\n\n.list-group {\n  background-color: black;\n  border: none;\n}\n\n.content {\n  font-size: 16px;\n  line-height: 1.5;\n  color: #fafafa;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
