import React, { useState, useEffect } from 'react';
import '../euroscape/euroscape.scss';
import { Card, CardImg, Col, Row } from 'reactstrap';
import { CircleFill, CircleHalf, SquareFill, TriangleFill } from 'react-bootstrap-icons';
import axios from 'axios';
function Euroscape() {
  const [modal, setModal] = useState(false);
  const [euroscape, setEuroscape] = useState([]);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/euroscape-pages?populate=Title,description,MainImage,Content.title,Content.content,Icons,Icons.image,Icons.Percentage,Icons.Name,ImportantInformation,ImportantInformation.Title,ImportantInformation.content')
      .then(response => {
  
        const { data } = response;
        // Assuming data.data is an array of objects with a "title" property
        setEuroscape(data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>


      {euroscape?.map((item) => {
     
        return (
          <div className="bellwether-container-reserve" style={{ backgroundColor: 'transparent', fontFamily: 'Montserrat' }}>
            <div className="bellwether-container-reserve-div">
              <img
                alt="Card image cap"
                src="https://static.wixstatic.com/media/4f10d0_467e183d66f1498581c7eb0ce27f4513~mv2.jpeg/v1/fill/w_764,h_605,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Image-empty-state.jpeg"

                className="img-fluid bigtech-image"
              />
            </div>
            <Card inverse className="bellwether-section-reserve pt-md-5" style={{ backgroundColor: 'transparent', position: 'relative' }}>
              <Row className="w-100 text-white align-items-center justify-content-center pt-md-5">
                <Col xs={12} lg={8} className="mx-auto text-center p-lg-5 bg-black">
                  <Row>
                    <Col className="text-start">
                      {' '}
                      <a href="/StarlightSmartInvest" className="bellwether-nav-button">
                        {' '}
                        &#x3c; Back
                      </a>
                    </Col>
                    <Col className="text-end">
                      <a href="/StarlightSmartInvest/big-tech" className="bellwether-nav-button">
                        {' '}
                        Next
                      </a>
                    </Col>
                  </Row>
                  {item?.attributes.Title.map((head) => {
                    return (


                      <Row className="text-start bellwether-head pt-4">
                        <h3 style={{ color: '#FAFAFA' }}>{head.description}</h3>
                      </Row>
                    )
                  })}

                  <div style={{ textAlign: 'left' }}>
                    {item?.attributes?.description.map((item) => {
                      return (
                        <p className="bellwether-head-sub">
                          {item.description}
                        </p>
                      )
                    })}
                    {item?.attributes?.Content
                      .map((itm) => {
                        return (
                          <>
                            <h4 className="bellwether-des-head">{itm?.Title}</h4>
                            {itm?.content.map((itm) => {
                              return (


                                <p className="bellwether-des-head-sub">
                                  {itm?.description}
                                </p>
                              )
                            })}
                          </>
                        )
                      })}
                    {/* <div className="text-center bellwether-performance-head">Historical Performance</div> */}
                    <CardImg
                      onClick={toggle}
                      src={'https://starwealth-dev-strapie.livelocal.delivery' + (item?.attributes?.MainImage?.data?.attributes?.url || '')}
                    />
                    <br />
                    <br />


                    <Row>

                      <Col md="12">





                        <Row>
                          {item.attributes.Icons.map((item) => {
                         
                            return (

                              <Col md="3" className="text-center">
                                <div>
                                  <img color="#607980"
                                    src={'https://starwealth-dev-strapie.livelocal.delivery' + (item?.image?.data?.attributes?.url || '')}
                                    style={{ height: '75%', width: '75%' }}
                                  />

                                  {/* <SquareFill color="#607980" size={75} /> */}
                                  <div className="bigtech-percentage">{item.Percentage}</div>
                                </div>
                                <div className="bigtech-percentage-text">
                                  3 yr <br /> {item.Name}
                                </div>
                                <div></div>
                              </Col>
                            )
                          })}
                        </Row>

                      </Col>

                    </Row>

                    <br />
                    <br />
                    {item?.attributes?.ImportantInformation?.map((item) => {
                      return (
                        <>
                          <h4 className="bellwether-des-head">{item.Title}</h4>
                          {item.content.map((item) => {
                            return (
                              <p className="bellwether-des-head-sub">
                                {item.description}
                              </p>
                            )
                          })}
                        </>
                      )
                    })}
                    <div className="bellwether-warning-text">
                      {item?.attributes?.Warning?.map((item) => {
                        return (

                          <p className="bellwether-des-head-sub pt-2">
                            {item.description}
                          </p>
                        )
                      })}
                    </div>


                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        )
      })}
    </>
  );
}
export default Euroscape;
