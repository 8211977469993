import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';

// jhipster-needle-i18n-language-dayjs-imports - JHipster will import languages from dayjs here
const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';

const customDefaultFormatPlugin = (option, dayjsClass) => {
  dayjsClass.prototype.formatWithDefault = function (format) {
    return this.format(format || DEFAULT_DATE_FORMAT);
  };
};

// DAYJS CONFIGURATION
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customDefaultFormatPlugin);
dayjs.locale('en');
