import React, { useState, useEffect } from 'react';
import '../starsnstripes/starsnstripes.scss';
import { Card, CardImg, Col, Row } from 'reactstrap';
import { CircleFill, CircleHalf, SquareFill, TriangleFill } from 'react-bootstrap-icons';
import axios from 'axios';
function Starsnstripes() {
  const [modal, setModal] = useState(false);
  const [stars, setStars] = useState([]);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {

    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/stars-and-stripes-pages?populate=Title,description,MainImage,Content.title,Content.content,Icons,Icons.image,Icons.Percentage,Icons.Name,ImportantInformation,ImportantInformation..Title,ImportantInformation.content')
      .then(response => {

        const { data } = response;
        // Assuming data.data is an array of objects with a "title" property
        setStars(data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>
      {stars.map((item, index) => {
        return (
          <div key={index.toString()} className="bellwether-container-reserve" style={{ backgroundColor: 'transparent', fontFamily: 'Montserrat' }}>
          <div className="bellwether-container-reserve-div">
            <img
              alt="Card image cap"
              src="https://static.wixstatic.com/media/4f10d0_467e183d66f1498581c7eb0ce27f4513~mv2.jpeg/v1/fill/w_764,h_605,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Image-empty-state.jpeg"

              className="img-fluid bigtech-image"
            />
          </div>
          <Card inverse className="bellwether-section-reserve pt-md-5" style={{ backgroundColor: 'transparent', position: 'relative' }}>
            <Row className="w-100 text-white align-items-center justify-content-center pt-md-5">
              <Col xs={12} lg={8} className="mx-auto text-center p-lg-5 bg-black">
                <Row>
                  <Col className="text-start">
                    {' '}
                    <a href="/StarlightSmartInvest" className="bellwether-nav-button">
                      {' '}
                      &#x3c; Back
                    </a>
                  </Col>
                  <Col className="text-end">
                    <a href="/StarlightSmartInvest/big-tech" className="bellwether-nav-button">
                      {' '}
                      Next
                    </a>
                  </Col>
                </Row>
                {item?.attributes.Title.map((attributesTitle, attributesTitleIndex) => {
                  return (
                    <Row key={attributesTitleIndex.toString()} className="text-start bellwether-head pt-4">
                      <h3 style={{ color: '#FAFAFA' }}>{attributesTitle.description}</h3>
                    </Row>
                  )
                })}

                <div style={{ textAlign: 'left' }}>
                  {item?.attributes?.description.map((attributesDescriptionItem, attributesDescriptionItemIndex) => {
                    return (
                      <p key={attributesDescriptionItemIndex.toString()} className="bellwether-head-sub">
                        {attributesDescriptionItem.description}
                      </p>
                    )
                  })}
                  {item?.attributes?.Content
                    .map((attributesContentItem) => {
                      return (
                        <>
                          <h4 className="bellwether-des-head">{attributesContentItem?.Title}</h4>
                          {attributesContentItem?.content.map((attributesContentItemContent, attributesContentItemContentIndex) => {
                            return (


                              <p key={attributesContentItemContentIndex.toString()} className="bellwether-des-head-sub">
                                {attributesContentItemContent?.description}
                              </p>
                            )
                          })}
                        </>
                      )
                    })}
                  {/* <div className="text-center bellwether-performance-head">Historical Performance</div> */}
                  <CardImg
                    onClick={toggle}
                    src={'https://starwealth-dev-strapie.livelocal.delivery' + (item?.attributes?.MainImage?.data?.attributes?.url || '')}
                  />
                  <br />
                  <br />
                  <Row>
                    <Col md="12">





                      <Row>
                        <Col md="3" className="text-center">
                          <div>
                            <SquareFill color="#607980" size={75} />
                            <div className="bigtech-percentage">32.05%</div>
                          </div>
                          <div className="bigtech-percentage-text">
                            3 yr <br /> Annualised Rolling <br />
                            Return
                          </div>
                          <div></div>
                        </Col>
                        <Col md="3" className="text-center">
                          <div>
                            <CircleFill color="#607980" size={75} />
                            <div className="bigtech-percentage">31.03%</div>
                          </div>
                          <div className="bigtech-percentage-text">
                            5 year <br /> Annualised Rolling <br />
                            Return
                          </div>
                          <div></div>
                        </Col>
                        <Col md="3" className="text-center">
                          <div>
                            <CircleHalf color="#607980" size={75} />
                            <div className="bigtech-percentage">1.07</div>
                          </div>
                          <div className="bigtech-percentage-text">
                            1 yr <br /> Model Portfolio <br />
                            Beta
                          </div>
                          <div></div>
                        </Col>
                        <Col md="3" className="text-center">
                          <div>
                            <TriangleFill color="#607980" size={75} />
                            <div className="bigtech-percentage">0.68%</div>
                          </div>
                          <div className="bigtech-percentage-text">
                            1 yr <br /> Dividend Yield
                          </div>
                          <div></div>
                        </Col>{' '}
                      </Row>

                    </Col>
                  </Row>

                  <br />
                  <br />
                  {item?.attributes?.ImportantInformation?.map((attributesImportantInformationItem) => {
                    return (
                      <>
                        <h4 className="bellwether-des-head">{attributesImportantInformationItem.Title}</h4>
                        {attributesImportantInformationItem.content.map((attributesImportantInformationContentItem, attributesImportantInformationContentItemIndex) => {
                          return (
                            <p key={attributesImportantInformationContentItemIndex.toString()} className="bellwether-des-head-sub">
                              {attributesImportantInformationContentItem.description}
                            </p>
                          )
                        })}
                      </>
                    )
                  })}
                  <div className="bellwether-warning-text">
                    {item?.attributes?.Warning?.map((attributesWarningItem, attributesWarningItemIndex) => {
                      return (

                        <p key={attributesWarningItemIndex.toString()} className="bellwether-des-head-sub pt-2">
                          {attributesWarningItem.description}
                        </p>
                      )
                    })}
                  </div>


                </div>
              </Col>
            </Row>
          </Card>
        </div>
        )
      })}
    </>
  );
}
export default Starsnstripes;
