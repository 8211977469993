import React, { useState } from 'react';
import { ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FieldError, useForm } from 'react-hook-form';
import './login.scss';
import { customerLoginOtp, } from '../account/register/register.reducer';
import { useAppDispatch } from 'app/config/store';
import SuccessModal from './successmodal';
import './style.scss';
import './successmodal.scss';
import ErrorModal from './errormodal';
import { resetPassword } from '../account/register/register.reducer';
// const dispatch = useAppDispatch();
import OtpInput from 'react-otp-input';
import { ModalTitle } from 'react-bootstrap';
import CommonToast from '../components/CommonToast';
import { ILoginModalProps } from './login-modal';

const ResetPassword = (props:ILoginModalProps) => {
    
    const dispatch = useAppDispatch();
    const {
        handleSubmit,
        register,
        formState: { errors, touchedFields },
    } = useForm({ mode: 'onTouched' });
    const navigate = useNavigate();
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastType, setToastType] = useState<'success' | 'error'>('success');
    const [showSignInModal, setShowSignInModal] = useState(true);
    const [showSignInForgotModal, setShowSignInForgotModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [Username, setUsername] = useState('');
    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setNewPassword(newPassword);
    
        const minLength = 8;
    
        // Regular expressions for validation
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    
        let errorMessage = '';
    
        // Check if password meets the criteria
        if (newPassword.length < minLength || 
            !uppercaseRegex.test(newPassword) || 
            !lowercaseRegex.test(newPassword) || 
            !specialCharRegex.test(newPassword)) {
            
            errorMessage = 'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one special character';
        }
    
        setPasswordError(errorMessage || null);
    };
    
    
    
    const toggleSignInModal = () => {
        setShowSignInModal(!showSignInModal);
        navigate('/');
    };
    const handleModalClose = () => {
        toggleSignInModal();
    };
    const toggleSignInForgotModal = () => {
        setShowSignInForgotModal(!showSignInForgotModal)
        navigate('/');
    }
    const handleForgotModalClose = () => {
        toggleSignInForgotModal();
    };


    const handleResetPassword = async () => {
        const datas = {
            username: Username,
            current_password: currentPassword,
            password: newPassword,
            event: "RESET_EVENT"
        };

        try {
            const response: any = await dispatch(resetPassword(datas));
            if (
                typeof response?.payload?.data === "string" &&
                response.payload.data.toLowerCase().trim() ===
                  "password updated successfully"
              ) {
                setToastMessage(response?.payload?.data);
                setToastType('success');
                setToastVisible(true);

            } else {
                setToastMessage(response?.payload?.data??"Password not updated");
                setToastType('error');
                setToastVisible(true);
            }
            setShowSignInForgotModal(false);
            setShowSignInModal(false);

        } catch (error) {
            console.error("Error in forgot password flow:", error);
        }
    };

    const handleCloseSuccessModal = () => {
        window.location.reload();
        setShowSuccessModal(false);
        // navigate('/');
    };
    const handleClose=()=>{
        toggleSignInModal();
       }
    return (
        <>
            <>
                <CommonToast
                    visible={toastVisible}
                    onClose={() => setToastVisible(false)}
                    title={toastType === 'success' ? 'Success' : 'Error'}
                    description={toastMessage}
                    type={toastType}
                    isAutoClose={true}

                />
            </>
            <Modal isOpen={showSignInModal} toggle={toggleSignInModal} className="mt-4">
                <Row
                    style={{
                        // backgroundImage: "url('../../../content/images/mount.jpg')",
                        backgroundImage: "url('../../../content/images/starlight-login.webp')",
                        backgroundPosition: 'center top',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                       // border: '1px solid white',
                    }}
                    className="justify-content-center"
                >
                    <Col md="10">

                        <ModalBody>
                            <Row>
                                    <Col className="d-flex justify-content-center" xs={12} md={12}>
                                    {/* <label style={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' }}>Starlight Wealth</label> */}
                                    <img className="img-fluid logo-dashboard" src="../../../../../content/images/white_new_logo.svg" />
                        </Col>
                    <Col style={{marginTop:"30px"}}>
                    <label style={{ fontSize: '20px', fontWeight: '500', fontFamily: 'Raleway', color: 'white' ,marginTop:'0px',marginLeft:"1px"}}>Forgot Password</label>
                        <Button className="reg-close" onClick={handleClose} style={{
                            position: 'absolute',
                            top: '10px',
                            right: '-47px',
                            background: 'transparent',
                            border: 'none',
                            color: 'white',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            cursor: 'pointer'
                            }}>
                            X
                        </Button>
                </Col>

                                <Col md="12" className="mt-2">
                                    <ValidatedField
                                        name="Email"
                                        label="Email"
                                        placeholder="Enter your Email"
                                        required
                                        autoFocus
                                        onChange={e => setUsername(e.target.value)}
                                        className="validated-field1"
                                        data-cy="username"
                                        validate={{ required: 'Email cannot be empty!' }}
                                        style={{ padding: '3px 12px' }}
                                        // register={register}
                                        error={errors.username as FieldError}
                                    // isTouched={touchedFields.username}
                                    />

                                    <ValidatedField
                                        name="Current Password"
                                        label="Current Password"
                                        placeholder="Enter your new password"
                                        type="password"
                                        onChange={e => setCurrentPassword(e.target.value)}
                                        className="validated-field1"
                                        required
                                        data-cy="password"
                                        validate={{ required: 'current password cannot be empty!' }}
                                        error={errors.currentPassword as FieldError}
                                        style={{ padding: '3px 12px' }}

                                    />


                                            <ValidatedField
                                                name="New Password"
                                                label="New Password"
                                                placeholder="Enter your new password"
                                                type="password"
                                                onChange={handlePasswordChange}
                                                className="validated-field1"
                                                required
                                                data-cy="password"
                                                style={{ padding: '3px 12px' }}
                                            />
                                            {passwordError && (
                                                <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                                    {passwordError}
                                                </div>
                                            )}
                  <div className="col">
                    <ul className="text-start" style={{ color: 'white', fontFamily: 'Montserrat',fontSize:"14px" }}>
                      <li> Password must be at least 8 characters long</li>

                      <li>
                        Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character
                      </li>
                    </ul>
                  </div>

                                    {/* <ValidatedField
                                        name="Confirm Password"
                                        label="Confirm Password"
                                        placeholder="Enter your new password"
                                        type="password"
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        className="validated-field1"
                                        required
                                        data-cy="password"
                                        validate={{ required: 'password cannot be empty!' }}
                                        error={errors.confirmPassword as FieldError}
                                        style={{ padding: '3px 12px' }}

                                    /> */}

                                </Col>

                                {/* <Row>
                                    <Col className="d-flex">
                                        <ValidatedField
                                            name="rememberMe"
                                            type="checkbox"
                                            check
                                            id="rememberMeCheckbox"
                                            value={true}
                                            required
                                            register={register}
                                            style={{ color: 'white', fontSize: "12px" }}
                                            onChange={e => setCheckboxChecked(e.target.checked)}
                                        />
                                        <label htmlFor="rememberMeCheckbox" style={{ color: 'white', fontSize: "12px" }}>
                                            I agree to the Starlight Wealth{' '}
                                            <a
                                                href="../../../content/policies/starlight_wealth_terms_of_business.pdf"
                                                className="terms-and-conditions"
                                                target="_blank"
                                            >
                                                Terms of Business
                                            </a>
                                            ,{' '}
                                            <a
                                                href="../../../content/policies/starlight_wealth_data_privacy_notice.pdf"
                                                className="terms-and-conditions"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Privacy Policy
                                            </a>{' '}
                                            and{' '}
                                            <a
                                                href="../../../content/policies/starlight_wealth_remuneration_and_fee_schedule.pdf"
                                                className="terms-and-conditions"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Remuneration Policy
                                            </a>
                                            .
                                        </label>
                                    </Col>
                                </Row> */}


                            </Row>

                        </ModalBody>
                        <ModalFooter className="d-flex flex-column align-items-center">
                            <Button className="signin-btn-style"
                                // onClick={handleLoginSubmit}
                                onClick={() => {handleResetPassword()}}
                                type="submit">
                                Reset
                            </Button>
                            <Link className="d-flex flex-column align-items-right" to="/signin" style={{ color: "#2a9fd6", fontSize: 12 }}>
                                Log In
                            </Link>
                        </ModalFooter>
                    </Col >
                </Row >
            </Modal >
            <SuccessModal isOpen={showSuccessModal} onClose={() => handleCloseSuccessModal} onNav={() => { }} message="Password reset successful" />
            <ErrorModal isOpen={showErrorModal} onClose={() => setShowErrorModal(false)} message={errorMessage} />
        </>
    );
};

export default ResetPassword;
