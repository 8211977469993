import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  Table,
} from 'reactstrap';

import './customstyles.scss';

export const SmartInvestAccount = () => {
  //-------------------------------------------------------------------------------------

  return (
    <div>
      <Row className="bg-samrtinverst-custom">
        {/* <img src="" alt="" /> */}
        <Col className="d-md-flex justify-content-md-center pt-5 pb-3">
          <Card className="card-samrtinverst-custom">
            <CardBody>
              <CardTitle tag="h5">Thank you for your interest in opening a Starlight Wealth Account</CardTitle>
              <CardText className="cardtext-samrtinverst-custom mb-2 mt-2 mx-3">
                In order for us to begin to get to know you a little, we would be grateful if you could give us a little detail about
                yourself and your investment objectives
              </CardText>
              <Row className="text-start">
                <Col md="12">
                  <FormGroup className="fromgroup-smartinverst-custom">
                    <Label className="lable-smartinverst-custom">First Name *</Label>
                    <Input id="firstname" name="firstname" type="text" className="input-smartinverst-custom" />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="lable-smartinverst-custom">Surname *</Label>
                    <Input id="surname" name="surname" type="text" className="input-smartinverst-custom" />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="lable-smartinverst-custom">I'm interested in...</Label>
                    <Input id="iminterested" name="iminterested" type="select" className="input-smartinverst-custom">
                      <option value="">Starlight Smart Invest (Tax Efficient Equity Protfolios)</option>
                      <option value="">Starlight Polaris PRSA</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="lable-smartinverst-custom">I'm interested in opening an/a... *</Label>
                    <Input id="iminterestedan" name="iminterestedan" type="select" className="input-smartinverst-custom">
                      <option value="">Individual Account</option>
                      <option value="">Joint Account (Individual)</option>
                      <option value="">Company Account</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="lable-smartinverst-custom">What initial sum would you like to invest? *</Label>
                    <Input id="liketoinvest" name="liketoinvest" type="select" className="input-smartinverst-custom">
                      <option value="">€50000 to €100000</option>
                      <option value="">€100000 to €200000</option>
                      <option value="">€200000 to €300000</option>
                      <option value="">€300000 to €500000 </option>
                      <option value="">€500000 to €1000000</option>
                      <option value="">€1000000+</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="lable-smartinverst-custom">Email</Label>
                    <Input id="email" name="email" type="text" className="input-smartinverst-custom" />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="lable-smartinverst-custom">Mobile Phone</Label>
                    <Input id="mobilephone" name="mobilephone" type="text" className="input-smartinverst-custom" />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check>
                    <Input type="checkbox" className="checkbox-smartinverst-custom" />
                    <Label className="lable-smartinverst-custom" check>
                      I consent to Starlight Wealth Contacting me to discuss my investment objectives
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12" className="pt-5">
                  <FormGroup>
                    <Button className="button-smartinverst-custom">Submit</Button>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <CardText className="pt-4 cardtextfotter-samrtinverst-custom">
                  <div>Thanks for submitting!</div>
                  <div>A mail with next steps will be sent in the next few minutes from:</div>
                  <div className="pt-3">StarlightAdviser@starlightwealth.ie</div>
                  <div className="pt-3">
                    Please check your junk email folder if you cannot find it and add Starlight Adviser to your list of trusted contacts
                  </div>
                  <div className="pt-3">Welcome to Starlight!</div>
                </CardText>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SmartInvestAccount;
