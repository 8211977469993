import dayjs from 'dayjs';

export interface IWebsiteConfig {
  id?: string;
  websiteConfigContentType?: string | null;
  websiteConfig?: string | null;
  isEnabled?: boolean | null;
  createdAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IWebsiteConfig> = {
  isEnabled: false,
};
