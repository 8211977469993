import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICustomer, defaultValue } from 'app/shared/model/customer.model';

const initialState: EntityState<ICustomer> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  totalPages: 0,
  loginEvents: [],
};

const apiUrl = 'api/customers';
const customerUrl = 'api/customer/details';
const reportUrl = `api/admin/customer/event/LOGIN_EVENT`;

export const getEntities = createAsyncThunk('customer/fetch_entity_list', async ({ sort }: IQueryParams) => {
  const requestUrl = `${SERVER_API_URL + apiUrl}?${sort ? `sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICustomer[]>(requestUrl);
});

export const getCustomerDetails = createAsyncThunk('customer/fetch_details', async () => {
  const response = await axios.get<ICustomer[]>(SERVER_API_URL + customerUrl);

  return response.data;
});

export const getLoginEvents = createAsyncThunk(
  'customer/fetch_login_events',
  async ({
    startDate,
    endDate,
    page,
    size,
    sortedBy,
    loginstatus,
  }: {
    startDate: string;
    endDate: string;
    page: number;
    size: number;
    sortedBy: boolean;
    loginstatus: number;
  }) => {
    const requestUrl = `${
      SERVER_API_URL + reportUrl
    }?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&sortedBy=${sortedBy}&loginstatus=${loginstatus}`;
    const response = await axios.get(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  'customer/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${SERVER_API_URL + apiUrl}/${id}`;
    return axios.get<ICustomer>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'customer/create_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.post<ICustomer>(SERVER_API_URL + apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'customer/update_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.put<ICustomer>(`${SERVER_API_URL + apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'customer/partial_update_entity',
  async (entity: ICustomer, thunkAPI) => {
    const result = await axios.patch<ICustomer>(`${SERVER_API_URL + apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'customer/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${SERVER_API_URL + apiUrl}/${id}`;
    const result = await axios.delete<ICustomer>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const CustomerSlice = createEntitySlice({
  name: 'customer',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getCustomerDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getLoginEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.content;
        state.totalItems = action.payload.totalElements;
        state.totalPages = action.payload.totalPages;
        state.loginEvents = action.payload;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data.sort((a, b) => {
            if (!action.meta?.arg?.sort) {
              return 1;
            }
            const order = action.meta.arg.sort.split(',')[1];
            const predicate = action.meta.arg.sort.split(',')[0];
            return order === ASC ? (a[predicate] < b[predicate] ? -1 : 1) : b[predicate] < a[predicate] ? -1 : 1;
          }),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getLoginEvents), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CustomerSlice.actions;

export default CustomerSlice.reducer;
