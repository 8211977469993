import {
  faCogs,
  faBan,
  faAsterisk,
  faArrowLeft,
  faBell,
  faBook,
  faCloud,
  faDatabase,
  faEye,
  faFlag,
  faHeart,
  faHome,
  faList,
  faLock,
  faPencilAlt,
  faPlus,
  faSave,
  faSearch,
  faSort,
  faSync,
  faSignInAlt,
  faSignOutAlt,
  faTachometerAlt,
  faTasks,
  faThList,
  faTrash,
  faUser,
  faUserPlus,
  faUsers,
  faUsersCog,
  faWrench,
  faEnvelope,
  faMobileAlt,
  faCoins,
  faLocationDot,
  faSortUp,
  faSortDown,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

export const loadIcons = () => {
  library.add(
    faArrowLeft,
    faAsterisk,
    faBan,
    faBell,
    faBook,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faPencilAlt,
    faPlus,
    faSave,
    faSignInAlt,
    faSignOutAlt,
    faSearch,
    faSort,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faEnvelope,
    faMobileAlt,
    faCoins,
    faLocationDot,
    faSortUp,
    faSortDown,
    faCaretDown
  );
};
