import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Card } from 'reactstrap';
import { useAppSelector } from 'app/config/store';
import { useNavigate } from 'react-router-dom';
import './smartReserve4.scss'
import axios from 'axios';

const SmartReserve4 = () => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  const [smartReserveAdvantage, setSmartReserveAdvantage] = useState([])
  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/starlight-advantages?populate=*')
      .then(response => {

        const { data } = response;

        setSmartReserveAdvantage(prev => ([...prev, ...data.data]));




      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (



    <div
      className="smart-container"
      style={{
        width: '100%',
        backgroundImage: `linear-gradient(rgba(96, 121, 128, 0.9), rgba(96, 121, 128, 0.9)), url("https://static.wixstatic.com/media/4f10d0_47102bde72114dce9a1f1a301a3a1fd2~mv2.jpg/v1/fill/w_1899,h_1675,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/4f10d0_47102bde72114dce9a1f1a301a3a1fd2~mv2.jpg")`,
        fontFamily: 'Montserrat',
        fontSize: '20px',
        fontWeight: '400',
        color: 'white',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      {Array.isArray(smartReserveAdvantage) && smartReserveAdvantage.map((advantage: any) => {
        return (
          <>
            {advantage?.attributes?.Advantage.map((item, index) => {
              return (


                <Row key={index.toString()} className="d-flex x justify-content-md-start justify-content-center">
                  <Col md="12" className="flex-column d-flex custom-col" style={{ width: '60%', paddingTop: "2rem", marginLeft: '8rem' }}>

                    <h1 style={{
                      fontFamily: 'Raleway',
                    }}>{item.number}</h1>
                    <p >{item.title}</p>
                    <p className='para-content '>
                      {item.description}
                    </p>
                  </Col>
                </Row>
              )
            })}

          </>
        )
      })}
    </div>


  );
};

export default SmartReserve4;
