import React, { useEffect, useState } from 'react';
import { Row, Col,  Card,  CardBody, CardTitle, CardText } from 'reactstrap';

import './about.scss';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const About = () => {
  const navigate = useNavigate();
  const [aboutData, setAboutData] = useState([]);

  useEffect(() => {

    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/page-abouts?populate=image,description ,page_our_story,page_our_story.description,page_our_story.button')
      .then(response => {
        const { data } = response;

        setAboutData(prev => ([...prev, ...data.data]));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleClickMeetFounders = (item: any) => {
    const path = item.href;
    navigate(path);
  };
  return (
    <>
      {Array.isArray(aboutData) && aboutData.map((about: any) => {
        return (
          <Row>
            <Col md="12">
              <Row>
                <Col md="12" className="about-row-bg">
                  <div className="d-flex justify-content-center" id="section1">
                    <Card className="card-about-custom-first text-center">
                      <CardBody>
                        <CardTitle className=" cardtitle-about-first"> {about?.attributes.title} </CardTitle>
                        <CardText className="cardtitle-about-first-subhead ">{about?.attributes.subTitle}</CardText>
                        {about?.attributes?.description?.map((item) => {
                          return (
                            <Col className="about-main-content mt-5 text-start">
                              <CardText>
                                {item.description}
                              </CardText>


                            </Col>
                          )
                        })}
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12" className="about-cover-image">
                  <img
                    src={'https://starwealth-dev-strapie.livelocal.delivery' + (about?.attributes?.image?.data?.attributes?.url || '')}
                    style={{ height: '100%', width: '100%' }}
                  />

                </Col>
              </Row>
              <Row>
                <Col md="12" className="about-row-bg">

                  <div className="d-md-flex justify-content-md-center">
                    <Card className="card-about-custom-first text-center" id="section2">
                      <CardBody>
                        <CardTitle className="cardtitle-about-others"> {about.attributes.page_our_story.data.attributes.title} </CardTitle>
                        {about?.attributes?.page_our_story?.data?.attributes?.description?.map((item) => {
                          return (
                            <Col className="about-main-content mt-3 text-start">
                              <CardText>
                                {item.description}
                              </CardText>


                            </Col>
                          )
                        })}
                        <Col md="12  p-4">
                          {about?.attributes?.page_our_story?.data?.attributes?.button?.map((item, index) => (
                            <button key={index} onClick={() => handleClickMeetFounders(item)} className="broker-login-btn-style ms-3">
                              {item.title}
                            </button>
                          ))}

                        </Col>
                      </CardBody>
                    </Card>
                  </div>

                </Col>
              </Row>
            </Col>
          </Row>
        )
      })}
    </>
  );
};


export default About;
