import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IAccounts } from 'app/shared/model/accounts.model';
import { getEntity, updateEntity, createEntity } from './accounts.reducer';

export const AccountsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const accountsEntity = useAppSelector(state => state.accounts.entity);
  const loading = useAppSelector(state => state.accounts.loading);
  const updating = useAppSelector(state => state.accounts.updating);
  const updateSuccess = useAppSelector(state => state.accounts.updateSuccess);

  const handleClose = () => {
    navigate('/accounts' + location.search);
  };


  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.accountHolderDependents !== undefined && typeof values.accountHolderDependents !== 'number') {
      values.accountHolderDependents = Number(values.accountHolderDependents);
    }
    if (values.investmentAmount !== undefined && typeof values.investmentAmount !== 'number') {
      values.investmentAmount = Number(values.investmentAmount);
    }
    values.isAgeOverAgreedTs = convertDateTimeToServer(values.isAgeOverAgreedTs);
    values.beneficialOwnerAgreedTs = convertDateTimeToServer(values.beneficialOwnerAgreedTs);
    values.residentInIrelandAgreedTs = convertDateTimeToServer(values.residentInIrelandAgreedTs);
    values.taxResidentInIrelandAgreedTs = convertDateTimeToServer(values.taxResidentInIrelandAgreedTs);
    if (values.employmentIncomePercentage !== undefined && typeof values.employmentIncomePercentage !== 'number') {
      values.employmentIncomePercentage = Number(values.employmentIncomePercentage);
    }
    if (values.propertyPercentage !== undefined && typeof values.propertyPercentage !== 'number') {
      values.propertyPercentage = Number(values.propertyPercentage);
    }
    if (values.giftPercentage !== undefined && typeof values.giftPercentage !== 'number') {
      values.giftPercentage = Number(values.giftPercentage);
    }
    if (values.investmentPercentage !== undefined && typeof values.investmentPercentage !== 'number') {
      values.investmentPercentage = Number(values.investmentPercentage);
    }
    if (values.otherPercentage !== undefined && typeof values.otherPercentage !== 'number') {
      values.otherPercentage = Number(values.otherPercentage);
    }
    if (values.totalNetWorth !== undefined && typeof values.totalNetWorth !== 'number') {
      values.totalNetWorth = Number(values.totalNetWorth);
    }
    if (values.liquidNetWorth !== undefined && typeof values.liquidNetWorth !== 'number') {
      values.liquidNetWorth = Number(values.liquidNetWorth);
    }
    if (values.totalNetIncome !== undefined && typeof values.totalNetIncome !== 'number') {
      values.totalNetIncome = Number(values.totalNetIncome);
    }
    values.isRegisteredInIrelandTs = convertDateTimeToServer(values.isRegisteredInIrelandTs);
    values.isTaxResidentInIrelandTs = convertDateTimeToServer(values.isTaxResidentInIrelandTs);
    values.lumpSumAvailableToInvestTs = convertDateTimeToServer(values.lumpSumAvailableToInvestTs);
    values.createdAt = convertDateTimeToServer(values.createdAt);

    const entity = {
      ...accountsEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
        isAgeOverAgreedTs: displayDefaultDateTime(),
        beneficialOwnerAgreedTs: displayDefaultDateTime(),
        residentInIrelandAgreedTs: displayDefaultDateTime(),
        taxResidentInIrelandAgreedTs: displayDefaultDateTime(),
        isRegisteredInIrelandTs: displayDefaultDateTime(),
        isTaxResidentInIrelandTs: displayDefaultDateTime(),
        lumpSumAvailableToInvestTs: displayDefaultDateTime(),
        createdAt: displayDefaultDateTime(),
      }
      : {
        ...accountsEntity,
        isAgeOverAgreedTs: convertDateTimeFromServer(accountsEntity.isAgeOverAgreedTs),
        beneficialOwnerAgreedTs: convertDateTimeFromServer(accountsEntity.beneficialOwnerAgreedTs),
        residentInIrelandAgreedTs: convertDateTimeFromServer(accountsEntity.residentInIrelandAgreedTs),
        taxResidentInIrelandAgreedTs: convertDateTimeFromServer(accountsEntity.taxResidentInIrelandAgreedTs),
        isRegisteredInIrelandTs: convertDateTimeFromServer(accountsEntity.isRegisteredInIrelandTs),
        isTaxResidentInIrelandTs: convertDateTimeFromServer(accountsEntity.isTaxResidentInIrelandTs),
        lumpSumAvailableToInvestTs: convertDateTimeFromServer(accountsEntity.lumpSumAvailableToInvestTs),
        createdAt: convertDateTimeFromServer(accountsEntity.createdAt),
      };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="starlightwealthWebApp.accounts.home.createOrEditLabel" data-cy="AccountsCreateUpdateHeading">
            Create or edit a Accounts
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="accounts-id" label="ID" validate={{ required: true }} /> : null}
              <ValidatedField
                label="Account Id"
                id="accounts-accountId"
                name="accountId"
                data-cy="accountId"
                type="text"
                validate={{
                  required: { value: true, message: 'This field is required.' },
                }}
              />
              <ValidatedField
                label="Customer Id"
                id="accounts-customerId"
                name="customerId"
                data-cy="customerId"
                type="text"
                validate={{
                  required: { value: true, message: 'This field is required.' },
                }}
              />
              <ValidatedField
                label="Account Master Type"
                id="accounts-accountMasterType"
                name="accountMasterType"
                data-cy="accountMasterType"
                type="text"
              />
              <ValidatedField
                label="Account Holder Name"
                id="accounts-accountHolderName"
                name="accountHolderName"
                data-cy="accountHolderName"
                type="text"
              />
              <ValidatedField
                label="Account Holder Email"
                id="accounts-accountHolderEmail"
                name="accountHolderEmail"
                data-cy="accountHolderEmail"
                type="text"
              />
              <ValidatedField
                label="Account Holder Type"
                id="accounts-accountHolderType"
                name="accountHolderType"
                data-cy="accountHolderType"
                type="text"
              />
              <ValidatedField
                label="Account Holder Title"
                id="accounts-accountHolderTitle"
                name="accountHolderTitle"
                data-cy="accountHolderTitle"
                type="text"
              />
              <ValidatedField
                label="Account Holder Surname"
                id="accounts-accountHolderSurname"
                name="accountHolderSurname"
                data-cy="accountHolderSurname"
                type="text"
              />
              <ValidatedField
                label="Account Holder Marital Status"
                id="accounts-accountHolderMaritalStatus"
                name="accountHolderMaritalStatus"
                data-cy="accountHolderMaritalStatus"
                type="text"
              />
              <ValidatedField
                label="Account Holder Dependents"
                id="accounts-accountHolderDependents"
                name="accountHolderDependents"
                data-cy="accountHolderDependents"
                type="text"
              />
              <ValidatedField
                label="Account Holder Mobile Number"
                id="accounts-accountHolderMobileNumber"
                name="accountHolderMobileNumber"
                data-cy="accountHolderMobileNumber"
                type="text"
              />
              <ValidatedField
                label="Investment Amount"
                id="accounts-investmentAmount"
                name="investmentAmount"
                data-cy="investmentAmount"
                type="text"
              />
              <ValidatedField
                label="Is Age Over Agreed"
                id="accounts-isAgeOverAgreed"
                name="isAgeOverAgreed"
                data-cy="isAgeOverAgreed"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Is Age Over Agreed Ts"
                id="accounts-isAgeOverAgreedTs"
                name="isAgeOverAgreedTs"
                data-cy="isAgeOverAgreedTs"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Beneficial Owner Agreed"
                id="accounts-beneficialOwnerAgreed"
                name="beneficialOwnerAgreed"
                data-cy="beneficialOwnerAgreed"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Beneficial Owner Agreed Ts"
                id="accounts-beneficialOwnerAgreedTs"
                name="beneficialOwnerAgreedTs"
                data-cy="beneficialOwnerAgreedTs"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Resident In Ireland Agreed"
                id="accounts-residentInIrelandAgreed"
                name="residentInIrelandAgreed"
                data-cy="residentInIrelandAgreed"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Resident In Ireland Agreed Ts"
                id="accounts-residentInIrelandAgreedTs"
                name="residentInIrelandAgreedTs"
                data-cy="residentInIrelandAgreedTs"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Tax Resident In Ireland Agreed"
                id="accounts-taxResidentInIrelandAgreed"
                name="taxResidentInIrelandAgreed"
                data-cy="taxResidentInIrelandAgreed"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Tax Resident In Ireland Agreed Ts"
                id="accounts-taxResidentInIrelandAgreedTs"
                name="taxResidentInIrelandAgreedTs"
                data-cy="taxResidentInIrelandAgreedTs"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Employment Income Percentage"
                id="accounts-employmentIncomePercentage"
                name="employmentIncomePercentage"
                data-cy="employmentIncomePercentage"
                type="text"
              />
              <ValidatedField
                label="Property Percentage"
                id="accounts-propertyPercentage"
                name="propertyPercentage"
                data-cy="propertyPercentage"
                type="text"
              />
              <ValidatedField
                label="Gift Percentage"
                id="accounts-giftPercentage"
                name="giftPercentage"
                data-cy="giftPercentage"
                type="text"
              />
              <ValidatedField
                label="Investment Percentage"
                id="accounts-investmentPercentage"
                name="investmentPercentage"
                data-cy="investmentPercentage"
                type="text"
              />
              <ValidatedField
                label="Other Percentage"
                id="accounts-otherPercentage"
                name="otherPercentage"
                data-cy="otherPercentage"
                type="text"
              />
              <ValidatedField
                label="Total Net Worth"
                id="accounts-totalNetWorth"
                name="totalNetWorth"
                data-cy="totalNetWorth"
                type="text"
              />
              <ValidatedField
                label="Liquid Net Worth"
                id="accounts-liquidNetWorth"
                name="liquidNetWorth"
                data-cy="liquidNetWorth"
                type="text"
              />
              <ValidatedField
                label="Total Net Income"
                id="accounts-totalNetIncome"
                name="totalNetIncome"
                data-cy="totalNetIncome"
                type="text"
              />
              <ValidatedField
                label="Account Owner Name"
                id="accounts-accountOwnerName"
                name="accountOwnerName"
                data-cy="accountOwnerName"
                type="text"
              />
              <ValidatedField label="Name Of Bank" id="accounts-nameOfBank" name="nameOfBank" data-cy="nameOfBank" type="text" />
              <ValidatedField label="Is Pep" id="accounts-isPep" name="isPep" data-cy="isPep" check type="checkbox" />
              <ValidatedField
                label="Is Family Pep"
                id="accounts-isFamilyPep"
                name="isFamilyPep"
                data-cy="isFamilyPep"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Financial Employee"
                id="accounts-financialEmployee"
                name="financialEmployee"
                data-cy="financialEmployee"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Security Question Answer"
                id="accounts-securityQuestionAnswer"
                name="securityQuestionAnswer"
                data-cy="securityQuestionAnswer"
                type="text"
              />
              <ValidatedField label="Iban" id="accounts-iban" name="iban" data-cy="iban" type="text" />
              <ValidatedField
                label="Is Registered In Ireland"
                id="accounts-isRegisteredInIreland"
                name="isRegisteredInIreland"
                data-cy="isRegisteredInIreland"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Is Registered In Ireland Ts"
                id="accounts-isRegisteredInIrelandTs"
                name="isRegisteredInIrelandTs"
                data-cy="isRegisteredInIrelandTs"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Is Tax Resident In Ireland"
                id="accounts-isTaxResidentInIreland"
                name="isTaxResidentInIreland"
                data-cy="isTaxResidentInIreland"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Is Tax Resident In Ireland Ts"
                id="accounts-isTaxResidentInIrelandTs"
                name="isTaxResidentInIrelandTs"
                data-cy="isTaxResidentInIrelandTs"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Lump Sum Available To Invest"
                id="accounts-lumpSumAvailableToInvest"
                name="lumpSumAvailableToInvest"
                data-cy="lumpSumAvailableToInvest"
                check
                type="checkbox"
              />
              <ValidatedField
                label="Lump Sum Available To Invest Ts"
                id="accounts-lumpSumAvailableToInvestTs"
                name="lumpSumAvailableToInvestTs"
                data-cy="lumpSumAvailableToInvestTs"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Description Of Business"
                id="accounts-descriptionOfBusiness"
                name="descriptionOfBusiness"
                data-cy="descriptionOfBusiness"
                type="text"
              />
              <ValidatedField label="Your Name" id="accounts-yourName" name="yourName" data-cy="yourName" type="text" />
              <ValidatedField label="Your Role" id="accounts-yourRole" name="yourRole" data-cy="yourRole" type="text" />
              <ValidatedField label="Your Email" id="accounts-yourEmail" name="yourEmail" data-cy="yourEmail" type="text" />
              <ValidatedField label="Product Type" id="accounts-productType" name="productType" data-cy="productType" type="text" />
              <ValidatedField label="Pps Number" id="accounts-ppsNumber" name="ppsNumber" data-cy="ppsNumber" type="text" />
              <ValidatedField label="Pps Number Doc" id="accounts-ppsNumberDoc" name="ppsNumberDoc" data-cy="ppsNumberDoc" type="text" />
              <ValidatedField label="Passport Doc" id="accounts-passportDoc" name="passportDoc" data-cy="passportDoc" type="text" />
              <ValidatedField
                label="Proof Of Address Doc"
                id="accounts-proofOfAddressDoc"
                name="proofOfAddressDoc"
                data-cy="proofOfAddressDoc"
                type="text"
              />
              <ValidatedField
                label="Created At"
                id="accounts-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/accounts" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AccountsUpdate;
