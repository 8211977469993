import React, { useState } from "react";
import { FieldError } from "react-hook-form";
import { ValidatedField } from "react-jhipster";
import { Col, Row } from "reactstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker-field-style.scss'
import { ValidatedDatePickerFieldProps } from "./types";
import InputMask from 'react-input-mask';
import moment from 'moment-timezone';
 
 
const MaskedInput = React.forwardRef(({ value, onChange, onClick }: any, ref) => (
  <div className="maskInput" onClick={onClick}>
    <InputMask className="datefield-expiry-style"
 ref={ref} mask="99-99-9999" value={value} onChange={onChange} placeholder="DD-MM-YYYY" />
  </div>
));
 
 
const ValidatedDatePickerField = ({ onChange, register, getFieldState, defaultdate,validate}: ValidatedDatePickerFieldProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
 
  const handleDateInputChange = (date: Date | null) => {
    setSelectedDate(date);
    onChange(date);
  }
  const formattedDate = defaultdate ? moment(defaultdate).format("DD-MM-YYYY") : '';
 
  return (
    <>
      {/* <Row style={{marginBottom: '10px'}}>
        <span className='validated-fieldCustomLabel'>* <span className='validated-field2'>Date of Birth</span></span>
      </Row> */}
      <Row >
        <Col md="12">
        <ValidatedField
          name="dob"
          hidden
          required
          className="validated-field2"
          validate={validate}
          register={register}
          style={{ width: '100%', padding: '3px 12px', backgroundImage: 'none', fontFamily: 'Montserrat' }}
          />
        <DatePicker
          name="dob"
          selected={defaultdate?defaultdate:selectedDate}
          onChange={handleDateInputChange}
          dateFormat="dd-MM-yyyy"
          defaultValue={formattedDate}
          placeholderText="DD-MM-YYYY"
          className="customDatePickerWidth"
          customInput={<MaskedInput />}
          />
          </Col>
      </Row>
      {getFieldState('dob').invalid &&
      (<Row style={{marginTop: '6px'}}>
        <div className="custom-invalid-feedback">{getFieldState('dob').error.message}</div>
      </Row>)}
    </>
  );
};
 
export default ValidatedDatePickerField;
 
 