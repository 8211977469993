// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.para-content {
  font-size: 17px;
  line-height: 30px;
  width: 84%;
}

h1 {
  font-size: 101px;
  font-weight: normal;
}

@media (max-width: 768px) {
  .custom-col {
    margin-left: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/smartReserve/smartReserve4.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,UAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,yBAAA;EACF;AACF","sourcesContent":[".para-content {\n  font-size: 17px;\n  line-height: 30px;\n  width: 84%;\n}\n\nh1 {\n  font-size: 101px;\n  font-weight: normal;\n}\n\n@media (max-width: 768px) {\n  .custom-col {\n    margin-left: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
