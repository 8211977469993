import accounts from 'app/entities/accounts/accounts.reducer';
import websiteConfig from 'app/entities/website-config/website-config.reducer';
import securityQuestions from 'app/entities/security-questions/security-questions.reducer';
import customer from 'app/entities/customer/customer.reducer';

const entitiesReducers = {
  accounts,
  websiteConfig,
  securityQuestions,
  customer,
};

export default entitiesReducers;
