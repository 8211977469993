import React, { FC, ChangeEvent } from "react";
import { Controller } from "react-hook-form";

import "./customInput.scss";

interface CustomInputProps {
  onInputChange?: (input: string) => void;
  inputType: string;
  className: string;
  editable: boolean;
  placeholder: string;
  control: any;
  inputName: string;
  rules?: Record<string, any>;
  maxLength?: number;
  label?:string;
}

const CustomInput: FC<CustomInputProps> = ({
  onInputChange=()=>{},
  inputType,
  className,
  editable,
  placeholder,
  control,
  inputName,
  rules = {},
  maxLength,
  label
}) => {
  const handleOnChange = (input: string, onChange: any) => {
    onInputChange(input);
    onChange(input);
  };

  return (
    <Controller
      control={control}
      name={inputName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <div style={{marginBottom:'10px'}}>
          <div className="validated-field1">{label}</div>
          <input
            type={inputType}
            className={`${className} ${error ? "hasError" : "inputStyle"}`}
            placeholder={placeholder}
            autoComplete="off"
            style={{ padding: '3px 12px' }}
            disabled={!editable}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const inputValue =
                maxLength 
                  ? e.target.value.slice(0, maxLength)
                  : e.target.value;
              handleOnChange(inputValue, onChange);
            }}
            {...(maxLength  ? { maxLength } : {})}
          />
            <>
              {error && (
                <div className="errorTextContainer">
                  <div className="errorText">{error?.message}</div>
                </div>
              )}
            </>
        </div>
      )}
    />
  );
};

export default CustomInput;
