import { useAppDispatch } from "app/config/store";
import {
  DepositBankList,
  depositConfig,
  getBankList,
  getIbkr,
  transaction,
} from "app/entities/accounts/accounts.reducer";
import React, { useEffect, useState } from "react";
import { Accordion, Card, CardBody } from "react-bootstrap";
import { Button, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import FundingInstructions from "../fundinginstructions/FundingInstructions";
import { Navigate, redirectDocument, useNavigate } from "react-router";
import CommonToast from "app/modules/components/CommonToast";

const Deposit = () => {
  const [depositDetails, setDepositDetails] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({
    account_id: "",
    ibkr_account_number: "",
    amount: 0,
    transaction_type: "DEBIT",
    currency: "",
    bank: "",
  });
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const dispatch = useAppDispatch();
  const navigate=useNavigate();

  useEffect(() => {
    dispatch(depositConfig()).then((response: any) => {
      setDepositDetails(response?.payload);
   
    });
  }, []);
  useEffect(() => {
    dispatch(getIbkr()).then((response: any) => {
      if (response?.payload) {
        setTransactionDetails((prevState) => ({
          ...prevState,
          account_id: response?.payload?.sw_account_id,
          ibkr_account_number:response?.payload?.ibkr_account_number
        }));
      }
    });
  }, []);


  useEffect(() => {
    dispatch(DepositBankList()).then((res: any) => {
      if (res.payload) {
        setBankList(res?.payload);
      }
    });
  }, []);

  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);

  const handleSave = () => {
    setOpen(true);
  };
  const toggle = () => {
    setOpen(!open);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTransactionDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeposit = async() => {
    try{

      const  Depositbank=bankList?.find((obj)=>obj?.link===transactionDetails?.bank)?.name
      const payload = {
        account_id: transactionDetails?.account_id || null,
        ibkr_account_number: transactionDetails?.ibkr_account_number || null,
        amount: transactionDetails?.amount,
        transaction_type: transactionDetails?.transaction_type,
        currency: transactionDetails?.currency,
        bank: Depositbank,
      };
      const response:any=await dispatch(transaction(payload)); 
      if (response?.payload?.id){
        setOpen(true);
        window.open(`${transactionDetails.bank}`, '_blank');
      }else{
        setOpen(false)
        setToastType('error');
        setToastMessage('Deposit  failed')
        setToastVisible(true);
      }
    }catch(error){
      setToastVisible(true);
      setToastMessage('Deposit  failed') 
    }

   
  };
  return (
    <div className="col-12 pb-5">
           <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}
      />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="row pt-1">
            <div className="col dashboard-card-sub-heading align-content-center">
              Deposit Amount:
            </div>
            <div className="col-8">
              <Input
               type="number"
                className="dashboard-select-style"
                name="amount"
                onChange={handleInputChange}
                placeholder="0.00"
              />
            </div>
          </div>
          <div className="row pt-3">
            <div className="col dashboard-card-sub-heading align-content-center">
              Currency:
            </div>
            <div className="col-8">
              <Input
                type="select"
                className="dashboard-select-style"
                name="currency"
                onChange={handleInputChange}
                value={transactionDetails?.currency}
              >
                <option value="">Select..</option>
                {Array.isArray(depositDetails) &&
                  depositDetails?.map((obj, index) => (
                    <option value={obj?.currency}>{obj?.currency}</option>
                  ))}
              </Input>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col dashboard-card-sub-heading align-content-center">
              My Bank:
            </div>
            <div className="col-8">
              <Input
                type="select"
                name="bank"
                onChange={handleInputChange}
                value={transactionDetails?.bank}
                className="dashboard-select-style"
              >
                <option value="">Select..</option>
                {Array.isArray(bankList) &&
                  bankList?.map((bank, index) => (
                    <option value={bank.link} key={index}>
                      {bank?.name}
                    </option>
                  ))}
              </Input>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col dashboard-card-sub-heading ">
            Bank not listed?
            </div>
            <div className="col-12 sub-content-dashboard">
            If your bank is not yet listed in our system, please go your banks online banking portal and deposit funds in the normal way.
            </div>
          </div>
          <div className="row pt-5">
            <div className="col">
              <Button
                className="button-account-custom-submit"
                onClick={handleSave}
                disabled={!transactionDetails.amount ||!transactionDetails.bank||!transactionDetails.currency}
              >
                Deposit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5 accordion-div-dashboard">
        <Card className=" dashboard-custom-card pt-3">
          <CardBody>
            <div className="dashboard-card-heading">Funding Instructions</div>
            <Accordion alwaysOpen>
              {Array.isArray(depositDetails) &&
                depositDetails?.map((bank, index) => (
                  <Accordion.Item eventKey={bank?.currency}>
                    <Accordion.Header>
                      <span className="dashboard-card-sub-heading">
                        {bank?.title} Funding Instructions
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <FundingInstructions depositDetails={bank} modalturetochangetext={false} />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </CardBody>
        </Card>
      </div>
 
      <Modal returnFocusAfterClose={focusAfterClose} isOpen={open}>
        <ModalBody className="p-4">
          <div className="dashboard-card-heading text-center pt-2 pb-3">Depositing Funds</div>
          <FundingInstructions
            depositDetails={depositDetails?.find(
              (obj) => obj?.currency === transactionDetails?.currency
            )}
            modalturetochangetext={true}
          />
          <div className="row pt-4">
            <div className="col">
              <Button className="button-account-custom" onClick={toggle}>
                Cancel
              </Button>
            </div>
            <div className="col">
              <Button
                className="button-account-custom-submit"
                onClick={handleDeposit}
                disabled={!transactionDetails.amount ||!transactionDetails.bank||!transactionDetails.currency}
              >
                Deposit Funds
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* <div className="heading-dashboard">Deposit </div>
      <div className="pt-3 ">
        <Card className=" dashboard-custom-card">
          <CardBody>
            <div className="dashboard-card-heading">
              In order to deposit funds, please follow the instructions below:
            </div>
            <ol>
              <li className="sub-content-dashboard pt-2">
                Click the link to your online banking provider
                <div className="pt-2">
                  <a className="link-text-dashboard" href="">
                    Allied Irish Banks
                  </a>
                </div>
                <div className="pt-2">
                  <a className="link-text-dashboard" href="">
                    Allied Irish Banks Business (iBB)
                  </a>
                </div>
                <div className="pt-2">
                  <a className="link-text-dashboard" href="">
                    Bank of Ireland 365 Online
                  </a>
                </div>
                <div className="pt-2">
                  <a className="link-text-dashboard" href="">
                    Bank of Ireland Business Online
                  </a>
                </div>
                <div className="pt-2">
                  <a className="link-text-dashboard" href="">
                    Permanent TSB
                  </a>
                </div>
                <div className="pt-2">
                  <a className="link-text-dashboard" href="">
                    An POST Money
                  </a>
                </div>
              </li>
              <li className="sub-content-dashboard pt-2">
                Set Interactive Brokers Ireland Ltd. up as a new beneficiary
              </li>
              <li className="sub-content-dashboard pt-2">
                Copy Interactive Brokers Ireland Ltd. IBAN details
              </li>
              <li className="sub-content-dashboard pt-2">
                Copy Interactive Brokers BIC Details
              </li>
              <li className="sub-content-dashboard pt-2">
                Please ensure to include the reference <b>UXXXXXXXX </b> to
                brokerage account number
              </li>
            </ol>
          </CardBody>
        </Card>
      </div>
      <div className="pt-4">
        <Card className=" dashboard-custom-card">
          <CardBody>
            <div className="dashboard-card-heading">
              Interactive Brokers Ireland Ltd. Bank Account Details
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">
                Account name:
              </div>
              <div className="col sub-content-dashboard">
                {depositDetails?.["Account Title"]}
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">Address:</div>
              <div className="col sub-content-dashboard">
                {depositDetails?.["Address"]}
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">Bank:</div>
              <div className="col sub-content-dashboard">
                {depositDetails?.["Beneficiary Bank"]}
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">IBAN:</div>
              <div className="col sub-content-dashboard">
                {depositDetails?.["IBAN"]}
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-3 dashboard-card-sub-heading">BIC:</div>
              <div className="col sub-content-dashboard">
                {depositDetails?.["BIC"]}
              </div>
            </div>
          </CardBody>
        </Card>
      </div> */}
    </div>
  );
};

export default Deposit;
