import React, { useEffect, useState } from 'react';
import './smartReserve2.scss';
import { Card, CardImg, Col, Row } from 'reactstrap';
import axios from 'axios';
function SmartReserve3() {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const [smartReservePortfolio, setSmartReservePortfolio] = useState([])

  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/smart-reserve-portfolios?populate=portfolio ,ImportantInfo,ImportantInfo.title,ImportantInfo.description,warningMessage,image1,Image2')
      .then(response => {
        const { data } = response;

        setSmartReservePortfolio(prev => ([...prev, ...data.data]));

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>
      <div className="contact-container-reserve" style={{ backgroundColor: 'transparent', fontFamily: 'Montserrat' }}>
        {Array.isArray(smartReservePortfolio) && smartReservePortfolio.map((portfolio: any) => {
          return (
            <>
              <div className="contact-container-reserve-div">
                <img
                  alt="Card image cap"
                  src="https://static.wixstatic.com/media/4f10d0_60afbbabaf4c48afba00d859f896ff11~mv2.jpg"
                  className="img-fluid"
                />
              </div>
              <Card inverse className="section-reserve " style={{ backgroundColor: 'transparent', position: 'relative' }}>
                <Row className="w-100 text-white align-items-center justify-content-center m-40">
                  <Col xs={12} lg={8} className="mx-auto text-center p-lg-5 bg-black">
                    <Row className="d-flex align-items-center " style={{ margin: '10px' }}>
                      <h3 style={{ color: '#FAFAFA' }}>{portfolio.attributes.heading}</h3>
                    </Row>
                    <div style={{ textAlign: 'left', fontWeight: '300', fontFamily: 'Montserrat' }}>
                      <p>
                        {portfolio?.attributes?.description}
                      </p>
                      {portfolio?.attributes?.portfolio?.map((item) => {
                        return (

                          <>
                            <h3 style={{ fontWeight: '500', fontFamily: 'Montserrat', color: 'white', fontSize: '20px' }}>
                              {item?.title}
                            </h3>
                            <p style={{ fontWeight: '300', fontFamily: 'Montserrat' }}>
                              {item?.description}
                            </p>


                          </>
                        )
                      })}

                      < p className="text-center">Projected Return Profile</p>

                      <CardImg
                        onClick={toggle}
                        src={'https://starwealth-dev-strapie.livelocal.delivery' + (portfolio?.attributes?.Image2?.data?.attributes?.url || '')}
                      // src="https://static.wixstatic.com/media/4f10d0_bddf9acdb5ed43a7a152305bf0df9572~mv2.png/v1/crop/x_289,y_0,w_3227,h_1595/fill/w_1373,h_679,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/SSR3%25.png"
                      />
                      <br />
                      <br />
                      <br />


                      <div className="mt-60" style={{ marginTop: '30px' }}>
                        <Row>
                          <Col>
                            <img
                              // src={portfolio.attributes.image1.data.attributes.url}
                              src={'https://starwealth-dev-strapie.livelocal.delivery' + (portfolio?.attributes?.image1?.data?.attributes?.url || '')}
                              // src="https://static.wixstatic.com/media/4f10d0_740f77ce63d74a5489bca74229231090~mv2.jpg/v1/crop/x_96,y_29,w_1759,h_1499/fill/w_635,h_543,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/4f10d0_740f77ce63d74a5489bca74229231090~mv2.jpg"
                              alt="Image 1"
                              className="img-fluid"
                            />
                          </Col>
                          <Col>
                            <img
                              // src={portfolio.attributes.Image2.data.attributes.url}
                              src="https://static.wixstatic.com/media/4f10d0_563861bb80bf4af0865d49c3c289d82f~mv2.jpg/v1/crop/x_72,y_31,w_1687,h_1460/fill/w_628,h_543,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/4f10d0_563861bb80bf4af0865d49c3c289d82f~mv2.jpg"
                              alt="Image 2"
                              className="img-fluid"
                            />
                          </Col>
                        </Row>
                      </div>


                      <br />
                      <br />
                      {/* <h4 className="bigtech-des-head">{portfolio.attributes.title}</h4> */}
                      {portfolio?.attributes?.ImportantInfo?.map((item) => {
                        return (

                          <>
                            <h3 style={{ fontWeight: '500', fontFamily: 'Montserrat', color: 'white', fontSize: '20px' }}>
                              {item.title}
                            </h3>
                            {item.description.map((item) => {
                              return (


                                <p style={{ fontWeight: '300', fontFamily: 'Montserrat' }}>
                                  {item.description}
                                </p>

                              )
                            })}
                          </>
                        )
                      })}
                      {portfolio?.attributes?.warningMessage?.map((item) => {
                        return (
                          <div className="bigtech-warning-text">
                            <p className="bigtech-des-head-sub pt-2">
                              {item.description}
                            </p>

                          </div >
                        )
                      })}
                    </div>
                  </Col>
                </Row>
              </Card >
            </>
          )
        })}
      </div >

    </>
  );
}
export default SmartReserve3;
