import React, { useEffect, useState } from 'react'
import { AUTHORITIES } from 'app/config/constants';
import { ToastContainer, toast } from 'react-toastify';
import { useAppSelector } from 'app/config/store';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { Outlet, useLocation } from 'react-router-dom';




const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

const HomeIndex = () => {
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
    const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
    const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
    const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  
  const paddingTop = '60px';
  
  const path_location = useLocation();
  const [selectedNav, setSelectedNav] = useState(false);


  useEffect(() => {
    if (path_location.pathname === "/accounts") {
      setSelectedNav(true)
    }
    else if (path_location.pathname === "/company") {
      setSelectedNav(true)
    }
    else if (path_location.pathname === "/") {
      setSelectedNav(true)
    }
    else if (path_location.pathname === "/user-profile") {
      setSelectedNav(true)
    }
    else if (path_location.pathname === "/terms-and-disclosures") {
      setSelectedNav(true)
    }
    else {
      setSelectedNav(false)
    }
  }, [path_location.pathname]);

  
  return (
    <div className="app-container" style={{ paddingTop }}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <ErrorBoundary>
            <Header
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
          /> 
     
        </ErrorBoundary>
      <div className={` container-fluid view-container ${
          selectedNav ? "white-bg-body-custom" : ""
        }`}
        id="app-view-container">
              <Outlet/>
          {isAuthenticated &&  <Footer />}
         
         </div>
 
         
 
       </div>
 
 
  )
}

export default HomeIndex