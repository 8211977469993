// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainer {
  width: 100%;
  margin-bottom: 1px;
}

.errorTextContainer {
  text-align: start;
  margin-top: 5px;
}

.errorText {
  padding: 2px;
  border: none;
  outline: none;
  color: red;
  font-weight: bold;
  font-size: 11px;
  font-family: "Montserrat";
}

.hasError {
  width: 100%;
  border-color: red;
  border-radius: 5px;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  color: #000;
  outline: none;
  background-color: #E8F0FE;
}

.inputStyle {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #000;
  background-color: #E8F0FE;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
}

.validated-field1 {
  font-weight: 400;
  font-size: 15px;
  font-family: "Montserrat";
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/components/CustomInput/customInput.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;EACA,iBAAA;EACA,eAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;AAEF;;AACE;EACE,gBAAA;EACA,eAAA;EACA,yBAAA;AAEJ","sourcesContent":[".inputContainer {\n  width: 100%;\n  margin-bottom: 1px;\n}\n\n.errorTextContainer {\n  text-align: start;\n  margin-top: 5px;\n}\n\n.errorText {\n  padding: 2px;\n  border: none;\n  outline: none;\n  color: red;\n  font-weight: bold;\n  font-size: 11px;\n  font-family: 'Montserrat';\n}\n\n.hasError {\n  width: 100%;\n  border-color: red;\n  border-radius: 5px;\n  font-family: 'Montserrat';\n  font-size: 15px;\n  font-weight: 400;\n  color: #000;\n  outline: none;\n  background-color: #E8F0FE;\n}\n.inputStyle{\n  width: 100%;\n  border: none;\n  outline: none;\n  border-radius: 5px;\n  color: #000;\n  background-color: #E8F0FE;\n  font-family: 'Montserrat';\n  font-size: 15px;\n  font-weight: 400;\n}\n\n  .validated-field1 {\n    font-weight: 400;\n    font-size: 15px;\n    font-family: 'Montserrat';\n  }\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
