export interface ContactForm {
    firstname: string;
    lastname: string;
    email: string;
    message: string;
    consent: boolean;
    error: string | null;
}

export const defaultValue: Readonly<ContactForm> = {
    consent: false,
    firstname: "",
    lastname: "",
    email: "",
    message: "",
    error: null // Remove the error property from here
};


