// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-td {
  position: relative;
  padding-right: 30px; /* Ensure space for the icon */
}

.pencil-icon {
  /* display: none; 
  position: absolute;
  right: 10px; 
  top: 20%; */
  transform: translateY(-50%); /* Center the icon vertically */
  cursor: pointer; /* Change the cursor to a pointer */
}

/* .table-td:hover .pencil-icon {
  display: inline; 
} */
.new-date-box {
  border-color: 1px solid #607980;
}

.ok-button-disabled:disabled,
.ok-button-disabled.disabled {
  background-color: gray !important;
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.date-picker-container {
  color: black;
  font-weight: 400;
}

.refresh-admin-profile {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/admin-profile/adminProfile.css"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA,EAAA,8BAAA;AACF;;AAEA;EACE;;;aAAA;EAIA,2BAAA,EAAA,+BAAA;EACA,eAAA,EAAA,mCAAA;AACF;;AAEA;;GAAA;AAIA;EACE,+BAAA;AAAF;;AAGA;;EAEE,iCAAA;EACA,8BAAA;EACA,8BAAA;AAAF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".table-td {\n  position: relative;\n  padding-right: 30px; /* Ensure space for the icon */\n}\n\n.pencil-icon {\n  /* display: none; \n  position: absolute;\n  right: 10px; \n  top: 20%; */\n  transform: translateY(-50%); /* Center the icon vertically */\n  cursor: pointer; /* Change the cursor to a pointer */\n}\n\n/* .table-td:hover .pencil-icon {\n  display: inline; \n} */\n\n.new-date-box {\n  border-color: 1px solid #607980;\n}\n\n.ok-button-disabled:disabled,\n.ok-button-disabled.disabled {\n  background-color: gray !important;\n  cursor: not-allowed !important;\n  pointer-events: all !important;\n}\n.date-picker-container{\n  color: black;\n  font-weight: 400;\n}\n\n.refresh-admin-profile {\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
