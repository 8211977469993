import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  Table,
} from 'reactstrap';

import './customstyles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

export const StarlightSmartInvest = () => {
  //-------------------------------------------------------------------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [smartInvest, setSmartInvest] = useState([])
  useEffect(() => {
    // axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/accounts')
    axios.get('https://starwealth-dev-strapie.livelocal.delivery/api/starlight-smart-invests?populate=Advantage,Advantage.image,Advantage,title,Advantage.description,HowItWork,HowItWork.number,HowItWork.title,HowItWork.description,Content,portfolioData,portfolioData.Head,portfolioData.Portfolio,portfolioData.Portfolio.Image')
      .then(response => {
        const { data } = response;
        setSmartInvest(prev => ([...prev, ...data.data]));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <div>
      {Array.isArray(smartInvest) && smartInvest.map((smart: any) => {
        return (
          <Row>

            <Col md="12" className="smartinvest-first-bg">
              <div className="smartinvest-overlay-bg-black">
                {/* -------------------------first div---------------------------------- */}

                <Row className="d-flex justify-content-center">
                  <Card className="card-smartinvest-custom-first mt-5">
                    <CardBody>
                      <CardTitle className="first-heading-card-port">{smart.attributes.heading}</CardTitle>
                      <CardText className="second-heading-card-port mt-3">{smart.attributes.subHead}</CardText>
                      {smart?.attributes?.Content.map((item) => {
                        return (


                          <Col className="content-style-smartinvest-first mt-5 me-3">
                            <CardText>
                              {item.description}
                            </CardText>

                          </Col>
                        )
                      })}
                    </CardBody>
                  </Card>
                </Row>

                {/* -------------------------first div---------------------------------- */}

                {/* -------------------------second div---------------------------------- */}
                <Row className="d-md-flex justify-content-md-center pt-5">

                  <Card className="card-smartinvest-custom-second">
                    <CardBody>

                      <Row className="mb-4">
                        {smart.attributes.Advantage.map((item) => {
                          return (
                            <Col xxl="3" xl="4" md="4" className="pt-3">
                              <Card className="back-bg-card-second-card">
                                <CardBody>
                                  <div>
                                    {/* {item.image.map((item) => {
                                      return ( */}


                                    <img
                                      src={'https://starwealth-dev-strapie.livelocal.delivery' + (item.image.data.attributes.url || '')}
                                      // src="https://static.wixstatic.com/media/11062b_6856f2dda2bb4b679834f24c8a8f72fa~mv2.jpg/v1/crop/x_1095,y_0,w_1849,h_2166/fill/w_429,h_503,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Abstract%20Lines.jpg"
                                      alt=""
                                      style={{ height: '400px', width: '100%' }}
                                    />
                                    {/* )
                                    })} */}
                                  </div>
                                  <div className="bg-black style-position-text p-3">
                                    <CardTitle tag="h5" className="content-style-smartinvest-overlay-heading">
                                      {item.title}
                                    </CardTitle>
                                    <CardText className="content-style-smartinvest-overlay mt-3">
                                      {item.description}
                                    </CardText>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                        })}
                      </Row>

                    </CardBody>
                  </Card>

                </Row>
                {/* -------------------------second div---------------------------------- */}
              </div>
            </Col>
            <Col className="smartinvest-second-bg" md="12">
              {/* -------------------------third div---------------------------------- */}

              <Row className="d-md-flex justify-content-md-center pt-3">

                {smart?.attributes?.portfolioData?.map((item) => {

                  return (
                    <>
                      <h2 className="pt-5">{item?.Head}</h2>
                      {item?.Portfolio?.map((item) => {
                        return (


                          <Col xl="6" xxl="6" id="Bellwether">
                            <Card className="card-portfolios-width card-portfolios-bg-one pt-3 mt-3">
                              <CardBody>
                                <div>
                                  <img
                                    src={'https://starwealth-dev-strapie.livelocal.delivery' + (item?.Image?.data?.attributes?.url || '')}
                                   
                                    alt=""
                                    style={{ height: '450px', width: '100%' }}
                                  />
                                </div>
                                <div className="bg-black style-position-text-port">
                                  <div className="ps-md-5 p-md-3">
                                    <CardTitle tag="h5">{item.title}</CardTitle>
                                    <CardText className="content-style-smartinvest-overlay-port">
                                      {item.description}
                                    </CardText>
                                    <Row>
                                      <Col md="12" className=" readmore-button-footer">
                                        <Link
                                          to={
                                            item.title === "Bellwether"
                                              ? "/StarlightSmartInvest/bellwether"
                                              : item.title === "Big Tech"
                                                ? "/StarlightSmartInvest/big-tech"
                                                : item.title === "Euroscape"
                                                  ? "/StarlightSmartInvest/euroscape"
                                                  : item.title === "Stars & Stripes"
                                                    ? "/StarlightSmartInvest/stars&strips" // Changed to a different URL for Stars & Stripes
                                                    : null
                                          }
                                          className="read-btn"
                                        >
                                          Read More
                                        </Link>


                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col >

                        )
                      })}
                    </>
                  )
                })}


              </Row>

              {/* -------------------------third div---------------------------------- */}
              {/* -------------------------forth div---------------------------------- */}


              <Row className="pt-3" id="How-it-works">

                <h2>{smart?.attributes?.subTitle1}</h2>
                <div className="bg-end-smartinverst pt-5">

                  <>
                    <Row className="height-div-how-work">

                      {smart?.attributes?.HowItWork.map((item) => {
                        return (
                          <Col md="4" className="mb-5">
                            <Card className="card-how-works ">
                              <div className="bottam-head-overlay pt-2">
                                <div className="p-bottam-head-overlay">{item.number}</div>
                              </div>
                              <CardBody className="bg-black padding-bottom-card-overlay">
                                <CardTitle tag="h5" className="font-bottom-cardtitle">
                                  {item.title}
                                </CardTitle>
                                <CardText className="font-bottom-cardtext mt-4">
                                  {item.description}
                                </CardText>
                              </CardBody>
                            </Card>
                          </Col>

                        )
                      })}
                    </Row>
                  </>

                </div>

              </Row>
              <Row>
                <Col md="12" className="mb-3 mt-3">
                  <Link to="/customer" className="open-new">
                    <Button className="px-5 all-btn-style underline-none">Open Smart</Button>
                  </Link>
                </Col>
              </Row>
              {/* -------------------------forth div---------------------------------- */}
            </Col>
          </Row >
        )
      })}
    </div >
  );
};

export default StarlightSmartInvest;
